import {Box, Flex, Heading, Text, Button, GridItem, Grid} from "@chakra-ui/react";
import moment from "moment";
import {TextareaLabel} from "../../../../components/Theme";
import {ReactComponent as Arrow} from "../../../../assets/arrowRightUp.svg";
import {ReactComponent as Pending} from "../../../../assets/pendingIcon.svg";
import {ReactComponent as Fulfilled} from "../../../../assets/fulfiled.svg";
import {useUser} from "../../../../core/hooks";

const ShippingMain = ({estimate,companySu, luminaires, setShippingPageStatus, setTrackId, onOpenDelivered}) => {
    const {user} = useUser()
    return (
        <>
            <Flex justifyContent='space-between'>
                <img src="/images/LogoIcon.svg" alt=""/>
                <Flex flexDirection='column'>
                    <Heading m={0} textAlign='end'>Shipping Overview</Heading>
                    <Text textAlign='end'>Reference #: {estimate?.reference}</Text>
                    <Text textAlign='end'>Date : {moment(estimate?.shippingDateCreated?.date).format('MM/DD/YYYY')}</Text>
                </Flex>
            </Flex>
            {estimate?.estimateSu && (
                <Flex justifyContent='space-between' mt={4}>
                    <Flex width='49%' borderRight='2px solid #9C9C9C' flexDirection='column'>
                        <Box as='h2' m={0} mb={3} backgroundColor='#EEEEEE' width='98%' borderRadius='5px' fontSize='18px' pl={4} pt={2} pb={2} color='#222222' fontWeight='700'>
                            Sender Information:
                        </Box>
                        {
                            user?.type === 'SUPERADMIN' ? (
                                    estimate?.estimateSu?.length > 1 ? (
                                        <Box bg='#ECECEC' width='98%' borderRadius='5px' p={4} height='100%'>
                                            <Heading as='h2' m={0} fontSize='xl' fontStyle='italic'>
                                                Multiple Senders
                                            </Heading>
                                            <Text fontSize='12px'>
                                                Review Shipping Details For Sender Information
                                            </Text>
                                        </Box>
                                    ) : (
                                        <Box fontSize='xl' width='98%'>
                                        <Text>{companySu?.name}</Text>
                                        <Text>Attn: {estimate?.estimateSu[0]?.userSu?.firstName} {estimate?.userSu?.lastName}</Text>
                                        <Text>{companySu?.address}</Text>
                                        <Text>{companySu?.address2}</Text>
                                        <Text>{companySu?.city}{companySu?.state ? ', ' + companySu?.state : ''}{companySu?.zipCode ? ', '+companySu?.zipCode : ''}</Text>
                                        <Text>USA</Text>
                                        <Text>{estimate?.estimateSu[0]?.userSu?.phone}</Text>
                                        </Box>
                                    )
                            ) : (
                                <Box fontSize='xl' width='98%'>
                                    <Text>Focus Earth</Text>
                                    <Text>Attn: Pete Musser</Text>
                                    <Text>1070 Tunnel Road, Building 1, Suite 10, #202</Text>
                                    <Text></Text>
                                    <Text>Asheville, NC 28805</Text>
                                    <Text>USA</Text>
                                    <Text>(925) 323-0053</Text>
                                </Box>
                                )
                        }
                    </Flex>
                    <Flex width='50%' flexDirection='column' fontSize='18px'>
                        <Box as='h2' mt={0} mb={3} backgroundColor='#EEEEEE' width='100%' borderRadius='5px' fontSize='18px' pl={4} pt={2} pb={2} color='#222222' fontWeight='700'>
                            Recipient Information:
                        </Box>
                     <Box fontSize='xl'>
                         <Text>{estimate?.userReu?.company?.length > 0 ? estimate?.userReu?.company[0]?.name : estimate?.userReu?.associatedToCompany ? estimate?.userReu?.associatedToCompany?.name : ''}</Text>
                         <Text>Attn : {estimate?.userReu?.firstName + ' ' + estimate?.userReu?.lastName}</Text>
                         <Text>{estimate?.report?.project?.address}</Text>
                         <Text>{estimate?.report?.project?.address2}</Text>
                         <Text>{estimate?.report?.project?.city + ', ' + estimate?.report?.project?.state + ', ' + estimate?.report?.project?.zipCode}</Text>
                         <Text>USA</Text>
                         <Text>{estimate?.userReu?.phone}</Text>
                     </Box>
                    </Flex>
                </Flex>
            )}
            <Box backgroundColor='#222222' width='100%' fontSize='18px' fontWeight='700' color='white' pl={4} pt={2} pb={2} borderRadius='5px' mt={8}>
                Shipping Information
            </Box>
            <Grid templateColumns='repeat(6, 1fr)' my={5} px={4}>
                <GridItem colSpan={3} as='h2' m={0}>
                    Description
                </GridItem>
                <GridItem colSpan={1} as='h2' m={0} textAlign='center'>
                    Part Number
                </GridItem>
                <GridItem colSpan={1} as='h2' m={0} textAlign='center'>
                    QTY
                </GridItem>
                <GridItem colSpan={1} as='h2' m={0} textAlign='center'>
                    Status
                </GridItem>
            </Grid>
            {luminaires?.map((item, index) =>
                        <Grid templateColumns='repeat(6, 1fr)' my={5} px={4} key={index} border='1px solid #A9A9A9' borderRadius='6px' minH='64px' alignItems='center' mb={5}>
                            <GridItem colSpan={3}>
                                {item?.lampRecomendedAirtable?.length > 0 ? JSON.parse(item.lampRecomendedAirtable)['Description'] : item?.fixtureRecomendedAirtable?.length > 0 ? JSON.parse(item.fixtureRecomendedAirtable)['FE Product Description'] : ''}
                            </GridItem>
                            <GridItem colSpan={1} textAlign='center'>
                                {item?.lampRecomendedAirtable?.length > 0 ? JSON.parse(item.lampRecomendedAirtable)['FE Order Code'] : item?.fixtureRecomendedAirtable?.length > 0 ? JSON.parse(item.fixtureRecomendedAirtable)['FE Order Code'] : ''}
                            </GridItem>
                            <GridItem colSpan={1} textAlign='center'>
                                {((item.recomendedLampQty ? Number(item.recomendedLampQty) : (item.lampPerFixture ? item.lampPerFixture : 1)) * (item.recomendedFixtureQty ? Number(item.recomendedFixtureQty) : (item.qty ? Number(item.qty) : 1))).toLocaleString('en-US')}
                            </GridItem>
                            <GridItem colSpan={1} textAlign='center'>
                                {
                                    estimate?.estimateSu.find(shipDetail => shipDetail?.shippingDetails?.find(detail =>
                                        detail?.luminaires?.find(led => led.id === item.id && ((item.recomendedLampQty ? Number(item.recomendedLampQty) : (item.lampPerFixture ? Number(item.lampPerFixture) : 1)) * (item.recomendedFixtureQty ? Number(item.recomendedFixtureQty) : (item.qty ? Number(item.qty) : 1))) <= Number(led?.qtyShippingDetails) && detail.status === 2)
                                    )) ? 'Delivered' : 'Pending'
                                }
                            </GridItem>
                        </Grid>
                )}
            <TextareaLabel minH='56px' backgroundColor='#EEEEEE !important' disabled label='Notes'/>
            <Box backgroundColor='#FF9A7A' width='100%' fontSize='18px' fontWeight='700' pl={4} pt={2} pb={2} borderRadius='5px' mt={10}>
                Shipping Details
            </Box>
            <Flex mt={7} mb={7} fontWeight='600'>
                <Text ml={2} width='50%'>Shipping Reference #</Text>
                <Text width='25%'>Created</Text>
                <Text width='25%'>Tracking Link</Text>
                <Text width='25%' mr={2}>Status</Text>
            </Flex>
            {estimate?.estimateSu?.map((ships) => ships?.shippingDetails?.map((item, idx) =>
                    <>
                        <Flex cursor='pointer' mb={4} key={idx} backgroundColor='#EEEEEE' border='1px solid #A9A9A9' borderRadius='6px' minH='64px' alignItems='center'>
                            <Text
                                onClick={() => {
                                    setTrackId(item?.shippingReference)
                                    return setShippingPageStatus(3)
                                }}
                                ml={2} width='50%'>{item?.shippingReference}</Text>
                            <Text
                                onClick={() => {
                                    setTrackId(item?.shippingReference)
                                    return setShippingPageStatus(3)
                                }}
                                width='25%'>{moment(item?.createdAt?.date).format('MM.DD.YYYY')}</Text>
                            <Text width='25%'>
                                <Button
                                    onClick={() => {
                                        window.open((item?.senderTrackingLink && !item?.senderTrackingLink.startsWith("https://")) ? `https://${item?.senderTrackingLink}` : item?.senderTrackingLink)
                                    }}
                                    rightIcon={<Arrow/>}
                                    color='black'
                                    bg='#FFE68D'>
                                    Track
                                </Button>
                            </Text>
                            <Text width='25%' mr={2}>
                                <Button
                                    onClick={() => {
                                        setTrackId(item?.shippingReference)
                                        onOpenDelivered()
                                    }}
                                    _hover='' _active='' _focus='' border={item?.status === 1 ? '1px solid #FF7F56' : '1px solid #8FCF74'} leftIcon={item?.status === 1 ? <Pending/> : <Fulfilled/>} color='black' bg='transparent'>
                                    {item?.status === 1 ? ('Pending') : item?.status === 2 ? ('Delivered') : ('')}
                                </Button>
                            </Text>
                        </Flex>
                    </>
                ))
            }
        </>
    )
}

export default ShippingMain