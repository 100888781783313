import {Box, Button, Flex, HStack, VStack, FormControl, Image, Text} from "@chakra-ui/react";
import CustomSelectCompanies from "../CustomSelectCompanies";
import {useEffect, useRef, useState} from "react";
import {ReactComponent as UploadIcon} from "../../../assets/uploadNewPhotoIcon.svg";
import {ReactComponent as SyncIcon} from "../../../assets/Icons/syncIcon.svg";
import {S3_BUCKET} from "../../../constants";
import {useUpload} from "../../../core/hooks";
import TextLabel from "../../../components/Theme/TextLabel";

const Header = (props) => {
    const [avatar, setAvatar] = useState(props?.image ? S3_BUCKET + props?.image : '')
    const {upload, isLoading} = useUpload()
    const inputRef = useRef()

    const handleAvatar = (e) => {
        const file = e.target.files[0]
        const blob = URL.createObjectURL(file)
        const type = file.type.split('/')[1] || 'jpeg'
        const name = `${new Date().getTime() * Math.random()}.${type}`
        setAvatar(blob)
        upload({name, file, type,},
            {onSuccess: (data) => props?.setValue('image', data.data.data)}
        )
    }

    useEffect(() => {
        setAvatar(props?.image ? S3_BUCKET + props?.image : '')
    }, [props?.image])

    return (
        <HStack spacing={8} justifyContent='space-between'>
            <HStack spacing={8}>
                <Flex alignItems="center" justifyContent="center" overflow="hidden" border="2px solid #DBDADA" width="106px" height="101px">
                    {avatar && (
                        <Image
                            src={avatar}
                            alt="company avatar"
                            height='100%'
                            objectFit="cover"/>
                    )}
                    <input value={undefined} onChange={handleAvatar} type="file" style={{opacity: '0'}} accept="image/*" ref={inputRef}/>
                </Flex>
                <VStack>
                    <TextLabel
                        minW='468px'
                        fontSize='3xl'
                        fontWeight='600'
                        text={props?.company?.name}/>
                    <FormControl>
                        <input value={undefined} onChange={handleAvatar} type="file" style={{display: 'none'}} accept="image/*" ref={inputRef}/>
                        <Button
                            width="180px"
                            height="40px"
                            leftIcon={<UploadIcon/>}
                            onClick={() => inputRef.current.click()}
                            variant="green">
                            {isLoading ? 'Processing...' : 'Upload Logo'}
                        </Button>
                    </FormControl>
                </VStack>
            </HStack>
            <VStack>
                <HStack spacing={8} w='500px'>
                    <Text fontSize='xl' whiteSpace='nowrap'>
                        Company Type:
                    </Text>
                    <CustomSelectCompanies
                        setValue={props?.setCompanySubType}
                        value={props?.companySubType}
                        options={props?.companyTypes}/>
                </HStack>
                <HStack w='full' justifyContent='end' spacing={5}>
                    <Button
                        onClick={props?.onOpenSync}
                        leftIcon={<SyncIcon/>}
                        w="173px"
                        bg='#FFE68D'
                        color='black'>
                        Sync Company
                    </Button>
                    <Button
                        onClick={props?.onOpen}
                        w="173px"
                        leftIcon={<Text fontSize='16px'>+</Text>}
                        variant="green">
                        Add New User
                    </Button>
                </HStack>
            </VStack>
        </HStack>
    )
}

export default Header