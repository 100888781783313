import {Box, Flex, Heading, IconButton, Text, useDisclosure, useToast} from "@chakra-ui/react";
import moment from "moment/moment";
import {InputLabel, TextareaLabel} from "../../../../components/Theme";
import React, {useEffect, useState} from "react";
import {ReactComponent as DeleteLum} from "../../../../assets/del.svg";
import {Checkbox} from '@chakra-ui/react'
import styles from "./styles";
import DeleteShippingDetailModal from "../../EstimateModals/deleteShippingDetailModal";
import httpRequest from "../../../../core/services/http-request";
import {ReactComponent as SendMessage} from "../../../../assets/unfinishedIcon.svg";
import InputMask from "react-input-mask";
import ToastComponent from "../../../../components/toastComponent/ToastComponent";

const ShippingCreateDetails = ({
                                   estimate,
                                   companyReu,
                                    companySu,
                                   luminaires,
                                   callback,
                                   setShippingPageStatus,
                                   createShippingAllow,
                                   setCreateShippingAllow,
                                   setLoading,
                                   getEstimate
                               }) => {
    const [luminairesForDetails, setLuminairesForDetails] = useState(luminaires)
    const {post} = httpRequest()
    const toast = useToast()
    const [error, setError] = useState(false)
    const {isOpen, onOpen, onClose} = useDisclosure()
    const [info, setInfo] = useState({
        senderCompanyName: '',
        senderTrackingLink: 'Tracking Link',
        senderAddress1: '',
        senderAddress2: '',
        senderCity: '',
        senderState: '',
        senderZipCode: '',
        senderPhone: '',
        receiverCompanyName: companyReu?.name,
        receiverAddress1: companyReu?.address,
        receiverAddress2: companyReu?.address2,
        receiverCity: companyReu?.city,
        receiverState: companyReu?.state,
        receiverZipCode: companyReu?.zipCode,
        senderCountry: 'USA',
        receiverCountry: companyReu?.country,
        receiverPhone: estimate?.userReu?.phone,
        receiverName: estimate?.userReu?.firstName + ' ' + estimate?.userReu?.lastName,
    })

    useEffect(() => {
        if (createShippingAllow) {
            let leds = []
            for (let i = 0; i < luminairesForDetails?.length; i++) {
                if (luminairesForDetails[i]?.error){
                    return
                }
                if (luminairesForDetails[i]?.isChecked){
                    if (!luminairesForDetails[i].qtyShippingDetails){
                        let qty = (luminairesForDetails[i].recomendedLampQty ? Number(luminairesForDetails[i].recomendedLampQty) : (luminairesForDetails[i].lampPerFixture ? Number(luminairesForDetails[i].lampPerFixture) : 1)) * (luminairesForDetails[i].recomendedFixtureQty ? Number(luminairesForDetails[i].recomendedFixtureQty) : (luminairesForDetails[i].qty ? Number(luminairesForDetails[i].qty): 1))
                        luminairesForDetails[i].qtyShippingDetails = qty;
                    }

                    leds.push(luminairesForDetails[i])
                }
            }
            let data = {
                estimateId: estimate.id,
                luminaires: leds,
                data: info,
            }
            post('create-shipping-detail', data).then(() => {
                setShippingPageStatus(1)
                getEstimate()
                setLoading(false)
                toast({
                    render: () => (
                        <ToastComponent title='Shipping Detail Created!' bg='#8FCF74' icon={<SendMessage/>}/>
                    ),
                    duration: 4000,
                    isClosable: true,
                    position: "top-right"
                })
            }).catch(() => {
                setLoading(false)
            }).then(() => {

            })
            setCreateShippingAllow(false)
        }
    }, [createShippingAllow])

    return (
        <>
            <Flex
                position='relative'
                justifyContent='space-between'>
                <DeleteShippingDetailModal
                    getEstimate={getEstimate}
                    setShippingPageStatus={setShippingPageStatus}
                    onClose={onClose}
                    isOpen={isOpen}/>
                <IconButton
                    onClick={onOpen}
                    height="30px"
                    minW="30px"
                    position="absolute"
                    top="-45px"
                    left="-45px"
                    borderRadius="5px"
                    bg="#FB7575"
                    aria-label="del"
                    icon={<DeleteLum width="15px" height="15px"/>}/>
                <img
                    src="/images/LogoIcon.svg"
                    alt=""/>
                <Flex
                    flexDirection='column'>
                    <Heading
                        m={0}
                        textAlign='end'>
                        Shipping Detail
                    </Heading>
                    <Text textAlign='end'>
                        Reference #: {estimate?.reference}
                    </Text>
                    <Text
                        textAlign='end'>
                        Date : {moment(estimate?.shippingDateCreated?.date).format('MM/DD/YYYY')}
                    </Text>
                </Flex>
            </Flex>
            <Flex
                mt={5}
                justifyContent='space-between'>
                <Flex
                    width='49%'
                    borderRight='2px solid #9C9C9C'
                    flexDirection='column'>
                    <Box as='h2' m={0} mb={3} backgroundColor='#EEEEEE' width='98%' borderRadius='5px' fontSize='18px' pl={4} pt={2} pb={2} color='#222222' fontWeight='700'>
                        Sender Information:
                    </Box>
                    <InputLabel
                        maxW='320px'
                        placeholder={companySu?.name}
                        onChange={(e) => setInfo({...info, senderCompanyName: e.target.value})}
                        />
                    <InputLabel
                        maxW='320px'
                        placeholder={'Tracking Link'}
                        onChange={(e) => setInfo({...info, senderTrackingLink: e.target.value})}
                        />
                    <InputLabel
                        maxW='320px'
                        placeholder={companySu?.address}
                        onChange={(e) => setInfo({...info, senderAddress1: e.target.value})}
                        />
                    <InputLabel
                        maxW='320px'
                        placeholder={companySu?.address2}
                        onChange={(e) => setInfo({...info, senderAddress2: e.target.value})}
                        />
                    <Flex
                        justifyContent='space-between'
                        width='320px'>
                        <InputLabel
                            maxW='150px'
                            placeholder={companySu?.city}
                            onChange={(e) => setInfo({...info, senderCity: e.target.value})}
                            />
                        <InputLabel
                            maxW='150px'
                            placeholder={companySu?.state}
                            onChange={(e) => setInfo({...info, senderState: e.target.value})}
                            />
                    </Flex>
                    <Flex
                        justifyContent='space-between'
                        width='320px'>
                        <InputLabel
                            maxW='150px'
                            placeholder={companySu?.zipCode}
                            onChange={(e) => setInfo({...info, senderZipCode: e.target.value})}
                            />
                        <InputLabel
                            maxW='150px'
                            placeholder={companySu?.country}
                            onChange={(e) => setInfo({...info, senderCountry: e.target.value})}
                            />
                    </Flex>
                    <InputLabel
                        mask="999-999-9999"
                        maskChar=""
                        autoComplete="on"
                        placeholder={estimate?.userSu?.phone}
                        as={InputMask}
                        formatChars={{'9': '[0-9]'}}
                        maxW='320px'
                        onChange={(e) => setInfo({...info, senderPhone: e.target.value})}
                        />
                </Flex>
                <Flex
                    width='50%'
                    flexDirection='column'>
                    <Box as='h2' mt={0} mb={3} backgroundColor='#EEEEEE' width='100%' borderRadius='5px' fontSize='18px' pl={4} pt={2} pb={2} color='#222222' fontWeight='700'>
                        Recipient Information:
                    </Box>
                    <InputLabel
                        maxW='320px'
                        readOnly={true}
                        placeholder={info?.receiverCompanyName}
                        />
                    <InputLabel
                        maxW='320px'
                        readOnly={true}
                        placeholder={info?.receiverName}
                        />
                    <InputLabel
                        maxW='320px'
                        readOnly={true}
                        placeholder={info?.receiverAddress1}
                        />
                    <InputLabel
                        maxW='320px'
                        readOnly={true}
                        placeholder={info?.receiverAddress2}
                        />
                    <Flex
                        justifyContent='space-between'
                        width='320px'>
                        <InputLabel
                            maxW='150px'
                            readOnly={true}
                            placeholder={info?.receiverCity}
                            />
                        <InputLabel
                            maxW='150px'
                            readOnly={true}
                            placeholder={info?.receiverState}
                            />
                    </Flex>
                    <Flex
                        justifyContent='space-between'
                        width='320px'>
                        <InputLabel
                            readOnly={true}
                            maxW='150px'
                            placeholder={info?.receiverZipCode}
                            />
                        <InputLabel
                            readOnly={true}
                            placeholder={info?.receiverCountry}
                            maxW='150px'
                            />
                    </Flex>
                    <InputLabel
                        maxW='320px'
                        mask="999-999-9999"
                        readOnly={true}
                        maskChar=""
                        placeholder={info?.receiverPhone}
                        autoComplete="on"
                        as={InputMask}
                        formatChars={{'9': '[0-9]'}}
                        />
                </Flex>
            </Flex>
            <Box as='h2' backgroundColor='#FF9A7A' width='100%' fontSize='18px' fontWeight='700' pl={4} pt={2} pb={2} borderRadius='5px' mt={10}>
                Shipping Information
            </Box>
            <Flex mt={7} mb={7} fontWeight='600'>
                <Text as='h2' m={0} width='10%'>Select</Text>
                <Text as='h2' m={0} ml={2} width='40%'>Description</Text>
                <Text as='h2' m={0} width='30%'>Part Number</Text>
                <Text as='h2' m={0} width='20%'>QTY</Text>
            </Flex>
            {
                luminairesForDetails?.map((item, index) =>
                    <Flex mb={4} key={index} backgroundColor='#EEEEEE' border='1px solid #A9A9A9' borderRadius='6px' minH='64px' alignItems='center'>
                        <Text width='10%'>
                            <Checkbox
                                css={styles.checkbox}
                                onChange={(e) => {
                                    luminairesForDetails[index].isChecked = e.target.checked
                                    setLuminairesForDetails(luminairesForDetails)
                                    callback(luminairesForDetails)
                                }}
                                size='lg'
                                iconColor='black'/>
                        </Text>
                        <Text ml={2} pr={5} width='40%'>
                            {item?.lampRecomendedAirtable?.length > 0 ? JSON.parse(item.lampRecomendedAirtable)['Description'] : item?.fixtureRecomendedAirtable?.length > 0 ? JSON.parse(item.fixtureRecomendedAirtable)['FE Product Description'] : ''}
                        </Text>
                        <Text width='30%'>
                            {item?.lampRecomendedAirtable?.length > 0 ? JSON.parse(item.lampRecomendedAirtable)['Order Code'] : item?.fixtureRecomendedAirtable?.length > 0 ? JSON.parse(item.fixtureRecomendedAirtable)['Order Code'] : ''}
                        </Text>
                        <Box mb={2} width='20%'>
                            <InputLabel
                                maxW='50%'
                                type='number'
                                // isInvalid={luminairesForDetails[index]?.error}
                                onChange={(e) => {
                                    let maxQty = (item.recomendedLampQty ? Number(item.recomendedLampQty) : (item.lampPerFixture ? Number(item.lampPerFixture) : 1)) * (item.recomendedFixtureQty ? Number(item.recomendedFixtureQty) : (item.qty ? Number(item.qty): 1))
                                    let qty = e.target.value
                                    luminairesForDetails[index].error = false
                                    luminairesForDetails[index].qtyShippingDetails = qty;
                                    luminairesForDetails[index].availableQty = Number(maxQty) - Number(qty);
                                    setLuminairesForDetails(luminairesForDetails)
                                    return callback(luminairesForDetails)
                                }}
                                defaultValue={(luminairesForDetails[index].recomendedLampQty ? Number(luminairesForDetails[index].recomendedLampQty) : (luminairesForDetails[index].lampPerFixture ? Number(luminairesForDetails[index].lampPerFixture):1)) * (luminairesForDetails[index].recomendedFixtureQty ? Number(luminairesForDetails[index].recomendedFixtureQty) : (luminairesForDetails[index].qty ? Number(luminairesForDetails[index].qty): 1))}/>
                        </Box>
                    </Flex>
                )
            }
            <TextareaLabel
                minH='56px'
                backgroundColor='#EEEEEE !important'
                disabled
                label='Notes'/>
        </>
    )
}

export default ShippingCreateDetails