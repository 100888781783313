export const STATES = [
    'AL',
    'AK',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'FL',
    'GA',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'OH',
    'OK',
    'OR',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY',
]
export const AREAS = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
    'Puerto Rico'
]
export const AREAS_STATES = [
    {
        id: 1,
        name: 'Alabama',
        stateId: 'AL',
        child: [
            {name: 'Autauga', value: false, zipCode: [36066,36067,36022,36006,36003,36051]},
            {name: 'Baldwin', value: false, zipCode: [36535,36580,36507,36527,36576,36530,36562,36526,36564,36532,36555,36578,36511,36551,36567,36561,36549,36542,36547,36579,36559]},
            {name: 'Barbour', value: false, zipCode: [36374,36017,36027,36048,36016]},
            {name: 'Bibb', value: false, zipCode: [35042,35034,35184,35188,35111]},
            {name: 'Blount', value: false, zipCode: [35180,35121,35952,35031,35079,35049,35172,35133,35097,35013]},
            {name: 'Bullock', value: false, zipCode: [36053,36089]},
            {name: 'Butler', value: false, zipCode: [36033,36037,36456]},
            {name: 'Calhoun', value: false, zipCode: [36271,36265,36207,36277,36272,36260,36203,36250,36201,36206,36205]},
            {name: 'Chambers', value: false, zipCode: [36862,36863,36854,36855,36276,36852,36879]},
            {name: 'Cherokee', value: false, zipCode: [35983,35973,36272,35959,35960]},
            {name: 'Chilton', value: false, zipCode: [35085,36750,35045,35046,35171]},
            {name: 'Choctaw', value: false, zipCode: [36904,36919,36912,36915,36921,36916,36908]},
            {name: 'Clarke', value: false, zipCode: [36545,36784,36451,36446,36524,36482]},
            {name: 'Clay', value: false, zipCode: [35072,36267,36251,36266,36258]},
            {name: 'Cleburne', value: false, zipCode: [36262,36273,36264,36258,36272]},
            {name: 'Coffee', value: false, zipCode: [36467,36453,36351,36323,36330]},
            {name: 'Colbert', value: false, zipCode: [35646,35674,35654,35660,35616,35661]},
            {name: 'Conecuh', value: false, zipCode: [36432,36401,36475]},
            {name: 'Coosa', value: false, zipCode: [35136,36026,35010,35089,35072,35151,35183]},
            {name: 'Covington', value: false, zipCode: [36467,36420,36421,36442,36474,36476,36038,36455]},
            {name: 'Crenshaw', value: false, zipCode: [36035,36034,36009,36049,36028]},
            {name: 'Cullman', value: false, zipCode: [35077,35055,35058,35057,35179,35083,35019,35070,35016,35087]},
            {name: 'Dale', value: false, zipCode: [36322,36330,36303,36350,36352,36360,36371,36362,36311]},
            {name: 'Dallas', value: false, zipCode: [36767,36703,36701]},
            {name: 'DeKalb', value: false, zipCode: [35962,35988,35968,35986,35971,35978,35967,35989,35974,35981,35961,35984]},
            {name: 'Elmore', value: false, zipCode: [36093,36024,36078,36022,36020,36092,36025,36054]},
            {name: 'Escambia', value: false, zipCode: [36426,36441,36503,36502]},
            {name: 'Etowah', value: false, zipCode: [35952,35990,35903,35905,35901,35904,36272,35954,35972,35956,35906,35907]},
            {name: 'Fayette', value: false, zipCode: [35545,35555,35559,35594,35554,35546]},
            {name: 'Franklin', value: false, zipCode: [35581,35571,35593,35582,35653,35654,35585]},
            {name: 'Geneva', value: false, zipCode: [36340,36375,36301,36305,36318,36344,36314,36477]},
            {name: 'Greene', value: false, zipCode: [36740,35462,35443]},
            {name: 'Hale', value: false, zipCode: [35441,35474,36765,36744]},
            {name: 'Henry', value: false, zipCode: [36310,36319,36353,36345]},
            {name: 'Houston', value: false, zipCode: [36375,36320,36343,36376,36312,36319,36301,36305,36303,36345,36321]},
            {name: 'Jackson', value: false, zipCode: [35768,35776,35772,35764,35769,35752,35744,35740,35771,35755,35765]},
            {name: 'Jefferson', value: false, zipCode: [35216,35243,35242,35022,35020,35117,35118,35005,35064,35071,35119,35215,35213,35223,35224,35023,35210,35116,35130,35062,35173,35126,35127,35235,35221,35228,35214,35073,35036,35217,35068,35229,35209,35218,35211,35212,35222,35203,35207,35206,35205,35204,35208,35094,35254,35061,35234,35233,35249,35172,35091,35226,35244,35111,35006,35060]},
            {name: 'Lamar', value: false, zipCode: [35592,35544,35576,35586,35574,35552]},
            {name: 'Lauderdale', value: false, zipCode: [35652,35632,35633,35630,35610,35645,35634,35648,35677]},
            {name: 'Lawrence', value: false, zipCode: [35650,35672,35643,35618]},
            {name: 'Lee', value: false, zipCode: [36832,36865,36849,36830,36801,36804,36877]},
            {name: 'Limestone', value: false, zipCode: [35649,35739,35620,35611,35613,35647]},
            {name: 'Lowndes', value: false, zipCode: [36040,36032,36785,36752]},
            {name: 'Macon', value: false, zipCode: [36083,36088,36075,36866]},
            {name: 'Madison', value: false, zipCode: [35761,35773,35750,35759,35801,35803,35802,35805,35806,35741,35824,35763,35757,35756,35810,35811,35816,35896,35760,35749,35758,35808,35748]},
            {name: 'Marengo', value: false, zipCode: [36748,36732,36742,36763,36782,36764,36783,36738,36784]},
            {name: 'Marion', value: false, zipCode: [35581,35543,35570,35563,35594,35564,35548]},
            {name: 'Marshall', value: false, zipCode: [35957,35956,35175,35747,35016,35951,35950,35976,35980]},
            {name: 'Mobile', value: false, zipCode: [36611,36522,36572,36582,36619,36544,36509,36560,36512,36541,36663,36610,36613,36612,36521,36505,36528,36575,36652,36606,36607,36604,36605,36602,36603,36608,36609,36688,36615,36617,36618,36695,36693,36525,36571]},
            {name: 'Monroe', value: false, zipCode: [36445,36480,36460,36425,36481,36471,36439]},
            {name: 'Montgomery', value: false, zipCode: [36112,36113,36110,36111,36116,36114,36115,36109,36130,36117,36101,36105,36104,36107,36106,36057,36108,36064,36013]},
            {name: 'Morgan', value: false, zipCode: [35603,35601,35673,35621,35670,35640,35622]},
            {name: 'Perry', value: false, zipCode: [36756,36786]},
            {name: 'Pickens', value: false, zipCode: [35461,35442,35447,35481,35466]},
            {name: 'Pike', value: false, zipCode: [36035,36079,36081,36082,36005,36010]},
            {name: 'Randolph', value: false, zipCode: [36274,36280,36263,36276,36278]},
            {name: 'Russell', value: false, zipCode: [36870,36869,36860,36867]},
            {name: 'Shelby', value: false, zipCode: [35147,35078,35094,35115,35242,35114,35051,35178,35043,35143,35186,35124,35022,35080,35040,35176,35007]},
            {name: 'St. Clair', value: false, zipCode: [35146,35987,35173,35120,35112,35094,35004,35953,35125,35128,35135,35131]},
            {name: 'Sumter', value: false, zipCode: [35459,35460,35470,36901,36925,36907,35477,35464]},
            {name: 'Talladega', value: false, zipCode: [35150,35044,35032,36268,35151,35160,35096]},
            {name: 'Tallapoosa', value: false, zipCode: [36861,36850,36256,36853,36078,36866,35010,35072,36255]},
            {name: 'Tuscaloosa', value: false, zipCode: [35452,35188,35490,35473,35475,35476,35453,35444,35111,35404,35405,35406,35401,35487]},
            {name: 'Walker', value: false, zipCode: [35550,35549,35573,35578,35584,35063,35579,35554,35062,35148,35580,35130,35503,35504,35501]},
            {name: 'Washington', value: false, zipCode: [36583,36529,36518,36584,36553,36558,36556,36548,36539,36513,36560,36569]},
            {name: 'Wilcox', value: false, zipCode: [36726,36769,36723,36768,36766]},
            {name: 'Winston', value: false, zipCode: [35541,35575,35577,35553,35565,35540]},
        ]
    },
    {
        id: 2,
        name: 'Alaska',
        stateId: 'AK',
        child: [
            {name: 'Aleutians East', value: false, zipCode: [99571,99583,99553,99661,99612]},
            {name: 'Aleutians West', value: false, zipCode: [99546,99638,99547,99591,99660,99692,99685]},
            {name: 'Anchorage', value: false, zipCode: [99518,99515,99517,99516,99513,99540,99530,99567,99587,99508,99506,99507,99504,99505,99502,99503,99501,99577]},
            {name: 'Bethel', value: false, zipCode: [99552,99609,99557,99656,99589,99621,99651,99551,99614,99655,99668,99626,99559,99578,99690,99561,99679,99634,99622,99681,99641,99680,99630,99575,99607,99637]},
            {name: 'Bristol Bay', value: false, zipCode: [99633,99613,99670]},
            {name: 'Chugach', value: false, zipCode: [99574,99677,99693,99686]},
            {name: 'Copper River', value: false, zipCode: [99588,99573,99586,99780,99566]},
            {name: 'Denali', value: false, zipCode: [99755,99729,99743,99704,99744]},
            {name: 'Dillingham', value: false, zipCode: [99628,99576,99636,99555,99678,99580,99569]},
            {name: 'Fairbanks North Star', value: false, zipCode: [99712,99709,99701,99705,99702,99775,99714,99703,99725]},
            {name: 'Haines', value: false, zipCode: [99850,99827]},
            {name: 'Hoonah-Angoon', value: false, zipCode: [99829,99820,99825,99826,99827,99832,99841]},
            {name: 'Juneau', value: false, zipCode: [99824,99801]},
            {name: 'Kenai Peninsula', value: false, zipCode: [99568,99639,99610,99611,99669,99663,99664,99605,99603,99682,99672,99572,99556,99635,99631]},
            {name: 'Ketchikan Gateway', value: false, zipCode: [99901]},
            {name: 'Kodiak Island', value: false, zipCode: [99550,99615,99624,99644,99608,99643]},
            {name: 'Kusilvak', value: false, zipCode: [99650,99620,99581,99662,99666,99632,99658,99604,99585,99554,99563,99657]},
            {name: 'Lake and Peninsula', value: false, zipCode: [99613,99606,99549,99548,99653,99647,99640,99564,99625,99649,99565,99648,99579]},
            {name: 'Matanuska-Susitna', value: false, zipCode: [99654,99645,99652,99694,99676,99688,99674,99667,99623,99683]},
            {name: 'Nome', value: false, zipCode: [99771,99671,99762,99772,99753,99783,99739,99684,99785,99769,99784,99778,99659,99742]},
            {name: 'North Slope', value: false, zipCode: [99782,99723,99519,99734,99747,99789,99791,99766,99759,99721]},
            {name: 'Northwest Arctic', value: false, zipCode: [99727,99786,99770,99749,99763,99751,99752,99773,99761,99750,99736]},
            {name: 'Petersburg', value: false, zipCode: [99833]},
            {name: 'Prince of Wales-Hyder', value: false, zipCode: [99921,99926,99919,99922,99925,99918,99836,99923,99830]},
            {name: 'Sitka', value: false, zipCode: [99835]},
            {name: 'Skagway', value: false, zipCode: [99840]},
            {name: 'Southeast Fairbanks', value: false, zipCode: [99737,99732,99776,99764,99706,99780,99738]},
            {name: 'Wrangell', value: false, zipCode: [99903,99929]},
            {name: 'Yakutat', value: false, zipCode: [99689]},
            {name: 'Yukon-Koyukuk', value: false, zipCode: [99675,99691,99765,99754,99777,99558,99722,99788,99730,99724,99733,99748,99720,99760,99726,99602,99665,99590,99774,99746,99740,99745,99741,99756,99768,99627,99758,99767,99757,99701,99781,99790]},
        ]
    },
    {
        id: 3,
        name: 'Arizona',
        stateId: 'AZ',
        child: [
            {name: 'Apache', value: false, zipCode: [86556,85930,85936,86514,86503,86504,85920,86538,86505,86545,86511,86502,86535,86512,86515,86506,85938,86540,86547,86508,86507,85932,85940,85925]},
            {name: 'Cochise', value: false, zipCode: [85650,85635,85613,85626,85607,85615,85630,85616,85625,85603,85602,85632,85617,85620,85610,85638,85608,85643,85605]},
            {name: 'Coconino', value: false, zipCode: [86023,86040,86024,86046,86038,86022,86044,86320,86015,86001,86035,86045,86053,86004,86336,86011,86005,86018,86036,86047,86017,86020]},
            {name: 'Gila', value: false, zipCode: [85545,85539,85550,85541,85941,85501,85544,85192,85553,85554,85135,85542,85532]},
            {name: 'Graham', value: false, zipCode: [85546,85552,85530,85536,85543,85551,85531]},
            {name: 'Greenlee', value: false, zipCode: [85540,85533,85534]},
            {name: 'La Paz', value: false, zipCode: [85344,85346,85371,85325,85348,85334,85357,85328]},
            {name: 'Maricopa', value: false, zipCode: [85009,85003,85006,85007,85004,85083,85086,85087,85085,85331,85033,85306,85308,85020,85021,85022,85023,85024,85026,85027,85028,85029,85353,85019,85018,85013,85012,85015,85014,85016,85254,85008,85044,85045,85042,85043,85040,85041,85048,85037,85035,85034,85032,85031,85017,85304,85307,85266,85310,85054,85051,85050,85053]},
            {name: 'Mohave', value: false, zipCode: [86022,86436,86409,86403,86406,86404,86432,86401,86437,86434,86438,86411,86441,86433,86440,86021,86413,86444,86442,86429,85360,86426,86431,86445]},
            {name: 'Navajo', value: false, zipCode: [85926,86039,86047,85901,86032,86510,85941,85933,86043,85942,86025,86033,86520,86034,85911,86031,86030,86054,85923,86042,85939,85912,85935,85929,85934,85937,84536,85930,86029,86505]},
            {name: 'Pima', value: false, zipCode: [85755,85704,85742,85737,85757,85629,85614,85622,85756,85634,85735,85736,85653,85641,85749,85748,85743,85645,85619,85639,85705,85716,85321,85713,85739,85741,85707,85706,85709,85710,85723,85726,85724,85701,85708,85719,85745,85747,85714,85715,85712,85711,85734,85730,85721,85746,85750,85718,85601,85658]},
            {name: 'Pinal', value: false, zipCode: [85248,85147,85139,85192,85623,85172,85128,85631,85194,85193,85122,85123,85132,85138,85121,85173,85119,85120,85739,85618,85339,85131,85145,85658,85143,85142,85140,85141,85137,85118,85539,85545]},
            {name: 'Santa Cruz', value: false, zipCode: [85646,85621,85637,85624,85645,85611,85640,85648]},
            {name: 'Yavapai', value: false, zipCode: [86323,86334,86337,86320,86332,86333,86336,86305,86301,86303,86313,86329,86327,85362,86315,86314,86325,86322,86326,85324,86351,86331,86324,86321,86335,85332]},
            {name: 'Yuma', value: false, zipCode: [85365,85364,85356,85352,85336,85350,85349,85333,85367]},
        ]
    },
    {
        id: 4,
        name: 'Arkansas',
        stateId: 'AR',
        child: [
            {name: 'Arkansas', value: false, zipCode: [72140,72160,72003,72073,72055,72042]},
            {name: 'Ashley', value: false, zipCode: [71676,71663,71646,71642,71661,71635,71658]},
            {name: 'Baxter', value: false, zipCode: [72635,72626,72617,72653,72642,72651,72658,72544,72537]},
            {name: 'Benton', value: false, zipCode: [72756,72761,72713,72718,72714,72715,72751,72734,72745,72736,72719,72758,72712,72739,72764,72732,72722]},
            {name: 'Boone', value: false, zipCode: [72601,72644,72630,72633,72662,72615]},
            {name: 'Bradley', value: false, zipCode: [71647,71631,71671]},
            {name: 'Calhoun', value: false, zipCode: [71744,71745,71766]},
            {name: 'Carroll', value: false, zipCode: [72611,72616,72638,72660,72632,72631]},
            {name: 'Chicot', value: false, zipCode: [71640,71638,71653]},
            {name: 'Clark', value: false, zipCode: [71923,71962,71921,71772,71743,71999,71998]},
            {name: 'Clay', value: false, zipCode: [72461,72470,72456,72441,72422,72453,72454,72464,72435,72430,72424]},
            {name: 'Cleburne', value: false, zipCode: [72067,72044,72581,72543,72530,72131,72179,72523]},
            {name: 'Cleveland', value: false, zipCode: [71665,71652,71660,71671]},
            {name: 'Columbia', value: false, zipCode: [71753,71770,71740,71752,71861]},
            {name: 'Conway', value: false, zipCode: [72110,72127,72027,72107,72063,72157]},
            {name: 'Craighead', value: false, zipCode: [72476,72427,72437,72447,72421,72411,72414,72417,72416,72419,72467,72401,72404,72405]},
            {name: 'Crawford', value: false, zipCode: [72932,72921,72956,72955,72935,72947,72952,72934,72946]},
            {name: 'Crittenden', value: false, zipCode: [72376,72325,72384,72364,72327,72339,72331,72301,72332,72348]},
            {name: 'Cross', value: false, zipCode: [72373,72347,72396,72324,72387,72101]},
            {name: 'Dallas', value: false, zipCode: [71742,71763,71748,71725]},
            {name: 'Desha', value: false, zipCode: [71638,71654,71630,71674,71639,71670]},
            {name: 'Drew', value: false, zipCode: [71675,71642,71655,71638,71677,71670]},
            {name: 'Faulkner', value: false, zipCode: [72106,72039,72111,72058,72047,72173,72032,72035,72034,72061]},
            {name: 'Franklin', value: false, zipCode: [72821,72928,72933,72949]},
            {name: 'Fulton', value: false, zipCode: [72554,72583]},
            {name: 'Garland', value: false, zipCode: [71968,71913,71909,72087,71901,71956,71964]},
            {name: 'Grant', value: false, zipCode: [72084,72129,72128,72015,72150]},
            {name: 'Greene', value: false, zipCode: [72450,72436,72443,72425]},
            {name: 'Hempstead', value: false, zipCode: [71801,71847,71825,71859,71855,71838,71862]},
            {name: 'Hot Spring', value: false, zipCode: [71923,71942,72104,71929,71941]},
            {name: 'Howard', value: false, zipCode: [71833,71852,71851]},
            {name: 'Independence', value: false, zipCode: [72501,72553,72527,72564,72568,72579,72526,72562,72534]},
            {name: 'Izard', value: false, zipCode: [72584,72566,72519,72512,72561,72556,72540,72536,72517,72565]},
            {name: 'Jackson', value: false, zipCode: [72005,72112,72473,72169,72014,72101,72471,72075,72431]},
            {name: 'Jefferson', value: false, zipCode: [72152,72168,72004,72132,71602,71603,72175,71601]},
            {name: 'Johnson', value: false, zipCode: [72830,72846,72854,72840,72832,72839,72845]},
            {name: 'Lafayette', value: false, zipCode: [71827,71826,71845,71860]},
            {name: 'Lawrence', value: false, zipCode: [72459,72476,72469,72440,72415,72458,72466,72433,72434,72445,72410,72457]},
            {name: 'Lee', value: false, zipCode: [72341,72360,72311,72368,72352,72355]},
            {name: 'Lincoln', value: false, zipCode: [71667,71644,71643]},
            {name: 'Little River', value: false, zipCode: [71822,71836,71865,71866,71820,71853]},
            {name: 'Logan', value: false, zipCode: [72826,72943,72855,72865,72927,72951,72863,72851,72835]},
            {name: 'Lonoke', value: false, zipCode: [72046,72023,72072,72024,72083,72142,72007,72176,72086]},
            {name: 'Madison', value: false, zipCode: [72740,72738,72760,72773,72742]},
            {name: 'Marion', value: false, zipCode: [72634,72687,72677,72661,72672,72619]},
            {name: 'Miller', value: false, zipCode: [71839,71837,71854]},
            {name: 'Mississippi', value: false, zipCode: [72330,72315,72426,72351,72395,72370,72358,72438,72428,72442,72350,72386]},
            {name: 'Monroe', value: false, zipCode: [72021,72134,72108,72029,72069]},
            {name: 'Montgomery', value: false, zipCode: [71960,71935,71961,71965,71957]},
            {name: 'Nevada', value: false, zipCode: [71835,71858,71864,71857,71722]},
            {name: 'Newton', value: false, zipCode: [72641,72685,72683,72655,72628]},
            {name: 'Ouachita', value: false, zipCode: [71751,71720,71701,71764,71726,71857]},
            {name: 'Perry', value: false, zipCode: [72016,72126,72070,72001,72025,72125]},
            {name: 'Phillips', value: false, zipCode: [72389,72333,72374,72390,72342,72366,72355]},
            {name: 'Pike', value: false, zipCode: [71950,71922,71921,71940,71943,71959,71958]},
            {name: 'Poinsett', value: false, zipCode: [72365,72386,72377,72354,72472,72432,72429,72479]},
            {name: 'Polk', value: false, zipCode: [71937,71972,71953,71973,71945,71944]},
            {name: 'Pope', value: false, zipCode: [72823,72843,72802,72801,72847,72858,72837]},
            {name: 'Prairie', value: false, zipCode: [72017,72040,72170,72064,72041]},
            {name: 'Pulaski', value: false, zipCode: [72099,72076,72053,72206,72120,72117,72135,72113,72211,72210,72212,72223,72227,72209,72202,72201,72207,72204,72205,72103,72065,72183,72180,72116,72114,72118]},
            {name: 'Randolph', value: false, zipCode: [72462,72413,72460,72478,72455,72449,72444]},
            {name: 'Saline', value: false, zipCode: [72167,72015,72019,72210,72002,72065,72206,72103,72011,72022]},
            {name: 'Scott', value: false, zipCode: [72958,72926,72944]},
            {name: 'Searcy', value: false, zipCode: [72645,72650,72675,72636,72686,72669]},
            {name: 'Sebastian', value: false, zipCode: [72940,72945,72941,72908,72901,72904,72923,72916,72903,72936,72938,72937]},
            {name: 'Sevier', value: false, zipCode: [71842,71823,71846,71841,71832]},
            {name: 'Sharp', value: false, zipCode: [72482,72542,72513,72521,72532,72577,72529]},
            {name: 'St. Francis', value: false, zipCode: [72326,72322,72335,72372,72359,72348,72394,72392]},
            {name: 'Stone', value: false, zipCode: [72051,72153,72560,72567,72555,72533]},
            {name: 'Union', value: false, zipCode: [71724,71730,71747,71765,71759,71749,71758,71764,71762]},
            {name: 'Van Buren', value: false, zipCode: [72013,72088,72645,72629,72031,72153,72039]},
            {name: 'Washington', value: false, zipCode: [72729,72769,72703,72727,72701,72774,72744,72730,72717,72762,72959,72749,72753,72704,72764]},
            {name: 'White', value: false, zipCode: [72085,72012,72139,72143,72136,72121,72010,72081,72178,72045,72102,72137,72020,72068,72060,72052,72082]},
            {name: 'Woodruff', value: false, zipCode: [72074,72101,72036,72006,72123,72059]},
            {name: 'Yell', value: false, zipCode: [72853,72824,72834,72833,72857,72860,72842,72829]},
        ]
    },
    {
        id: 5,
        name: 'California',
        stateId: 'CA',
        child: [
            {name: 'Alameda', value: false, zipCode: [94560,94536,94538,94539,94555,94546,94552,94578,94708,94707,94705,94704,94703,94702,94709,94710,94720,94579,94577,94566,94588,94587,94541,94542,94544,94545,94501,94502,94580,94568,94608,94611,94610,94706,94550,94551,94586,94621,94613,94612,94619,94618,94606,94607,94602,94603,94601,94609,94605]},
            {name: 'Alpine', value: false, zipCode: [96120,95646]},
            {name: 'Amador', value: false, zipCode: [95629,95642,95666,95689,95640,95685,95699,95669,95675,95665,95601]},
            {name: 'Butte', value: false, zipCode: [95942,95914,95965,95966,95958,95928,95938,95916,95968,95973,95974,95969,95954,95917,95948,95941,95930,95901,95978,95926,95929]},
            {name: 'Calaveras', value: false, zipCode: [95251,95246,95245,95248,95252,95233,95224,95255,95223,95222,95221,95254,95249,95228,95247]},
            {name: 'Colusa', value: false, zipCode: [95912,95950,95979,95932,95970,95987,95955]},
            {name: 'Contra Costa', value: false, zipCode: [94806,94595,94565,94506,94526,94548,94513,94553,94549,94530,94801,94803,94804,94805,94561,94523,94597,94598,94596,94582,94583,94525,94572,94569,94708,94707,94547,94531,94509,94511,94564,94520,94528,94517,94518,94519,94521,94556,94575,94514,94505,94563,94507]},
            {name: 'Del Norte', value: false, zipCode: [95543,95548,95531,95567,95538]},
            {name: 'El Dorado', value: false, zipCode: [95667,95623,95619,96150,95614,95634,95726,95709,95672,95682,95636,95762,95651,95613]},
            {name: 'Fresno', value: false, zipCode: [93704,93705,93630,93609,93234,93657,93605,93634,93626,93602,93727,93608,93725,93706,93640,93646,93611,93612,93619,93703,93210,93656,93606,93652,93624,93242,93625,93660,93662,93648,93740,93741,93730,93650,93702,93701,93722,93728,93720,93721,93726,93711,93710,93631,93668,93664,93616,93622,93675,93654]},
            {name: 'Glenn', value: false, zipCode: [95939,95988,95913,95951,95963]},
            {name: 'Humboldt', value: false, zipCode: [95570,95547,95540,95555,95511,95546,95564,95560,95554,95553,95521,95573,95562,95503,95542,95536,95559,95524,95501,95519,95551,95569,95537,95565,95571,95525,95589]},
            {name: 'Imperial', value: false, zipCode: [92275,92274,92233,92250,92243,92227,92251,92283,92231,92257,92259,92249,92266,92281,92273]},
            {name: 'Inyo', value: false, zipCode: [93514,93549,92389,89061,92328,93513,93530,93522,93526,92384,93545]},
            {name: 'Kern', value: false, zipCode: [93203,93240,93307,93581,93561,93555,93527,93523,93268,93308,93224,93313,93252,93311,93276,93306,93304,93305,93301,93309,93314,93312,93255,93280,93528,93225,93501,93285,93263,93531,93250,93205,93287,93226,93516,93220,93560,93251,93206,93215,93249,93243,93238,93554,93283,93241,93222,93524,93505]},
            {name: 'Kings', value: false, zipCode: [93239,93230,93212,93246,93245,93266,93202,93204]},
            {name: 'Lake', value: false, zipCode: [95426,95451,95453,95458,95423,95485,95457,95464,95461,95422,95467]},
            {name: 'Lassen', value: false, zipCode: [96117,96130,96137,96119,96121,96113,96009,96068,96056,96114,96109]},
            {name: 'Los Angeles', value: false, zipCode: [91367,90291,90293,90292,91316,91311,90035,90034,90037,90031,90039,90008,90004,90005,90006,90007,90001,90002,90003,91504,91040,90710,90089,91307,91344,91345,91340,91342,91343,90036,90247,91411,91436,91371,91608,91605,91604,91607,91606,91601,91602,90402,90068,90069,90062,90063,90061,90066,90067,90064,90065,90248,91326,91324,91325,90013,90012,90011,90010,90017,90016,90015,90014,91364,90028,90095,90094,91042,91352,91356,91406,91402,90041,90042,90043,90044,90045,90046,90047,90048,90049,90019,90018,91423,90210,91303,91304,91306,90079,90071,90077,90059,91331,91330,91335,90026,90027,90024,90025,90023,90020,90021,90029,90272,90732,90731,90230,91405,91403,91401,90033,90032,90038,90057,90052,90058,90744,90501,90502]},
            {name: 'Madera', value: false, zipCode: [93601,93638,93610,93614,93637,93669,93643,93604,93636,93644]},
            {name: 'Marin', value: false, zipCode: [94970,94939,94904,94941,94971,94937,94956,94920,94960,94957,94965,94901,94903,94963,94938,94933,94924,94929,94930,94973,94949,94945,94947,94946,94925]},
            {name: 'Mariposa', value: false, zipCode: [95338,93623,95311,95329,95325,95345,95389,95318,95220,95306]},
            {name: 'Mendocino', value: false, zipCode: [95466,95482,95437,95460,95420,95427,95490,95470,95445,95449,95454,95456,95469,95585,95468,95459,95410,95428,95415]},
            {name: 'Merced', value: false, zipCode: [95312,95388,95341,95301,95324,95322,95369,95333,95374,95340,95348,95317,93620,95334,93635,95315,95365,93665,95303]},
            {name: 'Modoc', value: false, zipCode: [96015,96054,96115,97635,96110,96134,96101,96006,96112,96104]},
            {name: 'Mono', value: false, zipCode: [93517,93529,93541,93514,93546,96107,93512,96133]},
            {name: 'Monterey', value: false, zipCode: [93450,93907,95012,95076,93943,93944,93940,93426,93954,93932,93924,93933,93928,93930,93927,93926,93923,93921,95039,93955,95004,93950,93960,93962,93901,93905,93906,93953,93925]},
            {name: 'Napa', value: false, zipCode: [94558,94573,94503,94599,94574,94576,94515,94508,94559,94562]},
            {name: 'Nevada', value: false, zipCode: [95946,96161,95960,95959,96111,95728,95986,95975,95949,95945,95602]},
            {name: 'Orange', value: false, zipCode: [92655,92679,92688,92870,92886,92887,92656,92705,92780,92808,92806,92807,92804,92805,92802,92801,92868,92869,92866,92867,92865,92676,92678,90630,90621,90620,90743,90740,92843,92840,92841,92844,92845,92861,92662,92663,92660,92661,92625,92657,92677,92626,92627,90720,92617,92614,92612,92618,92697,92620,92602,92603,92604,92606,92782,92673,92672,92637,92651,92692,92691,92675,92610,92630,92694,92708,92835,92833,92832,92831,92629,92624,92821,92823,92648,92649,92646,92647,90742,90631,92707,92701,92703,92704,92706,92683,90623,90680,92653]},
            {name: 'Placer', value: false, zipCode: [95663,95650,96142,96141,95714,95631,95746,95722,96145,96140,96143,96148,95701,95603,95602,95604,95681,95648,95658,95713,95661,95747,95678,95677,95765]},
            {name: 'Plumas', value: false, zipCode: [96103,95971,96020,96122,96106,96137,95983,95956,95984,95934,95947,96105,95923]},
            {name: 'Riverside', value: false, zipCode: [92225,92567,92570,92544,92543,92320,92587,92545,92548,92241,92585,92274,92860,92276,92234,92536,92260,92211,92203,92563,92562,92223,92595,92549,92504,92539,92509,91752,92583,92582,92532,92571,92518,92201,92882,92880,92881,92879,92236,92230,92239,92590,92591,92592,92507,92596,92210,92253,92530,92240,92270,92503,92220,92262,92264,92258,92282,92254,92561,92508,92501,92506,92505,92521,92586,92584,92883,92551,92553,92555,92557]},
            {name: 'Sacramento', value: false, zipCode: [95655,95827,95670,95841,95628,95608,95826,95830,95829,95828,95662,95632,95630,95671,95624,95757,95758,95832,95820,95680,95641,95690,95823,95742,95638,95626,95824,95693,95843,95621,95610,95821,95864,95825,95683,95660,95838,95833,95831,95834,95835,95811,95814,95815,95818,95819,95822,95816,95817,95639,95673,95615,95842,95652]},
            {name: 'San Benito', value: false, zipCode: [95023,95004,95045,95075]},
            {name: 'San Bernardino', value: false, zipCode: [92382,92311,91784,91786,92358,92359,92410,92346,92356,92277,92278,92310,92371,92372,92252,92399,92313,92301,92242,92391,92407,92322,92325,91708,91710,92335,92336,92337,92363,92342,91762,91761,91764,92309,92394,92395,92392,92256,92324,92315,92378,92317,92385,92352,92321,91709,91763,92350,92354,92373,92404,92369,92401,92405,92408,92411,92415,92284,92374,92316,92308,92307,92345,92344,92314,92386,92285,92376,92377,92397,91730,91701,91737,91739,93562,92398]},
            {name: 'San Diego', value: false, zipCode: [92182,92109,92108,92107,92106,92103,92101,92111,92154,92110,92140,92161,92105,92104,92102,92071,92132,92130,92131,92136,92134,92139,92025,92027,92147,92145,92014,92093,92092,92119,92116,92117,92121,92120,92123,92122,92124,92127,92126,92129,92128,92037,92173,92113,92114,92115]},
            {name: 'San Francisco', value: false, zipCode: [94130,94131,94132,94133,94134,94103,94102,94105,94104,94188,94158,94124,94123,94122,94121,94129,94118,94108,94107,94112,94110,94116,94117,94114,94115,94111,94109,94127]},
            {name: 'San Joaquin', value: false, zipCode: [95240,95237,95207,95219,95212,95210,95211,95209,95203,95205,95204,95206,95202,95632,95686,95242,95215,95366,95236,95231,95377,95376,95304,95230,95330,95253,95220,95337,95336,95320,95258,95391]},
            {name: 'San Luis Obispo', value: false, zipCode: [93420,93461,93410,93405,93407,93446,93442,93430,93444,93465,93433,93426,93445,93449,93424,93401,93452,93432,93402,93422,93453,93428]},
            {name: 'San Mateo', value: false, zipCode: [94044,94070,94020,94062,94025,94002,94005,94402,94015,94014,94065,94063,94061,94028,94060,94066,94038,94037,94019,94018,94080,94010,94404,94401,94403,94027,94030,94021,94303]},
            {name: 'Santa Barbara', value: false, zipCode: [93437,93454,93455,93458,93105,93101,93103,93109,93463,93108,93460,93429,93436,93434,93117,93106,93441,93067,93013,93254,93440,93111,93110,93427]},
            {name: 'Santa Clara', value: false, zipCode: [94022,94024,95033,95128,95008,94028,94303,94301,94306,94304,95046,95020,95127,95032,95030,95037,95054,95050,95051,95053,94085,94086,94087,94089,95035,95119,95118,95124,95125,95122,95002,95117,95116,95111,95110,95113,95112,95126,95120,95123,95148,95133,95132,95131,95130,95136,95134,95139,95138,95013,95129,95121,95070,94305,95014,94043,94040,94041]},
            {name: 'Santa Cruz', value: false, zipCode: [95076,95003,95018,95066,95041,95019,95060,95073,95065,95062,95007,95005,95010,95017,95006,95064]},
            {name: 'Shasta', value: false, zipCode: [96008,96003,96073,96007,96013,96002,96001,96084,96040,96022,96076,96056,96028,96051,96047,96096,96088,96087,96069,96016,96062,96071,96025,96017,96019,96065,96033,96011]},
            {name: 'Sierra', value: false, zipCode: [95944,96125,96126,95960,96118,96124,89439,95910,95936]},
            {name: 'Siskiyou', value: false, zipCode: [96027,96037,96094,96097,96034,96058,96038,96039,96025,96134,96044,96032,96064,96023,96067,96057]},
            {name: 'Solano', value: false, zipCode: [94510,94534,95688,94585,94592,94591,94589,94590,94571,95620,95687,95625,94533,94535]},
            {name: 'Sonoma', value: false, zipCode: [94923,95442,95439,95421,95450,95425,95476,95436,94951,95462,95486,95465,94922,94972,94952,95472,95403,95404,95492,94928,94931,94954,95448,95409,95401,95407,95405,95419,95497,95441,95444,95452,95446,95471]},
            {name: 'Stanislaus', value: false, zipCode: [95360,95363,95387,95380,95382,95367,95357,95361,95358,95313,95356,95355,95354,95351,95350,95386,95328,95307,95368,95319,95329,95326,95323,95316]},
            {name: 'Sutter', value: false, zipCode: [95659,95674,95982,95991,95993,95957,95676,95645]},
            {name: 'Tehama', value: false, zipCode: [96059,96022,96021,96063,96061,96080,96035,96029,96078,96055,96075,96074,96090,96092]},
            {name: 'Trinity', value: false, zipCode: [96046,96024,96091,96048,95527,96093,96041,95563,95552,95526,96052]},
            {name: 'Tulare', value: false, zipCode: [93615,93221,93267,93256,93219,93201,93215,93618,93223,93272,93261,93270,93235,93291,93227,93265,93274,93286,93244,93257,93527,93292,93260,93277,93218,93207,93258,93247,93647,93208,93271,93237,93670,93666,93673,93631]},
            {name: 'Tuolumne', value: false, zipCode: [95370,95383,95327,95346,95321,95310,95335,95305,95379,95309,95372]},
            {name: 'Ventura', value: false, zipCode: [93004,93001,93003,93021,91377,93036,93035,93030,93033,91320,91361,93023,93066,93060,91360,91362,93012,93010,91307,93043,93041,93015,93040,93063,93065,93022]},
            {name: 'Yolo', value: false, zipCode: [95605,95691,95645,95695,95697,95618,95627,95653,95612,95637,95616,95937,95694,95606,95776,95679]},
            {name: 'Yuba', value: false, zipCode: [95977,95692,95961,95903,95901,95925,95919,95935,95922]},
        ]
    },
    {
        id: 6,
        name: 'Colorado',
        stateId: 'CO',
        child: [
            {name: 'Adams', value: false, zipCode: [80640,80022,80216,80221,80229,80024,80102,80031,80260,80233,80241,80023,80602,80030,80234,80005,80003,80021,80020,80603,80601]},
            {name: 'Alamosa', value: false, zipCode: [81101,81136]},
            {name: 'Arapahoe', value: false, zipCode: [80123,80018,80136,80112,80102,80103,80113,80111,80121,80137,80246,80231,80247,80045,80019,80016,80014,80015,80012,80013,80010,80017,80011,80222,80110,80122,80128,80120,80105]},
            {name: 'Archuleta', value: false, zipCode: [81147,81137,81121]},
            {name: 'Baca', value: false, zipCode: [81084,81087,81073,81090,81029,81064]},
            {name: 'Bent', value: false, zipCode: [81057,81054,81044]},
            {name: 'Boulder', value: false, zipCode: [80304,80540,80510,80025,80303,80302,80466,80503,80301,80455,80481,80310,80305,80027,80544,80501,80504,80026]},
            {name: 'Broomfield', value: false, zipCode: [80023,80021,80020,80516]},
            {name: 'Chaffee', value: false, zipCode: [81201,81242,81211,81236]},
            {name: 'Cheyenne', value: false, zipCode: [80825,80802,80810]},
            {name: 'Clear Creek', value: false, zipCode: [80439,80476,80452,80438,80444,80436]},
            {name: 'Conejos', value: false, zipCode: [81124,81141,81120,81151,81148,81140,81129]},
            {name: 'Costilla', value: false, zipCode: [81133,81123,81152,81151]},
            {name: 'Crowley', value: false, zipCode: [81062,81063,81033,81076]},
            {name: 'Custer', value: false, zipCode: [81252]},
            {name: 'Delta', value: false, zipCode: [81419,81415,81410,81418,81413,81428,81416]},
            {name: 'Denver', value: false, zipCode: [80264,80266,80230,80231,80236,80237,80235,80238,80239,80123,80249,80247,80246,80290,80293,80294,80218,80210,80212,80216,80221,80220,80223,80222,80224,80227,80219,80211,80209,80207,80206,80205,80204,80202,80203]},
            {name: 'Dolores', value: false, zipCode: [81324,81332]},
            {name: 'Douglas', value: false, zipCode: [80134,80138,80118,80112,80124,80125,80131,80135,80129,80126,80130,80116,80104,80108,80109]},
            {name: 'Eagle', value: false, zipCode: [81620,81632,81637,81631,81649,81645,80463,81621,81657,81623]},
            {name: 'El Paso', value: false, zipCode: [80132,80832,80908,80106,80808,80921,80829,80819,80809,80863,80906,80911,80817,80925,80840,80902,80913,80915,80922,80133,80951,80909,80904,80905,80907,80903,80938,80910,80917,80916,80914,80919,80918,80927,80924,80923,80920,80929,80926]},
            {name: 'Elbert', value: false, zipCode: [80835,80107,80106,80830,80117]},
            {name: 'Fremont', value: false, zipCode: [81223,81244,81226,81212,81240,81233,81222]},
            {name: 'Garfield', value: false, zipCode: [81635,81601,81647,81650,81623,81652]},
            {name: 'Gilpin', value: false, zipCode: [80452,80427,80422,80474]},
            {name: 'Grand', value: false, zipCode: [80482,80468,80442,80478,80459,80451,80446,80447]},
            {name: 'Gunnison', value: false, zipCode: [81434,81224,81623,81241,81230,81225]},
            {name: 'Hinsdale', value: false, zipCode: [81235,81147]},
            {name: 'Huerfano', value: false, zipCode: [81089,81055,81040]},
            {name: 'Jackson', value: false, zipCode: [80480]},
            {name: 'Jefferson', value: false, zipCode: [80403,80128,80127,80123,80212,80401,80232,80235,80214,80215,80227,80226,80228,80433,80453,80465,80002,80005,80004,80007,80003,80439,80457,80419,80454,80033]},
            {name: 'Kiowa', value: false, zipCode: [81036,81071,81045]},
            {name: 'Kit Carson', value: false, zipCode: [80815,80807,80861,80805,80836,80834]},
            {name: 'La Plata', value: false, zipCode: [81301,81326,81137,81122]},
            {name: 'Lake', value: false, zipCode: [80461]},
            {name: 'Larimer', value: false, zipCode: [80537,80538,80534,80513,80549,80545,80547,80528,80521,80526,80524,80525,80517,80535]},
            {name: 'Las Animas', value: false, zipCode: [81082,81027,81081,81020,81091,81049,81046]},
            {name: 'Lincoln', value: false, zipCode: [80818,80821,80828,80804]},
            {name: 'Logan', value: false, zipCode: [80722,80726,80736,80728,80747,80745,80751,80741]},
            {name: 'Mesa', value: false, zipCode: [81524,81526,81507,81503,81506,81505,81504,81501,81521,81630,81520,81624]},
            {name: 'Mineral', value: false, zipCode: [81130]},
            {name: 'Moffat', value: false, zipCode: [81625,81640,81610]},
            {name: 'Montezuma', value: false, zipCode: [81334,81323,81327,81321,81328]},
            {name: 'Montrose', value: false, zipCode: [81403,81401,81422,81425,81431,81424]},
            {name: 'Morgan', value: false, zipCode: [80705,80701,80653,80750,80723,80654,80649,80733]},
            {name: 'Otero', value: false, zipCode: [81030,81050,81067,81058,81077,81039]},
            {name: 'Ouray', value: false, zipCode: [81403,81432,81427]},
            {name: 'Park', value: false, zipCode: [80420,80440]},
            {name: 'Phillips', value: false, zipCode: [80731,80734,80746,80721]},
            {name: 'Pitkin', value: false, zipCode: [81615,81611,81656,81623]},
            {name: 'Prowers', value: false, zipCode: [81043,81047,81052,81041,81092]},
            {name: 'Pueblo', value: false, zipCode: [81022,81069,81004,81005,81003,81001,81008,81006,81019,81007,81023,81025]},
            {name: 'Rio Blanco', value: false, zipCode: [81648,81641]},
            {name: 'Rio Grande', value: false, zipCode: [81154,81144,81132]},
            {name: 'Routt', value: false, zipCode: [80487,81639,80469,80467,80483]},
            {name: 'Saguache', value: false, zipCode: [81155,81125,81131,81149,81143]},
            {name: 'San Juan', value: false, zipCode: [81433]},
            {name: 'San Miguel', value: false, zipCode: [81435,81423,81430,81426]},
            {name: 'Sedgwick', value: false, zipCode: [80737,80749,80744]},
            {name: 'Summit', value: false, zipCode: [80435,80424,80443,80498]},
            {name: 'Teller', value: false, zipCode: [80863,80813,80860,80814,80816]},
            {name: 'Washington', value: false, zipCode: [80743,80720,80812]},
            {name: 'Weld', value: false, zipCode: [80631,80644,80634,80520,80504,80603,80645,80620,80534,80611,80514,80516,80643,80542,80513,80651,80621,80610,80550,80642,80650,80543,80729,80623,80742,80530,80546,80615,80648]},
            {name: 'Yuma', value: false, zipCode: [80758,80735,80824,80822,80759,80727]},
        ]
    },
    {
        id: 7,
        name: 'Connecticut',
        stateId: 'CT',
        child: [
            {name: 'Fairfield', value: false, zipCode: [6877,6615,6820,6812,6784,6896,6470,6878,'06810','06811',6482,6611,'06906','06907','06905','06902','06903','06901',6840,6880,6824,6825,6890,'06784','06812',6804,6831,6830,6897,6484,6883,6614,'06614','06615',6870,6468,'06605','06604','06607','06606','06608','06610','06896','06897','06854','06855','06850','06851','06853','06825','06612',6807]},
            {name: 'Hartford', value: false, zipCode: ['06106','06105','06103','06120','06112','06114',6081,6010,'06052','06053','06051','06092','06070','06089',6082,6078,6060,6447,6033,6035,6070,6016,6019,6002,6479,6037]},
            {name: 'Litchfield', value: false, zipCode: [6018,'06779','06795',6752,'06753','06754','06796',6031,'06039','06068',6776,6063,6791,6754,6098,6796,6790,6786,6751,'06804','06776',6065,6750,6798,'06057','06061',6777,6755]},
            {name: 'Middlesex', value: false, zipCode: [6475,'06438','06441','06457','06459',6417,6469,6424,6426,6412,6498]},
            {name: 'New Haven', value: false, zipCode: [6418,'06450','06451',6405,'06708','06702','06704','06705','06706','06710',6472,6518,6443,6437,6460,'06461','06460',6488,6770,'06519','06511','06510','06513','06512','06515',6401,6492,6516,6410]},
            {name: 'New London', value: false, zipCode: [6340,'06380','06389','06360','06355','06340',6351,6355,6330,6357,6320,6379,'06370','06353','06382','06339','06338','06378',6335]},
            {name: 'Tolland', value: false, zipCode: ['06250','06268','06269',6238,6076,6066,6029]},
            {name: 'Windham', value: false, zipCode: [6239,6278,6281,'06256','06226','06255','06277','06374','06387','06239','06234','06281','06282','06262',6241,6374,'06266',6234,6354,6255]},
        ]
    },
    {
        id: 8,
        name: 'Delaware',
        stateId: 'DE',
        child: [
            {name: 'Kent', value: false, zipCode: [19901,19902,19904,19943,19962,19977,19954,19953,19938,19946,19934,19936,19979,19980,19952,19955,19950]},
            {name: 'New Castle', value: false, zipCode: [19731,19711,19802,19809,19810,19707,19736,19713,19703,19717,19716,19730,19734,19720,19701,19709,19733,19807,19804,19805,19801,19806,19808,19702,19706]},
            {name: 'Sussex', value: false, zipCode: [19971,19970,19966,19940,19947,19930,19945,19939,19975,19960,19950,19967,19973,19944,19968,19956,19931,19963,19941,19958,19933]},
        ]
    },
    {
        id: 9,
        name: 'District of Columbia',
        stateId: 'DC',
        child: [
            {name: 'District of Columbia', value: false, zipCode: [20250,20010,20011,20012,20015,20520,20418,20415,20020,20024,20510,20515,20427,20422,20530,20542,20540,20240,20245,20204,20005,20004,20003,20002,20001,20009,20008,20317,20319,20390,20059,20057,20052,20388,20017,20018,20565,20566,20560,20591,20220,20064,20551,20230,20373,20260,20007,20006,20032,20036,20037,20431,20045,20408,20535,20016,20019]},
        ]
    },
    {
        id: 10,
        name: 'Florida',
        stateId: 'FL',
        child: [
            {name: 'Alachua', value: false, zipCode: [32608,32609,32607,32610,32612,32653,32603,32601,32605,32618,32669,32694,32667,32640,32643,32615,32616,32622,32658]},
            {name: 'Baker', value: false, zipCode: [32063,32040]},
            {name: 'Bay', value: false, zipCode: [32456,32410,32408,32403,32413,32401,32407,32404,32405,32444]},
            {name: 'Bradford', value: false, zipCode: [32622,32058,32091,32044]},
            {name: 'Brevard', value: false, zipCode: [32955,32940,32950,32952,32937,32953,32904,32796,32780,32775,32754,32925,32931,32951,32926,32927,32976,32935,32903,32934,32901,32920,32909,32908,32905,32907,32922,32949,32959]},
            {name: 'Broward', value: false, zipCode: [33308,33062,33324,33325,33331,33330,33328,33314,33073,33322,33323,33317,33313,33388,33023,33009,33301,33304,33305,33306,33309,33334,33316,33315,33312,33311,33063,33066,33326,33351,33319,33029,33028,33025,33024,33026,33027,33060,33064,33069,33321,33076,33067,33068,33327,33332,33021,33022,33020,33004,33019,33071,33065,33442,33441]},
            {name: 'Calhoun', value: false, zipCode: [32421,32424]},
            {name: 'Charlotte', value: false, zipCode: [33950,33982,34224,33947,33983,34223,33955,33980,33954,33952,33948]},
            {name: 'Citrus', value: false, zipCode: [34465,34450,34453,34452,34446,34448,34436,34442,34445,34487,34461,34433,34434,34428,34429]},
            {name: 'Clay', value: false, zipCode: [32073,32079,32043,32068,32065,32003,32656]},
            {name: 'Collier', value: false, zipCode: [34145,34140,34108,34114,34139,34109,34119,34142,34141,34113,34120,34116,34103,34102,34101,34105,34104,34138]},
            {name: 'Columbia', value: false, zipCode: [32055,32038,32025]},
            {name: 'DeSoto', value: false, zipCode: [34266]},
            {name: 'Dixie', value: false, zipCode: [32648,32628]},
            {name: 'Duval', value: false, zipCode: [32234,32224,32225,32226,32227,32220,32221,32222,32223,32228,32219,32218,32214,32217,32216,32211,32210,32212,32246,32244,32233,32202,32250,32254,32257,32256,32208,32209,32206,32207,32204,32205,32203,32258,32277,32266]},
            {name: 'Escambia', value: false, zipCode: [32503,32501,32533,32534,32577,32507,32505,32508,32535,32506,32504,32514,32526,32502,32511,32512]},
            {name: 'Flagler', value: false, zipCode: [32164,32137,32136,32110,32080]},
            {name: 'Franklin', value: false, zipCode: [32322,32328,32320]},
            {name: 'Gadsden', value: false, zipCode: [32333,32343,32351,32330,32332,32324]},
            {name: 'Gilchrist', value: false, zipCode: [32619,32643,32693]},
            {name: 'Glades', value: false, zipCode: [33471,34974]},
            {name: 'Gulf', value: false, zipCode: [32456,32465]},
            {name: 'Hamilton', value: false, zipCode: [32052,32096,32053]},
            {name: 'Hardee', value: false, zipCode: [33890,33873,33834,33865]},
            {name: 'Hendry', value: false, zipCode: [33440,33935]},
            {name: 'Hernando', value: false, zipCode: [34604,34613,34661,34601,34608,34609,34606,34602,34607,33523]},
            {name: 'Highlands', value: false, zipCode: [33825,33870,33875,33852]},
            {name: 'Hillsborough', value: false, zipCode: [33637,33629,33621,33620,33609,33604,33606,33602,33619,33616,33613,33617,33614,33610,33611,33612,33605,33607,33603,33647]},
            {name: 'Holmes', value: false, zipCode: [32455,32425,32464]},
            {name: 'Indian River', value: false, zipCode: [32963,32967,32958,32962,32948,32966,32960,32968,32970]},
            {name: 'Jackson', value: false, zipCode: [32440,32460,32423,32446,32448,32431,32442,32420,32426,32443,32445]},
            {name: 'Jefferson', value: false, zipCode: [32344,32361,32336,32337]},
            {name: 'Lafayette', value: false, zipCode: [32066,32013]},
            {name: 'Lake', value: false, zipCode: [32102,34736,34753,32726,32736,32784,34737,34731,32163,32767,32159,34711,34762,32776,34756,32720,34705,34788,34715,34729,32702,32757,34748,32778,34797]},
            {name: 'Lee', value: false, zipCode: [33908,33967,34135,33928,33922,33993,33956,33965,33903,33913,33916,33966,33901,33907,33905,33936,33973,33972,33971,33976,33974,33917,33955,33920,33919,33931,33990,33914,33991,33909,33904,33912,33924,34134,33957]},
            {name: 'Leon', value: false, zipCode: [32305,32310,32312,32309,32317,32313,32399,32307,32306,32304,32303,32301,32308,32311]},
            {name: 'Levy', value: false, zipCode: [32626,32693,32696,32683,34498,32621,32618,32625,34449,32668]},
            {name: 'Liberty', value: false, zipCode: [32321,32334]},
            {name: 'Madison', value: false, zipCode: [32340,32059,32331]},
            {name: 'Manatee', value: false, zipCode: [34208,34203,34217,34221,34228,34209,34205,34216,34202,34212,34211,34240,34207,34243,34215,34210,34222]},
            {name: 'Marion', value: false, zipCode: [34431,34473,32134,32179,34432,34481,34482,32686,32664,34475,34474,34471,34470,34476,34472,34488,34479,34420]},
            {name: 'Martin', value: false, zipCode: [34957,33455,34996,34997,34990,34994,34956]},
            {name: 'Miami-Dade', value: false, zipCode: [33128,33129,33125,33126,33127,33149,33144,33145,33142,33139,33138,33137,33136,33135,33134,33133,33132,33131,33130,33150,33101,33109]},
            {name: 'Monroe', value: false, zipCode: [33040,33050,33051,33043,33036,33070,33042,33001,33037]},
            {name: 'Nassau', value: false, zipCode: [32011,32046,32034,32097]},
            {name: 'Okaloosa', value: false, zipCode: [32544,32547,32579,32541,32567,32548,32569,32542,32580,32536,32539,32578]},
            {name: 'Okeechobee', value: false, zipCode: [34974,34972]},
            {name: 'Orange', value: false, zipCode: [32806,32809,32839,32825,32824,34761,32798,32757,32832,34734,34786,32821,32804,32789,32810,32812,34787,32836,32819,32829,32827,32822,32805,32807,32801,32803,32808,32835,32811,32814,32830,34760,32703,32837,32828,32712,32768,32817,32833,32709,32818,32792,32820,32751]},
            {name: 'Osceola', value: false, zipCode: [34747,34743,34769,34771,34772,34746,34741,34744,34972]},
            {name: 'Palm Beach', value: false, zipCode: [33408,33417,33403,33462,33406,33435,33480,33445,33444,33483,33413,33438,33411,33463,33430,33407,33404,33496,33487,33486,33434,33432,33433,33431,33412,33409,33405,33401,33458,33493,33426,33436,33477,33415,33461,33476,33470,33478,33428,33469,33440,33449,33414,33418,33410]},
            {name: 'Pasco', value: false, zipCode: [34655,34654,33543,33545,33559,33544,34668,34669,33523,33576,33574,34653,33525,33541,33542,34610,34667,34607,34679,34691,34652,34690,33549,34639,34638,34637,33540,33556,33558]},
            {name: 'Pinellas', value: false, zipCode: [33702,33709,33714,33756,33770,33760,33764,33773,33778,33771,33774,33777,34689,33785,33708,33744,33781,33782,33762,34677,34685,34688,34684,34681,34683,33707,34695,33715,33706,33772,33786,33763,34698,33759,33755,33761,33767,33765,33711,33713,33712,33710,33716,33705,33703,33704,33701]},
            {name: 'Polk', value: false, zipCode: [33898,33853,33859,33881,33851,33838,33844,33877,33827,33843,33855,33880,33839,33801,33823,33812,33813,33830,33803,33805,33815,33811,33841,33884,33860,33896,34758,34759,33850,33847,33868,33858,33837,33835,33810]},
            {name: 'Putnam', value: false, zipCode: [32181,32193,32112,32131,32148,32177]},
            {name: 'Santa Rosa', value: false, zipCode: [32565,32563,32583,32564,32570,32561,32571,32566,32530]},
            {name: 'Sarasota', value: false, zipCode: [34231,34232,34237,34239,34229,34240,34234,34243,34285,34292,34275,34287,34241,34236,34293,34242,34233,34235,34223,34224,34286,34291,34288,34289,34238]},
            {name: 'Seminole', value: false, zipCode: [32751,32730,32779,32708,32714,32703,32732,32765,32766,32746,32773,32771,32792,32750,32707,32701]},
            {name: 'St. Johns', value: false, zipCode: [32086,32080,32082,32095,32084,32259,32145,32081,32092]},
            {name: 'St. Lucie', value: false, zipCode: [34982,34952,34983,34947,34946,34951,34957,34981,34949,34950,34986,34984,34987,34953]},
            {name: 'Sumter', value: false, zipCode: [33521,32163,32162,32159,33597,33514,34484,34785,33585,33538,33513]},
            {name: 'Suwannee', value: false, zipCode: [32008,32064]},
            {name: 'Taylor', value: false, zipCode: [32359,32347,32348]},
            {name: 'Union', value: false, zipCode: [32083,32054,32697]},
            {name: 'Volusia', value: false, zipCode: [32725,32738,32141,32168,32132,32176,32720,32130,32190,32724,32128,32180,32124,32117,32114,32118,32119,32763,32174,32127,32169,32129,32759,32744,32723,32713]},
            {name: 'Wakulla', value: false, zipCode: [32358,32355,32327,32346]},
            {name: 'Walton', value: false, zipCode: [32567,32433,32435,32550,32439]},
            {name: 'Washington', value: false, zipCode: [32437,32427,32428,32463,32462]},
        ]
    },
    {
        id: 11,
        name: 'Georgia',
        stateId: 'GA',
        child: [
            {name: 'Appling', value: false, zipCode: [31513,31539,31563]},
            {name: 'Atkinson', value: false, zipCode: [31650,31642,31624]},
            {name: 'Bacon', value: false, zipCode: [31510]},
            {name: 'Baker', value: false, zipCode: [39870]},
            {name: 'Baldwin', value: false, zipCode: [31061,31034,31062]},
            {name: 'Banks', value: false, zipCode: [30510,30547]},
            {name: 'Barrow', value: false, zipCode: [30666,30011,30680,30620]},
            {name: 'Bartow', value: false, zipCode: [30137,30121,30120,30184,30145,30103,30178]},
            {name: 'Ben Hill', value: false, zipCode: [31750]},
            {name: 'Berrien', value: false, zipCode: [31622,31749,31639,31645]},
            {name: 'Bibb', value: false, zipCode: [31210,31052,31211,31213,31216,31217,31220,31207,31206,31204,31201]},
            {name: 'Bleckley', value: false, zipCode: [31014]},
            {name: 'Brantley', value: false, zipCode: [31553,31542,31543,31566]},
            {name: 'Brooks', value: false, zipCode: [31629,31638,31720,31643]},
            {name: 'Bryan', value: false, zipCode: [31324,31321]},
            {name: 'Bulloch', value: false, zipCode: [30450,30460,30461,30458,30452,30415]},
            {name: 'Burke', value: false, zipCode: [30426,30456,30816,30441,30830,30434]},
            {name: 'Butts', value: false, zipCode: [30233,30234,30216]},
            {name: 'Calhoun', value: false, zipCode: [39813,39846,39862,39866]},
            {name: 'Camden', value: false, zipCode: [31558,31565,31569,31548,31547]},
            {name: 'Candler', value: false, zipCode: [30439,30451]},
            {name: 'Carroll', value: false, zipCode: [30179,30117,30182,30170,30118,30116,30180,30185,30108]},
            {name: 'Catoosa', value: false, zipCode: [30736,30741,30707,30742]},
            {name: 'Charlton', value: false, zipCode: [31537]},
            {name: 'Chatham', value: false, zipCode: [31411,31328,31302,31409,31408,31401,31405,31404,31407,31406,31421,31419,31415,31410,31322]},
            {name: 'Chattahoochee', value: false, zipCode: [31905,31805]},
            {name: 'Chattooga', value: false, zipCode: [30753,30731,30747,30730]},
            {name: 'Cherokee', value: false, zipCode: [30114,30115,30189,30188,30107,30183]},
            {name: 'Clarke', value: false, zipCode: [30602,30622,30601,30605,30606,30607,30609,30683]},
            {name: 'Clay', value: false, zipCode: [39851,39824]},
            {name: 'Clayton', value: false, zipCode: [30274,30296,30260,30228,30250,30236,30238,30288,30297,30294]},
            {name: 'Clinch', value: false, zipCode: [31634,31623,31631,31630]},
            {name: 'Cobb', value: false, zipCode: [30090,30060,30062,30064,30067,30008,30126,30082,30080,30144,30152,30101,30168,30111,30106,30127,30339]},
            {name: 'Coffee', value: false, zipCode: [31554,31533,31535,31519,31512]},
            {name: 'Colquitt', value: false, zipCode: [31744,31768,31788,31722,31771,31747,31753]},
            {name: 'Columbia', value: false, zipCode: [30907,30809,30814,30813,30802]},
            {name: 'Cook', value: false, zipCode: [31632,31627,31620,31637,31647]},
            {name: 'Coweta', value: false, zipCode: [30259,30277,30263,30276,30229,30265,30289,30220]},
            {name: 'Crawford', value: false, zipCode: [31066,31050,31078]},
            {name: 'Crisp', value: false, zipCode: [31015,31712]},
            {name: 'Dade', value: false, zipCode: [30757,30752,30738,30750]},
            {name: 'Dawson', value: false, zipCode: [30534]},
            {name: 'Decatur', value: false, zipCode: [39815,39819,39817,39834,39825]},
            {name: 'DeKalb', value: false, zipCode: [30002,30341,30345,30032,30058,30088,30079,30021,30030,30033,30072,30083,30316,30034,30329,30319,30340,30360,30338,30346,30307,30306,30087,30084,30324,30038,30035]},
            {name: 'Dodge', value: false, zipCode: [31011,31023,31012,31014,31077]},
            {name: 'Dooly', value: false, zipCode: [31092,31091,31063,31007,31051,31070]},
            {name: 'Dougherty', value: false, zipCode: [31705,31721,31701,31707]},
            {name: 'Douglas', value: false, zipCode: [30134,30135,30122,30187]},
            {name: 'Early', value: false, zipCode: [39841,39861,39823,39832]},
            {name: 'Effingham', value: false, zipCode: [31329,31312,31326]},
            {name: 'Elbert', value: false, zipCode: [30624,30635,30634]},
            {name: 'Emanuel', value: false, zipCode: [30471,30436,30401,30448,31002,30464,30441,30425]},
            {name: 'Evans', value: false, zipCode: [30414,30417,30429,30423]},
            {name: 'Fannin', value: false, zipCode: [30555,30559,30560,30541,30513]},
            {name: 'Fayette', value: false, zipCode: [30215,30290,30269,30205,30214]},
            {name: 'Floyd', value: false, zipCode: [30161,30147,30173,30165,30149,30124]},
            {name: 'Forsyth', value: false, zipCode: [30040,30041]},
            {name: 'Franklin', value: false, zipCode: [30553,30557,30662,30639,30521,30520]},
            {name: 'Fulton', value: false, zipCode: [30334,30331,30332,30309,30308,30354,30327,30322,30326,30324,30319,30342,30305,30307,30306,30316,30317,30314,30315,30312,30313,30310,30311,30303,30363,30318]},
            {name: 'Gilmer', value: false, zipCode: [30540,30539,30536]},
            {name: 'Glascock', value: false, zipCode: [30810,30820]},
            {name: 'Glynn', value: false, zipCode: [31520,31527,31522,31523,31525]},
            {name: 'Gordon', value: false, zipCode: [30701,30735,30139,30733,30734]},
            {name: 'Grady', value: false, zipCode: [39828,39897]},
            {name: 'Greene', value: false, zipCode: [30678,30642,30669,30665]},
            {name: 'Gwinnett', value: false, zipCode: [30097,30096,30017,30046,30071,30092,30360,30047,30078,30087,30024,30019,30518,30519]},
            {name: 'Habersham', value: false, zipCode: [30535,30523,30573,30552,30510,30531,30511,30563]},
            {name: 'Hall', value: false, zipCode: [30566,30542,30554,30543,30527,30518,30501,30507,30504]},
            {name: 'Hancock', value: false, zipCode: [31087]},
            {name: 'Haralson', value: false, zipCode: [30176,30110,30182,30113]},
            {name: 'Harris', value: false, zipCode: [31831,31811,31808,31804,31807,31826,31822]},
            {name: 'Hart', value: false, zipCode: [30516,30643]},
            {name: 'Heard', value: false, zipCode: [30217,30170,30108,30240]},
            {name: 'Henry', value: false, zipCode: [30253,30252,30281,30248,30228]},
            {name: 'Houston', value: false, zipCode: [31098,31069,31088,31005,31093,31028]},
            {name: 'Irwin', value: false, zipCode: [31774]},
            {name: 'Jackson', value: false, zipCode: [30548,30558,30549,30567,30575,30529,30565,30517]},
            {name: 'Jasper', value: false, zipCode: [31064,31085]},
            {name: 'Jeff Davis', value: false, zipCode: [31532,31539]},
            {name: 'Jefferson', value: false, zipCode: [30477,30818,30833,30413,30803,30434,30823]},
            {name: 'Jenkins', value: false, zipCode: [30442,30822]},
            {name: 'Johnson', value: false, zipCode: [31049,31002,31096]},
            {name: 'Jones', value: false, zipCode: [31032]},
            {name: 'Lamar', value: false, zipCode: [30204,30257]},
            {name: 'Lanier', value: false, zipCode: [31635,31649]},
            {name: 'Laurens', value: false, zipCode: [31065,31009,31027,31019,31022,31021,31075]},
            {name: 'Lee', value: false, zipCode: [31763,31787]},
            {name: 'Liberty', value: false, zipCode: [31313,31323,31315,31314,31301,31333,31320]},
            {name: 'Lincoln', value: false, zipCode: [30817]},
            {name: 'Long', value: false, zipCode: [31316]},
            {name: 'Lowndes', value: false, zipCode: [31641,31699,31605,31602,31601,31632,31698,31636,31606]},
            {name: 'Lumpkin', value: false, zipCode: [30533,30597]},
            {name: 'Macon', value: false, zipCode: [31063,31068,31057,31041]},
            {name: 'Madison', value: false, zipCode: [30629,30633,30628,30530,30646,30627]},
            {name: 'Marion', value: false, zipCode: [31803]},
            {name: 'McDuffie', value: false, zipCode: [30824,30808]},
            {name: 'McIntosh', value: false, zipCode: [31331,31305]},
            {name: 'Meriwether', value: false, zipCode: [30293,31830,30222,30251,30218,30220,30230,31816]},
            {name: 'Miller', value: false, zipCode: [39837]},
            {name: 'Mitchell', value: false, zipCode: [31784,31730,31716,31779]},
            {name: 'Monroe', value: false, zipCode: [31029,31046,31210,31004,31016]},
            {name: 'Montgomery', value: false, zipCode: [30470,30445,30473,30410,30412,30474]},
            {name: 'Morgan', value: false, zipCode: [30650,30625,30623,30621,30663]},
            {name: 'Murray', value: false, zipCode: [30705,30724]},
            {name: 'Muscogee', value: false, zipCode: [31820,31829,31909,31904,31905,31906,31907,31901,31903,31808]},
            {name: 'Newton', value: false, zipCode: [30056,30054,30014,30055,30070,30016]},
            {name: 'Oconee', value: false, zipCode: [30621,30622,30677]},
            {name: 'Oglethorpe', value: false, zipCode: [30648,30619,30630,30667,30669]},
            {name: 'Paulding', value: false, zipCode: [30132,30157,30153,30141]},
            {name: 'Peach', value: false, zipCode: [31008,31030]},
            {name: 'Pickens', value: false, zipCode: [30175,30143]},
            {name: 'Pierce', value: false, zipCode: [31557,31556,31516,31518,31551]},
            {name: 'Pike', value: false, zipCode: [30292,30295,30256,30258,30206]},
            {name: 'Polk', value: false, zipCode: [30153,30125,30104]},
            {name: 'Pulaski', value: false, zipCode: [31036]},
            {name: 'Putnam', value: false, zipCode: [31024]},
            {name: 'Rabun', value: false, zipCode: [30537,30562,30568,30525,30576]},
            {name: 'Randolph', value: false, zipCode: [39840,39836,39886]},
            {name: 'Richmond', value: false, zipCode: [30805,30912,30815,30812,30907,30906,30904,30905,30901,30909]},
            {name: 'Rockdale', value: false, zipCode: [30012,30013,30094]},
            {name: 'Schley', value: false, zipCode: [31806]},
            {name: 'Screven', value: false, zipCode: [30446,30467,30449,30455]},
            {name: 'Seminole', value: false, zipCode: [39845,39859]},
            {name: 'Spalding', value: false, zipCode: [30223,30224]},
            {name: 'Stephens', value: false, zipCode: [30557,30577]},
            {name: 'Stewart', value: false, zipCode: [31815,31825]},
            {name: 'Sumter', value: false, zipCode: [31711,31743,31780,31764,31709,31719]},
            {name: 'Talbot', value: false, zipCode: [31801,31812,31836,31827,31810]},
            {name: 'Taliaferro', value: false, zipCode: [30631,30664]},
            {name: 'Tattnall', value: false, zipCode: [30421,30417,30453,30420,30427]},
            {name: 'Taylor', value: false, zipCode: [31076,31006]},
            {name: 'Telfair', value: false, zipCode: [31544,31060,31549,31083,31055,30411,31037]},
            {name: 'Terrell', value: false, zipCode: [39842,39885,39877,39826]},
            {name: 'Thomas', value: false, zipCode: [31773,31738,31778,31626,31792,31765]},
            {name: 'Tift', value: false, zipCode: [31794,31795,31775,31793]},
            {name: 'Toombs', value: false, zipCode: [30436,30474]},
            {name: 'Towns', value: false, zipCode: [30582,30546]},
            {name: 'Treutlen', value: false, zipCode: [30457]},
            {name: 'Troup', value: false, zipCode: [30230,31833,30240,30241]},
            {name: 'Turner', value: false, zipCode: [31783,31790,31714]},
            {name: 'Twiggs', value: false, zipCode: [31017,31044]},
            {name: 'Union', value: false, zipCode: [30512]},
            {name: 'Upson', value: false, zipCode: [31016,30286,30285,31097]},
            {name: 'Walker', value: false, zipCode: [30741,30707,30725,30728,30739,30750]},
            {name: 'Walton', value: false, zipCode: [30656,30655,30025,30014,30018,30641,30052]},
            {name: 'Ware', value: false, zipCode: [31501,31550,31503,31564]},
            {name: 'Warren', value: false, zipCode: [30807,30828,30821]},
            {name: 'Washington', value: false, zipCode: [31089,31082,31035,31094,31067,31018]},
            {name: 'Wayne', value: false, zipCode: [31560,31599,31545,31546,31555]},
            {name: 'Wheeler', value: false, zipCode: [30411,30428]},
            {name: 'White', value: false, zipCode: [30571,30545,30528]},
            {name: 'Whitfield', value: false, zipCode: [30721,30720,30756,30710,30755]},
            {name: 'Wilcox', value: false, zipCode: [31001,31072,31084,31079,31071]},
            {name: 'Wilkes', value: false, zipCode: [30673,30660,30668]},
            {name: 'Wilkinson', value: false, zipCode: [31054,31065,31003,31017,31031,31042,31090]},
            {name: 'Worth', value: false, zipCode: [31781,31791,31789,31796]},
        ]
    },
    {
        id: 12,
        name: 'Hawaii',
        stateId: 'HI',
        child: [
            {name: 'Hawaii', value: false, zipCode: [96785,96778,96738,96720,96750,96749,96737,96755,96772,96704,96726,96740,96727,96771,96743,96725,96764,96780,96776,96728,96781,96760,96777,96719,96783]},
            {name: 'Honolulu', value: false, zipCode: [96857,96786,96797,96707,96791,96706,96712,96744,96792,96821,96825,96789,96859,96858,96850,96819,96820,96822,96826,96813,96815,96814,96817,96816,96818,96848,96717,96762,96734,96731,96701,96730,96795,96782,96853]},
            {name: 'Kauai', value: false, zipCode: [96752,96754,96769,96756,96765,96741,96703,96722,96746,96714,96766,96705,96747,96716]},
            {name: 'Maui', value: false, zipCode: [96713,96757,96729,96748,96761,96763,96770,96793,96790,96753,96708,96732,96768,96779]},
        ]
    },
    {
        id: 13,
        name: 'Idaho',
        stateId: 'ID',
        child: [
            {name: 'Ada', value: false, zipCode: [83634,83642,83714,83616,83669,83703,83702,83706,83705,83704,83709,83712,83716,83713,83725,83720,83646]},
            {name: 'Adams', value: false, zipCode: [83612,83654]},
            {name: 'Bannock', value: false, zipCode: [83245,83214,83246,83209,83204,83201,83202,83234,83250,83203,83221]},
            {name: 'Bear Lake', value: false, zipCode: [83272,83223,83261,83254,83239]},
            {name: 'Benewah', value: false, zipCode: [83851,83870,83824,83861,83830]},
            {name: 'Bingham', value: false, zipCode: [83221,83236,83215,83218,83210,83274]},
            {name: 'Blaine', value: false, zipCode: [83320,83354,83353,83340,83313,83333]},
            {name: 'Boise', value: false, zipCode: [83631,83602,83622,83637,83716,83666,83629]},
            {name: 'Bonner', value: false, zipCode: [83811,83864,83852,83825,83840,83836,83804,83822,83856,83841,83821]},
            {name: 'Bonneville', value: false, zipCode: [83401,83406,83427,83443,83449,83428,83454,83402,83404]},
            {name: 'Boundary', value: false, zipCode: [83805,83845]},
            {name: 'Butte', value: false, zipCode: [83213,83255]},
            {name: 'Camas', value: false, zipCode: [83327]},
            {name: 'Canyon', value: false, zipCode: [83660,83676,83656,83607,83626,83651,83687,83686,83641,83644,83605]},
            {name: 'Caribou', value: false, zipCode: [83217,83276,83241]},
            {name: 'Cassia', value: false, zipCode: [83342,83323,83318,83346,83311]},
            {name: 'Clark', value: false, zipCode: [83423,83446]},
            {name: 'Clearwater', value: false, zipCode: [83553,83546,83544,83827]},
            {name: 'Custer', value: false, zipCode: [83251,83278,83227,83226]},
            {name: 'Elmore', value: false, zipCode: [83647,83623,83627,83648]},
            {name: 'Franklin', value: false, zipCode: [83263,83228,83237,83286,83232]},
            {name: 'Fremont', value: false, zipCode: [83420,83438,83445,83433,83429,83451,83436]},
            {name: 'Gem', value: false, zipCode: [83636,83617]},
            {name: 'Gooding', value: false, zipCode: [83332,83314,83355,83330]},
            {name: 'Idaho', value: false, zipCode: [83554,83552,83526,83530,83549,83522,83525,83539]},
            {name: 'Jefferson', value: false, zipCode: [83444,83442,83431,83434,83450,83425,83443]},
            {name: 'Jerome', value: false, zipCode: [83335,83338,83325]},
            {name: 'Kootenai', value: false, zipCode: [83876,83815,83814,83801,83854,83833,83869,83858,83835]},
            {name: 'Latah', value: false, zipCode: [83823,83857,83806,83537,83843,83844,83855,83872,83832,83871,83535]},
            {name: 'Lemhi', value: false, zipCode: [83467,83464]},
            {name: 'Lewis', value: false, zipCode: [83543,83536,83555,83523,83548]},
            {name: 'Lincoln', value: false, zipCode: [83352,83349,83324]},
            {name: 'Madison', value: false, zipCode: [83448,83460,83440]},
            {name: 'Minidoka', value: false, zipCode: [83336,83347,83350]},
            {name: 'Nez Perce', value: false, zipCode: [83540,83524,83545,83501]},
            {name: 'Oneida', value: false, zipCode: [83252]},
            {name: 'Owyhee', value: false, zipCode: [83650,83604,83624,83639,83628]},
            {name: 'Payette', value: false, zipCode: [83619,83655,83661]},
            {name: 'Power', value: false, zipCode: [83211,83204,83271]},
            {name: 'Shoshone', value: false, zipCode: [83837,83873,83868,83849,83867,83846,83850]},
            {name: 'Teton', value: false, zipCode: [83452,83455,83422]},
            {name: 'Twin Falls', value: false, zipCode: [83301,83321,83344,83341,83328,83334,83316]},
            {name: 'Valley', value: false, zipCode: [83611,83677,83615,83638]},
            {name: 'Washington', value: false, zipCode: [83645,83610,83672]},
        ]
    },
    {
        id: 14,
        name: 'Illinois',
        stateId: 'IL',
        child: [
            {name: 'Adams', value: false, zipCode: [62376,62347,62305,62346,62365,62320,62349,62360,62301,62348,62325,62359,62339,62351,62338,62324]},
            {name: 'Alexander', value: false, zipCode: [62990,62957,62988,62969,62914]},
            {name: 'Bond', value: false, zipCode: [62275,62249,62273,62246,62086,62262,62253,62284]},
            {name: 'Boone', value: false, zipCode: [61012,61008,61065,61011,61038]},
            {name: 'Brown', value: false, zipCode: [62378,62353,62375]},
            {name: 'Bureau', value: false, zipCode: [61361,61323,61356,61338,61379,61362,61346,61359,61345,61344,61376,61329,61337,61368,61349,61312,61317,61322,61314,61330,61320]},
            {name: 'Calhoun', value: false, zipCode: [62006,62045,62047,62013,62053]},
            {name: 'Carroll', value: false, zipCode: [61046,61074,61078,61053,61285,61051,61014]},
            {name: 'Cass', value: false, zipCode: [62611,62618,62627,62691,62612]},
            {name: 'Champaign', value: false, zipCode: [61853,61878,61872,61822,61820,61821,61873,61874,61816,61877,61871,61864,61863,61852,60949,61851,61815,61875,61801,61802,61843,61862,61847,61859,61866,61880,61845,61849,61840]},
            {name: 'Christian', value: false, zipCode: [62556,62546,62568,62555,62557,62540,62547,62517,62567,62570,62510,62531]},
            {name: 'Clark', value: false, zipCode: [62474,62442,62477,62441,62420]},
            {name: 'Clay', value: false, zipCode: [62839,62879,62824,62858,62838,62899]},
            {name: 'Clinton', value: false, zipCode: [62293,62265,62218,62215,62245,62230,62216,62250,62231,62219]},
            {name: 'Coles', value: false, zipCode: [62440,61912,61938,61943,62469,61931,61920]},
            {name: 'Cook', value: false, zipCode: [60018,60649,60641,60640,60643,60642,60645,60644,60647,60646,60616,60617,60614,60615,60612,60613,60610,60611,60618,60619,60631,60827,60661,60660,60638,60639,60634,60636,60637,60630,60632,60633,60605,60604,60607,60606,60601,60603,60602,60609,60608,60106,60659,60652,60653,60656,60657,60654,60655,60651,60623,60622,60621,60620,60626,60625,60624,60629,60628,60707]},
            {name: 'Crawford', value: false, zipCode: [62413,62454,62464,62478,62449,62433,62451,62427]},
            {name: 'Cumberland', value: false, zipCode: [62436,62428,62447,62468]},
            {name: 'De Witt', value: false, zipCode: [61735,61749,61882,61778,61727,61750,61842,61777]},
            {name: 'DeKalb', value: false, zipCode: [60150,60135,60520,60115,60548,60178,60145,60151,60556,60146,60552,60550,60112]},
            {name: 'Douglas', value: false, zipCode: [61953,61919,61910,61956,61913,61942,61911,61930]},
            {name: 'DuPage', value: false, zipCode: [60143,60137,60191,60521,60523,60181,60185,60514,60172,60515,60516,60126,60148,60106,60190,60188,60133,60527,60540,60563,60564,60565,60103,60101,60532,60561,60187,60189,60108,60559,60555,60139,60184,60517]},
            {name: 'Edgar', value: false, zipCode: [61944,61949,61933,61917,61955,61940,61932,61924]},
            {name: 'Edwards', value: false, zipCode: [62818,62476,62806,62815]},
            {name: 'Effingham', value: false, zipCode: [62401,62443,62414,62461,62424,62467,62473,62411,62426,62445]},
            {name: 'Fayette', value: false, zipCode: [62458,62838,62880,62426,62471,62418,62080,62011]},
            {name: 'Ford', value: false, zipCode: [60962,60959,60919,60952,60946,60957,60936,60933,61773]},
            {name: 'Franklin', value: false, zipCode: [62874,62865,62999,62836,62812,62891,62983,62822,62825,62841,62896,62890,62856,62819,62884]},
            {name: 'Fulton', value: false, zipCode: [61432,61542,61531,61501,61477,61553,61519,61563,61520,61543,61441,61415,61482,61431,61459,61427,61484,61524,61544]},
            {name: 'Gallatin', value: false, zipCode: [62984,62867,62871,62934,62979,62954]},
            {name: 'Greene', value: false, zipCode: [62092,62044,62050,62016,62082,62054,62027,62081]},
            {name: 'Grundy', value: false, zipCode: [60474,60424,60416,60450,60437,60479,60407,60481,60447,60444]},
            {name: 'Hamilton', value: false, zipCode: [62828,62859,62860,62817]},
            {name: 'Hancock', value: false, zipCode: [62330,62321,61450,62313,62336,62358,62380,62354,62379,62311,62341,62334,62367,62316]},
            {name: 'Hardin', value: false, zipCode: [62982,62931,62919]},
            {name: 'Henderson', value: false, zipCode: [61460,61469,61471,61425,61418,61480,61437,61454]},
            {name: 'Henry', value: false, zipCode: [61490,61413,61258,61419,61241,61238,61262,61468,61254,61273,61234,61344,61434,61443,61233,61235,61274]},
            {name: 'Iroquois', value: false, zipCode: [60973,60948,60966,60953,60951,60912,60927,60968,60955,60956,60931,60924,60974,60918,60928,60938,60930,60911,60945,60970]},
            {name: 'Jackson', value: false, zipCode: [62927,62940,62932,62916,62958,62966,62901,62902,62903,62942,62907,62924,62994]},
            {name: 'Jasper', value: false, zipCode: [62475,62481,62459,62448,62432,62479,62480]},
            {name: 'Jefferson', value: false, zipCode: [62898,62872,62814,62816,62846,62830,62894,62810,62864]},
            {name: 'Jersey', value: false, zipCode: [62028,62052,62037,62031,62030]},
            {name: 'Jo Daviess', value: false, zipCode: [61025,61075,61041,61036,61001,61028,61087,61059,61085]},
            {name: 'Johnson', value: false, zipCode: [62912,62995,62908,62967,62923,62985,62972,62939]},
            {name: 'Kane', value: false, zipCode: [60119,60144,60538,60506,60140,60109,60178,60120,60123,60124,60118,60136,60183,60175,60151,60554,60539,60510,60110,60177,60134,60174,60542,60511,60505,60504,60502,60503]},
            {name: 'Kankakee', value: false, zipCode: [60913,60964,60954,60940,60961,60950,60922,60969,60901,60914,60941,60915,60958,60935,60910,60917]},
            {name: 'Kendall', value: false, zipCode: [60541,60536,60560,60512,60450,60538,60545,60543]},
            {name: 'Knox', value: false, zipCode: [61474,61428,61410,61436,61467,61472,61448,61572,61401,61402,61489,61414,61439,61488,61485,61458,61430]},
            {name: 'Lake', value: false, zipCode: [60048,60087,60085,60020,60081,60041,60073,60044,60047,60002,60040,60061,60083,60030,60060,60046,60099,60010,60031,60035,60084,60045,60015,60069,60089,60088,60064,60096]},
            {name: 'LaSalle', value: false, zipCode: [61350,60531,61371,60548,60552,60557,61301,61372,60470,61342,61354,61341,61332,61358,60537,60551,61334,61348,61316,61364,61325,61321,60518,61370,60549,60450,61360,61373]},
            {name: 'Lawrence', value: false, zipCode: [62466,62439,62460,62417]},
            {name: 'Lee', value: false, zipCode: [61310,61331,60553,61021,61367,60530,61006,61318,61378,61057,61071,61031,61353,61042]},
            {name: 'Livingston', value: false, zipCode: [61333,60921,61319,61775,61739,61764,61769,61740,60929,61741,60934,60920,60460,60420]},
            {name: 'Logan', value: false, zipCode: [62512,62548,62671,62518,62635,61723,62634,62666,62519,62643,62543,62656]},
            {name: 'Macon', value: false, zipCode: [62532,62513,62522,62523,62521,62526,62537,62554,62549,62535,61756,62573,62551,62544,62501,62514]},
            {name: 'Macoupin', value: false, zipCode: [62630,62690,62033,62009,62093,62023,62069,62063,62685,62674,62649,62085,62640,62626,62667,62088,62672,62012,62014]},
            {name: 'Madison', value: false, zipCode: [62058,62074,62024,62018,62010,62090,62060,62046,62097,62021,62025,62048,62040,62026,62084,62234,62087,62002,62095,62001,62249,62281,62088,62061,62067,62034,62035,62062,62294]},
            {name: 'Marion', value: false, zipCode: [62870,62893,62807,62881,62801,62882,62853,62892,62849,62875,62854]},
            {name: 'Marshall', value: false, zipCode: [61375,61565,61540,61377,61570,61541,61537,61369]},
            {name: 'Mason', value: false, zipCode: [61546,61567,62655,62682,62664,62644,61532,62633,62617]},
            {name: 'Massac', value: false, zipCode: [62960,62910,62953]},
            {name: 'McDonough', value: false, zipCode: [61422,62374,61470,61420,61475,61455,61440,61411,61438,61416,62326]},
            {name: 'McHenry', value: false, zipCode: [60042,60081,60180,60142,60014,60012,60050,60013,60071,60033,60098,60021,60102,60097,60051,60034,60156,60072,60152]},
            {name: 'McLean', value: false, zipCode: [61770,61772,61754,61725,61774,61720,61724,61730,61728,61748,61744,61722,61752,61705,61736,61732,61745,61737,61726,61761,61701,61704,61753,61776]},
            {name: 'Menard', value: false, zipCode: [62673,62688,62675,62642,62613]},
            {name: 'Mercer', value: false, zipCode: [61442,61260,61466,61262,61281,61272,61486,61476,61264,61276,61231,61263]},
            {name: 'Monroe', value: false, zipCode: [62248,62298,62295,62244,62236,62256]},
            {name: 'Montgomery', value: false, zipCode: [62049,62056,62015,62538,62560,62076,62091,62075,62533,62051,62032,62572,62089,62017,62077,62019,62094]},
            {name: 'Morgan', value: false, zipCode: [62650,62668,62692,62628,62631,62695,62665,62638,62601]},
            {name: 'Moultrie', value: false, zipCode: [61937,61925,61928,61951,61914]},
            {name: 'Ogle', value: false, zipCode: [61007,61084,61068,61021,61064,61010,61030,60113,61054,61047,61052,61043,61020,61061]},
            {name: 'Peoria', value: false, zipCode: [61523,61604,61517,61547,61539,61559,61607,61552,61615,61536,61529,61625,61614,61605,61603,61602,61606,61569,61533,61616,61562,61525]},
            {name: 'Perry', value: false, zipCode: [62888,62832,62274,62238,62997]},
            {name: 'Piatt', value: false, zipCode: [61936,61929,61818,61913,61856,61884,61813,61830,61854,61839]},
            {name: 'Pike', value: false, zipCode: [62363,62312,62356,62362,62352,62340,62370,62345,62366,62355,62343,62314,62357,62361]},
            {name: 'Pope', value: false, zipCode: [62928,62938]},
            {name: 'Pulaski', value: false, zipCode: [62964,62970,62992,62976,62956,62963,62941]},
            {name: 'Putnam', value: false, zipCode: [61326,61363,61327,61340,61336,61335]},
            {name: 'Randolph', value: false, zipCode: [62217,62241,62272,62288,62292,62278,62277,63673,62233,62280,62237,62286,62242]},
            {name: 'Richland', value: false, zipCode: [62450,62419,62421,62425,62868,62452]},
            {name: 'Rock Island', value: false, zipCode: [61201,61264,61244,61282,61284,61259,61257,61275,61265,61237,61279,61236,61240,61239,61242,61278,61232,61256]},
            {name: 'Saline', value: false, zipCode: [62935,62917,62946,62965,62977,62987,62930]},
            {name: 'Sangamon', value: false, zipCode: [62558,62615,62677,62545,62693,62707,62703,62670,62711,62712,62629,62702,62701,62706,62704,62689,62661,62684,62625,62563,62520,62561,62530,62539,62515]},
            {name: 'Schuyler', value: false, zipCode: [62624,61452,62319,62681]},
            {name: 'Scott', value: false, zipCode: [62694,62082,62663,62665,62621,62650,62610]},
            {name: 'Shelby', value: false, zipCode: [61957,62422,62465,62431,62463,62462,62565,62553,62550,62438,62571,62534]},
            {name: 'St. Clair', value: false, zipCode: [62207,62205,62203,62269,62264,62204,62201,62206,62254,62221,62232,62282,62208,62260,62240,62257,62239,62258,62059,62255,62220,62226,62223,62298,62285,62243,62293,62289,62234,62225]},
            {name: 'Stark', value: false, zipCode: [61483,61421,61491,61449]},
            {name: 'Stephenson', value: false, zipCode: [61019,61018,61039,61062,61013,61089,61048,61032,61070,61060,61067]},
            {name: 'Tazewell', value: false, zipCode: [61568,61734,61611,61721,61554,61733,61759,61550,61755,61564,61747,61534,61610,61571]},
            {name: 'Union', value: false, zipCode: [62926,62961,62906,62920,62952,62905]},
            {name: 'Vermilion', value: false, zipCode: [61817,61883,61811,61865,61848,61844,61841,61857,61834,61832,61846,61870,61876,60932,61833,60942,61858,61814,60963,61850,60960,61810]},
            {name: 'Wabash', value: false, zipCode: [62863,62811,62852,62410]},
            {name: 'Warren', value: false, zipCode: [61473,61453,61447,61412,61462,61423]},
            {name: 'Washington', value: false, zipCode: [62263,62801,62848,62214,62831,62268,62271,62808,62876,62877,62803]},
            {name: 'Wayne', value: false, zipCode: [62851,62842,62837,62823,62843,62886,62850,62895,62446]},
            {name: 'White', value: false, zipCode: [62827,62835,62821,62869,62861,62887,62862,62809,62820,62844]},
            {name: 'Whiteside', value: false, zipCode: [61270,61081,61037,61277,61230,61243,61252,61250,61071,61261,61283]},
            {name: 'Will', value: false, zipCode: [60432,60468,60481,60448,60433,60404,60441,60435,60401,60431,60417,60408,60407,60484,60449,60423,60440,60490,60421,60586,60436,60450,60410,60403,60442,60491,60446,60544,60585,60451]},
            {name: 'Williamson', value: false, zipCode: [62948,62933,62924,62918,62922,62951,62915,62949,62974,62959,62921]},
            {name: 'Winnebago', value: false, zipCode: [61109,61115,61024,61111,61011,61073,61088,61019,61063,61112,61016,61108,61101,61114,61104,61107,61103,61102,61080,61072]},
            {name: 'Woodford', value: false, zipCode: [61548,61771,61738,61545,61611,61729,61530,61570,61742,61561,61516,61760]},
        ]
    },
    {
        id: 15,
        name: 'Indiana',
        stateId: 'IN',
        child: [
            {name: 'Adams', value: false, zipCode: [46740,46711,46733,46772]},
            {name: 'Allen', value: false, zipCode: [46797,46745,46835,46818,46814,46815,46816,46825,46845,46803,46802,46807,46806,46805,46804,46809,46808,46773,46765,46741,46774,46743,46748]},
            {name: 'Bartholomew', value: false, zipCode: [47226,47246,47244,47232,47203,47201,47280,47247]},
            {name: 'Benton', value: false, zipCode: [47917,47971,47942,47944,47921,47986]},
            {name: 'Blackford', value: false, zipCode: [47348,47359]},
            {name: 'Boone', value: false, zipCode: [46052,46075,46102,46147,46071,46077]},
            {name: 'Brown', value: false, zipCode: [46164,47448]},
            {name: 'Carroll', value: false, zipCode: [46915,46917,47997,46923,46929,46913]},
            {name: 'Cass', value: false, zipCode: [46978,46932,46994,46947,46967]},
            {name: 'Clark', value: false, zipCode: [47147,47162,47130,47129,47172,47106,47143,47111,47126]},
            {name: 'Clay', value: false, zipCode: [47881,47834,47837,47841,47853,47840,47845,47438,47857]},
            {name: 'Clinton', value: false, zipCode: [46041,46058,46065,46050,46057,46035]},
            {name: 'Crawford', value: false, zipCode: [47140,47137,47145,47115,47118]},
            {name: 'Daviess', value: false, zipCode: [47501,47553,47568,47519,47529,47562,47558]},
            {name: 'Dearborn', value: false, zipCode: [47032,47060,47012,47025,47018,47001]},
            {name: 'Decatur', value: false, zipCode: [47225,46173,47240,47263,47272,47283]},
            {name: 'DeKalb', value: false, zipCode: [46705,46793,46785,46721,46730,46706,46738]},
            {name: 'Delaware', value: false, zipCode: [47334,47396,47304,47342,47320,47306,47305,47303,47302,47383,47338]},
            {name: 'Dubois', value: false, zipCode: [47527,47513,47546,47532,47541,47542]},
            {name: 'Elkhart', value: false, zipCode: [46553,46540,46550,46514,46517,46516,46543,46507,46573,46528,46526]},
            {name: 'Fayette', value: false, zipCode: [47331]},
            {name: 'Floyd', value: false, zipCode: [47124,47119,47122,47150]},
            {name: 'Fountain', value: false, zipCode: [47987,47949,47918,47958,47932,47952,47994,47969]},
            {name: 'Franklin', value: false, zipCode: [47012,47016,47030,47024,47035,47060,47006,47036]},
            {name: 'Fulton', value: false, zipCode: [46975,46931,46910,46939]},
            {name: 'Gibson', value: false, zipCode: [47640,47648,47683,47660,47649,47670,47666,47654,47639,47665]},
            {name: 'Grant', value: false, zipCode: [46928,46987,46952,46933,46953,46957,46989,46991,46986,46930,46919,46938]},
            {name: 'Greene', value: false, zipCode: [47471,47465,47424,47443,47453,47462,47441,47449,47457,47438]},
            {name: 'Hamilton', value: false, zipCode: [46069,46038,46040,46256,46037,46034,46033,46074,46030,46290,46032,46280,46060,46062,46031]},
            {name: 'Hancock', value: false, zipCode: [46163,46140,46055,46040,46229,46186,47384]},
            {name: 'Harrison', value: false, zipCode: [47142,47114,47110,47160,47136,47117,47135,47112,47161,47164]},
            {name: 'Hendricks', value: false, zipCode: [46234,46112,46118,46168,46231,46123,46149,46167,46122,46121,46180,46165,46103]},
            {name: 'Henry', value: false, zipCode: [47362,47360,47384,47351,47356,47386,47388,47344,47361,46148,47354,47352,47337,47385,47387]},
            {name: 'Howard', value: false, zipCode: [46902,46901,46965,46979,46936]},
            {name: 'Huntington', value: false, zipCode: [46783,46702,46792,46750,46770]},
            {name: 'Jackson', value: false, zipCode: [47274,47220,47281,47229,47235,47260]},
            {name: 'Jasper', value: false, zipCode: [47978,47977,46392,46310]},
            {name: 'Jay', value: false, zipCode: [47371,47381,47369,47373,47326,47336]},
            {name: 'Jefferson', value: false, zipCode: [47243,47250,47231,47230]},
            {name: 'Jennings', value: false, zipCode: [47273,47282,47265,47223]},
            {name: 'Johnson', value: false, zipCode: [46184,46131,46106,46143,46124,46164,46181,46142]},
            {name: 'Knox', value: false, zipCode: [47596,47512,47573,47578,47591,47557,47561,47516,47597,47524,47528,47535]},
            {name: 'Kosciusko', value: false, zipCode: [46542,46524,46539,46538,46555,46567,46510,46562,46582,46580,46508,46590,46982]},
            {name: 'LaGrange', value: false, zipCode: [46761,46565,46571,46746]},
            {name: 'Lake', value: false, zipCode: [46307,46312,46342,46410,46375,46319,46377,46356,46311,46405,46303,46321,46394,46323,46320,46327,46324,46373,46409,46408,46407,46406,46404,46403,46402,46322,46376]},
            {name: 'LaPorte', value: false, zipCode: [46345,46350,46360,46371,46391,46340,46348,46346,46574,46552,46390]},
            {name: 'Lawrence', value: false, zipCode: [47420,47421,47462,47446,47451,47470]},
            {name: 'Madison', value: false, zipCode: [46011,46064,46048,46040,46001,46044,46017,46056,46016,46012,46013,46070,46036,46063,46051]},
            {name: 'Marion', value: false, zipCode: [46227,46228,46216,46226,46235,46236,46234,46237,46218,46219,46217,46214,46260,46268,46225,46183,46231,46239,46254,46250,46208,46201,46203,46202,46205,46204,46206,46256,46259,46224,46222,46221,46220,46229,46113,46278,46241,46240,46107]},
            {name: 'Marshall', value: false, zipCode: [46506,46563,46537,46511,46501,46504]},
            {name: 'Martin', value: false, zipCode: [47522,47581,47553]},
            {name: 'Miami', value: false, zipCode: [46951,46970,46914,46919,46911,46958,46926,46971]},
            {name: 'Monroe', value: false, zipCode: [47458,47401,47429,47404,47403,47434,47462,47464,47405,47408,47406]},
            {name: 'Montgomery', value: false, zipCode: [47990,47940,47989,47994,47968,47965,47954,47933,47967,47955,47916]},
            {name: 'Morgan', value: false, zipCode: [46158,46160,46166,46111,46151,46157]},
            {name: 'Newton', value: false, zipCode: [47922,46310,47951,47948,46349,47964,47963]},
            {name: 'Noble', value: false, zipCode: [46767,46784,46710,46795,46732,46760,46701,46755]},
            {name: 'Ohio', value: false, zipCode: [47040]},
            {name: 'Orange', value: false, zipCode: [47432,47454,47469,47452]},
            {name: 'Owen', value: false, zipCode: [47460,47433]},
            {name: 'Parke', value: false, zipCode: [47862,47874,47860,47872,47832,47859]},
            {name: 'Perry', value: false, zipCode: [47586,47520,47588]},
            {name: 'Pike', value: false, zipCode: [47598,47584,47660,47564,47567]},
            {name: 'Porter', value: false, zipCode: [46385,46383,46347,46368,46393,46342,46304,46403,46341,46301,46360]},
            {name: 'Posey', value: false, zipCode: [47631,47712,47638,47616,47620,47633,47612]},
            {name: 'Pulaski', value: false, zipCode: [47957,46996,47946,46985,46960]},
            {name: 'Putnam', value: false, zipCode: [46128,46172,46121,46105,46135,46120,46175]},
            {name: 'Randolph', value: false, zipCode: [47358,47354,47394,47382,47355,47390,47340,47380,47368]},
            {name: 'Ripley', value: false, zipCode: [47042,47031,47006,47034,47037,47023,47041]},
            {name: 'Rush', value: false, zipCode: [46104,46133,46150,46156,46173,46115]},
            {name: 'Scott', value: false, zipCode: [47170,47102]},
            {name: 'Shelby', value: false, zipCode: [46182,46126,46161,46176]},
            {name: 'Spencer', value: false, zipCode: [47523,47579,47611,47634,47537,47635,47615,47577]},
            {name: 'St. Joseph', value: false, zipCode: [46536,46561,46637,46556,46616,46635,46628,46601,46613,46617,46619,46614,46615,46552,46545,46530,46554,46574,46544]},
            {name: 'Starke', value: false, zipCode: [46374,46574,46532,46534,46366]},
            {name: 'Steuben', value: false, zipCode: [46776,46703,46737,46742,46747]},
            {name: 'Sullivan', value: false, zipCode: [47850,47848,47879,47882,47861,47855,47838]},
            {name: 'Switzerland', value: false, zipCode: [47040,47043,47020,47038]},
            {name: 'Tippecanoe', value: false, zipCode: [47906,47907,47970,47981,47920,47905,47983,47909,47930,47992,47924,47941,47904,47901]},
            {name: 'Tipton', value: false, zipCode: [46076,46068,46072,46049]},
            {name: 'Union', value: false, zipCode: [47353,47003]},
            {name: 'Vanderburgh', value: false, zipCode: [47725,47714,47715,47710,47711,47713,47708]},
            {name: 'Vermillion', value: false, zipCode: [47928,47974,47842,47875,47884,47831,47847,47966]},
            {name: 'Vigo', value: false, zipCode: [47885,47804,47807,47802,47803,47809,47805,47871,47863,47876,47842,47880,47874,47851,47834]},
            {name: 'Wabash', value: false, zipCode: [46940,46941,46962,46984,46992,46943,46974]},
            {name: 'Warren', value: false, zipCode: [47993,47918,47982,47932,47975,47917,47906,47970,47991]},
            {name: 'Warrick', value: false, zipCode: [47619,47601,47610,47630,47613,47637]},
            {name: 'Washington', value: false, zipCode: [47108,47125,47120,47167,47170,47165]},
            {name: 'Wayne', value: false, zipCode: [47324,47341,47345,47327,47357,47374,47370,47335,47346,47339,47330]},
            {name: 'Wells', value: false, zipCode: [46777,46714,46799,46770,46791,46781]},
            {name: 'White', value: false, zipCode: [47959,47950,47960,47925,47980,47923,47995,47929,47926]},
            {name: 'Whitley', value: false, zipCode: [46725,46787,46764,46723]},
        ]
    },
    {
        id: 16,
        name: 'Iowa',
        stateId: 'IA',
        child: [
            {name: 'Adair', value: false, zipCode: [50837,50846,50858,50002,50849,50250]},
            {name: 'Adams', value: false, zipCode: [50841,50839,50857,50859]},
            {name: 'Allamakee', value: false, zipCode: [52146,52170,52160,52162,52151,52172]},
            {name: 'Appanoose', value: false, zipCode: [52544,52574,52593,52571,52572,52555,52549,52581,52594]},
            {name: 'Audubon', value: false, zipCode: [50042,50110,50025,50076,51543]},
            {name: 'Benton', value: false, zipCode: [52346,52249,52315,52229,52209,52208,52349,52318,52257,52332,52206,52354,52345,52313]},
            {name: 'Black Hawk', value: false, zipCode: [50613,50702,50643,50701,50634,50651,50667,50626,50707,50703]},
            {name: 'Boone', value: false, zipCode: [50036,50212,50220,50152,50156,50040,50223,50031]},
            {name: 'Bremer', value: false, zipCode: [50668,50677,50666,50674,50647,50676,50631,50622]},
            {name: 'Buchanan', value: false, zipCode: [52329,50671,50650,50607,52210,50641,50629,50648,50644,52326,50682]},
            {name: 'Buena Vista', value: false, zipCode: [50568,50588,50565,51002,50585,50576,50592,51033,50510]},
            {name: 'Butler', value: false, zipCode: [50605,50660,50625,50636,50604,50619,50665,50602,50670,50611]},
            {name: 'Calhoun', value: false, zipCode: [50563,50579,50586,50575,51453,50538,50552,51449,51433,50551]},
            {name: 'Carroll', value: false, zipCode: [51444,51455,51459,51462,51443,51401,51436,51440,51463,51430,51451,51453,50058,51452]},
            {name: 'Cass', value: false, zipCode: [50022,51544,51552,50274,50020,51535,50853,50843]},
            {name: 'Cedar', value: false, zipCode: [52216,52337,52358,52255,52772,52747,52721,52306]},
            {name: 'Cerro Gordo', value: false, zipCode: [50401,50467,50428,50433,50482,50469,50464,50457,50479,50477]},
            {name: 'Cherokee', value: false, zipCode: [51012,51005,51037,51061,51049,51029,51014,51035]},
            {name: 'Chickasaw', value: false, zipCode: [50645,50659,52154,50630,50658,50603]},
            {name: 'Clarke', value: false, zipCode: [50213,50174,50275]},
            {name: 'Clay', value: false, zipCode: [51340,51301,51333,51357,51341,51338,51366,51343,51047]},
            {name: 'Clayton', value: false, zipCode: [52077,52072,52048,52157,52159,52052,52035,52049,52066,52053,52043,52044,52047,52076,52158,52156]},
            {name: 'Clinton', value: false, zipCode: [52730,52037,52729,52701,52757,52732,52777,52774,52742,52254,52751,52750,52731]},
            {name: 'Crawford', value: false, zipCode: [51442,51528,51520,51465,51467,51432,51448,51439,51460,51454,51461,51441]},
            {name: 'Dallas', value: false, zipCode: [50276,50146,50003,50063,50070,50167,50039,50263,50109,50069,50220,50261,50233,50066]},
            {name: 'Davis', value: false, zipCode: [52552,52560,52537,52584]},
            {name: 'Decatur', value: false, zipCode: [50264,50065,50262,50103,50108,50123,50144,50067,50140]},
            {name: 'Delaware', value: false, zipCode: [52223,52036,52042,52057,52035,52050,52038,52330,50654,52237,52041]},
            {name: 'Des Moines', value: false, zipCode: [52637,52638,52623,52601,52660,52650,52655,52658]},
            {name: 'Dickinson', value: false, zipCode: [51363,51364,51360,51355,51351,51347,51331]},
            {name: 'Dubuque', value: false, zipCode: [52046,52045,52068,52073,52033,52040,52039,52032,52065,52002,52078,52001,52056,52079,52003,52053]},
            {name: 'Emmet', value: false, zipCode: [51334,50578,50514,50531,51365]},
            {name: 'Fayette', value: false, zipCode: [52142,52135,52164,52169,50681,52141,50655,52166,52171,52175,50662,52147,50606]},
            {name: 'Floyd', value: false, zipCode: [50435,50620,50616,50471,50458,50653,50468]},
            {name: 'Franklin', value: false, zipCode: [50427,50475,50227,50633,50441,50452,50431,50420,50041,50126]},
            {name: 'Fremont', value: false, zipCode: [51650,51652,51648,51653,51654,51649,51645,51639,51640]},
            {name: 'Greene', value: false, zipCode: [50064,50050,50129,50107,50217,51462,50235]},
            {name: 'Grundy', value: false, zipCode: [50680,50624,50638,50609,50673,50665,50642,50621,50669,50657]},
            {name: 'Guthrie', value: false, zipCode: [50048,50216,50164,50026,50070,50277,50128,50029,50115]},
            {name: 'Hamilton', value: false, zipCode: [50246,50271,50075,50231,50034,50249,50595,50132]},
            {name: 'Hancock', value: false, zipCode: [50447,50423,50438,50484,50430,50439,50432,50449]},
            {name: 'Hardin', value: false, zipCode: [50102,50230,50126,50627,50601,50269,50006,50122,50672,50206,50258]},
            {name: 'Harrison', value: false, zipCode: [51564,51529,51557,51555,51556,51563,51545,51550,51546,51579]},
            {name: 'Henry', value: false, zipCode: [52641,52654,52644,52630,52659,52647,52649,52652,52645]},
            {name: 'Howard', value: false, zipCode: [50466,50628,52163,52155,52136,52134]},
            {name: 'Humboldt', value: false, zipCode: [50582,50519,50548,50577,50570,50560,50529,50541,50591,50558,50520,50545]},
            {name: 'Ida', value: false, zipCode: [51025,51445,51431,51006,51020]},
            {name: 'Iowa', value: false, zipCode: [52301,52325,52347,52334,52361,52203,52316,52236,52308,52307,52251,52220]},
            {name: 'Jackson', value: false, zipCode: [52070,52054,52030,52031,52071,52207,52069,52309,52075,52060,52064,52074]},
            {name: 'Jasper', value: false, zipCode: [50208,50170,50232,50251,50135,50168,50054,50228,50153,50112,50028]},
            {name: 'Jefferson', value: false, zipCode: [52556,52635,52533,52540,52557,52567,52580]},
            {name: 'Johnson', value: false, zipCode: [52245,52246,52240,52242,52340,52755,52247,52241,52235,52338,52317,52333,52322]},
            {name: 'Jones', value: false, zipCode: [52320,52321,52205,52323,52212,52312,52310,52305,52362]},
            {name: 'Keokuk', value: false, zipCode: [52550,50268,52591,52562,52576,50136,52335,50104,52563,52248,52231,52568,52355,50255,52585]},
            {name: 'Kossuth', value: false, zipCode: [50590,50517,50560,50539,50451,50556,50483,50511,50519,50480,50522,50598,50559]},
            {name: 'Lee', value: false, zipCode: [52656,52625,52657,52627,52624,52658,52639,52632,52631,52619]},
            {name: 'Linn', value: false, zipCode: [52202,52403,52352,52253,52219,52336,52314,52411,52233,52302,52328,52218,52213,52404,52405,52401,52402,52227,52351,52228,52214,52324]},
            {name: 'Louisa', value: false, zipCode: [52640,52752,52754,52738,52646,52653,52737]},
            {name: 'Lucas', value: false, zipCode: [50151,50049,50068,50272,50238]},
            {name: 'Lyon', value: false, zipCode: [51243,51240,51235,51246,51237,51241,51230,51242]},
            {name: 'Madison', value: false, zipCode: [50222,50155,50072,50240,50218,50273,50257,50229,50033]},
            {name: 'Mahaska', value: false, zipCode: [52577,52534,52595,50027,50171,52586,50143,52561,50207]},
            {name: 'Marion', value: false, zipCode: [50138,50219,50225,50116,50044,50119,50252,50163,50062]},
            {name: 'Marshall', value: false, zipCode: [50234,50120,50106,50141,50247,50051,50158,50632,50148,50142,50239,50078,50005,50162]},
            {name: 'Mills', value: false, zipCode: [51534,51561,51541,51554,51540,51571,51533,51551]},
            {name: 'Mitchell', value: false, zipCode: [50426,50455,50461,50476,50460,50472,50454]},
            {name: 'Monona', value: false, zipCode: [51523,51572,51558,51060,51040,51034,51010,51051,51063]},
            {name: 'Monroe', value: false, zipCode: [52569,52531,50150]},
            {name: 'Montgomery', value: false, zipCode: [50864,50847,51532,51573,51566]},
            {name: 'Muscatine', value: false, zipCode: [52749,52769,52761,52760,52766,52778,52776,52739,52720,52726,52759]},
            {name: "O'Brien", value: false, zipCode: [51248,51009,51245,51346,51058,51201,51231,51046]},
            {name: 'Osceola', value: false, zipCode: [51350,51249,51354,51345,51232]},
            {name: 'Page', value: false, zipCode: [51632,51637,51601,51647,51631,51638,51651,51636,51656,51630]},
            {name: 'Palo Alto', value: false, zipCode: [50527,50528,51358,51342,50536,50597,50515,50562]},
            {name: 'Plymouth', value: false, zipCode: [51028,51038,51031,51050,51062,51008,51024,51001,51045]},
            {name: 'Pocahontas', value: false, zipCode: [50540,50574,50554,50593,50581,50571,50546,50573]},
            {name: 'Polk', value: false, zipCode: [50111,50169,50237,50131,50243,50007,50244,50327,50309,50312,50314,50315,50316,50317,50310,50313,50319,50321,50320,50311,50324,50073,50226,50023,50021,50035,50047,50265,50266,50325,50323,50322,50009]},
            {name: 'Pottawattamie', value: false, zipCode: [51560,51510,51559,51549,51525,51577,51526,51548,51503,51501,51576,51536,51521,51575,51553]},
            {name: 'Poweshiek', value: false, zipCode: [50171,52211,50157,52222,50112,52232,52221,50242]},
            {name: 'Ringgold', value: false, zipCode: [50860,50133,50074,50863,50835,50854,50845,50836]},
            {name: 'Sac', value: false, zipCode: [50535,51466,51450,51433,50561,51458,50583,51053,50567]},
            {name: 'Scott', value: false, zipCode: [52745,52748,52777,52726,52756,52722,52773,52746,52753,52758,52768,52765,52728,52804,52802,52803,52801,52806,52807]},
            {name: 'Shelby', value: false, zipCode: [51527,51531,51565,51570,51537,51562,51447,51578,51530,51446]},
            {name: 'Sioux', value: false, zipCode: [51234,51023,51003,51036,51022,51238,51247,51239,51011,51250,51244,51041,51027]},
            {name: 'Story', value: false, zipCode: [50134,50248,50046,50278,50201,50055,50154,50161,50105,50244,50236,50124,50014,50013,50012,50010,50056]},
            {name: 'Tama', value: false, zipCode: [50675,50635,50652,52348,52215,50173,52224,52225,52339,52342,52217,50632]},
            {name: 'Taylor', value: false, zipCode: [50848,50833,50862,50836,50851,50840,51646]},
            {name: 'Union', value: false, zipCode: [50831,50830,50149,50861,50842,50801,50254]},
            {name: 'Van Buren', value: false, zipCode: [52535,52570,52542,52620,52626,52573,52551,52565,52651]},
            {name: 'Wapello', value: false, zipCode: [52554,52536,52530,52553,52501,52548,52566]},
            {name: 'Warren', value: false, zipCode: [50125,50139,50211,50061,50160,50166,50241,50210,50118,50047,50001]},
            {name: 'Washington', value: false, zipCode: [52621,52201,52327,52353,52540,52356,52359,52247,52654]},
            {name: 'Wayne', value: false, zipCode: [50008,50060,50123,52590,52583,50147,50165,50052]},
            {name: 'Webster', value: false, zipCode: [50543,50518,50594,50501,50532,50516,50523,50569,50557,50544,50530,50566,50524]},
            {name: 'Winnebago', value: false, zipCode: [50424,50465,50478,50436,50453,50473,50450]},
            {name: 'Winneshiek', value: false, zipCode: [52168,52132,52165,52133,52144,52101,52161,52171]},
            {name: 'Woodbury', value: false, zipCode: [51055,51054,51039,51015,51052,51048,51026,51056,51004,51044,51030,51018,51106,51104,51105,51103,51101,51108,51109,51111,51016,51007,51019]},
            {name: 'Worth', value: false, zipCode: [50440,50446,50448,50456,50459,50444,50434]},
            {name: 'Wright', value: false, zipCode: [50533,50101,50525,50542,50071,50470,50599,50421]},
        ]
    },
    {
        id: 17,
        name: 'Kansas',
        stateId: 'KS',
        child: [
            {name: 'Allen', value: false, zipCode: [66772,66748,66755,66749,66742,66732,66751]},
            {name: 'Anderson', value: false, zipCode: [66091,66015,66033,66039,66032,66093]},
            {name: 'Atchison', value: false, zipCode: [66023,66041,66058,66002]},
            {name: 'Barber', value: false, zipCode: [67057,67061,67143,67104,67138,67071,67070,67065]},
            {name: 'Barton', value: false, zipCode: [67511,67544,67525,67567,67565,67564,67526,67530]},
            {name: 'Bourbon', value: false, zipCode: [66779,66701,66738,66769,66754,66716,66741]},
            {name: 'Brown', value: false, zipCode: [66515,66434,66439,66527,66424,66425,66532]},
            {name: 'Butler', value: false, zipCode: [67154,67017,67144,67002,67042,67133,67012,67132,67074,67039,66842,67010,67123,67072,67041,67114]},
            {name: 'Chase', value: false, zipCode: [66843,66845,66862,66869,66850]},
            {name: 'Chautauqua', value: false, zipCode: [67024,67361,67355,67360,67334]},
            {name: 'Cherokee', value: false, zipCode: [66725,66770,66773,66781,66739,66782,66713]},
            {name: 'Cheyenne', value: false, zipCode: [67731,67756]},
            {name: 'Clark', value: false, zipCode: [67840,67831,67865]},
            {name: 'Clay', value: false, zipCode: [67458,67468,67432,67487,67447]},
            {name: 'Cloud', value: false, zipCode: [66938,66948,67445,66901,67417,67466]},
            {name: "Coffey", value: false, zipCode: [66871,66857,66839,66856,66852]},
            {name: "Comanche", value: false, zipCode: [67029,67155,67127]},
            {name: 'Cowley', value: false, zipCode: [67131,67008,67156,67023,67019,67005,67038,67146]},
            {name: "Crawford", value: false, zipCode: [66735,66762,66756,66712,66711,66743,66753,66763,66746,66734,66780,66724]},
            {name: 'Decatur', value: false, zipCode: [67643,67635,67653,67749]},
            {name: 'Dickinson', value: false, zipCode: [67448,67410,67482,67480,67441,67451,67492,67431]},
            {name: 'Doniphan', value: false, zipCode: [66532,66017,66087,66008,66094,66090,66035,66024]},
            {name: 'Douglas', value: false, zipCode: [66025,66006,66045,66044,66047,66046,66049,66050]},
            {name: 'Edwards', value: false, zipCode: [67552,67547,67563,67519]},
            {name: 'Elk', value: false, zipCode: [67346,67352,67349,67353,67345]},
            {name: 'Ellis', value: false, zipCode: [67627,67601,67667,67671,67637]},
            {name: 'Ellsworth', value: false, zipCode: [67459,67454,67439,67490,67450]},
            {name: 'Finney', value: false, zipCode: [67851,67846]},
            {name: 'Ford', value: false, zipCode: [67842,67834,67801,67882,67843,67876]},
            {name: 'Franklin', value: false, zipCode: [66067,66042,66095,66078,66079,66080,66076,66092]},
            {name: 'Geary', value: false, zipCode: [66441,66514]},
            {name: 'Gove', value: false, zipCode: [67738,67751,67736,67737,67752]},
            {name: 'Graham', value: false, zipCode: [67650,67625,67642]},
            {name: 'Grant', value: false, zipCode: [67880]},
            {name: 'Gray', value: false, zipCode: [67837,67841,67835,67867,67853]},
            {name: 'Greeley', value: false, zipCode: [67879]},
            {name: 'Greenwood', value: false, zipCode: [67137,66870,66853,67045,66860,67122,67047,66863]},
            {name: 'Hamilton', value: false, zipCode: [67878,67836]},
            {name: 'Harper', value: false, zipCode: [67036,67003,67009,67058,67150,67018]},
            {name: 'Harvey', value: false, zipCode: [67062,67117,67151,67114,67056,67020,67135]},
            {name: 'Haskell', value: false, zipCode: [67870,67877]},
            {name: 'Hodgeman', value: false, zipCode: [67854,67849]},
            {name: 'Jackson', value: false, zipCode: [66418,66509,66419,66552,66440,66540,66436,66416,66516]},
            {name: 'Jefferson', value: false, zipCode: [66060,66066,66073,66088,66512,66054,66097,66429,66070]},
            {name: 'Jewell', value: false, zipCode: [66949,66942,66936,66941,66963,66970,66956]},
            {name: 'Johnson', value: false, zipCode: [66205,66021,66030,66214,66215,66219,66227,66220,66216,66211,66209,66206,66224,66202,66210,66212,66213,66221,66223,66207,66204,66013,66251,66085,66208,66062,66061,66203,66217,66106,66018,66083,66218,66226]},
            {name: 'Kearny', value: false, zipCode: [67860,67838]},
            {name: 'Kingman', value: false, zipCode: [67112,67035,67068,67142,67118,67159,67111]},
            {name: 'Kiowa', value: false, zipCode: [67054,67109,67059]},
            {name: 'Labette', value: false, zipCode: [67342,67341,67354,67336,67335,67357,67330,67332,67356]},
            {name: 'Lane', value: false, zipCode: [67839,67850]},
            {name: 'Leavenworth', value: false, zipCode: [66086,66052,66027,66048,66043,66020,66007]},
            {name: 'Lincoln', value: false, zipCode: [67455,67481,67423,67418]},
            {name: 'Linn', value: false, zipCode: [66767,66075,66040,66056,66072,66010]},
            {name: 'Logan', value: false, zipCode: [67764,67748,67747]},
            {name: 'Lyon', value: false, zipCode: [66833,66830,66835,66865,66801,66854,66868,66864]},
            {name: 'Marion', value: false, zipCode: [67438,67063,66861,67483,67073,66859,67475,66840,66866,66858,67053,66851]},
            {name: 'Marshall', value: false, zipCode: [66544,66438,66548,66541,66403,66427,66406,66411,66518,66412,66508]},
            {name: 'McPherson', value: false, zipCode: [67464,67491,67460,67443,67448,67476,67546,67428,67107,67456]},
            {name: 'Meade', value: false, zipCode: [67864,67869,67844]},
            {name: 'Miami', value: false, zipCode: [66064,66026,66071,66013,66053]},
            {name: 'Mitchell', value: false, zipCode: [67430,67478,67420,67485,67446,67452]},
            {name: 'Montgomery', value: false, zipCode: [67344,67347,67351,67337,67363,67301,67364,67335,67333,67340]},
            {name: 'Morris', value: false, zipCode: [67449,66838,66849,66846,66872,66873]},
            {name: 'Morton', value: false, zipCode: [67950,67954,67953]},
            {name: 'Nemaha', value: false, zipCode: [66538,66417,66415,66404,66408,66428,66534,66550,66522]},
            {name: 'Neosho', value: false, zipCode: [66720,66740,67357,66775,66771,66776,66733]},
            {name: 'Ness', value: false, zipCode: [67516,67584,67521,67560,67572]},
            {name: 'Norton', value: false, zipCode: [67654,67622,67645,67629]},
            {name: 'Osage', value: false, zipCode: [66537,66523,66414,66543,66524,66510,66856,66451,66413,66528]},
            {name: 'Osborne', value: false, zipCode: [67651,67473,67623,67474,67437]},
            {name: 'Ottawa', value: false, zipCode: [67422,67467,67480,67484,67436]},
            {name: 'Pawnee', value: false, zipCode: [67574,67550,67523,67529]},
            {name: 'Phillips', value: false, zipCode: [67647,67621,67661,67639,67646,67664,67644]},
            {name: 'Pottawatomie', value: false, zipCode: [66407,66521,66549,66547,66535,66536,66422,66432,66520]},
            {name: 'Pratt', value: false, zipCode: [67066,67124,67134,67021,67028,67583]},
            {name: 'Rawlins', value: false, zipCode: [67730,67739,67744,67745]},
            {name: 'Reno', value: false, zipCode: [67502,67501,67543,67510,67570,67514,67583,67505,67568,67561,67581,67566,67522]},
            {name: 'Republic', value: false, zipCode: [66939,66966,66960,66935,66964,66930,66959,66961,66940]},
            {name: 'Rice', value: false, zipCode: [67512,67427,67444,67457,67573,67554,67524,67579]},
            {name: 'Riley', value: false, zipCode: [66554,66502,66531,66442,66506,66503,66449,66517]},
            {name: 'Rooks', value: false, zipCode: [67669,67663,67651,67675,67657,67632]},
            {name: 'Rush', value: false, zipCode: [67575,67553,67565,67548,67556,67513,67520]},
            {name: 'Russell', value: false, zipCode: [67640,67648,67665,67658,67626,67649,67634,67673]},
            {name: 'Saline', value: false, zipCode: [67456,67470,67401,67416,67442,67425,67448]},
            {name: 'Scott', value: false, zipCode: [67871]},
            {name: 'Sedgwick', value: false, zipCode: [67206,67207,67208,67216,67147,67037,67050,67101,67223,67230,67235,67260,67217,67215,67214,67213,67212,67211,67210,67219,67218,67220,67226,67202,67203,67204,67205,67209,67067,67030,67055,67108,67001,67060,67026,67149,67120,67221,67025,67052,67016]},
            {name: 'Seward', value: false, zipCode: [67859,67901]},
            {name: 'Shawnee', value: false, zipCode: [66615,66533,66607,66542,66621,66622,66609,66608,66606,66605,66604,66603,66619,66611,66612,66614,66616,66546,66539,66402]},
            {name: 'Sheridan', value: false, zipCode: [67740,67757]},
            {name: 'Sherman', value: false, zipCode: [67735,67733,67741]},
            {name: 'Smith', value: false, zipCode: [66932,66967,67628,67638,66951,66952]},
            {name: 'Stafford', value: false, zipCode: [67576,67557,67578,67545,67550]},
            {name: 'Stanton', value: false, zipCode: [67855,67862]},
            {name: 'Stevens', value: false, zipCode: [67952,67951]},
            {name: 'Sumner', value: false, zipCode: [67022,67106,67031,67105,67004,67119,67103,67152,67110,67120,67051,67013,67140]},
            {name: 'Thomas', value: false, zipCode: [67743,67701,67753,67734,67732]},
            {name: 'Trego', value: false, zipCode: [67656,67672,67631]},
            {name: 'Wabaunsee', value: false, zipCode: [66423,66507,66526,66501,66547,66431,66401,66834]},
            {name: 'Wallace', value: false, zipCode: [67762,67761,67758]},
            {name: 'Washington', value: false, zipCode: [66937,66945,66968,66933,66953,66946,66944,66943,66955,66958,66962]},
            {name: 'Wichita', value: false, zipCode: [67861,67863]},
            {name: 'Wilson', value: false, zipCode: [66717,66736,66710,66757,66714]},
            {name: 'Woodson', value: false, zipCode: [66761,66777,66758,66783]},
            {name: 'Wyandotte', value: false, zipCode: [66111,66101,66102,66103,66104,66105,66106,66109,66160,66112,66115,66118,66012]},
        ]
    },
    {
        id: 18,
        name: 'Kentucky',
        stateId: 'KY',
        child: [
            {name: 'Adair', value: false, zipCode: [42728]},
            {name: 'Allen', value: false, zipCode: [42164]},
            {name: 'Anderson', value: false, zipCode: [40342]},
            {name: 'Ballard', value: false, zipCode: [42087,42053,42022,42056,42024]},
            {name: 'Barren', value: false, zipCode: [42127,42141,42160]},
            {name: 'Bath', value: false, zipCode: [40371,40360,40374]},
            {name: 'Bell', value: false, zipCode: [40965,40902,40977]},
            {name: 'Boone', value: false, zipCode: [41042,41005,41091,41080,41092,41030,41048,41094]},
            {name: 'Bourbon', value: false, zipCode: [40361,40311,40348,40357]},
            {name: 'Boyd', value: false, zipCode: [41102,41129,41101]},
            {name: 'Boyle', value: false, zipCode: [40422,40440,40468]},
            {name: 'Bracken', value: false, zipCode: [41002,41004,41044,41034]},
            {name: 'Breathitt', value: false, zipCode: [41339]},
            {name: 'Breckinridge', value: false, zipCode: [40146,40143,40111]},
            {name: 'Bullitt', value: false, zipCode: [40047,40229,40165,40150,40109]},
            {name: 'Butler', value: false, zipCode: [42261,42273]},
            {name: 'Caldwell', value: false, zipCode: [42411,42445]},
            {name: 'Calloway', value: false, zipCode: [42049,42036,42020,42071]},
            {name: 'Campbell', value: false, zipCode: [41076,41075,41071,41001,41073,41007,41074,41085,41059]},
            {name: 'Carlisle', value: false, zipCode: [42021,42035,42023]},
            {name: 'Carroll', value: false, zipCode: [41098,41083,41045,41008]},
            {name: 'Carter', value: false, zipCode: [41164,41143]},
            {name: 'Casey', value: false, zipCode: [42539]},
            {name: 'Christian', value: false, zipCode: [42220,42266,42254,42232,42223,42217,42262,42240]},
            {name: 'Clark', value: false, zipCode: [40391]},
            {name: 'Clay', value: false, zipCode: [40962,40972]},
            {name: 'Clinton', value: false, zipCode: [42602]},
            {name: 'Crittenden', value: false, zipCode: [42064]},
            {name: 'Cumberland', value: false, zipCode: [42759,42717]},
            {name: 'Daviess', value: false, zipCode: [42303,42366,42355,42301,42376,42356,42378]},
            {name: 'Edmonson', value: false, zipCode: [42210]},
            {name: 'Elliott', value: false, zipCode: [41171]},
            {name: 'Estill', value: false, zipCode: [40472,40336]},
            {name: 'Fayette', value: false, zipCode: [40526,40509,40504,40502,40503,40508,40505,40507,40517,40536,40513,40511,40510,40516,40515,40514]},
            {name: 'Fleming', value: false, zipCode: [41039,41041]},
            {name: 'Floyd', value: false, zipCode: [41602,41653,41666,41651,41647,41606,41669,41645,41621,41649,41601,41605,41635]},
            {name: 'Franklin', value: false, zipCode: [40601]},
            {name: 'Fulton', value: false, zipCode: [42041,42050]},
            {name: 'Gallatin', value: false, zipCode: [41086,41046,41095]},
            {name: 'Garrard', value: false, zipCode: [40444]},
            {name: 'Grant', value: false, zipCode: [41035,41010,41030,41097]},
            {name: 'Graves', value: false, zipCode: [42085,42051,42066,42040,42082,42039,42088,42035,42061,42079]},
            {name: 'Grayson', value: false, zipCode: [42726,42754,42712,42721]},
            {name: 'Green', value: false, zipCode: [42743]},
            {name: 'Greenup', value: false, zipCode: [41144,41139,41183,41169,41101,41175]},
            {name: 'Hancock', value: false, zipCode: [42348,42351]},
            {name: 'Hardin', value: false, zipCode: [42776,42784,42701,40162,40160,40177,42724,40121,40175]},
            {name: 'Harlan', value: false, zipCode: [40854,40819,40831,40828,40855,40863,40873,40847,40823,40807,40815]},
            {name: 'Harrison', value: false, zipCode: [41031,41003]},
            {name: 'Hart', value: false, zipCode: [42749,42765,42746,42713]},
            {name: 'Henderson', value: false, zipCode: [42406,42420,42452,42458]},
            {name: 'Henry', value: false, zipCode: [40011,40068,40057,40058,40050,40019]},
            {name: 'Hickman', value: false, zipCode: [42031,42032]},
            {name: 'Hopkins', value: false, zipCode: [42441,42413,42442,42453,42431,42440,42410,42408,42436,42464]},
            {name: 'Jackson', value: false, zipCode: [40402,40486,40447]},
            {name: 'Jefferson', value: false, zipCode: [40213,40242,40223,40245,40241,40218,40219,40214,40216,40211,40059,40291,40299,40220,40222,40229,40228,40023,40272,40207,40209,40258,40118,40243,40205,40217,40025]},
            {name: 'Jessamine', value: false, zipCode: [40390,40356,40339]},
            {name: 'Johnson', value: false, zipCode: [41265,41222,41240,41268]},
            {name: 'Kenton', value: false, zipCode: [41015,41011,41017,41018,41051,41016,41042,41014]},
            {name: 'Knott', value: false, zipCode: [41822,41844]},
            {name: 'Knox', value: false, zipCode: [40903,40906,40935]},
            {name: 'Larue', value: false, zipCode: [42748,42716,42757]},
            {name: 'Laurel', value: false, zipCode: [40701,40741,40744,40729]},
            {name: 'Lawrence', value: false, zipCode: [41124,41230]},
            {name: 'Lee', value: false, zipCode: [41311]},
            {name: 'Leslie', value: false, zipCode: [41749]},
            {name: 'Letcher', value: false, zipCode: [41858,41837,41838,41835,41537,41840,41804,41517]},
            {name: 'Lewis', value: false, zipCode: [41179,41141]},
            {name: 'Lincoln', value: false, zipCode: [40437,40448,40484,40419]},
            {name: 'Livingston', value: false, zipCode: [42081,42028,42078,42058,42045]},
            {name: "Logan", value: false, zipCode: [42202,42276,42256,42206]},
            {name: 'Lyon', value: false, zipCode: [42038,42055]},
            {name: 'Madison', value: false, zipCode: [40475,40404,40403]},
            {name: 'Magoffin', value: false, zipCode: [41465]},
            {name: 'Marion', value: false, zipCode: [40037,40033,40060,40009]},
            {name: 'Marshall', value: false, zipCode: [42044,42029,42048,42025]},
            {name: 'Martin', value: false, zipCode: [41267,41224]},
            {name: 'Mason', value: false, zipCode: [41034,41056,41055,41064]},
            {name: 'McCracken', value: false, zipCode: [42003,42001]},
            {name: 'McCreary', value: false, zipCode: [42635,42649,42653,42647]},
            {name: 'McLean', value: false, zipCode: [42352,42327,42372,42322,42350]},
            {name: 'Meade', value: false, zipCode: [40108,40117,40155]},
            {name: 'Menifee', value: false, zipCode: [40322]},
            {name: 'Mercer', value: false, zipCode: [40330,40310,40372]},
            {name: 'Metcalfe', value: false, zipCode: [42129,42166]},
            {name: 'Monroe', value: false, zipCode: [42167,42133,42140]},
            {name: 'Montgomery', value: false, zipCode: [40353,40337]},
            {name: 'Morgan', value: false, zipCode: [41472,41425]},
            {name: 'Muhlenberg', value: false, zipCode: [42345,42367,42330,42332,42256,42339,42374,42337,42325,42323,42321]},
            {name: 'Nelson', value: false, zipCode: [40107,40008,40012,40052,40004,40051,40013,40020]},
            {name: 'Nicholas', value: false, zipCode: [40311]},
            {name: 'Ohio', value: false, zipCode: [42347,42370,42349,42369,42320,42354,42328,42343]},
            {name: 'Oldham', value: false, zipCode: [40056,40031,40014,40010,40026,40077,40059]},
            {name: 'Owen', value: false, zipCode: [40359]},
            {name: 'Owsley', value: false, zipCode: [41314]},
            {name: 'Pendleton', value: false, zipCode: [41040,41006]},
            {name: 'Perry', value: false, zipCode: [41701,41773,41751,41774,41729,41721]},
            {name: 'Pike', value: false, zipCode: [41572,41503,41522,41501,41514,41544,41528,41553]},
            {name: 'Powell', value: false, zipCode: [40380,40312]},
            {name: 'Pulaski', value: false, zipCode: [42501,42533,42503,42519,42544,42518,42553,42567]},
            {name: 'Robertson', value: false, zipCode: [41064]},
            {name: 'Rockcastle', value: false, zipCode: [40456,40409,40445]},
            {name: 'Rowan', value: false, zipCode: [40351]},
            {name: 'Russell', value: false, zipCode: [42629,42642]},
            {name: 'Scott', value: false, zipCode: [40379,40324,40370]},
            {name: 'Shelby', value: false, zipCode: [40067,40065]},
            {name: 'Simpson', value: false, zipCode: [42134]},
            {name: 'Spencer', value: false, zipCode: [40023,40071]},
            {name: 'Taylor', value: false, zipCode: [42718]},
            {name: 'Todd', value: false, zipCode: [42286,42220,42204,42234]},
            {name: 'Trigg', value: false, zipCode: [42215,42211]},
            {name: 'Trimble', value: false, zipCode: [40006,40045]},
            {name: 'Union', value: false, zipCode: [42437,42461,42459,42462]},
            {name: 'Warren', value: false, zipCode: [42101,42171,42104,42103,42170,42159]},
            {name: 'Washington', value: false, zipCode: [40069,40061,40078,40040]},
            {name: 'Wayne', value: false, zipCode: [42633]},
            {name: 'Webster', value: false, zipCode: [42404,42455,42456,42444,42406,42409,42450,42463]},
            {name: 'Whitley', value: false, zipCode: [40759,40701,40769]},
            {name: 'Wolfe', value: false, zipCode: [41301,41332]},
            {name: 'Woodford', value: false, zipCode: [40347,40383]},
        ]
    },
    {
        id: 19,
        name: 'Louisiana',
        stateId: 'LA',
        child: [
            {name: 'Acadia', value: false, zipCode: [70525,70531,70526,70559,70516,70543,70534,70578,70556]},
            {name: 'Allen', value: false, zipCode: [70658,70655,70648,70638,71463]},
            {name: 'Ascension', value: false, zipCode: [70778,70737,70346,70086,70769,70725]},
            {name: 'Assumption', value: false, zipCode: [70390,70391,70346,70341,70301,70372,70380,70339]},
            {name: 'Avoyelles', value: false, zipCode: [71369,71350,71362,71323,71355,71327,71351,71341,71322,71333]},
            {name: 'Beauregard', value: false, zipCode: [70660,70653,70634,70662,70652]},
            {name: 'Bienville', value: false, zipCode: [71028,71045,71008,71016,71070,71068,71001]},
            {name: 'Bossier', value: false, zipCode: [71064,71112,71111,71110,71037,71067,71006]},
            {name: 'Caddo', value: false, zipCode: [71029,71061,71069,71004,71129,71101,71103,71104,71105,71106,71107,71108,71109,71119,71118,71044,71060,71009,71043,71082,71033]},
            {name: 'Calcasieu', value: false, zipCode: [70663,70665,70611,70647,70605,70668,70669,70607,70601,70609,70615,70629,70661,70633,70646]},
            {name: 'Caldwell', value: false, zipCode: [71435,71418,71415]},
            {name: 'Cameron', value: false, zipCode: [70631,70645]},
            {name: 'Catahoula', value: false, zipCode: [71343,71368,71340]},
            {name: 'Claiborne', value: false, zipCode: [71040,71003,71038,71048]},
            {name: 'Concordia', value: false, zipCode: [71334,71373,71326,71354]},
            {name: 'De Soto', value: false, zipCode: [71049,71052,71027,71032,71030,71046,71078]},
            {name: 'East Baton Rouge', value: false, zipCode: [70809,70817,70820,70810,70791,70814,70812,70714,70811,70836,70808,70802,70803,70801,70806,70807,70805,70825,70819,70813,70815,70816,70770,70739,70818]},
            {name: 'East Carroll', value: false, zipCode: [71254]},
            {name: 'East Feliciana', value: false, zipCode: [70761,70789,70722,70777,70748]},
            {name: 'Evangeline', value: false, zipCode: [70576,70586,70515,70585,70554,70524,70580]},
            {name: 'Franklin', value: false, zipCode: [71336,71219,71295,71378]},
            {name: 'Grant', value: false, zipCode: [71423,71417,71407,71467,71454,71432]},
            {name: 'Iberia', value: false, zipCode: [70544,70560,70552,70563]},
            {name: 'Iberville', value: false, zipCode: [70788,70764,70757,70740,70772,70721,70776,70780]},
            {name: 'Jackson', value: false, zipCode: [71226,71247,71251,71268,71238]},
            {name: 'Jefferson Davis', value: false, zipCode: [70549,70591,70650,70640,70648,70581,70532,70546]},
            {name: 'Jefferson', value: false, zipCode: [70067,70094,70056,70002,70003,70001,70006,70005,70058,70053,70121,70123,70358,70072,70065,70062,70036]},
            {name: 'Lafayette', value: false, zipCode: [70583,70507,70506,70508,70503,70501,70592,70518,70520,70558,70529]},
            {name: 'Lafourche', value: false, zipCode: [70345,70373,70301,70394,70310,70357,70374,70359,70364,70354]},
            {name: 'LaSalle', value: false, zipCode: [71342,71480,71479,71465,71371]},
            {name: 'Lincoln', value: false, zipCode: [71235,71272,71270,71275,71245,71227]},
            {name: 'Livingston', value: false, zipCode: [70733,70726,70711,70754,70785,70462,70786,70706]},
            {name: 'Madison', value: false, zipCode: [71233,71282]},
            {name: 'Morehouse', value: false, zipCode: [71261,71264,71220,71229,71223]},
            {name: 'Natchitoches', value: false, zipCode: [71457,71066,71469,71002,71497,71468,71031,71411,71414,71450,71456]},
            {name: 'Orleans', value: false, zipCode: [70139,70130,70131,70148,70114,70115,70116,70117,70112,70113,70118,70119,70129,70128,70125,70124,70127,70126,70122,70163]},
            {name: 'Ouachita', value: false, zipCode: [71225,71203,71212,71209,71202,71201,71291,71292,71280]},
            {name: 'Plaquemines', value: false, zipCode: [70041,70050,70037,70091,70038,70082,70083]},
            {name: 'Pointe Coupee', value: false, zipCode: [70732,70783,70757,70755,70760,70759,70715]},
            {name: 'Rapides', value: false, zipCode: [71485,71328,71360,71359,71302,71303,71301,71430,71330,71351,71433,71325,71405,71346,71409]},
            {name: 'Red River', value: false, zipCode: [71019,71034]},
            {name: 'Richland', value: false, zipCode: [71259,71269,71264,71279,71232]},
            {name: 'Sabine', value: false, zipCode: [71462,71449,71486,71469,71426,71406,71450,71419,71429,71065]},
            {name: 'St. Bernard', value: false, zipCode: [70040,70085,70043,70032,70075,70092]},
            {name: 'St. Charles', value: false, zipCode: [70079,70047,70080,70039,70030,70057,70031,70070,70068,70087]},
            {name: 'St. Helena', value: false, zipCode: [70422,70441]},
            {name: 'St. James', value: false, zipCode: [70723,70763,70090,70086,70743,70071,70052]},
            {name: 'St. John the Baptist', value: false, zipCode: [70090,70049,70076,70084,70051,70068]},
            {name: 'St. Landry', value: false, zipCode: [70541,71358,70584,70570,70512,70551,70550,71353,70535,71322,71356,70577,70750,70589]},
            {name: 'St. Martin', value: false, zipCode: [70582,70518,70519,70517,70512]},
            {name: 'St. Mary', value: false, zipCode: [70380,70538,70340,70342,70522,70544,70514,70523,70392]},
            {name: 'St. Tammany', value: false, zipCode: [70445,70448,70458,70420,70452,70471,70433,70437,70463,70427,70447,70460]},
            {name: 'Tangipahoa', value: false, zipCode: [70402,70403,70401,70444,70454,70443,70465,70456,70422,70466,70451]},
            {name: 'Tensas', value: false, zipCode: [71375,71357,71366]},
            {name: 'Terrebonne', value: false, zipCode: [70359,70364,70353,70363,70301,70395,70377,70360,70344,70343]},
            {name: 'Union', value: false, zipCode: [71260,71256,71222,71241,71749,71234,71277]},
            {name: 'Vermilion', value: false, zipCode: [70510,70575,70548,70533,70555,70542,70528]},
            {name: 'Vernon', value: false, zipCode: [71403,71474,70656,71459,71446,71461,71439,70659,70634]},
            {name: 'Washington', value: false, zipCode: [70427,70426,70438]},
            {name: 'Webster', value: false, zipCode: [71021,71075,71018,71071,71023,71055,71073,71072,71039,71024]},
            {name: 'West Baton Rouge', value: false, zipCode: [70710,70719,70767]},
            {name: 'West Carroll', value: false, zipCode: [71263,71242,71266,71253,71237]},
            {name: 'West Feliciana', value: false, zipCode: [70775]},
            {name: 'Winn', value: false, zipCode: [71483,71422,71410,71457,71454,71473,71404]},
        ]
    },
    {
        id: 20,
        name: 'Maine',
        stateId: 'ME',
        child: [
            {name: 'Androscoggin', value: false, zipCode: [4240,4236,4282,4252,4210,4280,4250]},
            {name: 'Aroostook', value: false, zipCode: [4769,4763,4736,4747,4746]},
            {name: 'Cumberland', value: false, zipCode: ['4096','4074','4062','4038','4021','4106','4085','04101','04102','04103','04108','04109','04019','4105','4092']},
            {name: 'Franklin', value: false, zipCode: [4239,4970,4947]},
            {name: 'Hancock', value: false, zipCode: [4662,4605]},
            {name: 'Kennebec', value: false, zipCode: ['4347','4901','04345','04359','4330']},
            {name: 'Knox', value: false, zipCode: [4841]},
            {name: 'Oxford', value: false, zipCode: [4217,4281]},
            {name: 'Penobscot', value: false, zipCode: ['4928','4459','4401','4412','4411','04468','04489','4765']},
            {name: 'Piscataquis', value: false, zipCode: ['04414','04415']},
            {name: 'Sagadahoc', value: false, zipCode: [4530]},
            {name: 'Somerset', value: false, zipCode: [4958]},
            {name: 'Waldo', value: false, zipCode: [4915]},
            {name: 'Washington', value: false, zipCode: [4424,4619,4491,4658,4631,4649]},
            {name: 'York', value: false, zipCode: ['04005','04006','4020','04073','04083','3908','3905','4072','3903','4043','04048','04061','03909','03910','04047','04068','03911']},
        ]
    },
    {
        id: 21,
        name: 'Maryland',
        stateId: 'MD',
        child: [
            {name: 'Allegany', value: false, zipCode: [21532,21502,21557,21562,21530,21555,21539,21521,21560,21545,21542,21529,21766,21540,21543,21524]},
            {name: 'Anne Arundel', value: false, zipCode: [21061,21012,20751,20733,20779,21403,21037,21409,21144,21108,21035,21140,21060,21401,21032,21077,21076,21122,21146,20701,20794,20724,21054,21113,20755,21225,20765,20778,21226,21114,20764,21090,21402,20758]},
            {name: 'Baltimore', value: false, zipCode: [21218,21211,21210,21213,21212,21215,21214,21217,21216,21233,21231,21230,21239,21251,21209,21202,21206,21207,21205,21287,21223,21226,21201,21224,21225,21229]},
            {name: 'Calvert', value: false, zipCode: [20736,20657,20714,20678,20615,20685,20732,20688,20629,20639,20754]},
            {name: 'Caroline', value: false, zipCode: [21649,21640,21641,21639,21629,21660,21636,21655,21632]},
            {name: 'Carroll', value: false, zipCode: [21776,21784,21791,21102,21158,21157,21104,21771,21787,21074]},
            {name: 'Cecil', value: false, zipCode: [21913,21911,21921,21901,21914,21915,21904,21903]},
            {name: 'Charles', value: false, zipCode: [20637,20603,20675,20695,20640,20625,20661,20664,20617,20601,20602,20646,20612,20616,20677]},
            {name: 'Dorchester', value: false, zipCode: [21659,21669,21631,21622,21648,21677,21613,21664,21643,21634,21869,19973]},
            {name: 'Frederick', value: false, zipCode: [21703,21702,21714,21758,21704,21754,21701,21710,21716,21773,21788,21717,21770,21771,21727,21774,21755,21718,21793,21777,21762,21791,21769,21780,21705,21798]},
            {name: 'Garrett', value: false, zipCode: [21536,21550,21520,21538,21523,21532,21531]},
            {name: 'Harford', value: false, zipCode: [21001,21130,21034,21009,21010,21040,21014,21084,21047,21015,21085,21132,21078,21017,21005,21050,21087]},
            {name: 'Howard', value: false, zipCode: [20723,21043,21075,20763,20794,20759,20777,21044,21045,21046,21029,21042,21797,21076]},
            {name: 'Kent', value: false, zipCode: [21635,21651,21620,21610,21661,21678,21667,21645]},
            {name: 'Montgomery', value: false, zipCode: [20901,20812,20816,20895,20896,20814,20817,20851,20852,20838,20906,20902,20837,20815,20872,20882,20841,20871,20861,20905,20854,20880,20878,20874,20892,20894,20889,20705,20904,20868,20866,20707,20879,20860,20862,20855,20850,20910,20912,20903,20832,20833,20853,20899,20877,20876,20886,20818,20783]},
            {name: "Prince George's", value: false, zipCode: [20742,20740,20784,20737,20783,20613,20772,20708,20716,20774,20748,20743,20721,20706,20770,20712,20744,20746,20747,20745,20707,20720,20781,20608,20710,20705,20623,20782,20769,20735,20762,20722,20785,20903,20601,20607,20715]},
            {name: "Queen Anne's", value: false, zipCode: [21668,21620,21619,21623,21638,21607,21666,21658,21617,21628]},
            {name: 'Somerset', value: false, zipCode: [21821,21822,21871,21853,21817,21824,21866,21867,21851]},
            {name: "St. Mary's", value: false, zipCode: [20692,20690,20674,20622,20659,20653,20650,20620,20619,20634]},
            {name: 'Talbot', value: false, zipCode: [21654,21663,21625,21601,21673,21665,21671]},
            {name: 'Washington', value: false, zipCode: [21719,21756,21742,21722,21740,21758,21733,21713,21783,21782,21711,21750,21715,21795,21781,21779,21767,21734]},
            {name: 'Wicomico', value: false, zipCode: [21856,21850,21849,21861,21814,21830,21840,21826,21875,21837,21804,21801,21810,21874,21865]},
            {name: 'Worcester', value: false, zipCode: [21864,21811,21841,21829,21813,21872,21842,21851,21863]},
        ]
    },
    {
        id: 22,
        name: 'Massachusetts',
        stateId: 'MA',
        child: [
            {name: 'Barnstable', value: false, zipCode: [2645,'02671','02645','02646',2556,'02670','02660',2675,2649,2537,'02644','02563',2540,2532,2642,'02561','02562','02638','02641',2559,'02673','02664','02543','02540','02532','02553','02559',2536,2633,2660,2639,2673]},
            {name: 'Berkshire', value: false, zipCode: [1242,1247,1201,'01236','01230']},
            {name: 'Bristol', value: false, zipCode: [2743,'02780','02767','02718',2703,2748,2747,2767,2048,2771,'02740','02744','02745','02746','02724','02723','02720','02721','02717','02790','02747',2790,2766,2777]},
            {name: 'Dukes', value: false, zipCode: [2568,2557]},
            {name: 'Essex', value: false, zipCode: ['01843','01840','01841',1930,1960,'01965','01915',1970,1844,1950,'01832','01830','01835','01905','01904','01902','01901']},
            {name: 'Franklin', value: false, zipCode: [1376,'01349','01344',1373,1370]},
            {name: 'Hampden', value: false, zipCode: [1057,1085,'01020','01022','01013','01151','01144','01118','01119','01129','01128','01107','01105','01104','01103','01109','01108',1040]},
            {name: 'Hampshire', value: false, zipCode: ['01060','01062','01063','01053']},
            {name: 'Middlesex', value: false, zipCode: [1752,1460,1778,'01701','01702',2148,'02464','02466','02461','02462','02468','02465','02467','02460','02458','02459','02142','02140','02141','02139','02138','02144','02145','02143','01851','01850','01852','01854',2176,'02453','02451','02452',1742,1801,2472,1821,2149,1463,2155,1731]},
            {name: 'Nantucket', value: false, zipCode: [2564,2554]},
            {name: 'Norfolk', value: false, zipCode: [2054,'02169','02171','02170']},
            {name: 'Plymouth', value: false, zipCode: [2359,2360,2738,2332,2050,2739,2346,2571,'02558','02538',2066,2538,2347,'02050','02332','02301','02302','02576','02571']},
            {name: 'Suffolk', value: false, zipCode: [2151,'02120','02121','02122','02124','02125','02126','02127','02128','02129','02210','02215','02467','02119','02118','02111','02110','02113','02115','02114','02116','02199','02134','02136','02135','02133','02132','02131','02130','02203','02205','02163','02108','02109',2150]},
            {name: 'Worcester', value: false, zipCode: [1516,1430,1436,1420,'01608','01610','01609','01602','01603','01604','01605','01606','01607',1453,1588,'01523','01561','01518','01566',1440,'01434','01432',1092,1772]},
        ]
    },
    {
        id: 23,
        name: 'Michigan',
        stateId: 'MI',
        child: [
            {name: 'Alcona', value: false, zipCode: [48742,49747,48762,48740]},
            {name: 'Alger', value: false, zipCode: [49862,49816,49839]},
            {name: 'Allegan', value: false, zipCode: [49406,49408,49348,49078,49080,49328,49070,49453,49010,49323]},
            {name: 'Alpena', value: false, zipCode: [49707,49766]},
            {name: 'Antrim', value: false, zipCode: [49729,49659,49730,49627,49622,49611,49629,49615,49612]},
            {name: 'Arenac', value: false, zipCode: [48766,48703,48765,48659,48658,48749]},
            {name: 'Baraga', value: false, zipCode: [49861,49946,49958,49908,49919,49970,49962]},
            {name: 'Barry', value: false, zipCode: [49060,49058,48897,49050,49333,49073,49046,49325]},
            {name: 'Bay', value: false, zipCode: [48706,48708,48650,48732,48611]},
            {name: 'Benzie', value: false, zipCode: [49650,49683,49635,49617,49643,49640,49616,49628]},
            {name: 'Berrien', value: false, zipCode: [49117,49098,49022,49128,49101,49119,49125,49113,49038,49085,49103,49106,49115,49111,49107,49127,49120]},
            {name: 'Branch', value: false, zipCode: [49089,49094,49028,49036,49082]},
            {name: 'Calhoun', value: false, zipCode: [49011,49015,49037,49224,49017,49029,49092,49014,49068,49245]},
            {name: 'Cass', value: false, zipCode: [49112,49095,49031,49067,49047]},
            {name: 'Charlevoix', value: false, zipCode: [49796,49720,49712,49713,49727,49782]},
            {name: 'Cheboygan', value: false, zipCode: [49721,49799,49765,49749]},
            {name: 'Chippewa', value: false, zipCode: [49715,49788,49725,49783]},
            {name: 'Clare', value: false, zipCode: [48622,48617,48625]},
            {name: 'Clinton', value: false, zipCode: [48894,48879,48831,48835,48853,48808,48866,48848,48822,48837,48820]},
            {name: 'Crawford', value: false, zipCode: [49738]},
            {name: 'Delta', value: false, zipCode: [49878,49835,49880,49837,49829]},
            {name: 'Dickinson', value: false, zipCode: [49870,49801,49876,49802]},
            {name: 'Eaton', value: false, zipCode: [49021,48917,48890,48827,49076,48821,48837,48876,48861,49096,48813]},
            {name: 'Emmet', value: false, zipCode: [49706,49701,49740,49770,49722,49716,49755,49764,49718,49769]},
            {name: 'Genesee', value: false, zipCode: [48436,48473,48529,48509,48519,48420,48502,48504,48505,48506,48507,48503,48430,48438,48451,48458,48433,48463,48423,48439,48457]},
            {name: 'Gladwin', value: false, zipCode: [48624,48612]},
            {name: 'Gogebic', value: false, zipCode: [49959,49911,49947,49968,49969,49938]},
            {name: 'Grand Traverse', value: false, zipCode: [49686,49684,49633,49643,49637,49649,49685]},
            {name: 'Gratiot', value: false, zipCode: [48877,48801,48806,48847,48871,48880,48615]},
            {name: 'Hillsdale', value: false, zipCode: [49274,49252,49271,49242,49262,49250,49232,49227,49288,49255,49249]},
            {name: 'Houghton', value: false, zipCode: [49917,49963,49931,49905,49916,49945,49913,49934,49930,49921,49955,49922]},
            {name: 'Huron', value: false, zipCode: [48731,48441,48445,48468,48413,48759,48467,48754,48725,48475,48720,48755]},
            {name: 'Ingham', value: false, zipCode: [49251,48912,48933,48915,48911,48910,48906,48819,48823,48825,48824,48917,49285,48854,48840,48892,48842,48895,48864]},
            {name: 'Ionia', value: false, zipCode: [48851,48881,48875,48846,48815,48870,48834,48845,48860,48873,48809,48849]},
            {name: 'Iosco', value: false, zipCode: [48748,48763,48770,48730,48750]},
            {name: 'Iron', value: false, zipCode: [49915,49935,49920,49902,49903,49927]},
            {name: 'Isabella', value: false, zipCode: [48858,48859,48893,48878,48883,48896,48617]},
            {name: 'Jackson', value: false, zipCode: [49201,49261,49230,49202,49203,49269,49254,49284,49240,49237,49283,49241]},
            {name: 'Kalamazoo', value: false, zipCode: [49012,49001,49006,49007,49008,49074,49097,49004,49088,49034,49083,49048,49087,49002,49024,49060,49053]},
            {name: 'Kalkaska', value: false, zipCode: [49646,49680,49676]},
            {name: 'Kent', value: false, zipCode: [49546,49506,49505,49503,49507,49504,49508,49525,49301,49341,49512,49548,49345,49321,49331,49330,49318,49418,49319,49316,49315,49534,49544,49509,49519]},
            {name: 'Keweenaw', value: false, zipCode: [49950,49918,49901]},
            {name: 'Lake', value: false, zipCode: [49304,49656]},
            {name: 'Lapeer', value: false, zipCode: [48464,48446,48412,48444,48421,48455,48461,48727,48428,48003]},
            {name: 'Leelanau', value: false, zipCode: [49664,49682,49653,49654,49670,49621,49684,49630,49636,49674]},
            {name: 'Lenawee', value: false, zipCode: [49247,49286,49220,49228,49238,49229,49233,49256,49236,49235,49248,49265,49253,49221]},
            {name: 'Livingston', value: false, zipCode: [48836,48843,48116,48169,48353,48430]},
            {name: 'Luce', value: false, zipCode: [49868]},
            {name: 'Mackinac', value: false, zipCode: [49762,49781,49757]},
            {name: 'Macomb', value: false, zipCode: [48015,48081,48080,48082,48065,48047,48315,48317,48062,48021,48005,48092,48093,48091,48089,48088,48310,48313,48312,48314,48026,48043,48048,48066,48041]},
            {name: 'Manistee', value: false, zipCode: [49660,49626,49619,49675,49689,49613,49625,49634,49645]},
            {name: 'Marquette', value: false, zipCode: [49861,49849,49841,49855,49866,49879,49871,49808]},
            {name: 'Mason', value: false, zipCode: [49454,49405,49411,49410,49431]},
            {name: 'Mecosta', value: false, zipCode: [49332,49336,49338,49346,49307,49305]},
            {name: 'Menominee', value: false, zipCode: [49887,49858,49847,49812,49874,49821]},
            {name: 'Midland', value: false, zipCode: [48642,48640,48657,48618]},
            {name: 'Missaukee', value: false, zipCode: [49651,49632,49657]},
            {name: 'Monroe', value: false, zipCode: [48144,49270,48182,48117,48166,48162,48161,48157,48133,48179,48160,48131,48159,48140]},
            {name: 'Montmorency', value: false, zipCode: [48850,49347,49339,48818,48884,48838,48829,48888,48811,48852,49329]},
            {name: 'Muskegon', value: false, zipCode: [49746,49709,49756]},
            {name: 'Newaygo', value: false, zipCode: [49444,49441,49415,49445,49442,49443,49440,49457,49461,49451,49437]},
            {name: 'Oakland', value: false, zipCode: [49349,49337,49327,49412]},
            {name: 'Oceana', value: false, zipCode: [48336,48335,48069,48381,48307,48320,48076,48334,48331,48025,48220,48098,48083,48085,48084,48367,48390,48030,48017,48371,48237,48009,48033,48034,48075,48071,48306,48309,48178,48304,48377,48375,48374,48167,48326,48072,48070,48362,48393,48442,48342,48340,48341,48462,48067,48073]},
            {name: 'Ogemaw', value: false, zipCode: [49455,49421,49437,49446,49452,49459,49449,49436,49420]},
            {name: 'Ontonagon', value: false, zipCode: [48635,48654,48661,48610,48756]},
            {name: 'Osceola', value: false, zipCode: [49947,49910,49929,49912,49953,49925,49960,49971,49948]},
            {name: 'Oscoda', value: false, zipCode: [49655,49677,49631,49639,49688,49665]},
            {name: 'Otsego', value: false, zipCode: [48647]},
            {name: 'Ottawa', value: false, zipCode: [49735,49795]},
            {name: 'Presque Isle', value: false, zipCode: [49417,49456,49423,49401,49464,49404,49430,49435,49448,49426,49424,49428,49409]},
            {name: 'Roscommon', value: false, zipCode: [49765,49777,49759,49779,49776]},
            {name: 'Saginaw', value: false, zipCode: [48651,48656,48653,48630,48629]},
            {name: 'Sanilac', value: false, zipCode: [48601,48604,48457,48417,48626,48734,48649,48415,48637,48609,48623,48722,48655,48602,48607,48616]},
            {name: 'Schoolcraft', value: false, zipCode: [48469,48419,48472,48453,48422,48471,48466,48456,48416,48454,48427,48401,48434,48450]},
            {name: 'Shiawassee', value: false, zipCode: [49854]},
            {name: 'St. Clair', value: false, zipCode: [48414,48867,48872,48841,48418,48429,48476,48817,48460,48857,48449,48848]},
            {name: 'St. Joseph', value: false, zipCode: [48049,48059,48039,48014,48001,48022,48040,48079,48097,48060]},
            {name: 'Tuscola', value: false, zipCode: [49030,49091,49099,49042,49032,49072,49093,49040]},
            {name: 'Van Buren', value: false, zipCode: [48726,48757,48723,48701,48735,48768,48767,48435,48746,48733,48744,48741]},
            {name: 'Washtenaw', value: false, zipCode: [49056,49027,49079,49065,49064,49013,49055,49071,49045,49090,49026,49057]},
            {name: 'Wayne', value: false, zipCode: [48130,48105,48158,48189,48178,48103,48104,48108,48109,48198,48197,48176,48118]},
            {name: 'Wexford', value: false, zipCode: [48209,48208,48202,48201,48207,48206,48205,48204,48227,48238,48239,48234,48235,48233,48203,48243,48228,48226,48214,48215,48216,48217,48210,48211,48212,48213,48219,48224,48221,48223]},
        ]
    },
    {
        id: 24,
        name: 'Minnesota',
        stateId: 'MN',
        child: [
            {name: 'Aitkin', value: false, zipCode: [56350,55760,56469,56431,55787,55748]},
            {name: 'Anoka', value: false, zipCode: [55303,55005,55092,55011,55014,55330,55433,55448,55025,55421,55304,55079,55434,55449,55038,55432,55070]},
            {name: 'Becker', value: false, zipCode: [56544,56554,56575,56521,56464,56569,56511,56501,56570,56591]},
            {name: 'Beltrami', value: false, zipCode: [56601,56650,56678,56671,56670,56683,56647,56666,56630]},
            {name: 'Benton', value: false, zipCode: [56367,56329,56379]},
            {name: 'Big Stone', value: false, zipCode: [56278,56211,56225,56227,56276,56236,56240,56210]},
            {name: 'Blue Earth', value: false, zipCode: [56037,56055,56065,56001,56090,56024,56063,56010,56080,56078,56034]},
            {name: 'Brown', value: false, zipCode: [56019,56073,56087,56030,56085,56041,56266]},
            {name: 'Carlton', value: false, zipCode: [55707,55720,55726,55718,55733,55767,55797,55798,55757]},
            {name: 'Carver', value: false, zipCode: [55322,55360,55315,55318,55386,55331,55375,55388,55387,55367,55317,55339,55397,55368]},
            {name: 'Cass', value: false, zipCode: [56672,56474,56473,56633,56484,56401,56641,56468,56435,56452,56626,56655]},
            {name: 'Chippewa', value: false, zipCode: [56262,56295,56265,56222,56260,56241]},
            {name: 'Chisago', value: false, zipCode: [55084,55092,55013,55025,55079,55069,55032,55074,55045,55056,55012]},
            {name: 'Clay', value: false, zipCode: [56546,56529,56514,56580,56560,56563,56562,56547,56549,56536,56585,56552,56525]},
            {name: 'Clearwater', value: false, zipCode: [56634,56557,56652,56621,56676,56644]},
            {name: 'Cook', value: false, zipCode: [55612,55604]},
            {name: 'Cottonwood', value: false, zipCode: [56183,56174,56101,56159,56145,56118]},
            {name: 'Crow Wing', value: false, zipCode: [56450,56449,56455,56472,56444,56442,56448,56468,56441,56465,56401,56447,56425,56474]},
            {name: 'Dakota', value: false, zipCode: [55009,55033,55031,55306,55337,55118,55077,55044,55124,55068,55085,55150,55120,55076,55024,55075,55121,55122,55123,55065]},
            {name: 'Dodge', value: false, zipCode: [55955,55940,55927,55924,55944,55985]},
            {name: 'Douglas', value: false, zipCode: [56319,56326,56354,56355,56308,56343,56360,56332,56315]},
            {name: 'Faribault', value: false, zipCode: [56051,56025,56014,56097,56013,56033,56027,56098,56068,56023]},
            {name: 'Fillmore', value: false, zipCode: [55935,55939,55949,55954,55990,55962,55923,55975,55922,55961,55965,55971]},
            {name: 'Freeborn', value: false, zipCode: [56043,56042,56009,56020,56032,56007,56036,56016,56029,56045,56035]},
            {name: 'Goodhue', value: false, zipCode: [55009,55992,55027,55018,55983,55946,55026,55066,55041,55089,55963]},
            {name: 'Grant', value: false, zipCode: [56309,56248,56339,56311,56274,56590,56531]},
            {name: 'Hennepin', value: false, zipCode: [55429,55430,55441,55373,55357,55442,55447,55446,55422,55331,55423,55427,55428,55369,55327,55391,55364,55311,55375,55404,55409,55408,55407,55406,55405,55403,55402,55401,55454,55455,55418,55419,55410,55411,55412,55413,55414,55415,55416,55417,55426,55359,55374,55421,55345,55343,55305,55340,55316,55424,55436,55435,55439,55356,55344,55346,55347,55384,55443,55445,55444,55420,55425,55431,55437,55438]},
            {name: 'Houston', value: false, zipCode: [55974,55943,55919,55947,55941,55921,55931]},
            {name: 'Hubbard', value: false, zipCode: [56467,56470,56461,56433,56458]},
            {name: 'Isanti', value: false, zipCode: [55080,55040,55008,55006]},
            {name: 'Itasca', value: false, zipCode: [55721,55769,55775,56636,55742,55746,55753,55744,55709,55722,55793,55764,56681,56639,56628,55716,55786]},
            {name: 'Jackson', value: false, zipCode: [56143,56101,56111,56137,56161,56150]},
            {name: 'Kanabec', value: false, zipCode: [56358,55051,55006,55030,55007]},
            {name: 'Kandiyohi', value: false, zipCode: [56282,56209,56201,56289,56281,56279,56288,56251,56216,56253,56273,56312]},
            {name: 'Kittson', value: false, zipCode: [56720,56731,56728,56755,56733,56735,56732,56729,56734]},
            {name: 'Koochiching', value: false, zipCode: [56653,56649,56660,56661,56627,56668]},
            {name: 'Lac qui Parle', value: false, zipCode: [56257,56256,56212,56232,56218]},
            {name: 'Lake of the Woods', value: false, zipCode: [56623,56686]},
            {name: 'Lake', value: false, zipCode: [55616,55603,55614,55601,55609]},
            {name: 'Le Sueur', value: false, zipCode: [56096,56017,56057,56052,56028,56071,56050,56082,56058,56069]},
            {name: 'Lincoln', value: false, zipCode: [56149,56136,56178,56142,56113]},
            {name: 'Lyon', value: false, zipCode: [56291,56239,56229,56170,56169,56132,56258,56115,56264,56157,56175]},
            {name: 'Mahnomen', value: false, zipCode: [56516,56651,56557,56566,56589]},
            {name: 'Marshall', value: false, zipCode: [56758,56757,56738,56727,56762,56744,56710,56760,56737,56713]},
            {name: 'Martin', value: false, zipCode: [56171,56176,56181,56039,56127,56031,56075,56121,56088]},
            {name: 'McLeod', value: false, zipCode: [55381,55350,55385,55395,55336,55354,55312,55370]},
            {name: 'Meeker', value: false, zipCode: [56228,55350,55325,55355,55324,55389,56243,55329]},
            {name: 'Mille Lacs', value: false, zipCode: [56363,56353,56342,56386,55371,56359,56313,56330]},
            {name: 'Morrison', value: false, zipCode: [56364,56384,56340,56318,56382,56314,56328,56345,56475,56466,56338,56373]},
            {name: 'Mower', value: false, zipCode: [55951,55973,55936,55977,55912,55953,55933,55909,55970,55918,55950,55982,55926,55967]},
            {name: 'Murray', value: false, zipCode: [56125,56122,56141,56151,56172,56131,56114,56123,56175,56132]},
            {name: 'Nicollet', value: false, zipCode: [56082,56021,56003,56054,56074]},
            {name: 'Nobles', value: false, zipCode: [56155,56153,56119,56131,56110,56129,56168,56185,56167,56187,56117]},
            {name: 'Norman', value: false, zipCode: [56550,56519,56548,56510,56574,56584,56581,56545]},
            {name: 'Olmsted', value: false, zipCode: [55934,55960,55902,55903,55901,55906,55904,55905,55920,55976,55929]},
            {name: 'Otter Tail', value: false, zipCode: [56588,56524,56361,56518,56482,56527,56551,56534,56587,56537,56533,56573,56324,56567,56515,56528,56576,56571,56572,56586]},
            {name: 'Pennington', value: false, zipCode: [56701,56725,56754]},
            {name: 'Pine', value: false, zipCode: [55072,55037,55795,55704,55783,55712,55756,55735,55036,55063,55007,55069]},
            {name: 'Pipestone', value: false, zipCode: [56139,56164,56144,56128,56170,56140,56186]},
            {name: 'Polk', value: false, zipCode: [56651,56716,56684,56592,56736,56523,56535,56540,56646,56723,56721,56556,56542,56517,56568]},
            {name: 'Pope', value: false, zipCode: [56385,56323,56381,56349,56334,56327]},
            {name: 'Ramsey', value: false, zipCode: [55112,55117,55119,55109,55127,55110,55126,55114,55116,55130,55101,55155,55102,55103,55106,55107,55104,55105,55108,55113]},
            {name: 'Red Lake', value: false, zipCode: [56715,56750,56742,56748]},
            {name: 'Redwood', value: false, zipCode: [56293,56283,56166,56214,56255,56180,56263,56294,56224,56266,56152,56292,56287,56083]},
            {name: 'Renville', value: false, zipCode: [55342,56230,56270,55333,55314,55332,55310,56277,56285,56284]},
            {name: 'Rice', value: false, zipCode: [55053,55021,55019,55052,55057,55046,55087]},
            {name: 'Rock', value: false, zipCode: [56158,56116,56147,56173,56134,56156,56138,56144]},
            {name: 'Roseau', value: false, zipCode: [56759,56714,56763,56726,56673,56751]},
            {name: 'Scott', value: false, zipCode: [55352,56071,55378,56011,55379,55372,55020,55054]},
            {name: 'Sherburne', value: false, zipCode: [55398,55308,55319,55330,55309]},
            {name: 'Sibley', value: false, zipCode: [55338,55396,55334,55307,55336,55366,56044,55335]},
            {name: 'St. Louis', value: false, zipCode: [55711,55719,55713,55734,55771,55705,55708,55792,55803,55802,55807,55806,55805,55804,55808,55810,55811,55812,55736,55768,55710,55723,55765,55790,55782,55731,55741,55814,55751,55746,55706,55796,55750,55758]},
            {name: 'Stearns', value: false, zipCode: [56340,56316,56304,56301,56303,56352,56362,56310,55382,56376,56321,56335,56371,55320,56320,56369,56375,56331,56356,56377,56368,56307,56312,56325,56378,56387,55353,56374]},
            {name: 'Steele', value: false, zipCode: [55060,55917,56026,55049]},
            {name: 'Stevens', value: false, zipCode: [56207,56267,56244,56221,56235]},
            {name: 'Swift', value: false, zipCode: [56271,56215,56231,56208,56226,56249,56252]},
            {name: 'Todd', value: false, zipCode: [56437,56446,56347,56389,56360,56318,56440,56453,56336,56438]},
            {name: 'Traverse', value: false, zipCode: [56583,56296,56236,56219]},
            {name: 'Wabasha', value: false, zipCode: [55932,55991,55964,55956,55945,55957,55910,55041,55981]},
            {name: 'Wadena', value: false, zipCode: [56477,56482,56479,56481,56434,56464]},
            {name: 'Waseca', value: false, zipCode: [56072,56048,56091,56093]},
            {name: 'Washington', value: false, zipCode: [55128,55115,55025,55038,55043,55082,55016,55090,55110,55042,55055,55073,55047,55129,55125,55001,55071,55003]},
            {name: 'Watonwan', value: false, zipCode: [56056,56062,56160,56162,56120,56060,56081,56022]},
            {name: 'Wilkin', value: false, zipCode: [56522,56594,56520,56543,56553,56565,56579]},
            {name: 'Winona', value: false, zipCode: [55910,55969,55959,55987,55947,55952,55979,55972,55925]},
            {name: 'Wright', value: false, zipCode: [55302,55349,55321,55382,55320,55358,55341,55363,55301,55330,55362,55313,55390,55373,55376,55328]},
            {name: 'Yellow Medicine', value: false, zipCode: [56220,56223,56237,56297,56264,56280,56241,56245]},
        ]
    },
    {
        id: 25,
        name: 'Mississippi',
        stateId: 'MS',
        child: [
            {name: 'Adams', value: false, zipCode: [39120]},
            {name: 'Alcorn', value: false, zipCode: [38865,38846,38834]},
            {name: 'Amite', value: false, zipCode: [39645,39638,39633]},
            {name: 'Attala', value: false, zipCode: [39108,39160,39090,39067]},
            {name: 'Benton', value: false, zipCode: [38603,38633]},
            {name: 'Bolivar', value: false, zipCode: [38773,38746,38725,38769,38762,38781,38764,38732,38759,38774,38720,38730,38740,38733,38772,38726]},
            {name: 'Calhoun', value: false, zipCode: [38916,38839,38951,38914,38915,38878]},
            {name: 'Carroll', value: false, zipCode: [38917,39176,38947]},
            {name: 'Chickasaw', value: false, zipCode: [38851,39776,38850,38860]},
            {name: 'Choctaw', value: false, zipCode: [39735,39745,39772]},
            {name: 'Claiborne', value: false, zipCode: [39150,39144,39096,39086]},
            {name: 'Clarke', value: false, zipCode: [39330,39363,39355,39360,39347]},
            {name: 'Clay', value: false, zipCode: [39773,39755]},
            {name: 'Coahoma', value: false, zipCode: [38614,38617,38644,38639,38645,38720,38631]},
            {name: 'Copiah', value: false, zipCode: [39191,39059,39083,39078]},
            {name: 'Covington', value: false, zipCode: [39479,39428,39119]},
            {name: 'DeSoto', value: false, zipCode: [38641,38680,38632,38651,38671,38672,38654,38637]},
            {name: 'Forrest', value: false, zipCode: [39465,39401,39402,39406,39459]},
            {name: 'Franklin', value: false, zipCode: [39630,39653,39661]},
            {name: 'George', value: false, zipCode: [39452]},
            {name: 'Greene', value: false, zipCode: [39456,39451]},
            {name: 'Grenada', value: false, zipCode: [38925,38901,38940]},
            {name: 'Hancock', value: false, zipCode: [39572,39576,39520,39525,39556]},
            {name: 'Harrison', value: false, zipCode: [39503,39571,39501,39507,39540,39531,39530,39532,39534,39560,39574]},
            {name: 'Hinds', value: false, zipCode: [39154,39272,39212,39058,39056,39041,39170,39206,39204,39203,39202,39201,39209,39269,39174,39210,39211,39213,39216,39217,39066,39175]},
            {name: 'Holmes', value: false, zipCode: [39063,39146,39169,39192,38924,39095,39079]},
            {name: 'Humphreys', value: false, zipCode: [39038,39166,38754,39097]},
            {name: 'Issaquena', value: false, zipCode: [38745,39159,39177,39113]},
            {name: 'Itawamba', value: false, zipCode: [38843,38855,38876,38856,38824]},
            {name: 'Jackson', value: false, zipCode: [39563,39553,39564,39565,39532,39562,39581,39567,39595]},
            {name: 'Jasper', value: false, zipCode: [39439,39338,39422]},
            {name: 'Jefferson Davis', value: false, zipCode: [39421,39474]},
            {name: 'Jefferson', value: false, zipCode: [39069]},
            {name: 'Jones', value: false, zipCode: [39440,39443,39437,39480,39459,39464,39477]},
            {name: 'Kemper', value: false, zipCode: [39328,39358]},
            {name: 'Lafayette', value: false, zipCode: [38673,38655,38601,38677,38965,38949,38913]},
            {name: 'Lamar', value: false, zipCode: [39475,39455,39402,39482]},
            {name: 'Lauderdale', value: false, zipCode: [39335,39307,39305,39301,39325,39364,39342,39309]},
            {name: 'Lawrence', value: false, zipCode: [39654,39140,39663]},
            {name: 'Leake', value: false, zipCode: [39094,39051,39189]},
            {name: 'Lee', value: false, zipCode: [38866,38857,38804,38801,38826,38849,38824,38868,38879,38862]},
            {name: 'Leflore', value: false, zipCode: [38930,38946,38941,38952,38954]},
            {name: 'Lincoln', value: false, zipCode: [39601]},
            {name: 'Lowndes', value: false, zipCode: [39705,39702,39701,39736,39743,39740]},
            {name: 'Madison', value: false, zipCode: [39046,39110,39071,39157]},
            {name: 'Marion', value: false, zipCode: [39643,39483,39429]},
            {name: 'Marshall', value: false, zipCode: [38635,38659,38661,38611,38685]},
            {name: 'Monroe', value: false, zipCode: [38821,38844,39740,38870,39746,38858,39730]},
            {name: 'Montgomery', value: false, zipCode: [39767,38925,38967,39747]},
            {name: 'Neshoba', value: false, zipCode: [39350,39354]},
            {name: 'Newton', value: false, zipCode: [39057,39323,39336,39092,39327,39345,39365,39332]},
            {name: 'Noxubee', value: false, zipCode: [39361,39739,39341]},
            {name: 'Oktibbeha', value: false, zipCode: [39759,39762,39769,39750]},
            {name: 'Panola', value: false, zipCode: [38621,38666,38658,38620,38619,38606]},
            {name: 'Pearl River', value: false, zipCode: [39466,39470,39426]},
            {name: 'Perry', value: false, zipCode: [39476,39465,39423,39462]},
            {name: 'Pike', value: false, zipCode: [39648,39666,39657,39635,39652]},
            {name: 'Pontotoc', value: false, zipCode: [38863,38871,38864,38841,38828,38869]},
            {name: 'Prentiss', value: false, zipCode: [38856,38829,38865,38859]},
            {name: 'Quitman', value: false, zipCode: [38622,38670,38623,38643,38646]},
            {name: 'Rankin', value: false, zipCode: [39042,39073,39218,39114,39145,39208,39232]},
            {name: 'Scott', value: false, zipCode: [39117,39074,39359,39189]},
            {name: 'Sharkey', value: false, zipCode: [39159,38765,39054,38721]},
            {name: 'Simpson', value: false, zipCode: [39044,39111,39062,39114]},
            {name: 'Smith', value: false, zipCode: [39153,39168,39116,39422,39117]},
            {name: 'Stone', value: false, zipCode: [39577]},
            {name: 'Sunflower', value: false, zipCode: [38751,38737,38753,38771,38761,38736,38778]},
            {name: 'Tallahatchie', value: false, zipCode: [38928,38966,38957,38921,38963]},
            {name: 'Tate', value: false, zipCode: [38665,38618,38668]},
            {name: 'Tippah', value: false, zipCode: [38663,38625,38683,38629,38610]},
            {name: 'Tishomingo', value: false, zipCode: [38852,38827,38847,38873,38833,38838]},
            {name: 'Tunica', value: false, zipCode: [38676,38664,38626]},
            {name: 'Union', value: false, zipCode: [38652,38650,38828]},
            {name: 'Walthall', value: false, zipCode: [39667]},
            {name: 'Warren', value: false, zipCode: [39180,39183,39156]},
            {name: 'Washington', value: false, zipCode: [38744,38748,38776,38756,38703,38760,38722,38701]},
            {name: 'Wayne', value: false, zipCode: [39362,39367,39324,39322]},
            {name: 'Webster', value: false, zipCode: [39751,39752,39744,39771]},
            {name: 'Wilkinson', value: false, zipCode: [39631,39669]},
            {name: 'Winston', value: false, zipCode: [39339,39346]},
            {name: 'Yalobusha', value: false, zipCode: [38948,38965,38961,38922]},
            {name: 'Yazoo', value: false, zipCode: [39194,39040,39162,39039]},
        ]
    },
    {
        id: 26,
        name: 'Missouri',
        stateId: 'MO',
        child: [
            {name: 'Adair', value: false, zipCode: [63540,63533,63501,63559]},
            {name: 'Andrew', value: false, zipCode: [64506,64505,64485,64421,64483,64427,64480,64436,64449]},
            {name: 'Atchison', value: false, zipCode: [64482,64446,64496,64498,64491]},
            {name: 'Audrain', value: false, zipCode: [65265,63352,65280,65232,63345,65264,63382]},
            {name: 'Barry', value: false, zipCode: [65641,65658,65745,65647,65747,64874,65625,65623,65772,65708,65734]},
            {name: 'Barton', value: false, zipCode: [64759,64748,64769,64762]},
            {name: 'Bates', value: false, zipCode: [64730,64722,64780,64723,64720,64745,64779,64752]},
            {name: 'Benton', value: false, zipCode: [65335,65355,65325,65338]},
            {name: 'Bollinger', value: false, zipCode: [63781,63787,63764,63751]},
            {name: 'Boone', value: false, zipCode: [65203,65279,65010,65255,65039,65284,65240,65202,65201,65215,65216,65256]},
            {name: 'Buchanan', value: false, zipCode: [64448,64503,64501,64507,64506,64504,64505,64443,64484,64440,64401]},
            {name: 'Butler', value: false, zipCode: [63901,63954,63932,63940,63961]},
            {name: 'Caldwell', value: false, zipCode: [64649,64637,64671,64644,64625,64650,64624]},
            {name: 'Callaway', value: false, zipCode: [65043,65059,65063,65231,65251,65080,65262]},
            {name: 'Camden', value: false, zipCode: [65049,65324,65020,65052,65065,65591,65079,65567,65786]},
            {name: 'Cape Girardeau', value: false, zipCode: [63740,63755,63739,63785,63732,63769,63701,63703,63745,63766,63744,63770]},
            {name: 'Carroll', value: false, zipCode: [64639,64633,64643,64668,64682,64623,64622]},
            {name: 'Carter', value: false, zipCode: [63943,63965,63937]},
            {name: 'Cass', value: false, zipCode: [64742,64734,64747,64012,64083,64080,64090,64746,64739,64701,64743,64078,64034,64725]},
            {name: 'Cedar', value: false, zipCode: [65785,64744,64756]},
            {name: 'Chariton', value: false, zipCode: [65281,65246,65236,64676,64681,65261,65286,64660]},
            {name: 'Christian', value: false, zipCode: [65631,65714,65721,65653,65630,65754,65610,65669,65753]},
            {name: 'Clark', value: false, zipCode: [63430,63465,63453,63474,63445,63472]},
            {name: 'Clay', value: false, zipCode: [64060,64048,64024,64116,64062,64118,64161,64117,64119,64068,64089,64072]},
            {name: 'Clinton', value: false, zipCode: [64493,64465,64492,64429,64454,64477]},
            {name: 'Cole', value: false, zipCode: [65109,65053,65074,65032,65023,65101,65076]},
            {name: 'Cooper', value: false, zipCode: [65348,65276,65237,65322,65068,65233,65287]},
            {name: 'Crawford', value: false, zipCode: [65441,63080,65453,65535,65565]},
            {name: 'Dade', value: false, zipCode: [65661,65752,65603,65635,65682,65646]},
            {name: 'Dallas', value: false, zipCode: [65767,65622,65685]},
            {name: 'Daviess', value: false, zipCode: [64670,64620,64648,64654,64636,64647,64689,64640]},
            {name: 'DeKalb', value: false, zipCode: [64422,64497,64469,64494,64474,64430,64490]},
            {name: 'Dent', value: false, zipCode: [65560,63629]},
            {name: 'Douglas', value: false, zipCode: [65608]},
            {name: 'Dunklin', value: false, zipCode: [63855,63852,63857,63821,63829,63863,63837,63876,63880,63933]},
            {name: 'Franklin', value: false, zipCode: [63069,63077,63089,63080,63068,63056,63079,63084,63014,63090,63037,63039]},
            {name: 'Gasconade', value: false, zipCode: [65061,63091,65041,65066,65014]},
            {name: 'Gentry', value: false, zipCode: [64657,64489,64463,64438,64453,64402]},
            {name: 'Greene', value: false, zipCode: [65770,65648,65619,65742,65738,65802,65757,65810,65897,65801,65803,65804,65806,65807,65809,65604,65781]},
            {name: 'Grundy', value: false, zipCode: [64683,64642,64652,64641,64679]},
            {name: 'Harrison', value: false, zipCode: [64642,64424,64481,64442,64426,64632,64471]},
            {name: 'Henry', value: false, zipCode: [65323,64735,64788,64740,64770,64726,65360]},
            {name: 'Hickory', value: false, zipCode: [65634,65668,65779,65774,65732]},
            {name: 'Holt', value: false, zipCode: [64437,64466,64470,64473,64451]},
            {name: 'Howard', value: false, zipCode: [65250,65230,65248,65254,65274]},
            {name: 'Howell', value: false, zipCode: [65775,65793,65688,65789,65548]},
            {name: 'Iron', value: false, zipCode: [63650,63636,65566,63621,63663,63620]},
            {name: 'Jackson', value: false, zipCode: [64086,64034,64016,64015,64088,64066,64075,64082,64064,64063,64081,64126,64070,64014,64065,64139,64029,64058,64054,64050,64161,64163,64164,64165,64167,64053,64118,64116,64151,64153,64155,64154,64157,64156,64158,64108,64109,64106,64102,64128,64129,64124,64125,64127,64120,64123,64166,64119,64114,64117,64111,64110,64113,64112,64133,64146,64145,64149,64147,64105,64101,64138,64132,64131,64130,64137,64136,64134,64030,64055,64056,64057,64052]},
            {name: 'Jasper', value: false, zipCode: [64832,64836,64848,64870,64855,64755,64849,64835,64857,64801,64841,64834,64830,64833,64804,64862,64859]},
            {name: 'Jefferson', value: false, zipCode: [63050,63012,63016,63051,63025,63048,63070,63028,63049,63069,63010,63052,63020,63026,63053,63019]},
            {name: 'Johnson', value: false, zipCode: [64761,64019,64061,65336,65305,64733,64093,64747,64040]},
            {name: 'Knox', value: false, zipCode: [63458,63531,63446,63547,63460,63537,63469]},
            {name: 'Laclede', value: false, zipCode: [65536,65722,65632]},
            {name: 'Lafayette', value: false, zipCode: [64067,64020,64097,64076,64022,64071,64011,64096,64037,65327,64021,64074,64001]},
            {name: 'Lawrence', value: false, zipCode: [65605,65712,65723,65769,65654,65707,65705,65604,65664,65756]},
            {name: 'Lewis', value: false, zipCode: [63473,63435,63457,63448,63447,63440,63452]},
            {name: 'Lincoln', value: false, zipCode: [63369,63349,63377,63379,63362,63381,63347,63387,63343,63389]},
            {name: 'Linn', value: false, zipCode: [64651,64628,64653,64658,64630,64659,64631,64674]},
            {name: 'Livingston', value: false, zipCode: [64601,64686,64638,64643,64664,64688,64635,64656]},
            {name: 'Macon', value: false, zipCode: [63558,63549,63552,63538,63534,65247,63539,63530,63532]},
            {name: 'Madison', value: false, zipCode: [63645,63655]},
            {name: 'Maries', value: false, zipCode: [65582,65013]},
            {name: 'Marion', value: false, zipCode: [63461,63463,63401]},
            {name: 'McDonald', value: false, zipCode: [64861,64854,64863,64843,64856,64831,64847]},
            {name: 'Mercer', value: false, zipCode: [64661,64673]},
            {name: 'Miller', value: false, zipCode: [65049,65017,65026,65075,65082,65064,65486]},
            {name: 'Mississippi', value: false, zipCode: [63834,63882,63845,63823,63820]},
            {name: 'Moniteau', value: false, zipCode: [65025,65018,65050,65081,65034,65046]},
            {name: 'Monroe', value: false, zipCode: [63456,65258,65263,65283,65275]},
            {name: 'Montgomery', value: false, zipCode: [65069,63363,65041,63361,63359,63384,63350,63333,63351]},
            {name: "Morgan", value: false, zipCode: [65011,65037,65078,65354,65084]},
            {name: 'New Madrid', value: false, zipCode: [63873,63848,63870,63878,63866,63874,63828,63862,63868,63867,63869,63833]},
            {name: 'Newton', value: false, zipCode: [64804,64844,64840,64850,64865,64842,64867,64873,64866]},
            {name: 'Nodaway', value: false, zipCode: [64479,64445,64468,64487,64433,64475,64476,64423,64455,64428,64434,64457,64432,64431,64461]},
            {name: 'Oregon', value: false, zipCode: [65692,65606,65791]},
            {name: 'Osage', value: false, zipCode: [65051,65058,65085,65035,65001,65024]},
            {name: 'Ozark', value: false, zipCode: [65729,65609,65626,65773,65761,65655]},
            {name: 'Pemiscot', value: false, zipCode: [63851,63873,63879,63853,63839,63840,63877,63830,63827]},
            {name: 'Perry', value: false, zipCode: [63775,63673,63732,63748]},
            {name: 'Pettis', value: false, zipCode: [65334,65332,65350,65301,65333,65337]},
            {name: 'Phelps', value: false, zipCode: [65559,65409,65401,65550,65462]},
            {name: 'Pike', value: false, zipCode: [63330,63336,63353,63344,63334,63441,63339]},
            {name: 'Platte', value: false, zipCode: [64150,64152,64444,64098,64151,64163,64028,64439,64079,64018]},
            {name: 'Polk', value: false, zipCode: [65601,65674,65613,65725,65663,65649,65650,65710]},
            {name: 'Pulaski', value: false, zipCode: [65473,65459,65583,65452,65556,65584]},
            {name: 'Putnam', value: false, zipCode: [64655,64672,63551,63567,63565]},
            {name: 'Ralls', value: false, zipCode: [63401,63459,63436,63467,63462]},
            {name: 'Randolph', value: false, zipCode: [65270,65259,65244,65243,65260,65257,65278,65239]},
            {name: 'Ray', value: false, zipCode: [64084,64062,64017,64024,64085,64036,64035,64077]},
            {name: 'Reynolds', value: false, zipCode: [63633,63638]},
            {name: 'Ripley', value: false, zipCode: [63955,63935,63953,63939,63945]},
            {name: 'Saline', value: false, zipCode: [65349,65320,65347,65351,65344,65339,64001,65321,65340,65330]},
            {name: 'Schuyler', value: false, zipCode: [63548,63546,63561,63536,63541]},
            {name: 'Scotland', value: false, zipCode: [63555,63563,63543,63432]},
            {name: 'Scott', value: false, zipCode: [63736,63771,63801,63834,63742,63774,63824,63784,63767,63780,63758,63740]},
            {name: 'Shannon', value: false, zipCode: [65438,65466,65588]},
            {name: 'Shelby', value: false, zipCode: [63468,63443,63437,63451,63469,63434]},
            {name: 'St. Charles', value: false, zipCode: [63341,63366,63386,63301,63303,63367,63373,63368,63385,63304,63332,63376,63348,63365]},
            {name: 'St. Clair', value: false, zipCode: [64776,64744,64781,64763,64738,64724]},
            {name: 'St. Francois', value: false, zipCode: [63628,63601,63624,63640,63637,63036,63653]},
            {name: 'St. Louis', value: false, zipCode: [63129,63135,63074,63021,63011,63121,63131,63133,63132,63136,63114,63130,63125,63119,63137,63033,63031,63122,63123,63134,63017,63141,63026,63034,63140,63042,63044,63043,63124,63088,63126,63117,63128,63144,63040,63038,63069,63005,63110,63111,63112,63113,63115,63116,63118,63120,63107,63106,63104,63103,63102,63101,63109,63108,63155,63139,63143,63147,63138,63127,63025,63146,63105]},
            {name: 'Ste. Genevieve', value: false, zipCode: [63670,63627,63673]},
            {name: 'Stoddard', value: false, zipCode: [63825,63936,63730,63960,63846,63850,63735,63822,63841]},
            {name: 'Stone', value: false, zipCode: [65675,65633,65611,65737,65686,65656,65616]},
            {name: 'Sullivan', value: false, zipCode: [63556,64667,64646,63560,64645,63544,63566,64641,63545]},
            {name: 'Taney', value: false, zipCode: [65672,65759,65653,65616,65680,65679,65740]},
            {name: 'Texas', value: false, zipCode: [65483,65552,65542,65555,65689,65571]},
            {name: 'Vernon', value: false, zipCode: [64741,64772,64778,64765,64771,64783,64728,64790,64750,64767,64752,64784]},
            {name: 'Warren', value: false, zipCode: [63390,63357,63383,63348,63380]},
            {name: 'Washington', value: false, zipCode: [63660,63664,63648,63631]},
            {name: 'Wayne', value: false, zipCode: [63967,63952,63944,63957]},
            {name: 'Webster', value: false, zipCode: [65713,65746,65652,65706]},
            {name: 'Worth', value: false, zipCode: [64456,64441,64499,64486,64420]},
            {name: 'Wright', value: false, zipCode: [65711,65662,65667,65717,65704]},
        ]
    },
    {
        id: 27,
        name: 'Montana',
        stateId: 'MT',
        child: [
            {name: 'Beaverhead', value: false, zipCode: [59736,59762,59725,59724,59739,59761,59732,59727,59746]},
            {name: 'Big Horn', value: false, zipCode: [59066,59089,59075,59034,59022,59035,59050,59043,59016]},
            {name: 'Blaine', value: false, zipCode: [59524,59526,59523,59529,59547,59542,59527]},
            {name: 'Broadwater', value: false, zipCode: [59644,59643,59635,59752,59647]},
            {name: 'Carbon', value: false, zipCode: [59070,59041,59007,59013,59008,59068,59014,59071,59026,59029]},
            {name: 'Carter', value: false, zipCode: [59324,59311]},
            {name: 'Cascade', value: false, zipCode: [59463,59421,59472,59404,59401,59405,59412,59477,59465,59414,59485,59480,59487,59443,59483]},
            {name: 'Chouteau', value: false, zipCode: [59521,59442,59446,59450,59460,59420,59440,59520]},
            {name: 'Custer', value: false, zipCode: [59301]},
            {name: 'Daniels', value: false, zipCode: [59222,59263,59276,59253]},
            {name: 'Dawson', value: false, zipCode: [59339,59330,59315,59259]},
            {name: 'Deer Lodge', value: false, zipCode: [59722,59756,59762,59711]},
            {name: 'Fallon', value: false, zipCode: [59313,59344]},
            {name: 'Fergus', value: false, zipCode: [59471,59457,59489,59032,59430,59464]},
            {name: 'Flathead', value: false, zipCode: [59925,59911,59920,59937,59901,59919,59936,59932,59916,59926,59913,59845,59922,59927,59928,59912]},
            {name: 'Gallatin', value: false, zipCode: [59741,59752,59718,59730,59714,59760,59715,59758,59086,59716]},
            {name: 'Garfield', value: false, zipCode: [59337]},
            {name: 'Glacier', value: false, zipCode: [59417,59427,59434,59411]},
            {name: 'Golden Valley', value: false, zipCode: [59074,59046]},
            {name: 'Granite', value: false, zipCode: [59858,59832,59837]},
            {name: 'Hill', value: false, zipCode: [59521,59530,59501,59528,59525,59540,59532]},
            {name: 'Jefferson', value: false, zipCode: [59721,59634,59759,59638,59635,59631,59632]},
            {name: 'Judith Basin', value: false, zipCode: [59452,59479,59462,59447,59469]},
            {name: 'Lake', value: false, zipCode: [59860,59864,59855,59865,59910,59929,59911,59914,59915,59821,59824,59863,59931]},
            {name: 'Lewis and Clark', value: false, zipCode: [59648,59602,59410,59639,59636,59601,59635,59633,59625,59623,59640]},
            {name: 'Liberty', value: false, zipCode: [59522,59545,59444,59531]},
            {name: 'Lincoln', value: false, zipCode: [59917,59930,59923,59935,59934,59933,59918]},
            {name: 'Madison', value: false, zipCode: [59729,59749,59710,59740,59745,59755,59747,59735,59751,59754]},
            {name: 'McCone', value: false, zipCode: [59215,59274,59214]},
            {name: 'Meagher', value: false, zipCode: [59645,59053]},
            {name: 'Mineral', value: false, zipCode: [59842,59820,59872,59830,59866,59867]},
            {name: 'Missoula', value: false, zipCode: [59821,59808,59868,59825,59802,59851,59804,59833,59834,59847,59823,59826,59812,59803,59801,59846]},
            {name: 'Musselshell', value: false, zipCode: [59054,59072,59059]},
            {name: 'Park', value: false, zipCode: [59030,59081,59047,59027,59065,59086,59020,59018,59082]},
            {name: 'Petroleum', value: false, zipCode: [59087]},
            {name: 'Phillips', value: false, zipCode: [59524,59261,59546,59538,59544]},
            {name: 'Pondera', value: false, zipCode: [59416,59448,59432,59486,59425]},
            {name: 'Powder River', value: false, zipCode: [59317,59314]},
            {name: 'Powell', value: false, zipCode: [59722,59854,59843,59728,59713,59731,59733]},
            {name: 'Prairie', value: false, zipCode: [59349,59326]},
            {name: 'Ravalli', value: false, zipCode: [59833,59840,59875,59871,59870,59828,59841,59827,59829]},
            {name: 'Richland', value: false, zipCode: [59217,59243,59221,59270,59262]},
            {name: 'Roosevelt', value: false, zipCode: [59226,59213,59255,59212,59218,59201]},
            {name: 'Rosebud', value: false, zipCode: [59327,59323,59003,59043,59347]},
            {name: 'Sanders', value: false, zipCode: [59831,59845,59874,59853,59859,59856,59848,59873,59844]},
            {name: 'Sheridan', value: false, zipCode: [59258,59254,59275,59247,59211,59252,59242,59257]},
            {name: 'Silver Bow', value: false, zipCode: [59701,59748,59727,59750,59711]},
            {name: 'Stillwater', value: false, zipCode: [59019,59028,59067,59069,59063,59001]},
            {name: 'Sweet Grass', value: false, zipCode: [59033,59011]},
            {name: 'Teton', value: false, zipCode: [59436,59468,59419,59467,59433,59422]},
            {name: 'Toole', value: false, zipCode: [59484,59474,59482,59456,59454]},
            {name: 'Treasure', value: false, zipCode: [59038]},
            {name: 'Valley', value: false, zipCode: [59241,59250,59248,59230,59223,59231,59225]},
            {name: 'Wheatland', value: false, zipCode: [59036,59078,59085,59453]},
            {name: 'Wibaux', value: false, zipCode: [59353]},
            {name: 'Yellowstone', value: false, zipCode: [59088,59006,59002,59044,59015,59037,59024,59064,59079,59106,59105,59102,59101]},
        ]
    },
    {
        id: 28,
        name: 'Nebraska',
        stateId: 'NE',
        child: [
            {name: 'Adams', value: false, zipCode: [68883,68950,68955,68973,68901,68956,68925]},
            {name: 'Antelope', value: false, zipCode: [68773,68756,68761,68720,68636,68726,68764]},
            {name: 'Arthur', value: false, zipCode: [69121]},
            {name: 'Banner', value: false, zipCode: [69345]},
            {name: 'Blaine', value: false, zipCode: [68821,68833]},
            {name: 'Boone', value: false, zipCode: [68655,68660,68620,68627,68652]},
            {name: 'Box Butte', value: false, zipCode: [69301,69348]},
            {name: 'Boyd', value: false, zipCode: [68777,68722,68746,68719,68755]},
            {name: 'Brown', value: false, zipCode: [69210,69214,69217]},
            {name: 'Buffalo', value: false, zipCode: [68847,68845,68849,68812,68870,68840,68876,68869,68861,68858,68866,68836]},
            {name: 'Burt', value: false, zipCode: [68019,68038,68020,68045,68061]},
            {name: 'Butler', value: false, zipCode: [68624,68014,68658,68001,68632,68626,68669,68667,68635,68036]},
            {name: 'Cass', value: false, zipCode: [68407,68413,68307,68349,68058,68403,68304,68463,68048,68016,68455,68347,68037,68409,68366]},
            {name: 'Cedar', value: false, zipCode: [68717,68727,68739,68792,68730,68745,68749,68736,68771,68757,68774]},
            {name: 'Chase', value: false, zipCode: [69023,69027,69045,69033]},
            {name: 'Cherry', value: false, zipCode: [69211,69212,69221,69218,69166,69201,69216,69219]},
            {name: 'Cheyenne', value: false, zipCode: [69162,69156,69149,69141,69131]},
            {name: 'Clay', value: false, zipCode: [68941,68975,68944,68980,68933,68452,68979,68938,68934,68935]},
            {name: 'Colfax', value: false, zipCode: [68659,68629,68641,68661,68601,68643]},
            {name: 'Cuming', value: false, zipCode: [68004,68788,68716,68791]},
            {name: 'Custer', value: false, zipCode: [68822,68814,69120,68860,68874,68855,68825,68856,68813,68828]},
            {name: 'Dakota', value: false, zipCode: [68743,68741,68776,68731,68030]},
            {name: 'Dawes', value: false, zipCode: [69337,69367,69339]},
            {name: 'Dawson', value: false, zipCode: [68878,68863,69171,69138,68850,68834,69029,69130]},
            {name: 'Deuel', value: false, zipCode: [69122,69129]},
            {name: 'Dixon', value: false, zipCode: [68757,68751,68785,68732,68728,68710,68784,68770,68733]},
            {name: 'Dodge', value: false, zipCode: [68025,68044,68649,68664,68057,68031,68072,68063,68633]},
            {name: 'Douglas', value: false, zipCode: [68127,68069,68112,68114,68117,68116,68118,68182,68183,68105,68108,68178,68110,68122,68124,68111,68022,68137,68135,68132,68144,68134,68131,68130,68102,68106,68107,68104,68164,68152,68154,68007,68010,68064]},
            {name: 'Dundy', value: false, zipCode: [69030,69037,69021,69041]},
            {name: 'Fillmore', value: false, zipCode: [68365,68444,68351,68354,68361,68436,68416,68406]},
            {name: 'Franklin', value: false, zipCode: [68972,68932,68981,68939,68960,68929,68947]},
            {name: 'Frontier', value: false, zipCode: [69038,69025,69039,69028]},
            {name: 'Furnas', value: false, zipCode: [68922,68936,68926,68946,68967,69022,68948,69046]},
            {name: 'Gage', value: false, zipCode: [68381,68310,68309,68422,68318,68466,68458,68301,68357,68415,68328,68331]},
            {name: 'Garden', value: false, zipCode: [69154,69147,69148]},
            {name: 'Garfield', value: false, zipCode: [68823]},
            {name: 'Gosper', value: false, zipCode: [68976,68937]},
            {name: 'Grant', value: false, zipCode: [69350]},
            {name: 'Greeley', value: false, zipCode: [68875,68665,68842,68882]},
            {name: 'Hall', value: false, zipCode: [68803,68801,68883,68810,68824,68832]},
            {name: 'Hamilton', value: false, zipCode: [68846,68843,68854,68818,68841,68865]},
            {name: 'Harlan', value: false, zipCode: [68971,68949,68920,68966,68977]},
            {name: 'Hayes', value: false, zipCode: [69040,69032]},
            {name: 'Hitchcock', value: false, zipCode: [69044,69024,69040,69043]},
            {name: 'Holt', value: false, zipCode: [68725,68735,68713,68742,68734,68766,68763,68780]},
            {name: 'Hooker', value: false, zipCode: [69152]},
            {name: 'Howard', value: false, zipCode: [68820,68872,68835,68873,68838,68831]},
            {name: 'Jefferson', value: false, zipCode: [68350,68338,68440,68352,68377,68424,68342,68429]},
            {name: 'Johnson', value: false, zipCode: [68450,68329,68348,68332,68443]},
            {name: 'Kearney', value: false, zipCode: [68924,68959,68982,68945]},
            {name: 'Keith', value: false, zipCode: [69153,69146,69155,69144,69127,69147]},
            {name: 'Keya Paha', value: false, zipCode: [68778]},
            {name: 'Kimball', value: false, zipCode: [69133,69145,69128]},
            {name: 'Knox', value: false, zipCode: [68718,68786,68724,68783,68729,68730,68760,68789]},
            {name: 'Lancaster', value: false, zipCode: [68339,68368,68428,68528,68524,68526,68520,68521,68522,68514,68516,68510,68512,68506,68507,68504,68505,68502,68503,68508,68588,68317,68372,68461,68430,68402,68438,68404,68462,68358,68523,68336,68419]},
            {name: 'Lincoln', value: false, zipCode: [69165,69151,69169,69101,69170,69143,69123]},
            {name: 'Logan', value: false, zipCode: [69163]},
            {name: 'Loup', value: false, zipCode: [68879]},
            {name: 'Madison', value: false, zipCode: [68701,68781,68748,68758,68715,68752]},
            {name: 'McPherson', value: false, zipCode: [69167]},
            {name: 'Merrick', value: false, zipCode: [68826,68816,68663,68864,68827,68628]},
            {name: 'Morrill', value: false, zipCode: [69334,69125,69336]},
            {name: 'Nance', value: false, zipCode: [68623,68640,68638]},
            {name: 'Nemaha', value: false, zipCode: [68321,68320,68414,68378,68421,68305,68379]},
            {name: 'Nuckolls', value: false, zipCode: [68961,68957,68978,68964,68943,68974]},
            {name: 'Otoe', value: false, zipCode: [68417,68346,68448,68344,68324,68347,68446,68454,68410,68418,68382]},
            {name: 'Pawnee', value: false, zipCode: [68380,68323,68441,68420,68447,68345]},
            {name: 'Perkins', value: false, zipCode: [69134,69168,69150,69140]},
            {name: 'Phelps', value: false, zipCode: [68923,68927,68949,68940,68958]},
            {name: 'Pierce', value: false, zipCode: [68701,68765,68747,68767,68769]},
            {name: 'Platte', value: false, zipCode: [68631,68634,68642,68653,68647,68601,68644]},
            {name: 'Polk', value: false, zipCode: [68651,68666,68662,68654]},
            {name: 'Red Willow', value: false, zipCode: [69036,69034,69026,69001,69020]},
            {name: 'Richardson', value: false, zipCode: [68442,68457,68337,68437,68431,68355,68376,68433]},
            {name: 'Rock', value: false, zipCode: [68759,68714]},
            {name: 'Saline', value: false, zipCode: [68343,68359,68341,68333,68445,68464,68465,68453]},
            {name: 'Sarpy', value: false, zipCode: [68028,68046,68059,68128,68133,68138,68123,68147,68005,68157,68113]},
            {name: 'Saunders', value: false, zipCode: [68003,68033,68073,68070,68015,68042,68040,68050,68018,68648,68017,68064,68065,68066,68041]},
            {name: 'Scotts Bluff', value: false, zipCode: [69358,69341,69355,69334,69353,69361,69357,69356,69352]},
            {name: 'Seward', value: false, zipCode: [68456,68359,68330,68313,68314,68439,68364,68360,68423,68405,68434]},
            {name: 'Sheridan', value: false, zipCode: [69343,69347,69360]},
            {name: 'Sherman', value: false, zipCode: [68852,68853,68844,68871,68817]},
            {name: 'Sioux', value: false, zipCode: [69346]},
            {name: 'Stanton', value: false, zipCode: [68779,68768,68701]},
            {name: 'Thayer', value: false, zipCode: [68362,68340,68327,68335,68375,68326,68325,68322,68315,68303,68370]},
            {name: 'Thomas', value: false, zipCode: [69142,69161,69166]},
            {name: 'Thurston', value: false, zipCode: [68067,68062,68055,68071,68039,68047]},
            {name: 'Valley', value: false, zipCode: [68862,68859,68837,68815]},
            {name: 'Washington', value: false, zipCode: [68023,68068,68008,68029,68002,68034,68044]},
            {name: 'Wayne', value: false, zipCode: [68723,68771,68787,68790,68740]},
            {name: 'Webster', value: false, zipCode: [68928,68970,68930,68942,68952]},
            {name: 'Wheeler', value: false, zipCode: [68637,68622]},
            {name: 'York', value: false, zipCode: [68401,68460,68319,68316,68371,68367,68467]},
        ]
    },
    {
        id: 29,
        name: 'Nevada',
        stateId: 'NV',
        child: [
            {name: 'Carson City', value: false, zipCode: [89701,89703,89706]},
            {name: 'Churchill', value: false, zipCode: [89406]},
            {name: 'Clark', value: false, zipCode: [89103,89109,89158,89169,89118,89119,89121,89120,89123,89135,89046,89025,89104,89142,89110,89115,89156,89122,89018,89124,89178,89183,89139,89141,89148,89113,89034,89027,89040,89021,89029,89044,89052,89054,89002,89074,89015,89014,89011,89012,89146,89149,89107,89106,89102,89101,89108,89138,89130,89131,89134,89143,89145,89144,89117,89129,89128,89191,89019,89147,89005,89039,89007,89032,89031,89030,89086,89084,89085,89081,89004]},
            {name: 'Douglas', value: false, zipCode: [89413,89448,89449,89411,89423,89410,89705,89444,89460]},
            {name: 'Elko', value: false, zipCode: [89835,89801,89815,89830,89832,89822,89828,89825,89883]},
            {name: 'Esmeralda', value: false, zipCode: [89010,89013,89047]},
            {name: 'Eureka', value: false, zipCode: [89316,89821]},
            {name: 'Humboldt', value: false, zipCode: [89426,89445,89421,89414,89425,89438]},
            {name: 'Lander', value: false, zipCode: [89310,89820]},
            {name: 'Lincoln', value: false, zipCode: [89008,89042,89001,89017,89043]},
            {name: 'Lyon', value: false, zipCode: [89408,89406,89429,89403,89428,89447,89444,89430]},
            {name: 'Mineral', value: false, zipCode: [89415,89422,89427]},
            {name: 'Nye', value: false, zipCode: [89409,89049,89003,89020,89048,89060,89061]},
            {name: 'Pershing', value: false, zipCode: [89419,89418,89445]},
            {name: 'Storey', value: false, zipCode: [89440]},
            {name: 'Washoe', value: false, zipCode: [89451,89450,89442,89508,89441,89436,89433,89704,89523,89424,89506,89439,89405,89521,89503,89502,89501,89509,89557,89519,89512,89402,89412,89434,89431,89510]},
            {name: 'White Pine', value: false, zipCode: [89317,89311,89319,89301,89318]},
        ]
    },
    {
        id: 30,
        name: 'New Hampshire',
        stateId: 'NH',
        child: [
            {name: 'Belknap', value: false, zipCode: [3276,3246]},
            {name: 'Carroll', value: false, zipCode: [3254,3227,'03860','03847',3887,3872,'03853','03850',3814]},
            {name: 'Cheshire', value: false, zipCode: [3609,3446,3431]},
            {name: 'Coos', value: false, zipCode: [3582,3570,3597]},
            {name: 'Grafton', value: false, zipCode: [3785,'03740','03785',3262,3774,'03784','03766']},
            {name: 'Hillsborough', value: false, zipCode: ['03109','03104','03101','03103','03102','03062','03063','03060','03064',3070,3043,'03045',3054]},
            {name: 'Merrimack', value: false, zipCode: [3229,'03303','03301',3255,3106,3275,3235]},
            {name: 'Rockingham', value: false, zipCode: [3874,3801,3842]},
            {name: 'Strafford', value: false, zipCode: [3878,3852,3820,'03839','03868','03867']},
            {name: 'Sullivan', value: false, zipCode: [3743]},
        ]
    },
    {
        id: 31,
        name: 'New Jersey',
        stateId: 'NJ',
        child: [
            {name: 'Atlantic', value: false, zipCode: ['8317','8330','8201','8401','8205','8240','8094','08340','08330','08326','08310','08341','08360',8037,'08215','08205',8350,'08217','08037',8244,8203,8225,'08270','08317','08319',8402,8234,8403,8215,8241,8360,'08346',8232,'08094',8406]},
            {name: 'Bergen', value: false, zipCode: [7075,7070,7607,7624,7057,7631,7458,7072,7605,7024,7627,7675,7677,7603,7026,7407,7621,7648,7661,7640,7630,7401,7656,7452,7643,7646,7642,7657,7417,7423,7647,7604,7632,7020,7650,7644,7436,7601,'07074','07608',7432,7073,7660,7628,7410,7620,7450,7608,7641,7645,7010,7626,7649,7446,7022,7463,7670,7031,7430,7652]},
            {name: 'Burlington', value: false, zipCode: [8088,8055,8515,8505,'08087','08224','08022','08042',8057,8015,'08640','08562',8065,8068,8054,8554,8077,8511,8053,8041,8640,8641,8010]},
            {name: 'Camden', value: false, zipCode: [8034,8021,8109,8009,8043,'08002','08034',8012,8045,8083,8030,8029,8091,8107,'08003',8003,'08104','08105','08102','08103',8033,'08032','08012',8026,8031,8035,8059,8084,8089,8078,8106,'08107','08108','08033',8004,8002,8049,'08007',8081,'08009','08021']},
            {name: 'Cape May', value: false, zipCode: [8260,8210,8251,8230,8247,'08230','08223',8223,8270,8204,'08245','08210','08246','08242',8248,8242,8202,8243,8226,8212]},
            {name: 'Cumberland', value: false, zipCode: [8324,8332,8302,8314,'08352','08302','08318',8349,8311,8353,8323,'08329','08349','08332',8345,'08344','08360','08361','08323',8321,'08327','08316',8327,'08311','08348']},
            {name: 'Essex', value: false, zipCode: [7028,'07018','07017',7052,7006,'07103','07102','07105','07104','07107','07106','07108','07112','07114',7003,'07109','07003',7021,7043,7078,7068]},
            {name: 'Gloucester', value: false, zipCode: [8328,8085,8086,'08020','08061',8090,'08085','08062','08039',8093,'08071','08074',8028,8080,8066,8063,8097,8062,8344,8322,'08080','08012',8027,8094,8014,8056,8096,'08312','08094',8071]},
            {name: 'Hudson', value: false, zipCode: [7093,7002,'07310','07311','07302','07307','07306','07305','07304',7029,7032,7094,7087,7030]},
            {name: 'Hunterdon', value: false, zipCode: [8826,8559,8887,'08889','08858',8829,'07931','07979',8848,'08559','08551',8822,'08833',8530,7830,'08801','08809',8804,8825]},
            {name: 'Mercer', value: false, zipCode: [8620,8561,'08550','08540','08544','08542','08620','08691',8525,'08690','08610',8520,'08619',8534,'08638','08618',8560,'08611','08629','08609','08608',8648]},
            {name: 'Middlesex', value: false, zipCode: ['08879','07721','07735',8882,8536,8828,8812,8831,8852,'08536','08512',8854,7008,'08824','08852','08540',8904,8850,7067,8863,'08872','08859',7080,7077,8810,8840,8861,8830,8857,'08857','07077','07001','07064',8846,8884,8832,8901,7001,8879]},
            {name: 'Monmouth', value: false, zipCode: [7762,7716,7720,7719,7735,7712,8736,8501,7717,7718,7747,7756,7764,7728,'07719','08720','08724','07751','07726','07746',7704,7738,7732,7739,7750,7723,'07724','07703',7760,8730,7740,7726,'07748','07758',7751,7701,8555,7755,'07712','07753','07716','07760','07757',8750,7702,7734,7727,7711,'07735','07730',7753,8514,7748,7731,7737]},
            {name: 'Morris', value: false, zipCode: [7046,7034,7885,7927,7836,7058,7980,7856,7847,'07885','07842','07866',7405,7946,'07960','07926','07945',7940,7082,'07828','07836','07961','07932','07801',7950,'07849',7801,7850,'07920','07976',7444,'07852',7933,'07054','07834',7876,'07878','07950',7960,7054,7981,7005,7857,7035,'07005',7457,7853,'07935','07928',7866,7438]},
            {name: 'Ocean', value: false, zipCode: [8757,8759,8701,8758,8731,8087,8732,8008,8752,8742,8751,8735,'08050','08005',8006,8733,8050,8533,8741,8738,8722,8527,8740]},
            {name: 'Passaic', value: false, zipCode: [7456,7508,7480,'07522','07524','07514','07513','07505','07501','07504','07503','07502','07465','07420',7421,7470,7512,'07014','07013','07012','07011',7435,7055,'07403','07043','07424',7442,7424,7506]},
            {name: 'Salem', value: false, zipCode: [8318,'08079','08038',8069,8079,'08085','08098',8067,8098]},
            {name: 'Somerset', value: false, zipCode: ['08823','08873','07921','07920','08528','08540',8540,8558,8807,8873,'08821','08844','08890',8880,7920,8805,7069,8844,7924,'08502',8853,8502,'08836','08805','08807',7921,'07062','07063','07060',7931,'07939',8835,8876,8553]},
            {name: 'Sussex', value: false, zipCode: [7871,7874,7860,'07826','07851','07462','07422',7439,7416,'07874','07821','07843',7461,7462,7419,7822,7826,'07461','07418']},
            {name: 'Union', value: false, zipCode: [7065,7204,7901,7036,7023,7081,7083,7016,'07208','07202','07201','07206',7092,7033,7027,'07062','07063','07060',7974,'07083','07205',7203,7090,'07088']},
            {name: 'Warren', value: false, zipCode: ['07838','07840',7825,7840,7865,7882,8886,8865,'07880',8802,7832,7823,'07823','07863',7838,'07846','07821','07825','07882','07865']},
        ]
    },
    {
        id: 32,
        name: 'New Mexico',
        stateId: 'NM',
        child: [
            {name: 'Bernalillo', value: false, zipCode: [87105,87022,87121,87116,87015,87059,87008,87047,87123,87113,87114,87112,87110,87111,87109,87108,87101,87102,87104,87107,87106,87120,87122]},
            {name: 'Catron', value: false, zipCode: [87830,87827,88039,87821,87829,87820,87824]},
            {name: 'Chaves', value: false, zipCode: [88230,88232,88253,88203,88201]},
            {name: 'Cibola', value: false, zipCode: [87321,87040,87034,87357,87014,87020,87021,87038,87026,87051,87007,87005,87045,87315,87049]},
            {name: 'Colfax', value: false, zipCode: [87749,87747,87710,87718,87740,87714,87728]},
            {name: 'Curry', value: false, zipCode: [88101,88103,88135,88120,88124]},
            {name: 'De Baca', value: false, zipCode: [88119]},
            {name: 'Dona Ana', value: false, zipCode: [88021,88081,88005,88007,88012,88011,88001,87937,88063,88008,79922,88003,88002,88048,87941,88024,87940,88044,88046,88052,88027,88072]},
            {name: 'Eddy', value: false, zipCode: [88220,88250,88255,88210,88256,88263]},
            {name: 'Grant', value: false, zipCode: [88055,88061,88065,88043,88028,88038,88023,88041,88022,88026,88049,88025,88040,88053,88034]},
            {name: 'Guadalupe', value: false, zipCode: [88353,88435,87711,87724]},
            {name: 'Harding', value: false, zipCode: [87733,87746,87743]},
            {name: 'Hidalgo', value: false, zipCode: [88045,88056,88020,88009]},
            {name: 'Lea', value: false, zipCode: [88231,88240,88244,88260,88252,88242,88267,88265]},
            {name: 'Lincoln', value: false, zipCode: [88301,88316,88341,88346,88318,88312,88345,88355]},
            {name: 'Los Alamos', value: false, zipCode: [87544,87547]},
            {name: 'Luna', value: false, zipCode: [88029,88030]},
            {name: 'McKinley', value: false, zipCode: [87375,87311,87013,87305,87301,87045,87328,87321,87319,87325,87320,87323,87313,86515,87326,87312,87317,87327,87322,87310,87316,87347]},
            {name: 'Mora', value: false, zipCode: [87732,87715,87752,87753]},
            {name: 'Otero', value: false, zipCode: [88340,88310,88337,88325,88339,88350,88347,88352,88354,88342,88330,88317,88314,88311]},
            {name: 'Quay', value: false, zipCode: [88121,88434,88401,88426,88430]},
            {name: 'Rio Arriba', value: false, zipCode: [87064,87566,87551,87532,87582,87523,87537,87017,87511,87510,87520,87578,87527,87528,87575,87037,87018,87012,87521,87530,87515,87539,87029,87548,87516]},
            {name: 'Roosevelt', value: false, zipCode: [88116,88130,88118,88132,88113,88115]},
            {name: 'San Juan', value: false, zipCode: [87401,87417,87416,87418,87413,87412,87420,87410,87499,87421,87364,87325,87037,87415,87455,87328,87419,87461,87402]},
            {name: 'San Miguel', value: false, zipCode: [87552,87701,87562,87538,87583,87560,87565,87711,88416]},
            {name: 'Sandoval', value: false, zipCode: [87024,87047,87083,87048,87046,87124,87144,87053,87041,87013,87001,87025,87004,87043,87121,87072,87044,87052,87027]},
            {name: 'Santa Fe', value: false, zipCode: [87505,87507,87501,87010,87506,87535,87567,87508,87522,87540,87047,87015,87532,87056]},
            {name: 'Sierra', value: false, zipCode: [87931,87942,87935,87901,87943,88042]},
            {name: 'Socorro', value: false, zipCode: [87825,87801,87831,87828,87832,87062,87823,87028,87006]},
            {name: 'Taos', value: false, zipCode: [87557,87571,87524,87525,87549,87553,87521,87579,87556,87558,87564,87529,87514,87513]},
            {name: 'Torrance', value: false, zipCode: [87035,88321,88318,87036,87015,87061,87016,87032,87063]},
            {name: 'Union', value: false, zipCode: [88415,88419,88418,88414,88424]},
            {name: 'Valencia', value: false, zipCode: [87068,87002,87023,87031,87026,87042,87060]},
        ]
    },
    {
        id: 33,
        name: 'New York',
        stateId: 'NY',
        child: [
            {name: 'Albany', value: false, zipCode: [12208,12209,12204,12206,12207,12202,12203,12205,12210,12226,12222,12189,12143,12159,12186,12211,12469,12183,12110,12009,12047]},
            {name: 'Allegany', value: false, zipCode: [14735,14744,14895,14715,14774,14822,14813]},
            {name: 'Bronx', value: false, zipCode: [10453,10457,10460,10458,10467,10468,10451,10452,10456,10454,10455,10459,10474,10463,10471,10466,10469,10470,10475,10461,10462,10464,10465,10472,10473]},
            {name: 'Broome', value: false, zipCode: [13902,13760,13862,13777,13904,13905,13901,13903,13790]},
            {name: 'Cattaraugus', value: false, zipCode: [14171,14042,14760,14138,14778,14706,14719,14070,14753,14779,14788,14772,14101]},
            {name: 'Cayuga', value: false, zipCode: [13113,13033,13156,13034,13021,13024,13160,13140,13166]},
            {name: 'Chautauqua', value: false, zipCode: [14063,14767,14733,14701,14747,14048,14738,14062,14136,14081,14718,14782,14757,14712,14720,14716,14750]},
            {name: 'Chemung', value: false, zipCode: [14845,14894,14864,14872,14901,14905,14904,14903,14838,14816]},
            {name: 'Chenango', value: false, zipCode: [13841,13778,13815,13332]},
            {name: 'Clinton', value: false, zipCode: [12901,12903,12912,12955,12952,12992,12962,12918,12979,12978,12981]},
            {name: 'Columbia', value: false, zipCode: [12565,12517,12534,12513,12172,12521,12529,12516,12184,12130]},
            {name: 'Cortland', value: false, zipCode: [13101,13045,13738]},
            {name: 'Delaware', value: false, zipCode: [13755,13757,13751,13820,13739,13788,12455,13775,12430]},
            {name: 'Dutchess', value: false, zipCode: [12583,12533,12578,12594,12590,12601,12603,12538,12531,12580,12504,12572,12574,12507,12524,12545,12508,12592,12569,12540,12546,12604,12522,12571]},
            {name: 'Erie', value: false, zipCode: [14052,14218,14219,14217,14221,14004,14086,14061,14081,14043,14206,14212,14208,14209,14202,14203,14201,14207,14204,14220,14222,14211,14210,14213,14215,14214,14216,14260,14261,14228,14006,14075,14141,14059,14047,14032,14226,14001,14072,14085,14150]},
            {name: 'Essex', value: false, zipCode: [12996,12998,12946,12944,12870,12956]},
            {name: 'Franklin', value: false, zipCode: [13683,13655,13662,12914,12980,12970,12916,12983]},
            {name: 'Fulton', value: false, zipCode: [12032,12078,12095]},
            {name: 'Genesee', value: false, zipCode: [14020,14036]},
            {name: 'Greene', value: false, zipCode: [12473,12414,12015,12124,12192,12463,12451,12485,12492,12436,12482]},
            {name: 'Hamilton', value: false, zipCode: [12164]},
            {name: 'Herkimer', value: false, zipCode: [13350,13420,13491,13329,13454,13324,13357,13406,13416,13472,13365]},
            {name: 'Jefferson', value: false, zipCode: [13622,13619,13628,13605,13611,13685,13665,13634,13606,13643,13641,13640,13624,13656,13632,13607,13692,13693,13603,13602,13674,13661,13675,13691,13612,13638,13679,13601,13637,13608,13616]},
            {name: 'Kings', value: false, zipCode: [11212,11213,11216,11233,11238,11209,11214,11228,11204,11218,11219,11230,11234,11236,11239,11223,11224,11229,11235,11201,11205,11215,11217,11231,11203,11210,11225,11226,11207,11208,11211,11222,11220,11232,11206,11221,11237]},
            {name: 'Lewis', value: false, zipCode: [13325,13367,13620,13433,13626,13368]},
            {name: 'Livingston', value: false, zipCode: [14466,14486,14533,14437,14462,14480,14454,14487,14435,14517,14481,14836,14414,14539,14545,14846,14560,14423]},
            {name: 'Madison', value: false, zipCode: [13409,13163,13032,13030,13082,13037,13421,13408]},
            {name: 'Monroe', value: false, zipCode: [14618,14420,14546,14623,14472,14608,14609,14606,14607,14604,14605,14627,14620,14621,14619,14615,14614,14617,14611,14610,14613,14612,14642,14450,14468,14559,14428,14445]},
            {name: 'Montgomery', value: false, zipCode: [12010,13317,13339,12070,12177,12068,12072,13428,13410,12086]},
            {name: 'Nassau', value: false, zipCode: [11801,11560,11010,11558,11576,11501,11001,11566,11520,11590,11021,11596,11714,11756,11783,11710,11030,11024,11023,11563,11557,11791,11735,11565,11552,11530,11542,11758,11598,11548,11050,11561,11507,11577,11509,11771,11575,11559,11580,11003,11804,11514,11554,11581,11516,11545,11579,11020,11518,11042,11793,11765,11803,11510,11040,11096,11549,11553,11556,11709,11797,11570,11762,11568,11732,11547,11572,11753,11569,11550]},
            {name: 'New York', value: false, zipCode: [10026,10027,10030,10037,10039,10001,10011,10018,10019,10020,10036,10029,10035,10010,10016,10017,10022,10012,10013,10014,10004,10005,10006,10007,10038,10280,10002,10003,10009,10021,10028,10044,10065,10075,10128,10023,10024,10025,10031,10032,10033,10034,10040]},
            {name: 'Niagara', value: false, zipCode: [14120,14132,14028,14126,14067,14105,14303,14302,14301,14305,14304,14131,14094,14109,14174]},
            {name: 'Oneida', value: false, zipCode: [13461,13323,13480,13435,13486,13054,13322,13456,13403,13338,13494,13319,13413,13441,13440,13308,13321,13492,13417,13123,13421,13469,13425,13495,13502,13363,13354,13157,13424,13501]},
            {name: 'Onondaga', value: false, zipCode: [13027,13205,13120,13211,13088,13057,13029,13036,13219,13209,13210,13214,13290,13224,13207,13206,13204,13203,13202,13208,13244,13212,13078,13031,13116,13066,13080]},
            {name: 'Ontario', value: false, zipCode: [14548,14432,14537,14456,14463,14469,14424,14471]},
            {name: 'Orange', value: false, zipCode: [10992,10925,10950,12566,10928,10918,12520,12586,12771,12780,10988,10940,12550,10916,12543,12553,12518,10917,10926,10930,10941,10987,10922,12577,10963,10921,10996]},
            {name: 'Orleans', value: false, zipCode: [14470,14098,14411,14103]},
            {name: 'Oswego', value: false, zipCode: [13126,13302,13132,13135,13042,13036,13142,13069,13083]},
            {name: 'Otsego', value: false, zipCode: [13315,13342,13810,13326,13807,13488,12155,12197,13337,13468,13457,13348,13439,13859,13825,12064,13491,13820,13776,13834,12116,13411]},
            {name: 'Putnam', value: false, zipCode: [10509,10560,10516,12563,10512,10541,10542]},
            {name: 'Queens', value: false, zipCode: [11361,11362,11363,11364,11354,11355,11356,11357,11358,11359,11360,11365,11366,11367,11412,11423,11432,11433,11434,11435,11436,11101,11102,11103,11104,11105,11106,11374,11375,11379,11385,11691,11692,11693,11694,11695,11697,11004,11005,11411,11413,11422,11426,11427,11428,11429,11414,11415,11416,11417,11418,11419,11420,11421,11368,11369,11370,11372,11373,11377,11378]},
            {name: 'Rensselaer', value: false, zipCode: [12182,12180,12018,12185,12123,12144,12196,12198,12033,12090,12062]},
            {name: 'Richmond', value: false, zipCode: [10302,10303,10310,10306,10307,10308,10309,10312,10301,10304,10305,10314]},
            {name: 'Rockland', value: false, zipCode: [10993,10923,10901,10952,10977,10984,10960,10974,10962,10976,10994,10989,10965,10983,10954,10970,10931,10913,10956,10968,10964,10920]},
            {name: 'Saratoga', value: false, zipCode: [12065,12803,12871,12019,12020,12118,12151,12866]},
            {name: 'Schenectady', value: false, zipCode: [12053,12137,12306,12008,12302,12305,12304,12307,12309,12308,12056]},
            {name: 'Schoharie', value: false, zipCode: [12131,12035,12092,13459,12093]},
            {name: 'Schuyler', value: false, zipCode: [14865,14891,14869,14818]},
            {name: 'Seneca', value: false, zipCode: [14847,14456]},
            {name: 'St. Lawrence', value: false, zipCode: [13676,13647,12927,13680,13669,13668,13690,13645,13642,13681,13613,13697,13654]},
            {name: 'Steuben', value: false, zipCode: [14870,14827,14830,14807,14840,14843,14879]},
            {name: 'Suffolk', value: false, zipCode: [11951,11770,11747,11722,11752,11740,11743,11704,11730,11739,11937,11978,11948,11952,11717,11766,11949,11941,11721,11901,11768,11772,11749,11961,11931,11724,11742,11790,11794,11958,11971,11939,11944,11962,11967,11932,11976,11946,11702,11947,11970,11792,11786,11795,11715,11956,11957,11718,11963,11746,11741,11955,6390,11798,11735,11727,11776,11784,11980,11706,11733,11959,11726,11757,11701,11731,11738,11769,11782,11763,11953,11780,11954,11940,11720,11755,11777,11964,11965,11930,11933,11796,11975,11977,11713,11787,11767,11705,11934,11779,11754,11716,11960,11972,11950,11703,11942,11764,11778,11935,11725,11968,11789,11729,11788]},
            {name: 'Sullivan', value: false, zipCode: [12786,12749,12701,12778,12762,12723,12747,12721,12719,12790,12748,12741,12740,12759,12789,12776,12775,12733,12732,12783,12758,12788,12751,12779,12752,12764,12763]},
            {name: 'Tioga', value: false, zipCode: [13760,13732,14892]},
            {name: 'Tompkins', value: false, zipCode: [14853,14850,14886,14854,13068,14817,14882,14883,14881,13102,13045]},
            {name: 'Ulster', value: false, zipCode: [12566,12515,12528,12420,12428,12446,12401,12498,12458,12404,12561,12471,12487,12449,12484,12440,12465,12410,12441,12475,12453,12477,12466,12417,12432,12547,12542,12481,12464,12486,12491,12433,12589]},
            {name: 'Warren', value: false, zipCode: [12804,12853,12817,12801,12814,12860]},
            {name: 'Washington', value: false, zipCode: [12839,12832]},
            {name: 'Wayne', value: false, zipCode: [14513,14555,14589,14568,14502,13143,14516,14433]},
            {name: 'Westchester', value: false, zipCode: [10536,10502,10583,10530,10504,10526,10595,10507,10598,10546,10589,10532,10522,10596,10511,10549,10535,10588,10708,10520,10707,10541,10591,10514,10567,10547,10538,10594,10703,10701,10705,10704,10710,10801,10804,10805,10580,10577,10604,10528,10803,10503,10533,10510,10576,10552,10553,10550,10566,10570,10523,10573,10706,10548,10603,10601,10605,10606]},
            {name: 'Wyoming', value: false, zipCode: [14145,14024,14550,14591]},
            {name: 'Yates', value: false, zipCode: [14478,14527,14837,14544]},
        ]
    },
    {
        id: 34,
        name: 'North Carolina',
        stateId: 'NC',
        child: [
            {name: 'Alamance', value: false, zipCode: [27217,27202,27244,27302,27201,27215,27253,27258,27340]},
            {name: 'Alexander', value: false, zipCode: [28681,28678,28601,28636]},
            {name: 'Alleghany', value: false, zipCode: [28675]},
            {name: 'Anson', value: false, zipCode: [28133,28119,28007,28135,28091,28102,28170]},
            {name: 'Ashe', value: false, zipCode: [28640,28694,28643]},
            {name: 'Avery', value: false, zipCode: [28604,28622,28616,28657,28646]},
            {name: 'Beaufort', value: false, zipCode: [27860,27808,27889,27817,27810,27806,27865]},
            {name: 'Bertie', value: false, zipCode: [27847,27967,27983,27849,27872,27924,27805]},
            {name: 'Bladen', value: false, zipCode: [28320,28332,28456,28337,28448,28399,28392,28433]},
            {name: 'Brunswick', value: false, zipCode: [28451,28465,28456,28461,28468,28467,28422,28479,28462,28470,28469]},
            {name: 'Buncombe', value: false, zipCode: [28806,28709,28804,28787,28704,28805,28803,28801,28778,28757,28711]},
            {name: 'Burke', value: false, zipCode: [28612,28690,28655,28671,28637,28667,28601,28666,28602,28619,28628]},
            {name: 'Cabarrus', value: false, zipCode: [28075,28027,28083,28081,28025,28107,28124]},
            {name: 'Caldwell', value: false, zipCode: [28601,28645,28638,28630]},
            {name: 'Camden', value: false, zipCode: [27976,27921]},
            {name: 'Carteret', value: false, zipCode: [28584,28570,28512,28516,28528,28557,28553,28594,28531,28575,28524,28511]},
            {name: 'Caswell', value: false, zipCode: [27305,27379,27212]},
            {name: 'Catawba', value: false, zipCode: [28601,28602,28658,28650,28613,28610,28609]},
            {name: 'Chatham', value: false, zipCode: [27208,27344,27517,27252,27312,27516,27559]},
            {name: 'Cherokee', value: false, zipCode: [28906,28901,28905]},
            {name: 'Chowan', value: false, zipCode: [27932]},
            {name: 'Clay', value: false, zipCode: [28904]},
            {name: 'Cleveland', value: false, zipCode: [28150,28152,28073,28114,28090,28020,28017,28042,28089,28136,28021,28169,28086]},
            {name: 'Columbus', value: false, zipCode: [28472,28438,28463,28439,28430,28424,28436,28456,28442,28450,28423,28431]},
            {name: 'Craven', value: false, zipCode: [28560,28562,28523,28519,28526,28533,28532,28586]},
            {name: 'Cumberland', value: false, zipCode: [28312,28390,28304,28306,28307,28301,28303,28308,28305,28311,28310,28314,28395,28344,28391,28342,28331,28356,28348]},
            {name: 'Currituck', value: false, zipCode: [27917,27923,27939,27958]},
            {name: 'Dare', value: false, zipCode: [27920,27949,27981,27948,27943,27959,27953,27915,27982,27972,27936,27954,27968]},
            {name: 'Davidson', value: false, zipCode: [27351,27292,27295,27374,27239,27299,27360,27107,27127]},
            {name: 'Davie', value: false, zipCode: [27028,27006,27014]},
            {name: 'Duplin', value: false, zipCode: [28398,28349,28466,28572,28341,28464,28521,28518,28453,28458,28325,28365]},
            {name: 'Durham', value: false, zipCode: [27572,27703,27705,27704,27707,27701,27708,27712,27713]},
            {name: 'Edgecombe', value: false, zipCode: [27886,27819,27881,27852,27864]},
            {name: 'Forsyth', value: false, zipCode: [27045,27040,27106,27105,27101,27051,27050,27110,27127,27107,27104,27103,27109,27012,27023,27284]},
            {name: 'Franklin', value: false, zipCode: [27549,27882,27596,27508,27525]},
            {name: 'Gaston', value: false, zipCode: [28098,28164,28021,28054,28101,28016,28120,28012,28032,28034,28092,28077,28056,28052,28006]},
            {name: 'Gates', value: false, zipCode: [27938,27979]},
            {name: 'Graham', value: false, zipCode: [28771,28733]},
            {name: 'Granville', value: false, zipCode: [27581,27522,27509,27565,27582]},
            {name: 'Greene', value: false, zipCode: [28554,28580,28538,27888]},
            {name: 'Guilford', value: false, zipCode: [27342,27249,27377,27358,27406,27313,27357,27284,27282,27301,27310,27265,27235,27268,27262,27263,27260,27411,27410,27412,27455,27408,27409,27403,27401,27407,27405,27214]},
            {name: 'Halifax', value: false, zipCode: [27870,27874,27843,27850,27839,27823,27844,27890]},
            {name: 'Harnett', value: false, zipCode: [28323,28326,27332,28334,28339,27501,28390,27546,27506,27521]},
            {name: 'Haywood', value: false, zipCode: [28786,28745,28785,28716,28721,28751]},
            {name: 'Henderson', value: false, zipCode: [28792,28731,28791,28758,28739,28726,28759,28742,28729,28732,28735]},
            {name: 'Hertford', value: false, zipCode: [27942,27910,27818,27855,27986,27922]},
            {name: 'Hoke', value: false, zipCode: [28357,28386,28376,28306,28315]},
            {name: 'Hyde', value: false, zipCode: [27826,27885,27824,27960]},
            {name: 'Iredell', value: false, zipCode: [28117,28115,28166,28037,28682,28673,28634,28625,28677]},
            {name: 'Jackson', value: false, zipCode: [28723,28779,28717,28725,28736,28788]},
            {name: 'Johnston', value: false, zipCode: [27591,27527,27577,27576,27555,27593,27520,27568,27542,27504,27524,27569]},
            {name: 'Jones', value: false, zipCode: [28555,28585,28573]},
            {name: 'Lee', value: false, zipCode: [27330,27332,27505]},
            {name: 'Lenoir', value: false, zipCode: [28572,28525,28501,28504,28530,28551]},
            {name: 'Lincoln', value: false, zipCode: [28092,28033,28164,28080,28037]},
            {name: 'Macon', value: false, zipCode: [28734,28741]},
            {name: 'Madison', value: false, zipCode: [28753,28743,28754]},
            {name: 'Martin', value: false, zipCode: [27892,27857,27846,27841,27871,27861,27825,27840]},
            {name: 'McDowell', value: false, zipCode: [28762,28752,28761]},
            {name: 'Mecklenburg', value: false, zipCode: [28134,28105,28036,28269,28262,28280,28282,28209,28208,28205,28204,28207,28206,28203,28202,28223,28227,28226,28278,28270,28273,28274,28277,28216,28217,28214,28215,28212,28213,28210,28211,28244,28031,28078]},
            {name: 'Mitchell', value: false, zipCode: [28777,28705]},
            {name: 'Montgomery', value: false, zipCode: [27229,27209,27371,27356,27306]},
            {name: 'Moore', value: false, zipCode: [28374,28387,27376,28315,28373,28327,28326,27325,27281,28394]},
            {name: 'Nash', value: false, zipCode: [27856,27804,27809,27557,27891,27803,27878,27882,27807,27801,27816]},
            {name: 'New Hanover', value: false, zipCode: [28405,28411,28449,28428,28429,28401,28409,28412,28403,28480]},
            {name: 'Northampton', value: false, zipCode: [27845,27862,27877,27832,27831,27876,27820,27897,27869]},
            {name: 'Onslow', value: false, zipCode: [28546,28540,28574,28460,28584,28547,28544,28542,28543,28445]},
            {name: 'Orange', value: false, zipCode: [27599,27516,27514,27517,27278,27510,27243]},
            {name: 'Pamlico', value: false, zipCode: [28515,28510,28587,28529,28509,28571,28537,28556]},
            {name: 'Pasquotank', value: false, zipCode: [27909]},
            {name: 'Pender', value: false, zipCode: [28445,28443,28457,28425,28421,28478]},
            {name: 'Perquimans', value: false, zipCode: [27944,27985]},
            {name: 'Person', value: false, zipCode: [27573]},
            {name: 'Pitt', value: false, zipCode: [27829,27827,28590,27858,27879,27884,28530,27828,27837,27834,28513,27812]},
            {name: 'Polk', value: false, zipCode: [28722,28756,28773,28782]},
            {name: 'Randolph', value: false, zipCode: [27298,27205,27203,27355,27317,27370,27263,27316,27341,27248]},
            {name: 'Richmond', value: false, zipCode: [28345,28347,28379,28330,28338,28367]},
            {name: 'Robeson', value: false, zipCode: [28378,28384,28369,28371,28383,28364,28377,28372,28375,28359,28358,28360,28386,28340,28362,28357]},
            {name: 'Rockingham', value: false, zipCode: [27288,27025,27326,27320,27027,27048]},
            {name: 'Rowan', value: false, zipCode: [28144,28159,28023,28138,28088,28041,28146,28072,27013,28039,28147,28081,28071]},
            {name: 'Rutherford', value: false, zipCode: [28720,28160,28114,28076,28139,28043,28018,28040,28024,28746,28019]},
            {name: 'Sampson', value: false, zipCode: [28328,28385,28458,28453,28393,28447,28366,28441,28382,28334,28444,28318]},
            {name: 'Scotland', value: false, zipCode: [28352,28351,28396,28343]},
            {name: 'Stanly', value: false, zipCode: [28128,28129,28097,28001,28163,28109,28137,28127,28009,28107]},
            {name: 'Stokes', value: false, zipCode: [27016,27021,27043,27052,27019]},
            {name: 'Surry', value: false, zipCode: [27030,27041,27017,28621,27024]},
            {name: 'Swain', value: false, zipCode: [28719,28713]},
            {name: 'Transylvania', value: false, zipCode: [28712,28772]},
            {name: 'Tyrrell', value: false, zipCode: [27925]},
            {name: 'Union', value: false, zipCode: [28103,28079,28110,28104,28173,28112,28174,28108]},
            {name: 'Vance', value: false, zipCode: [27556,27544,27536,27537]},
            {name: 'Wake', value: false, zipCode: [27529,27603,27539,27540,27591,27526,27571,27587,27502,27523,27560,27518,27519,27511,27513,27617,27616,27615,27614,27613,27612,27610,27609,27601,27604,27605,27606,27608,27607,27697,27695,27545,27597]},
            {name: 'Warren', value: false, zipCode: [27589,27563,27551]},
            {name: 'Washington', value: false, zipCode: [27970,27962,27928]},
            {name: 'Watauga', value: false, zipCode: [28692,28698,28679,28604,28607,28605]},
            {name: 'Wayne', value: false, zipCode: [28551,27534,28333,27830,28578,28365,27530,27533,27531,27863]},
            {name: 'Wilkes', value: false, zipCode: [28659,28697,28651,28654,28635,28670,28621]},
            {name: 'Wilson', value: false, zipCode: [27851,27873,27883,27896,27893,27822,27880,27813]},
            {name: 'Yadkin', value: false, zipCode: [28642,27018,27011,27055]},
            {name: 'Yancey', value: false, zipCode: [28714]},
        ]
    },
    {
        id: 35,
        name: 'North Dakota',
        stateId: 'ND',
        child: [
            {name: 'Adams', value: false, zipCode: [58639,58649]},
            {name: 'Barnes', value: false, zipCode: [58480,58461,58031,58065,58049,58479,58429,58492,58063,58062,58072]},
            {name: 'Benson', value: false, zipCode: [58335,58332,58351,58343,58381,58386,58346,58348,58357]},
            {name: 'Billings', value: false, zipCode: [58645]},
            {name: 'Bottineau', value: false, zipCode: [58384,58783,58760,58711,58748,58762,58318,58793,58750]},
            {name: 'Bowman', value: false, zipCode: [58623,58653,58651]},
            {name: 'Burke', value: false, zipCode: [58752,58737,58772,58773,58727,58721]},
            {name: 'Burleigh', value: false, zipCode: [58504,58505,58501,58503,58494,58477,58558,58532]},
            {name: 'Cass', value: false, zipCode: [58042,58104,58078,58102,58103,58105,58047,58051,58071,58036,58059,58038,58048,58021,58031,58012,58079,58052,58006,58064,58005,58007,58004,58029,58011]},
            {name: 'Cavalier', value: false, zipCode: [58352,58355,58239,58281,58249,58372,58260,58269,58323,58311,58338]},
            {name: 'Dickey', value: false, zipCode: [58436,58474,58439,58441]},
            {name: 'Divide', value: false, zipCode: [58730,58765,58833,58844]},
            {name: 'Dunn', value: false, zipCode: [58636,58642,58640,58625,58626]},
            {name: 'Eddy', value: false, zipCode: [58356,58374]},
            {name: 'Emmons', value: false, zipCode: [58524,58542,58544,58552,58573]},
            {name: 'Foster', value: false, zipCode: [58421,58445,58464,58443]},
            {name: 'Golden Valley', value: false, zipCode: [58621,58654,58632]},
            {name: 'Grand Forks', value: false, zipCode: [58228,58278,58205,58204,58267,58244,58256,58251,58202,58203,58201,58235,58266]},
            {name: 'Grant', value: false, zipCode: [58529,58562,58564,58533]},
            {name: 'Griggs', value: false, zipCode: [58416,58448,58425,58484,58452]},
            {name: 'Hettinger', value: false, zipCode: [58646,58650,58647]},
            {name: 'Kidder', value: false, zipCode: [58475,58428,58478,58487,58482,58488]},
            {name: 'LaMoure', value: false, zipCode: [58431,58415,58458,58456,58433,58454,58490,58466]},
            {name: 'Logan', value: false, zipCode: [58561,58440,58442]},
            {name: 'McHenry', value: false, zipCode: [58741,58712,58789,58723,58792,58713,58788,58744,58731,58736,58710,58790]},
            {name: 'McIntosh', value: false, zipCode: [58460,58581,58413,58495]},
            {name: 'McKenzie', value: false, zipCode: [58763,59221,58757,58831,58854,58835]},
            {name: 'McLean', value: false, zipCode: [58559,58531,58759,58576,58579,58716,58775,58540,58577,58723,58565,58575]},
            {name: 'Mercer', value: false, zipCode: [58541,58545,58523,58571,58580]},
            {name: 'Morton', value: false, zipCode: [58631,58520,58535,58638,58554,58563]},
            {name: 'Mountrail', value: false, zipCode: [58770,58769,58784,58794,58763,58771,58776]},
            {name: 'Nelson', value: false, zipCode: [58344,58361,58254,58380,58212,58272,58224]},
            {name: 'Oliver', value: false, zipCode: [58530]},
            {name: 'Pembina', value: false, zipCode: [58265,58282,58238,58262,58222,58220,58216,58271,58225,58276]},
            {name: 'Pierce', value: false, zipCode: [58385,58341,58313,58368]},
            {name: 'Ramsey', value: false, zipCode: [58327,58330,58321,58377,58345,58338,58325]},
            {name: 'Ransom', value: false, zipCode: [58033,58068,58027,58057,58054]},
            {name: 'Renville', value: false, zipCode: [58782,58750,58740,58787,58761]},
            {name: 'Richland', value: false, zipCode: [58030,58001,58075,58076,58053,58015,58081,58061,58058,58041,58008,58018,58077]},
            {name: 'Rolette', value: false, zipCode: [58329,58353,58316,58367,58366,58369]},
            {name: 'Sargent', value: false, zipCode: [58040,58032,58017,58060,58043,58013,58067]},
            {name: 'Sheridan', value: false, zipCode: [58758,58430,58463,58444]},
            {name: 'Sioux', value: false, zipCode: [58570,58568,58538,58528]},
            {name: 'Slope', value: false, zipCode: [58643,58620]},
            {name: 'Stark', value: false, zipCode: [58656,58601,58652,58655,58630,58622]},
            {name: 'Steele', value: false, zipCode: [58277,58230,58056,58046]},
            {name: 'Stutsman', value: false, zipCode: [58420,58472,58401,58402,58405,58476,58424,58497,58483,58496,58467,58455,58426]},
            {name: 'Towner', value: false, zipCode: [58339,58363,58331,58317,58324]},
            {name: 'Traill', value: false, zipCode: [58016,58257,58218,58035,58219,58240,58009,58274,58275,58045]},
            {name: 'Walsh', value: false, zipCode: [58261,58243,58237,58229,58210,58270,58231,58273,58227,58233,58250]},
            {name: 'Ward', value: false, zipCode: [58725,58735,58734,58718,58705,58704,58756,58779,58701,58722,58785,58746,58707,58703,58781,58733]},
            {name: 'Wells', value: false, zipCode: [58418,58451,58341,58438,58422,58486]},
            {name: 'Williams', value: false, zipCode: [58849,58852,58830,58801,58853,58755,58843,58795,58845]},
        ]
    },
    {
        id: 36,
        name: 'Ohio',
        stateId: 'OH',
        child: [
            {name: 'Adams', value: false, zipCode: [45660,45679,45693,45684,45105,45144,45697,45618]},
            {name: 'Allen', value: false, zipCode: [45820,45806,45804,45850,45807,45854,45808,45830,45833,45809,45887,45801,45805,45817]},
            {name: 'Ashland', value: false, zipCode: [44864,44805,44874,44866,44842,44287,44838,44840,44880]},
            {name: 'Ashtabula', value: false, zipCode: [44010,44041,44004,44003,44048,44076,44084,44068,44030,44085,44047]},
            {name: 'Athens', value: false, zipCode: [45701,45732,45711,45766,45716,45764,45778,45761,45740,45710,45780,45782,45739,45723,45719]},
            {name: 'Auglaize', value: false, zipCode: [45885,45865,45806,45895,45871,45884,45896,45819,45888,45869,45870]},
            {name: 'Belmont', value: false, zipCode: [43977,43718,43950,43912,43947,43759,43928,43713,43985,43909,43971,43972,43934,43940,43906,43935,43942,43719,43951]},
            {name: 'Brown', value: false, zipCode: [45154,45121,45168,45101,45171,45118,45130,45142,45131,45167]},
            {name: 'Butler', value: false, zipCode: [45056,45011,45067,45014,45013,45064,45042,45050,45044,45036,45005,45069,45062,45015]},
            {name: 'Carroll', value: false, zipCode: [44657,44639,44644,44620,44675,44615]},
            {name: 'Champaign', value: false, zipCode: [45389,43084,43044,43078,43060,43072,45317,43070]},
            {name: 'Clark', value: false, zipCode: [45341,45369,45319,45323,45503,45502,45344,45368,45504,45324,45349,45501,45506,45505,45372,43010]},
            {name: 'Clermont', value: false, zipCode: [45120,45176,45244,45103,45102,45160,45156,45153,45150,45157,45245,45255,45106,45122,45140,45147,45112]},
            {name: 'Clinton', value: false, zipCode: [45164,45107,45177,45148,45159,45169,45146,45113]},
            {name: 'Columbiana', value: false, zipCode: [44427,43945,43962,44455,43920,44634,44408,44441,44445,44625,44490,44432,44431,44460,44413,44423,43968]},
            {name: 'Coshocton', value: false, zipCode: [43811,43812,43844,43824,43845,43836]},
            {name: 'Crawford', value: false, zipCode: [44820,44849,44833,44825,44818,44827,44887,44856,44881,44854]},
            {name: 'Cuyahoga', value: false, zipCode: [44132,44119,44117,44123,44139,44128,44122,44118,44112,44121,44106,44040,44143,44125,44105,44022,44107,44124,44108,44135,44120,44113,44111,44110,44115,44114,44144,44127,44104,44101,44102,44103,44109,44070,44017,44146,44147,44116,44130,44131,44137,44126,44134,44129,44136,44149,44142,44140,44133,44138,44145,44141]},
            {name: 'Darke', value: false, zipCode: [45388,45304,45390,45348,45331,45332,45303,45352,45351,45380,45362,45350,45308,45328,45346,45358]},
            {name: 'Defiance', value: false, zipCode: [43512,43549,43526]},
            {name: 'Delaware', value: false, zipCode: [43003,43015,43066,43032,43021,43074,43065,43061]},
            {name: 'Erie', value: false, zipCode: [44870,44089,44814,44846,44839,44889,43464,43438,44824]},
            {name: 'Fairfield', value: false, zipCode: [43105,43157,43107,43155,43046,43147,43148,43076,43110,43136,43130,43150,43154,43112,43102]},
            {name: 'Fayette', value: false, zipCode: [43142,43160,43128,43106,45123]},
            {name: 'Franklin', value: false, zipCode: [43026,43126,43017,43016,43228,43109,43110,43209,43123,43212,43213,43215,43068,43054,43220,43221,43222,43223,43224,43081,43227,43214,43217,43219,43218,43002,43004,43085,43240,43229,43235,43232,43231,43230,43119,43137,43211,43210,43202,43203,43201,43206,43207,43204,43205,43082,43125]},
            {name: 'Fulton', value: false, zipCode: [43533,43567,43521,43502,43540,43558,43515,43553]},
            {name: 'Gallia', value: false, zipCode: [45631,45620,45686,45623,45614,45674]},
            {name: 'Geauga', value: false, zipCode: [44491,44062,44080,44026,44022,44024,44023,44021]},
            {name: 'Greene', value: false, zipCode: [45435,45324,45314,45385,45440,45434,45432,45431,45430,45301,45305,45387,45433,45384,45307,45335,45370,45316]},
            {name: 'Guernsey', value: false, zipCode: [43773,43736,43780,43723,43778,43755,43725,43768,43749,43772,43732,43722]},
            {name: 'Hamilton', value: false, zipCode: [45246,45216,45140,45215,45241,45213,45227,45236,45242,45243,45002,45033,45211,45247,45239,45255,45230,45249,45231,45224,45240,45041,45252,45030,45251,45052,45233,45238,45217,45174,45226,45111,45237,45244,45248,45267,45219,45212,45214,45225,45223,45229,45232,45204,45205,45206,45207,45202,45203,45208,45209,45220,45001,45218]},
            {name: 'Hancock', value: false, zipCode: [45816,45840,45881,44804,45814,45868,45867,45858,45890,45889,45841]},
            {name: 'Hardin', value: false, zipCode: [45843,43340,45835,45810,45812,45836,43326,45859]},
            {name: 'Harrison', value: false, zipCode: [43974,43901,43907,44699,43981,43988,44693,43976,43973,43986,44695]},
            {name: 'Henry', value: false, zipCode: [43548,43545,43535,43555,43502,43516,43527,43534,43524,43532]},
            {name: 'Highland', value: false, zipCode: [45172,45155,45142,45123,45133,45132,45135,45154,45171]},
            {name: 'Hocking', value: false, zipCode: [43135,43107,43155,43138,43127,43144,43149,43111]},
            {name: 'Holmes', value: false, zipCode: [44654,44610,44660,44624,44690,44681,44687,44637,44633,44628,44661]},
            {name: 'Huron', value: false, zipCode: [44847,44865,44851,44890,44837,44855,44889,44811,44826,44857]},
            {name: 'Jackson', value: false, zipCode: [45692,45656,45621,45640]},
            {name: 'Jefferson', value: false, zipCode: [43939,43943,43952,43953,43961,43964,43908,43932,43944,43901,43903,43938,43913,43963,43926,43925,43910]},
            {name: 'Knox', value: false, zipCode: [43011,43014,43028,43050,43022,43019,43006,43080,43037,43005]},
            {name: 'Lake', value: false, zipCode: [44057,44060,44081,44095,44092,44094,44077,44045]},
            {name: 'Lawrence', value: false, zipCode: [45669,45680,45638,45619,45623]},
            {name: 'Licking', value: false, zipCode: [43062,43740,43031,43056,43721,43760,43739,43025,43008,43023,43055,43033,43046,43030,43076,43018,43013,43071,43080,43001]},
            {name: 'Logan', value: false, zipCode: [43360,43331,43348,43357,43324,43318,43333,43347,43310,43345,43343,43311,43319,43358]},
            {name: 'Lorain', value: false, zipCode: [44074,44011,44050,44090,44049,44001,44044,44054,44039,44012,44089,44035,44052,44053,44055]},
            {name: 'Lucas', value: false, zipCode: [43528,43412,43537,43616,43571,43522,43547,43532,43560,43566,43434,43615,43606,43614,43611,43613,43612,43607,43610,43604,43605,43608,43609,43620,43623,43504]},
            {name: 'Madison', value: false, zipCode: [43064,43140,43151,43143,43153,43119,43162]},
            {name: 'Mahoning', value: false, zipCode: [44452,44514,44502,44503,44504,44505,44506,44507,44509,44511,44510,44555,44601,44471,44672,44609,44443,44436,44406,44515,44429,44454,44442,44460,44619,44405]},
            {name: 'Marion', value: false, zipCode: [43322,43302,43341,43314,43337,43332,43356,43342]},
            {name: 'Medina', value: false, zipCode: [44275,44256,44273,44251,44274,44281,44215,44254,44280,44212]},
            {name: 'Meigs', value: false, zipCode: [45760,45775,45771,45779,45783,45723,45772,45769]},
            {name: 'Mercer', value: false, zipCode: [45828,45862,45882,45826,45883,45846,45860,45822,45310,45866]},
            {name: 'Miami', value: false, zipCode: [45356,45312,45318,45308,45339,45383,45326,45371,45359,45361,45373,45337,45344]},
            {name: 'Monroe', value: false, zipCode: [43716,43915,45734,43946,43747,43793,43754,43786,43931]},
            {name: 'Montgomery', value: false, zipCode: [45325,45449,45342,45327,45414,45371,45377,45439,45409,45419,45424,45322,45459,45458,45426,45417,45416,45405,45415,45309,45432,45469,45402,45403,45406,45404,45428,45420,45423,45410,45440,45429,45315,45431,45345,45354]},
            {name: 'Morgan', value: false, zipCode: [43728,43758,43787,43731,43756]},
            {name: 'Morrow', value: false, zipCode: [43334,43320,43338,44833,43325,43350,43315,43321,43317]},
            {name: 'Muskingum', value: false, zipCode: [43760,43738,43762,43701,43771,43802,43830,43842,43777,43735,43822,43821,43734,43767]},
            {name: 'Noble', value: false, zipCode: [43717,43724,43788,45727,43779,43773]},
            {name: 'Ottawa', value: false, zipCode: [43449,43440,43458,43452,43468,43616,43412,43430,43408,43445,43416,43456]},
            {name: 'Paulding', value: false, zipCode: [45880,45879,45821,45813,45855,45851,45849,45873,45861]},
            {name: 'Perry', value: false, zipCode: [43730,43766,43748,43782,43764,43731,43783,43739,43076,43777]},
            {name: 'Pickaway', value: false, zipCode: [43103,43113,43146,43117,43145,43156,43164,43116,43137]},
            {name: 'Pike', value: false, zipCode: [45661,45613,45612,45624,45690]},
            {name: 'Portage', value: false, zipCode: [44240,44243,44260,44231,44288,44241,44201,44234,44255,44202,44266]},
            {name: 'Preble', value: false, zipCode: [45381,45338,45378,45382,45320,45311,45321,45070,45064,45330,45347,45003]},
            {name: 'Putnam', value: false, zipCode: [45864,45815,45856,45876,45877,45875,45827,45831,45844,45848,45853,45830,45893]},
            {name: 'Richland', value: false, zipCode: [44904,44906,44822,44903,44878,44875,44905,44907,44901,44902,44843,44813]},
            {name: 'Ross', value: false, zipCode: [45617,45681,43101,45644,45673,45601,45628,43115]},
            {name: 'Sandusky', value: false, zipCode: [43407,43464,43469,43420,43442,43435,43410,43431]},
            {name: 'Scioto', value: false, zipCode: [45629,45682,45653,45652,45663,45630,45684,45662,45648,45657,45694,45671]},
            {name: 'Seneca', value: false, zipCode: [44815,44844,44830,44809,44883,44807,44853,44841,44861,44828,44811,44836,44818,44867]},
            {name: 'Shelby', value: false, zipCode: [45365,45363,45306,45302,45360,45334,45356,45845,45336]},
            {name: 'Stark', value: false, zipCode: [44626,44709,44720,44601,44689,44608,44643,44688,44666,44706,44707,44641,44714,44710,44702,44703,44704,44705,44708,44662,44646,44652,44721,44730,44685,44630,44613,44657,44614,44647,44670,44632,44640]},
            {name: 'Summit', value: false, zipCode: [44203,44333,44262,44087,44216,44319,44321,44224,44306,44307,44304,44305,44302,44303,44301,44308,44325,44320,44311,44310,44313,44312,44314,44236,44250,44286,44202,44685,44232,44720,44260,44056,44264,44221,44223,44067,44278]},
            {name: 'Trumbull', value: false, zipCode: [44446,44420,44483,44481,44437,44484,44425,44491,44473,44440,44438,44403,44485,44428,44444,44430,44515,44505,44410,44404]},
            {name: 'Tuscarawas', value: false, zipCode: [43804,44663,44629,44683,44682,44671,44612,43837,44681,44622,44624,44656,44680,44621,43832,44697,43840,44653]},
            {name: 'Union', value: false, zipCode: [43036,43040,43077,43064,43045,43344,43067]},
            {name: 'Van Wert', value: false, zipCode: [45898,45863,45894,45886,45832,45891,45838,45874,45899]},
            {name: 'Vinton', value: false, zipCode: [45651,45634,45695,45686,45698]},
            {name: 'Warren', value: false, zipCode: [45140,45068,45162,45066,45005,45040,45152,45039,45065,45036,45342,45032,45034]},
            {name: 'Washington', value: false, zipCode: [45750,45745,45784,45714,45715,45742,45746,45768,45786,45744,45767]},
            {name: 'Wayne', value: false, zipCode: [44214,44270,44677,44627,44659,44645,44691,44667,44606,44618,44217,44287,44276,44676,44230]},
            {name: 'Williams', value: false, zipCode: [43531,43501,43570,43554,43506,43543,43518,43517,43505,43557]},
            {name: 'Wood', value: false, zipCode: [44817,43465,44830,43467,43451,43443,43402,43403,43447,43551,43463,43406,43522,45872,43450,43619,43565,43511,43525,43529,43462,43457,43413,43466,43569,43541,43437,43460]},
            {name: 'Wyandot', value: false, zipCode: [43351,43323,43330,44849,43359,44882,43316]},
        ]
    },
    {
        id: 37,
        name: 'Oklahoma',
        stateId: 'OK',
        child: [
            {name: 'Adair', value: false, zipCode: [74965,74964,74960,74931,74347,74457,74948]},
            {name: 'Alfalfa', value: false, zipCode: [73728,73741,73739,73719,73749,73722,73716,73726]},
            {name: 'Atoka', value: false, zipCode: [74525,74533,74576,74569,74555]},
            {name: 'Beaver', value: false, zipCode: [73950,73932,73938,73844]},
            {name: 'Beckham', value: false, zipCode: [73662,73666,73668,73645,73644,73627]},
            {name: 'Blaine', value: false, zipCode: [73763,73744,73772,73755,73724,73043]},
            {name: 'Bryan', value: false, zipCode: [74730,74753,73449,74726,74721,74723,74731,74748,74729,74701,74747,74741,74720,74733]},
            {name: 'Caddo', value: false, zipCode: [73029,73053,73015,73005,73033,73047,73006,73038,73009,73042,73048,73017]},
            {name: 'Canadian', value: false, zipCode: [73762,73014,73040,73022,73036,73090,73099,73078,73064,73047]},
            {name: 'Carter', value: false, zipCode: [73401,73458,73443,73435,73463,73438,73481,73436,73487]},
            {name: 'Cherokee', value: false, zipCode: [74464,74451,74471,74441,74931,74427,74364,74434,74960,74457]},
            {name: 'Choctaw', value: false, zipCode: [74761,74738,74743,74735,74756,74759,74727]},
            {name: 'Cimarron', value: false, zipCode: [73946,73933,73947,73937]},
            {name: 'Cleveland', value: false, zipCode: [73051,73068,73160,73019,73026,73072,73070,73071,74857,73069]},
            {name: 'Coal', value: false, zipCode: [74538,74535,74572,74556,74534]},
            {name: 'Comanche', value: false, zipCode: [73540,73528,73552,73538,73006,73541,73507,73527,73567,73503,73505,73501,73543,73557]},
            {name: 'Cotton', value: false, zipCode: [73568,73562,73531,73572]},
            {name: 'Craig', value: false, zipCode: [74301,74333,74369,74332,74349]},
            {name: 'Creek', value: false, zipCode: [74047,74066,74068,74030,74028,74071,74010,74046,74052,74131,74041,74044,74039]},
            {name: 'Custer', value: false, zipCode: [73669,73096,73620,73625,73601,73639]},
            {name: 'Delaware', value: false, zipCode: [74346,74365,74364,74331,74344,74342,74347,74359,74368,74338,74964]},
            {name: 'Dewey', value: false, zipCode: [73654,73667,73859,73835,73663,73646,73659,73658]},
            {name: 'Ellis', value: false, zipCode: [73832,73843,73858,73840]},
            {name: 'Garfield', value: false, zipCode: [73733,73730,73701,73703,73705,73743,73727,73738,73720,74640,73736,73753,73735,73754,73773]},
            {name: 'Garvin', value: false, zipCode: [73052,74872,73074,73434,73433,73030,73098,73075]},
            {name: 'Grady', value: false, zipCode: [73018,73004,73079,73082,73010,73089,73011,73002,73067,73092,73059]},
            {name: 'Grant', value: false, zipCode: [73758,74636,73759,74643,73771,73761,73766]},
            {name: 'Greer', value: false, zipCode: [73547,73673,73554]},
            {name: 'Harmon', value: false, zipCode: [73550,73544]},
            {name: 'Harper', value: false, zipCode: [73834,73855,73851,73848]},
            {name: 'Haskell', value: false, zipCode: [74944,74462,74472,74561,74552,74941,74943]},
            {name: 'Hughes', value: false, zipCode: [74570,74827,74848,74531,74839,74850,74883,74825]},
            {name: 'Jackson', value: false, zipCode: [73521,73560,73549,73556,73523,73532,73537,73526,73539]},
            {name: 'Jefferson', value: false, zipCode: [73456,73569,73573,73520,73548,73565]},
            {name: 'Johnston', value: false, zipCode: [73447,73460,73461,74871,74836,73450,74856,74530,73432,73401,74748,73455]},
            {name: 'Kay', value: false, zipCode: [74641,74632,74604,74601,74647,74631,74653,74646]},
            {name: 'Kingfisher', value: false, zipCode: [73016,73742,73750,73734,73756]},
            {name: 'Kiowa', value: false, zipCode: [73564,73041,73566,73559,73655,73651,73062]},
            {name: 'Latimer', value: false, zipCode: [74578,74563]},
            {name: 'Le Flore', value: false, zipCode: [74953,74940,74901,74902,74941,74956,74932,74959,74966,74930,74935,74951,74949,74577,74571,74937,74942,74939,74947]},
            {name: 'Lincoln', value: false, zipCode: [74824,74832,74026,74855,74881,74834,74869,74079,74028,74864,74875]},
            {name: 'Logan', value: false, zipCode: [73044,73027,73058,73050,73028,73016,73056,73073]},
            {name: 'Love', value: false, zipCode: [73448,73459,73441,73430]},
            {name: 'Major', value: false, zipCode: [73838,73718,73737,73760,73768,73729,73747]},
            {name: 'Marshall', value: false, zipCode: [73439,73446,73440]},
            {name: 'Mayes', value: false, zipCode: [74365,74350,74367,74330,74352,74364,74301,74340,74337,74361,74366,74349]},
            {name: 'McClain', value: false, zipCode: [73010,73031,73095,74831,73057,73072,73065,73093,73080]},
            {name: 'McCurtain', value: false, zipCode: [74737,74734,74764,74736,74750,74745,74728,74766,74740,74957]},
            {name: 'McIntosh', value: false, zipCode: [74459,74426,74432,74438,74845]},
            {name: 'Murray', value: false, zipCode: [74865,73032,73086,73030]},
            {name: 'Muskogee', value: false, zipCode: [74403,74401,74434,74423,74450,74455,74470,74435,74428,74463,74469,74436,74422,74468]},
            {name: 'Noble', value: false, zipCode: [73077,74644,73757,74651,74630,73061]},
            {name: 'Nowata', value: false, zipCode: [74027,74016,74083,74072,74048,74042]},
            {name: 'Okfuskee', value: false, zipCode: [74880,74859,74833,74010,74829,74860]},
            {name: 'Oklahoma', value: false, zipCode: [73013,73007,73003,73034,73132,73122,73115,73117,73008,73121,73110,73141,73130,73020,73045,73054,73084,73116,73120,73049,73099,73119,73118,73114,73111,73112,73012,73142,73145,73149,73169,73078,73108,73109,73106,73107,74857,73139,73131,73135,73134,73097,73105,73162,73103,73151,73150,73159,73064,73104,73102,73179,73173,73170,73160,73165,73127,73128,73129,73066]},
            {name: 'Okmulgee', value: false, zipCode: [74431,74421,74047,74437,74447,74445,74460,74880,74456]},
            {name: 'Osage', value: false, zipCode: [74056,74084,74054,74035,74002,74001,74070,74652,74633,74637,74604,74060]},
            {name: 'Ottawa', value: false, zipCode: [74370,74343,74339,74354,74363,74358,74331]},
            {name: 'Pawnee', value: false, zipCode: [74058,74045,74020,74044,74650,74081,74038,74034,74085]},
            {name: 'Payne', value: false, zipCode: [74032,74078,74077,74075,74074,74062,73073,73063,74059,74023,74085]},
            {name: 'Pittsburg', value: false, zipCode: [74432,74547,74501,74442,74430,74565,74553,74546,74570,74528,74554,74560,74522,74425,74462,74561]},
            {name: 'Pontotoc', value: false, zipCode: [74820,74825,74865,74842,74844,74871,74843]},
            {name: 'Pottawatomie', value: false, zipCode: [74866,74826,74878,74804,74801,74840,74873,74851,74852,74854]},
            {name: 'Pushmataha', value: false, zipCode: [74558,74521,74523,74574,74536,74562,74557]},
            {name: 'Roger Mills', value: false, zipCode: [73650,73628,73642,73660]},
            {name: 'Rogers', value: false, zipCode: [74055,74019,74080,74017,74015,74036,74016,74053,74116]},
            {name: 'Seminole', value: false, zipCode: [74849,74859,74837,74884,74867,74868,74830]},
            {name: 'Sequoyah', value: false, zipCode: [74962,74435,74955,74948,74954,74936,74946,74945,74931]},
            {name: 'Stephens', value: false, zipCode: [73055,73491,73529,73533,73442]},
            {name: 'Texas', value: false, zipCode: [73901,73949,73942,73944,73945,73939,73951,73950]},
            {name: "Tillman", value: false, zipCode: [73555,73542,73570,73546,73553,73551,73530]},
            {name: 'Tulsa', value: false, zipCode: [74008,74037,74073,74130,74126,74021,74047,74033,74050,74131,74107,74055,74137,74134,74132,74133,74136,74135,74146,74145,74127,74112,74114,74115,74119,74015,74106,74129,74128,74120,74171,74110,74116,74117,74105,74103,74104,74108,74070,74063,74014,74011,74012]},
            {name: 'Wagoner', value: false, zipCode: [74458,74434,74467,74429,74014,74454,74446,74477]},
            {name: 'Washington', value: false, zipCode: [74006,74003,74051,74022,74082,74021,74061,74029]},
            {name: 'Washita', value: false, zipCode: [73647,73641,73632,73622,73664,73624,73626,73024,73021,73661]},
            {name: 'Woods', value: false, zipCode: [73717,73746,73731,73842,73860]},
            {name: 'Woodward', value: false, zipCode: [73801,73852,73857,73841,73853]},
        ]
    },
    {
        id: 38,
        name: 'Oregon',
        stateId: 'OR',
        child: [
            {name: 'Baker', value: false, zipCode: [97834,97884,97907,97814,97870,97877,97833]},
            {name: 'Benton', value: false, zipCode: [97324,97456,97370,97330,97333,97331,97361,97326]},
            {name: 'Clackamas', value: false, zipCode: [97222,97267,97034,97035,97028,97013,97002,97045,97004,97068,97049,97015,97089,97086,97042,97023,97070,97038,97009,97027,97055]},
            {name: 'Clatsop', value: false, zipCode: [97146,97121,97138,97103,97016,97110,97145]},
            {name: 'Columbia', value: false, zipCode: [97016,97056,97018,97053,97051,97048,97064,97054]},
            {name: 'Coos', value: false, zipCode: [97466,97459,97423,97458,97411,97420,97449]},
            {name: 'Crook', value: false, zipCode: [97754]},
            {name: 'Curry', value: false, zipCode: [97444,97450,97465,97415]},
            {name: 'Deschutes', value: false, zipCode: [97739,97759,97760,97702,97703,97707,97756,97701]},
            {name: 'Douglas', value: false, zipCode: [97443,97447,97471,97441,97435,97442,97479,97462,97467,97496,97432,97417,97469,97457,97429,97470,97499,97436,97495]},
            {name: 'Gilliam', value: false, zipCode: [97823,97812]},
            {name: 'Grant', value: false, zipCode: [97845,97864,97856,97877,97873,97865,97820,97825,97869]},
            {name: 'Harney', value: false, zipCode: [97732,97720,97738]},
            {name: 'Hood River', value: false, zipCode: [97031,97014,97041]},
            {name: 'Jackson', value: false, zipCode: [97537,97502,97539,97530,97504,97501,97524,97525,97540,97536,97520,97503,97522,97541,97535]},
            {name: 'Jefferson', value: false, zipCode: [97760,97761,97730,97741,97734]},
            {name: 'Josephine', value: false, zipCode: [97523,97538,97531,97526,97527,97544,97534,97532]},
            {name: 'Klamath', value: false, zipCode: [97623,97632,97733,97731,97626,97603,97601,97621,97624,97627,97633,97622,97737]},
            {name: 'Lake', value: false, zipCode: [97638,97630,97635,97636,97637]},
            {name: 'Lane', value: false, zipCode: [97424,97404,97478,97455,97408,97448,97431,97452,97454,97405,97401,97403,97402,97437,97463,97419,97439,97426,97453,97492,97487,97477]},
            {name: 'Lincoln', value: false, zipCode: [97498,97341,97364,97367,97365,97366,97391,97388,97368,97394,97380]},
            {name: 'Linn', value: false, zipCode: [97348,97377,97446,97345,97321,97355,97374,97327,97322,97358,97335,97386,97336,97389,97360]},
            {name: 'Malheur', value: false, zipCode: [97914,97901,97903,97913,97906,97918,97910,97911]},
            {name: 'Marion', value: false, zipCode: [97032,97346,97375,97325,97385,97002,97302,97301,97306,97304,97310,97317,97305,97137,97350,97383,97392,97071,97020,97381,97342,97352,97384,97303,97026,97362]},
            {name: 'Morrow', value: false, zipCode: [97844,97818,97843,97836,97839]},
            {name: 'Multnomah', value: false, zipCode: [97060,97219,97080,97220,97030,97233,97230,97024,97227,97221,97229,97203,97202,97201,97206,97205,97204,97209,97208,97258,97266,97213,97236,97232,97239,97218,97214,97215,97216,97217,97210,97211,97212]},
            {name: 'Polk', value: false, zipCode: [97338,97396,97347,97344,97361,97351]},
            {name: 'Sherman', value: false, zipCode: [97050,97039,97029,97065]},
            {name: 'Tillamook', value: false, zipCode: [97118,97135,97112,97141,97134,97122,97131,97130,97149,97108,97147,97136,97107]},
            {name: 'Umatilla', value: false, zipCode: [97801,97862,97813,97810,97868,97826,97886,97875,97835,97859,97880,97838,97882]},
            {name: 'Union', value: false, zipCode: [97824,97850,97867,97883,97841,97827,97876]},
            {name: 'Wallowa', value: false, zipCode: [97828,97885,97857,97846]},
            {name: 'Wasco', value: false, zipCode: [97063,97058,97040,97021,97037,97057,97001]},
            {name: 'Washington', value: false, zipCode: [97006,97123,97124,97223,97106,97133,97229,97116,97140,97225,97005,97062,97224,97078,97003,97007,97119,97113,97008]},
            {name: 'Wheeler', value: false, zipCode: [97750,97830,97874]},
            {name: 'Yamhill', value: false, zipCode: [97132,97128,97378,97111,97148,97396,97127,97115,97114,97101]},
        ]
    },
    {
        id: 39,
        name: 'Pennsylvania',
        stateId: 'PA',
        child: [
            {name: 'Adams', value: false, zipCode: [17320,17303,17307,17324,17353,17316,17304,17344,17331,17301,17372,17325,17350,17340,17306,17343]},
            {name: 'Allegheny', value: false, zipCode: [15006,15044,15133,15037,15120,15205,15220,15015,15139,15068,15239,15147,15236,15227,15034,15024,15076,15131,15642,15104,15145,15143,15148,15209,15132,15136,15106,15332,15025,15126,15026,15101,15057,15140,15146,15045,15202,15102,15234,15235,15221,15071,15035,15215,15237,15090,15144,15108,15110,15112,15056,15007,15223,15238,15122,15123,15084,15082,15135,15047,15065,15014,15049,15216,15088,15229,15210,15218,15116,15282,15217,15214,15212,15213,15211,15219,15290,15232,15260,15233,15204,15207,15206,15201,15203,15208,15222,15226,15224,15017]},
            {name: 'Armstrong', value: false, zipCode: [16242,16226,16222,16228,16262,16229,15774,15736,15690,16249,16201,15673,15613,16238,16259,15732,16049,15656]},
            {name: 'Beaver', value: false, zipCode: [15042,15001,15010,15005,15081,15050,15059,16117,15009,15052,15003,15066,15074,15061,16141,15027,16136,16115,16157,15043,15077]},
            {name: 'Bedford', value: false, zipCode: [15545,15550,16695,16678,15559,16650,15537,16667,15554,15522,16633,16679]},
            {name: 'Berks', value: false, zipCode: [19536,19533,19508,19530,19607,19540,19522,19606,19610,19559,19543,19562,19605,19601,19604,19602,19611,19510,17087,19567,19518,17569,19501,19511,19539,19551,19512,19503,19516,19541,19506,19560,19550,19538,19608,19529,19609,19555,19564,19544,17067,19507,19505,19526,19554,19534,19565,19545]},
            {name: 'Blair', value: false, zipCode: [16601,16693,16648,16662,16686,16625,16682,15946,16635,16602,16617,16636,16673,16665,16631,16637,16684]},
            {name: 'Bradford', value: false, zipCode: [18837,18848,18840,18810,16947,17724,16910,18829,18833]},
            {name: 'Bucks', value: false, zipCode: [18966,19053,19047,18077,18954,18917,18938,19030,19054,19055,19007,19067,19056,19057,18974,18951,18962,18944,18935,18914,18960,18902,18947,18969,18970,18955,19020,18940,19021]},
            {name: 'Butler', value: false, zipCode: [16046,16057,16024,16033,16041,16052,16373,16049,16051,16061,16038,16025,16022,16050,16001,16056,16063,16037,16045,16002,16030,16053,16029,16059]},
            {name: 'Cambria', value: false, zipCode: [15925,15930,15963,16641,16619,15905,15962,15958,15904,15909,15946,15955,15902,15906,15931,15901,15714,15775,16630,16613,15722,15921,15942,16646,15938,15943,15927,15961,15956,15952,16624,15940,15951,15948]},
            {name: 'Cameron', value: false, zipCode: [15832,15834]},
            {name: 'Carbon', value: false, zipCode: [18210,18255,18232,18030,18071,18229,18235,18250,18244,18216,18661,18240,18254]},
            {name: 'Centre', value: false, zipCode: [16841,16844,16801,16870,16852,16822,16823,16832,16677,16872,16826,16865,16866,16820,16802,16803,16882,16853,16856,16854,16868,16874,16829,16828,16859,16864,16851,16827,16875,16860,16845]},
            {name: 'Chester', value: false, zipCode: [19475,19333,19301,19312,19382,19335,19465,19320,19348,19317,19375,19520,19330,19380,19383,19425,19087,19365,19369,19341,19310,19372,19311,19352,19460,19442,19390,19362,19355,19343,19358,19319,19374,19367,19363]},
            {name: 'Clarion', value: false, zipCode: [16233,16214,16232,16049,16213,16255,16361,16258,16260,16028,16248,16242,16054,16254,16036,16230]},
            {name: 'Clearfield', value: false, zipCode: [16830,16847,16858,16833,15757,16840,16866,16666,16839,16651,16627,16645,15801,16656,16821,15866,15721,16838,16620,16843,16878,16671,16876,16692]},
            {name: 'Clinton', value: false, zipCode: [17779,17745,17747,17751,17764,17740,17748,17721,17760]},
            {name: 'Columbia', value: false, zipCode: [17945,17846,17820,17814,17888,17859,17815,18603,17920,17878,18631]},
            {name: 'Crawford', value: false, zipCode: [16438,16404,16424,16134,16316,16327,16433,16335,16422,16131,16434,16435,16110,16360,16406,16354,16328,16314,16440,16111,16403]},
            {name: 'Cumberland', value: false, zipCode: [17013,17043,17011,17025,17093,17257,17055,17027,17065,17240,17241,17050,17070,17007]},
            {name: 'Dauphin', value: false, zipCode: [17097,17113,17109,17112,17061,17103,17111,17033,17036,17057,17098,17048,17030,17005,17101,17102,17104,17120,17110,17018,17023,17034,17080]},
            {name: 'Delaware', value: false, zipCode: [19087,19085,19013,19050,19070,19032,19023,19033,19081,19061,19026,19014,19015,19063,19086,19082,19078,19074,19079,19342,19017,19008,19036,19094,19022,19041,19003,19076,19018]},
            {name: 'Elk', value: false, zipCode: [15857,15845,16734,15853,15868,15841,15846,15870,15827]},
            {name: 'Erie', value: false, zipCode: [16407,16427,16441,16510,16563,16438,16511,16417,16410,16442,16423,16401,16546,16508,16507,16502,16504,16503,16550,16501,16415,16412,16444]},
            {name: 'Fayette', value: false, zipCode: [15428,15480,15437,15474,15631,15425,15470,15464,15417,15413,15459,15438,15401,15445,15456,15476,15461,15473,15442,15012,15475,15478,15486,15472,15436,15610,15666,15444,15482,15466,15458]},
            {name: 'Forest', value: false, zipCode: [16239]},
            {name: 'Franklin', value: false, zipCode: [17235,17202,17201,17250,17268,17237,17222,17236,17244,17224,17225,17263,17254,17214]},
            {name: 'Fulton', value: false, zipCode: [15533,17238,17233]},
            {name: 'Greene', value: false, zipCode: [15351,15338,15370,15346,15352,15364,15359,15315,15316,15320,15349,15357,15344,15325,15322,15380,15337]},
            {name: 'Huntingdon', value: false, zipCode: [17066,17253,17249,17243,17052,16652,17060,16657,17264,16678,16621,16634,17255,16669,17260,16623,16611]},
            {name: 'Indiana', value: false, zipCode: [15742,15748,15727,15752,15756,16256,15701,15745,15705,15681,15728,15754,15767,15772,15734,15759,15717,15732,15731,15747,16246,15739,15729,15714,15710,15949,15774]},
            {name: 'Jefferson', value: false, zipCode: [15767,15778,15770,16240,15864,15840,15823,15824,15851,15715,15825,15784,15829,15865]},
            {name: 'Juniata', value: false, zipCode: [17056,17059,17049,17094,17086,17058,17035,17021,17082]},
            {name: 'Lackawanna', value: false, zipCode: [18504,18509,18508,18510,18503,18505,18507,18433,18444,18436,18424,18411,18512,18517,18518,18519,18407,18403,18447,18414,18452,18421,18641,18434]},
            {name: 'Lancaster', value: false, zipCode: [17552,17505,17602,17601,17540,17578,17522,17512,17582,17536,17554,17520,17543,17508,17576,17527,17535,17555,17507,19540,17517,17572,17529,17519,17538,17557,17581,17570,17022,17569,17563,17502,17509,17562,17566,17551,17603,17545,17534,17550,17547,17584,17501,17560,17568,17506]},
            {name: 'Lawrence', value: false, zipCode: [16101,16112,16117,16156,16120,16157,16102,16105,16142,16172,16160,16141,16143,16140]},
            {name: 'Lebanon', value: false, zipCode: [17064,17073,17010,17078,17038,17042,17088,17026,17046,17016,17087,17003,17028,17067]},
            {name: 'Lehigh', value: false, zipCode: [18104,18103,18106,18087,18031,18062,18037,18059,18052,18034,18066,18032,18049,18078,18080,18079,18011,18036,18017,18015,18018,18101,18102,18109]},
            {name: 'Luzerne', value: false, zipCode: [18643,18202,18640,18617,18661,18618,18612,18704,18642,18702,18701,18705,18621,18225,18234,18644,18249,18256,17878,18706,18655,18641,18603,18634,18708,18635,18660,18201,18602,18651,18222,18707,18709,18224,18219]},
            {name: 'Lycoming', value: false, zipCode: [17740,17701,17754,17756,17702,17762,17737,17752]},
            {name: 'McKean', value: false, zipCode: [16701,16738,16740,16735,16749,16731,16744,16743]},
            {name: 'Mercer', value: false, zipCode: [16146,16134,16130,16151,16125,16124,16153,16137,16161,16159,16154,16148,16113,16150,16127,16121,16133,16145]},
            {name: 'Mifflin', value: false, zipCode: [17084,17063,17044,17066,17054,17051,17004,17841,17009,17075,17002,17099]},
            {name: 'Monroe', value: false, zipCode: [18330,18610,18353,18301,18210,18327,18360,18322,18350,18326,18334,18344]},
            {name: 'Montgomery', value: false, zipCode: [19462,19046,19038,19460,19403,18073,19090,19096,19003,19066,19041,19010,19438,19446,19422,19075,19525,19426,19072,18936,19454,19473,19401,19004,19428,19031,18041,19027,19044,19002,19095,19009,19006,19464,18054,18076,19406,19405,19034,19012,18964,19477,19085,19468,19040,19453,19001]},
            {name: 'Montour', value: false, zipCode: [17821,17884]},
            {name: 'Northampton', value: false, zipCode: [18042,18055,18088,18035,18067,18017,18064,18045,18013,18032,18020,18083,18040,18091,18014,18063,18072,18085,18343,18351]},
            {name: 'Northumberland', value: false, zipCode: [17834,17847,17801,17772,17866,17857,17749,17777,17730,17872,17017,17851,17824,17832,17830,17881,17850,17868,17821]},
            {name: 'Perry', value: false, zipCode: [17053,17040,17006,17062,17020,17069]},
            {name: 'Philadelphia', value: false, zipCode: [19154,19151,19150,19153,19152,19102,19103,19106,19107,19104,19108,19109,19128,19129,19120,19121,19122,19123,19124,19125,19126,19127,19112,19111,19115,19114,19116,19119,19118,19148,19149,19146,19147,19144,19145,19142,19143,19140,19141,19139,19138,19137,19136,19135,19134,19133,19132,19131,19130]},
            {name: 'Pike', value: false, zipCode: [18337,18324,18435,18328,18336,18428]},
            {name: 'Potter', value: false, zipCode: [16748,16915,16922,16720]},
            {name: 'Schuylkill', value: false, zipCode: [18252,17901,17953,17951,17960,17957,17980,17967,17961,17931,17936,17972,17929,17976,17943,17921,17948,17922,17981,17982,17959,17954,19549,17930,18218,17965,17941,17935,17963,18214,18248,18241,17985,18237,17923,17979,17933,18250,17970,18231,17949,17934,17974]},
            {name: 'Snyder', value: false, zipCode: [17853,17812,17813,17870,17842,17862,17876,17861,17864,17827,17841]},
            {name: 'Somerset', value: false, zipCode: [15541,15936,15531,15563,15547,15935,15928,15963,15558,15937,15424,15542,15501,15926,15557,15564,15545,15552,15622,15924,15530,15560]},
            {name: 'Sullivan', value: false, zipCode: [18616,17731,18614]},
            {name: 'Susquehanna', value: false, zipCode: [18847,18801,18830,18421,18818,18824,18822,18465,18470]},
            {name: 'Tioga', value: false, zipCode: [16911,16912,16933,16950,16930,16901,16936,16920,16928,16929]},
            {name: 'Union', value: false, zipCode: [17829,17845,17810,17856,17844,17835,17886,17837,17855]},
            {name: 'Venango', value: false, zipCode: [16314,16323,16362,16341,16374,16372,16038,16301,16343,16317,16346,16373,16342,16344,16127]},
            {name: 'Warren', value: false, zipCode: [16402,16313,16365,16351,16350,16371,16345]},
            {name: 'Washington', value: false, zipCode: [15021,15317,15057,15321,15033,15067,15313,15333,15022,15366,15361,15417,15342,15477,15331,15423,15367,15004,15348,15314,15301,15350,15332,15312,15340,15378,15323,15379,15330,15053,15019,15078,15055,15368,15358,15429,15345,15063,15434,15038,15360,15324,15419,15432,15365,15483,15012,15347,15376,15054]},
            {name: 'Wayne', value: false, zipCode: [18431,18473,18428,18462,18436,18424,18445,18472,18456]},
            {name: 'Westmoreland', value: false, zipCode: [15675,15618,15479,15637,15656,15083,15620,15601,15068,15085,15632,15674,15666,15012,15639,15636,15642,15650,15683,15668,15626,15644,15638,15696,15628,15944,15672,15072,15954,15617,15697,15089,15695,15679,15663,15661,15655,15684,15689,15690,15923,15698,15624,15670,15087,15634,15062,15611,15629,15665,15627]},
            {name: 'Wyoming', value: false, zipCode: [18657,18615,18419,18623,18625,18446]},
            {name: 'York', value: false, zipCode: [17406,17302,17314,17365,17313,17311,17362,17402,17347,17403,17368,17317,17019,17323,17331,17319,17370,17408,17404,17366,17360,17342,17322,17315,17361,17363,17318,17356,17327,17339,17349,17070,17321,17371,17407,17401,17345,17355]},
        ]
    },
    {
        id: 40,
        name: 'Rhode Island',
        stateId: 'RI',
        child: [
            {name: 'Kent', value: false, zipCode: ['02818','02886','02889','02888',2827]},
            {name: 'Newport', value: false, zipCode: ['02842','02871','02841','02840',2842]},
            {name: 'Providence', value: false, zipCode: ['02915','02914','02916','02815','02825',2895,'02861','02860','02903','02906','02907','02908','02904','02905','02909','02912','02918','02831','02920','02910','02823','02921',2864,2814,2830,2825,2859,2863,'02814','02829','02917','02828']},
            {name: 'Washington', value: false, zipCode: [2891,2882,'02891','02808',2813,2832,2804,2898,'02812','02813','02879','02881']},
        ]
    },
    {
        id: 41,
        name: 'Rhode Island',
        stateId: 'RI',
        child: [
            {name: 'Kent', value: false, zipCode: ['02818','02886','02889','02888',2827]},
            {name: 'Newport', value: false, zipCode: ['02842','02871','02841','02840',2842]},
            {name: 'Providence', value: false, zipCode: ['02915','02914','02916','02815','02825',2895,'02861','02860','02903','02906','02907','02908','02904','02905','02909','02912','02918','02831','02920','02910','02823','02921',2864,2814,2830,2825,2859,2863,'02814','02829','02917','02828']},
            {name: 'Washington', value: false, zipCode: [2891,2882,'02891','02808',2813,2832,2804,2898,'02812','02813','02879','02881']},
        ]
    },
    {
        id: 42,
        name: 'South Dakota',
        stateId: 'SD',
        child: [
            {name: 'Aurora', value: false, zipCode: [57375,57383,57368,57359]},
            {name: 'Beadle', value: false, zipCode: [57324,57350,57381,57379,57384,57322,57386,57348]},
            {name: 'Bennett', value: false, zipCode: [57714,57551]},
            {name: 'Bon Homme', value: false, zipCode: [57063,57062,57059,57066,57315]},
            {name: 'Brookings', value: false, zipCode: [57002,57220,57026,57276,57071,57007,57006,57061]},
            {name: 'Brown', value: false, zipCode: [57441,57427,57474,57401,57481,57445,57479,57434,57433,57439,57432,57446,57460]},
            {name: 'Brule', value: false, zipCode: [57355,57325,57370]},
            {name: 'Buffalo', value: false, zipCode: [57339,57341]},
            {name: 'Butte', value: false, zipCode: [57760,57762,57788,57717]},
            {name: 'Campbell', value: false, zipCode: [57646,57648,57437,57632]},
            {name: 'Charles Mix', value: false, zipCode: [57361,57356,57369,57329,57367,57342,57380]},
            {name: 'Clark', value: false, zipCode: [57217,57278,57236,57271,57225,57258]},
            {name: 'Clay', value: false, zipCode: [57069,57010,57073]},
            {name: 'Codington', value: false, zipCode: [57245,57238,57235,57272,57201,57243,57263]},
            {name: 'Corson', value: false, zipCode: [57642,57645,57658,57639,57641,57621]},
            {name: 'Custer', value: false, zipCode: [57773,57722,57730,57738,57744]},
            {name: 'Davison', value: false, zipCode: [57301,57363,57334]},
            {name: 'Day', value: false, zipCode: [57239,57274,57273,57468,57422,57219,57261]},
            {name: 'Deuel', value: false, zipCode: [57226,57238,57218,57237,57268,57213]},
            {name: 'Dewey', value: false, zipCode: [57633,57652,57656,57625,57636,57661,57442]},
            {name: 'Douglas', value: false, zipCode: [57330,57328,57344,57364,57313]},
            {name: 'Edmunds', value: false, zipCode: [57451,57448,57428,57471]},
            {name: 'Fall River', value: false, zipCode: [57735,57747,57766,57782,57763]},
            {name: 'Faulk', value: false, zipCode: [57438,57435,57473,57466,57465,57470,57467]},
            {name: 'Grant', value: false, zipCode: [57252,57265,57216,57259,57251,57269,57264]},
            {name: 'Gregory', value: false, zipCode: [57523,57533,57335,57538,57571,57317,57529]},
            {name: 'Haakon', value: false, zipCode: [57567,57552]},
            {name: 'Hamlin', value: false, zipCode: [57248,57234,57212,57241,57242,57221,57223]},
            {name: 'Hand', value: false, zipCode: [57373,57371,57362]},
            {name: 'Hanson', value: false, zipCode: [57311,57301,57332,57340]},
            {name: 'Harding', value: false, zipCode: [57720,57724]},
            {name: 'Hughes', value: false, zipCode: [57501,57536,57522]},
            {name: 'Hutchinson', value: false, zipCode: [57354,57334,57052,57331,57366,57376,57029,57045]},
            {name: 'Hyde', value: false, zipCode: [57345,57346]},
            {name: 'Jackson', value: false, zipCode: [57750,57775,57567,57577,57543,57521]},
            {name: 'Jerauld', value: false, zipCode: [57312,57382,57358]},
            {name: 'Jones', value: false, zipCode: [57562,57531,57559]},
            {name: 'Kingsbury', value: false, zipCode: [57249,57353,57051,57212,57233,57214,57231]},
            {name: 'Lake', value: false, zipCode: [57016,57050,57075,57042,57054,57076]},
            {name: 'Lawrence', value: false, zipCode: [57754,57793,57783,57799,57732,57779,57785]},
            {name: 'Lincoln', value: false, zipCode: [57108,57032,57077,57039,57034,57027,57106,57064,57013]},
            {name: 'Lyman', value: false, zipCode: [57548,57568,57569,57365,57576,57544]},
            {name: 'Marshall', value: false, zipCode: [57247,57454,57270,57232,57430]},
            {name: 'McCook', value: false, zipCode: [57012,57058,57374,57319,57048]},
            {name: 'McPherson', value: false, zipCode: [57481,57437,57456,57457]},
            {name: 'Meade', value: false, zipCode: [57785,57718,57769,57626]},
            {name: 'Mellette', value: false, zipCode: [57560,57579,57521,57585]},
            {name: 'Miner', value: false, zipCode: [57323,57349,57076,57321,57337]},
            {name: 'Minnehaha', value: false, zipCode: [57041,57005,57003,57110,57068,57035,57104,57030,57022,57055,57108,57105,57106,57107,57103,57197,57117,57033,57020,57018]},
            {name: 'Moody', value: false, zipCode: [57017,57026,57065,57028,57024]},
            {name: 'Oglala Lakota', value: false, zipCode: [57764,57770,57752,57756,57794,57716,57772]},
            {name: 'Pennington', value: false, zipCode: [57701,57719,57703,57702,57791,57790,57751,57706,57775,57745,57761]},
            {name: 'Perkins', value: false, zipCode: [57638,57649,57620]},
            {name: 'Potter', value: false, zipCode: [57475,57450,57455,57442]},
            {name: 'Roberts', value: false, zipCode: [57266,57262,57260,57279,57257,57224,57255,57273,57256,57227]},
            {name: 'Sanborn', value: false, zipCode: [57359,57385,57314]},
            {name: 'Spink', value: false, zipCode: [57461,57436,57476,57477,57469,57440,57434,57424,57258,57429,57465]},
            {name: 'Stanley', value: false, zipCode: [57532]},
            {name: 'Sully', value: false, zipCode: [57501,57520,57564]},
            {name: 'Todd', value: false, zipCode: [57570,57555,57572,57566,69201,57580,57563]},
            {name: 'Tripp', value: false, zipCode: [57580,57541,57528]},
            {name: 'Turner', value: false, zipCode: [57036,57015,57047,57070,57053,57043,57319,57014,57021,57037]},
            {name: 'Union', value: false, zipCode: [57004,57049,57025,57001,57038]},
            {name: 'Walworth', value: false, zipCode: [57420,57472,57452,57631,57601]},
            {name: 'Yankton', value: false, zipCode: [57078,57031,57046,57072,57040,57067]},
            {name: 'Ziebach', value: false, zipCode: [57625,57748,57622,57623]},
        ]
    },
    {
        id: 43,
        name: 'Tennessee',
        stateId: 'TN',
        child: [
            {name: 'Anderson', value: false, zipCode: [37840,37830,37716,37769,37714,37710,37705,37828]},
            {name: 'Bedford', value: false, zipCode: [37160,37180,37020,37183,37360]},
            {name: 'Benton', value: false, zipCode: [38221,38320,38341,38333]},
            {name: 'Bledsoe', value: false, zipCode: [37367]},
            {name: 'Blount', value: false, zipCode: [37777,37701,37804,37737,37886,37882,37803,37801,37853]},
            {name: 'Bradley', value: false, zipCode: [37323,37310,37312,37353,37311]},
            {name: 'Campbell', value: false, zipCode: [37766,37714,37847,37757,37762]},
            {name: 'Cannon', value: false, zipCode: [37016,37190]},
            {name: 'Carroll', value: false, zipCode: [38342,38258,38344,38348,38235,38201,38390,38220,38317]},
            {name: 'Carter', value: false, zipCode: [37687,37643,37601,37694,37658,37682]},
            {name: 'Cheatham', value: false, zipCode: [37143,37082,37015,37146]},
            {name: 'Chester', value: false, zipCode: [38332,38359,38340]},
            {name: 'Claiborne', value: false, zipCode: [37752,37879,37825,37724]},
            {name: 'Clay', value: false, zipCode: [38551]},
            {name: 'Cocke', value: false, zipCode: [37821,37722,37843]},
            {name: "Coffee", value: false, zipCode: [37355,37342,37026,37018,37388]},
            {name: "Crockett", value: false, zipCode: [38001,38337,38343,38006,38050,38034]},
            {name: 'Cumberland', value: false, zipCode: [37723,38571,38555,38572,38558,38574,38578,38583]},
            {name: "Davidson", value: false, zipCode: [37204,37220,37215,37205,37211,37027,37138,37076,37072,37203,37206,37201,37080,37218,37219,37216,37217,37214,37212,37213,37210,37189,37232,37238,37209,37208,37207,37115,37013,37228,37221,37143]},
            {name: 'Decatur', value: false, zipCode: [38363,38329]},
            {name: 'DeKalb', value: false, zipCode: [37059,37095,37012,37166]},
            {name: 'Dickson', value: false, zipCode: [37036,37181,37165,37051,37187,37055,37029]},
            {name: 'Dyer', value: false, zipCode: [38024,38080,38007,38047,38059,38259,38030]},
            {name: 'Fayette', value: false, zipCode: [38060,38028,38039,38057,38046,38066,38017,38068,38036,38002,38049,38076]},
            {name: 'Fentress', value: false, zipCode: [38556,38565,38553]},
            {name: 'Franklin', value: false, zipCode: [37318,37330,37376,37375,37383,37345,37398,37324,37306]},
            {name: 'Gibson', value: false, zipCode: [38059,38330,38389,38233,38343,38382,38338,38316,38369,38355,38358]},
            {name: 'Giles', value: false, zipCode: [38473,38460,38477,38449,38455,38478,38459,38472]},
            {name: 'Grainger', value: false, zipCode: [37709,37708,37811,37861]},
            {name: 'Greene', value: false, zipCode: [37745,37743,37711,37818,37616]},
            {name: 'Grundy', value: false, zipCode: [37387,37366,37339,37375,37356,37313,37305,37301,37365]},
            {name: 'Hamblen', value: false, zipCode: [37813,37814,37860,37891]},
            {name: 'Hamilton', value: false, zipCode: [37343,37412,37416,37341,37377,37415,37405,37411,37379,37302,37363,37315,37350,37373,37410,37419,37409,37421,37408,37403,37402,37407,37406,37404,37450,37351]},
            {name: 'Hancock', value: false, zipCode: [37869]},
            {name: 'Hardeman', value: false, zipCode: [38008,38381,38340,38061,38052,38039,38042,38075,38067,38044]},
            {name: 'Hardin', value: false, zipCode: [38326,38372,38376,38370,38371,38357,38361,38310,38327]},
            {name: 'Hawkins', value: false, zipCode: [37711,37642,37645,37857,37873,37811]},
            {name: 'Haywood', value: false, zipCode: [38069,38012]},
            {name: 'Henderson', value: false, zipCode: [38388,38351,38390,38328,38371,38345,38374,38313]},
            {name: 'Henry', value: false, zipCode: [38251,38222,38242,38224,38231]},
            {name: 'Hickman', value: false, zipCode: [37098,37033]},
            {name: 'Houston', value: false, zipCode: [37061,37178]},
            {name: 'Humphreys', value: false, zipCode: [37134,37185,37101]},
            {name: 'Jackson', value: false, zipCode: [38562,38501]},
            {name: 'Jefferson', value: false, zipCode: [37760,37890,37820,37871,37725]},
            {name: 'Johnson', value: false, zipCode: [37640,37683]},
            {name: 'Knox', value: false, zipCode: [37924,37934,37849,37918,37938,37931,37806,37909,37902,37920,37921,37922,37923,37929,37919,37915,37914,37917,37916,37912]},
            {name: 'Lake', value: false, zipCode: [38079,38080,38077]},
            {name: 'Lauderdale', value: false, zipCode: [38037,38040,38063,38041]},
            {name: 'Lawrence', value: false, zipCode: [38469,38483,38468,38463,38481,38456,38464,38486]},
            {name: 'Lewis', value: false, zipCode: [38462]},
            {name: 'Lincoln', value: false, zipCode: [38488,37334,38453,37335,37144]},
            {name: 'Loudon', value: false, zipCode: [37774,37772,37771,37742,37846]},
            {name: 'Macon', value: false, zipCode: [37083,37150]},
            {name: 'Madison', value: false, zipCode: [38356,38366,38313,38305,38301,38392,38343]},
            {name: 'Marion', value: false, zipCode: [37374,37347,37419,37396,37380,37397]},
            {name: 'Marshall', value: false, zipCode: [37034,37047,37091]},
            {name: 'Maury', value: false, zipCode: [37174,37179,38401,38474]},
            {name: 'McMinn', value: false, zipCode: [37309,37331,37329,37826,37303,37370]},
            {name: 'McNairy', value: false, zipCode: [38375,38357,38379,38367,38339,38310,38334,38315]},
            {name: 'Meigs', value: false, zipCode: [37322]},
            {name: 'Monroe', value: false, zipCode: [37885,37801,37385,37874,37354]},
            {name: 'Montgomery', value: false, zipCode: [42223,37043,37042,37040]},
            {name: 'Moore', value: false, zipCode: [37334,37359,37352,37388]},
            {name: 'Morgan', value: false, zipCode: [37748,37829,37719,37840,37872,37845,37887]},
            {name: 'Obion', value: false, zipCode: [38260,38261,38240,38257,38232,38253]},
            {name: 'Overton', value: false, zipCode: [38568,38570]},
            {name: 'Perry', value: false, zipCode: [37096,37097]},
            {name: 'Pickett', value: false, zipCode: [38549]},
            {name: 'Polk', value: false, zipCode: [37362,37316,37317,37326,37325,37361,37307,37333]},
            {name: 'Putnam', value: false, zipCode: [38506,38503,38501,38505,38544,38574]},
            {name: 'Rhea', value: false, zipCode: [37338,37321,37381]},
            {name: 'Roane', value: false, zipCode: [37763,37748,37854]},
            {name: 'Robertson', value: false, zipCode: [37172,37188,37072,37073,37049,37148,37141,37032,37010,37146]},
            {name: 'Rutherford', value: false, zipCode: [37153,37130,37085,37129,37037,37128,37167,37060,37132,37127,37086]},
            {name: 'Scott', value: false, zipCode: [37852,37892,37755,37732,37872,37841,37756]},
            {name: 'Sequatchie', value: false, zipCode: [37327,37377]},
            {name: 'Sevier', value: false, zipCode: [37865,37862,37876,37764,37738,37863]},
            {name: 'Shelby', value: false, zipCode: [38053,38054,38120,38127,38126,38107,38106,38105,38104,38103,38109,38108,38152,38132,38141,38112,38114,38111,38113,38115,38116,38117,38118,38119,38018,38016,38133,38131,38134,38128,38122,38125,38017,38139,38138,38002,38028,38135,38029]},
            {name: 'Smith', value: false, zipCode: [38567,37030,38563]},
            {name: 'Stewart', value: false, zipCode: [37050,37058,37023]},
            {name: 'Sullivan', value: false, zipCode: [37620,37660,37663,37664,37665,37617,37618,37686]},
            {name: 'Sumner', value: false, zipCode: [37066,37148,37075,37031,37186,37022,37048,37072]},
            {name: 'Tipton', value: false, zipCode: [38049,38019,38058,38023,38015,38011,38004]},
            {name: 'Trousdale', value: false, zipCode: [37022,37074,37057,37031]},
            {name: 'Unicoi', value: false, zipCode: [37692,37650]},
            {name: 'Union', value: false, zipCode: [37807,37779,37721]},
            {name: 'Van Buren', value: false, zipCode: [38585]},
            {name: 'Warren', value: false, zipCode: [37110,37357,37394]},
            {name: 'Washington', value: false, zipCode: [37615,37614,37601,37604,37690,37650,37659,37663,37656]},
            {name: 'Wayne', value: false, zipCode: [38450,38485,38425]},
            {name: 'Weakley', value: false, zipCode: [38237,38255,38225,38226,38241,38230,38229]},
            {name: 'White', value: false, zipCode: [38583,38559]},
            {name: 'Williamson', value: false, zipCode: [37027,37135,37064,37179,37069,37067,37062]},
            {name: 'Wilson', value: false, zipCode: [37122,37184,37090,37138,37087,37076]},
        ]
    },
    {
        id: 44,
        name: 'Texas',
        stateId: 'TX',
        child: [
            {name: 'Anderson', value: false, zipCode: [75839,75763,75801,75803,75779]},
            {name: 'Andrews', value: false, zipCode: [79714]},
            {name: 'Angelina', value: false, zipCode: [75941,75904,75901,75903,75980,75949]},
            {name: 'Aransas', value: false, zipCode: [78382,78358]},
            {name: 'Archer', value: false, zipCode: [76366,76310,76308,76351,76370,76379,76389]},
            {name: 'Armstrong', value: false, zipCode: [79019]},
            {name: 'Atascosa', value: false, zipCode: [78052,78012,78064,78050,78026,78011,78065]},
            {name: 'Austin', value: false, zipCode: [77474,77418,77485,78950,78944,77473]},
            {name: 'Bailey', value: false, zipCode: [79347]},
            {name: 'Bandera', value: false, zipCode: [78003,78063]},
            {name: 'Bastrop', value: false, zipCode: [78662,78957,78602,78612,78650,78953,78659,78621]},
            {name: 'Baylor', value: false, zipCode: [76380]},
            {name: 'Bee', value: false, zipCode: [78102,78389,78146,78162,78104,78142,78391,78119,78145]},
            {name: 'Bell', value: false, zipCode: [76559,76501,76557,76579,76502,76504,76534,76541,76543,76542,76549,76571,76548,76513,76569,76554]},
            {name: 'Bexar', value: false, zipCode: [78201,78213,78232,78112,78236,78263,78148,78150,78256,78255,78233,78073,78231,78230,78249,78219,78212,78108,78154,78132,78006,78015,78152,78109,78239,78218,78023,78260,78069,78209,78259,78258,78252,78251,78250,78257,78254,78208,78202,78203,78204,78205,78207,78245,78242,78248,78235,78234,78237,78238,78266,78264,78217,78216,78215,78214,78211,78210,78244,78247,78240,78222,78223,78220,78221,78226,78227,78224,78225,78228,78229,78002]},
            {name: "Blanco", value: false, zipCode: [78663,78636,78606]},
            {name: "Borden", value: false, zipCode: [79738]},
            {name: 'Bosque', value: false, zipCode: [76671,76652,76634,76689,76690,76665,76649,76637]},
            {name: "Bowie", value: false, zipCode: [75561,75569,75501,75559,75567,75573,75503,75570]},
            {name: 'Brazoria', value: false, zipCode: [77583,77515,77566,77531,77541,77577,77511,77486,77422,77047,77089,77584,77581,77480,77578,77430,77534]},
            {name: 'Brazos', value: false, zipCode: [77843,77840,77845,77808,77807,77803,77802,77801]},
            {name: 'Brewster', value: false, zipCode: [79842,79852,79830,79832]},
            {name: 'Briscoe', value: false, zipCode: [79257,79255]},
            {name: 'Brooks', value: false, zipCode: [78353,78355]},
            {name: 'Brown', value: false, zipCode: [76801,76857,76432,76802,76890,76823]},
            {name: 'Burleson', value: false, zipCode: [77863,77879,77836,77878]},
            {name: 'Burnet', value: false, zipCode: [78654,78605,78608,78657,78611]},
            {name: 'Caldwell', value: false, zipCode: [78655,78644,78648]},
            {name: 'Calhoun', value: false, zipCode: [77979,77982,77983,77978]},
            {name: 'Callahan', value: false, zipCode: [76443,79504,76469,79510]},
            {name: 'Cameron', value: false, zipCode: [78586,78583,78552,78578,78559,78567,78550,78535,78592,78521,78597,78566,78520,78575,78593,78526,78569]},
            {name: 'Camp', value: false, zipCode: [75686]},
            {name: 'Carson', value: false, zipCode: [79068,79097,79080,79039]},
            {name: 'Cass', value: false, zipCode: [75566,75572,75630,75551,75563,75560,75555,75656,75565,75556]},
            {name: 'Castro', value: false, zipCode: [79063,79027,79043,79085]},
            {name: 'Chambers', value: false, zipCode: [77514,77523,77665,77661,77535,77580]},
            {name: 'Cherokee', value: false, zipCode: [75764,75766,75785,75759,75925,75976,75780,75757]},
            {name: 'Childress', value: false, zipCode: [79201]},
            {name: 'Clay', value: false, zipCode: [76357,76377,76310,76305,76365,76228]},
            {name: 'Cochran', value: false, zipCode: [79379,79314,79346]},
            {name: 'Coke', value: false, zipCode: [76933,76945]},
            {name: 'Coleman', value: false, zipCode: [76884,76878,79538,76834]},
            {name: 'Collin', value: false, zipCode: [75002,75013,75409,75069,75098,75166,75407,75173,75454,75009,75071,75078,75034,75036,75033,75035,75442,75093,75023,75025,75024,75074,75075,75094,75072,75070,75424,75164]},
            {name: 'Collingsworth', value: false, zipCode: [79079,79251,79230,79095]},
            {name: 'Colorado', value: false, zipCode: [78934,78943,77442,78962,77434,77460,77475,77470]},
            {name: 'Comal', value: false, zipCode: [78070,78163,78266,78132,78130,78623,78133]},
            {name: 'Comanche', value: false, zipCode: [76442,76455,76446,76444]},
            {name: 'Concho', value: false, zipCode: [76837,76866]},
            {name: 'Cooke', value: false, zipCode: [76252,76272,76253,76240,76241,76250]},
            {name: 'Coryell', value: false, zipCode: [76528,76525,76561,76596,76597,76598,76599,76522,76544]},
            {name: 'Cottle', value: false, zipCode: [79248]},
            {name: 'Crane', value: false, zipCode: [79731]},
            {name: 'Crockett', value: false, zipCode: [76943]},
            {name: 'Crosby', value: false, zipCode: [79322,79343,79357]},
            {name: 'Culberson', value: false, zipCode: [79855]},
            {name: 'Dallam', value: false, zipCode: [79022,79087]},
            {name: 'Dallas', value: false, zipCode: [75098,75287,75230,75231,75236,75237,75235,75254,75251,75252,75253,75043,75032,75227,75238,75232,75233,75234,75089,75088,75087,75247,75244,75249,75248,75051,75166,75210,75211,75212,75214,75215,75216,75217,75218,75219,75390,75182,75229,75228,75220,75223,75225,75224,75226,75243,75241,75240,75246,75270,75207,75206,75204,75203,75202,75201,75209,75208,75019]},
            {name: 'Dawson', value: false, zipCode: [79331,79377]},
            {name: 'Deaf Smith', value: false, zipCode: [79045]},
            {name: 'Delta', value: false, zipCode: [75469,75432]},
            {name: 'Denton', value: false, zipCode: [76227,76226,75077,75068,76247,76262,75065,75056,75010,76249,76207,76205,76203,76201,76208,76209,76210,75036,75067,75057,76258,75022,75028,76259,76266]},
            {name: 'DeWitt', value: false, zipCode: [78141,77954,78164]},
            {name: 'Dickens', value: false, zipCode: [79229,79370]},
            {name: 'Dimmit', value: false, zipCode: [78830,78827,78834,78836]},
            {name: 'Donley', value: false, zipCode: [79237,79240,79226]},
            {name: 'Duval', value: false, zipCode: [78376,78384,78349,78357,78341]},
            {name: 'Eastland', value: false, zipCode: [76471,76454,76437,76435,76470,76448]},
            {name: 'Ector', value: false, zipCode: [79762,79761,79763,79765,79741,79758,79764]},
            {name: 'Edwards', value: false, zipCode: [78880,78828]},
            {name: 'El Paso', value: false, zipCode: [79928,79906,79908,79938,79916,79918,79927,79835,79849,79901,79902,79903,79904,79905,79907,79930,79932,79935,79934,79936,79915,79912,79911,79922,79920,79924,79925,79836,79821,79853,79838]},
            {name: 'Ellis', value: false, zipCode: [76065,75146,75125,75154,76651,75167,76064,75101,76670,75165,75119,75152]},
            {name: 'Erath', value: false, zipCode: [76433,76401,76402,76446]},
            {name: 'Falls', value: false, zipCode: [76655,76632,76656,76570,76661]},
            {name: 'Fannin', value: false, zipCode: [75479,75438,75418,75496,75413,75452,75476,75449,75446,75439,75492,75490]},
            {name: 'Fayette', value: false, zipCode: [78941,78963,78945,78932,78956,78954,78940]},
            {name: 'Fisher', value: false, zipCode: [79560,79543,79546,79534]},
            {name: 'Floyd', value: false, zipCode: [79235,79241]},
            {name: 'Foard', value: false, zipCode: [79227]},
            {name: 'Fort Bend', value: false, zipCode: [77417,77451,77461,77469,77477,77406,77485,77476,77545,77071,77489,77459,77498,77478,77479,77583,77441,77083,77471,77464,77494,77450]},
            {name: 'Franklin', value: false, zipCode: [75457]},
            {name: 'Freestone', value: false, zipCode: [75859,76693,75848,75840,75860]},
            {name: 'Frio', value: false, zipCode: [78016,78005,78017,78061,78057]},
            {name: 'Gaines', value: false, zipCode: [79360,79342,79359]},
            {name: 'Galveston', value: false, zipCode: [77510,77563,77573,77598,77511,77539,77565,77518,77554,77517,77551,77550,77555,77546,77568,77591,77590,77650,77623,77617]},
            {name: 'Garza', value: false, zipCode: [79356]},
            {name: 'Gillespie', value: false, zipCode: [78631,78624,78671]},
            {name: 'Glasscock', value: false, zipCode: [79739]},
            {name: 'Goliad', value: false, zipCode: [77963]},
            {name: 'Gonzales', value: false, zipCode: [78140,78632,78959,78159,78629]},
            {name: 'Gray', value: false, zipCode: [79065,79057,79002,79054]},
            {name: 'Grayson', value: false, zipCode: [75414,75459,75092,75090,75020,75076,76273,76268,76245,75491,75495,75489,76271,75058,76264,76233]},
            {name: 'Gregg', value: false, zipCode: [75662,75647,75693,75603,75604,75602,75601,75605,75641]},
            {name: 'Grimes', value: false, zipCode: [77363,77830,77868,77861,77831,77873,77876]},
            {name: 'Guadalupe', value: false, zipCode: [78124,78123,78155,78130,78638,78670,78655,78108,78121,78666]},
            {name: 'Hale', value: false, zipCode: [79072,79041,79032,79311,79250]},
            {name: 'Hall', value: false, zipCode: [79233,79245,79261]},
            {name: 'Hamilton', value: false, zipCode: [76457,76531,76436]},
            {name: 'Hansford', value: false, zipCode: [79062,79040,79081]},
            {name: 'Hardeman', value: false, zipCode: [79225,79252]},
            {name: 'Hardin', value: false, zipCode: [77625,77657,77663,77656,77659]},
            {name: 'Harris', value: false, zipCode: [77069,77068,77061,77060,77063,77062,77065,77064,77067,77066,77346,77571,77036,77037,77034,77035,77032,77033,77030,77031,77038,77449,77093,77044,77043,77042,77204,77598,77018,77019,77015,77016,77017,77010,77011,77012,77013,77094,77091,77092,77053,77345,77547,77080,77025,77024,77027,77026,77021,77020,77023,77022,77029,77028,77096,77090,77098,77099,77078,77079,77072,77073,77070,77071,77076,77077,77074,77075,77407,77047,77046,77045,77041,77040,77048,77336,77339,77338,77489,77003,77002,77007,77006,77005,77004,77009,77008,77450,77086,77089,77088,77083,77082,77081,77087,77085,77084,77054,77055,77056,77057,77058,77059,77396,77051,77506,77504]},
            {name: 'Harrison', value: false, zipCode: [75650,75670,75661,75692,75672]},
            {name: 'Hartley', value: false, zipCode: [79018,79044]},
            {name: 'Haskell', value: false, zipCode: [79521,79544,79547,79539,79553,76388]},
            {name: 'Hays', value: false, zipCode: [78737,78610,78676,78620,78666,78640,78619,78656]},
            {name: 'Hemphill', value: false, zipCode: [79014]},
            {name: 'Henderson', value: false, zipCode: [75148,75124,75156,75770,75163,75758,75763,75782,75756,75143,75751,75752,75778]},
            {name: 'Hidalgo', value: false, zipCode: [78542,78576,78537,78574,78570,78516,78572,78596,78589,78560,78549,78539,78541,78538,78599,78543,78595,78573,78557,78579,78562,78563,78577,78558,78504,78503,78501]},
            {name: 'Hill', value: false, zipCode: [76055,76673,76631,76636,76676,76627,76645,76692,76666,76660,76648,76622,76621]},
            {name: 'Hockley', value: false, zipCode: [79336,79358,79372,79380,79313,79407,79367]},
            {name: 'Hood', value: false, zipCode: [76049,76048,76476,76035,76462]},
            {name: 'Hopkins', value: false, zipCode: [75482,75431,75433]},
            {name: 'Houston', value: false, zipCode: [75849,75835,75844,75851,75847]},
            {name: 'Howard', value: false, zipCode: [79720,79721,79733,79511]},
            {name: 'Hudspeth', value: false, zipCode: [79837,79839,79851]},
            {name: 'Hunt', value: false, zipCode: [75401,75402,75422,75474,75453,75189,75423,75428,75496,75135]},
            {name: 'Hutchinson', value: false, zipCode: [79036,79007,79083,79078]},
            {name: 'Irion', value: false, zipCode: [76941]},
            {name: 'Jack', value: false, zipCode: [76486,76427,76458]},
            {name: 'Jackson', value: false, zipCode: [77991,77962,77957,77970,77971]},
            {name: 'Jasper', value: false, zipCode: [75956,77612,75931,77615,75951]},
            {name: 'Jeff Davis', value: false, zipCode: [79734,79854]},
            {name: 'Jefferson', value: false, zipCode: [77655,77640,77642,77627,77713,77705,77613,77619,77622,77665,77708,77706,77707,77701,77702,77703,77651,77629]},
            {name: 'Jim Hogg', value: false, zipCode: [78361]},
            {name: 'Jim Wells', value: false, zipCode: [78332,78372,78383,78375,78342]},
            {name: 'Johnson', value: false, zipCode: [76028,76009,76031,76033,76058,76050,76093,76059,76084,76044]},
            {name: 'Jones', value: false, zipCode: [79525,79501,79533,79520]},
            {name: 'Karnes', value: false, zipCode: [78118,78151,78113,78119]},
            {name: 'Kaufman', value: false, zipCode: [75147,75142,75114,75126,75160,75161,75143,75158,75157,75159]},
            {name: 'Kendall', value: false, zipCode: [78013,78006]},
            {name: 'Kenedy', value: false, zipCode: [78385]},
            {name: 'Kent', value: false, zipCode: [79528,79518]},
            {name: 'Kerr', value: false, zipCode: [78025,78010,78028]},
            {name: 'Kimble', value: false, zipCode: [76849]},
            {name: 'King', value: false, zipCode: [79236]},
            {name: 'Kinney', value: false, zipCode: [78832]},
            {name: 'Kleberg', value: false, zipCode: [78379,78363]},
            {name: 'Knox', value: false, zipCode: [76363,79529,76371,79505]},
            {name: 'La Salle', value: false, zipCode: [78021,78014,78019]},
            {name: 'Lamar', value: false, zipCode: [75477,75462,75473,75435,75416,75460,75421,75470]},
            {name: 'Lamb', value: false, zipCode: [79064,79371,79339,79369,79082,79312,79031]},
            {name: 'Lampasas', value: false, zipCode: [76550,76853,76539,76522]},
            {name: 'Lavaca', value: false, zipCode: [77984,77995,77964,77975]},
            {name: 'Lee', value: false, zipCode: [77853,78942,78947]},
            {name: 'Leon', value: false, zipCode: [75833,75846,75831,77871,75855,77865,75850]},
            {name: 'Liberty', value: false, zipCode: [77533,77535,77575,77564,77561,77351,77369,77327,77328,77538]},
            {name: 'Limestone', value: false, zipCode: [76686,76687,76635,76653,76642,76667]},
            {name: 'Lipscomb', value: false, zipCode: [79005,79024,79034,79056,79046]},
            {name: 'Live Oak', value: false, zipCode: [78071,78022]},
            {name: 'Llano', value: false, zipCode: [78639,78609,78607,78643,78672,78657]},
            {name: 'Loving', value: false, zipCode: [79754]},
            {name: 'Lubbock', value: false, zipCode: [79363,79406,79423,79424,79416,79412,79410,79407,79414,79411,79404,79401,79403,79402,79415,79413,79366,79329,79364,79350,79382]},
            {name: 'Lynn', value: false, zipCode: [79373,79381,79383,79351]},
            {name: 'Madison', value: false, zipCode: [77864,75852]},
            {name: 'Marion', value: false, zipCode: [75657]},
            {name: 'Martin', value: false, zipCode: [79713,79782]},
            {name: 'Mason', value: false, zipCode: [76856]},
            {name: 'Matagorda', value: false, zipCode: [77465,77414,77483,77456,77457,77458,77482,77419]},
            {name: 'Maverick', value: false, zipCode: [78852,78877]},
            {name: 'McCulloch', value: false, zipCode: [76858,76825,76872]},
            {name: 'McLennan', value: false, zipCode: [76682,76654,76691,76655,76706,76633,76712,76705,76557,76643,76664,76624,76657,76711,76638,76640,76524,76630,76798,76710,76708,76701,76707,76704]},
            {name: 'McMullen', value: false, zipCode: [78072]},
            {name: 'Medina', value: false, zipCode: [78009,78016,78886,78861,78850,78039,78059]},
            {name: 'Menard', value: false, zipCode: [76859]},
            {name: 'Midland', value: false, zipCode: [79701,79706,79707,79705,79703]},
            {name: 'Milam', value: false, zipCode: [76577,76518,76556,76519,76520,76567,77857]},
            {name: 'Mills', value: false, zipCode: [76844,76442,76864]},
            {name: 'Mitchell', value: false, zipCode: [79512,79532,79565]},
            {name: 'Montague', value: false, zipCode: [76270,76251,76230,76265,76261,76255]},
            {name: 'Montgomery', value: false, zipCode: [77372,77357,77381,77384,77380,77382,77389,77302,77306,77303,77365,77301,77304,77378,77318,77385,77356,77316,77354,77355,77362,77386]},
            {name: 'Moore', value: false, zipCode: [79029,79013,79086]},
            {name: 'Morris', value: false, zipCode: [75638,75668,75571,75568]},
            {name: 'Motley', value: false, zipCode: [79244,79256]},
            {name: 'Nacogdoches', value: false, zipCode: [75760,75964,75965,75946,75937,75962,75961]},
            {name: 'Navarro', value: false, zipCode: [75110,75109,76641,75144,75153,75102,75155,76639,76626,76681]},
            {name: 'Newton', value: false, zipCode: [75966,75932,77614]},
            {name: 'Nolan', value: false, zipCode: [79506,79556,79545]},
            {name: 'Nueces', value: false, zipCode: [78380,78351,78339,78343,78415,78373,78408,78409,78406,78407,78404,78405,78402,78401,78419,78418,78414,78417,78416,78411,78410,78413,78412,78336,78383]},
            {name: 'Ochiltree', value: false, zipCode: [79033,79070,79093]},
            {name: 'Oldham', value: false, zipCode: [79001,79092,79010,79098]},
            {name: 'Orange', value: false, zipCode: [77662,77630,77632,77611]},
            {name: 'Palo Pinto', value: false, zipCode: [76449,76484,76472,76475,76463,76453,76088,76067]},
            {name: 'Panola', value: false, zipCode: [75633,75643,75631]},
            {name: 'Parker', value: false, zipCode: [76088,76066,76439,76462,76008,76085,76082,76020,76086,76087]},
            {name: 'Parmer', value: false, zipCode: [79009,79325,79035]},
            {name: 'Pecos', value: false, zipCode: [79781,79743,79735,79744,79730]},
            {name: 'Polk', value: false, zipCode: [77335,77360,75939,77351]},
            {name: 'Potter', value: false, zipCode: [79121,79109,79101,79102,79103,79104,79106,79107,79118,79111,79110,79124,79119]},
            {name: 'Presidio', value: false, zipCode: [79845,79846,79843]},
            {name: 'Rains', value: false, zipCode: [75472,75453,75440]},
            {name: 'Randall', value: false, zipCode: [79091,79015,79118,79016]},
            {name: 'Reagan', value: false, zipCode: [76932]},
            {name: 'Real', value: false, zipCode: [78873,78833]},
            {name: 'Red River', value: false, zipCode: [75417,75550,75554,75426,75436]},
            {name: 'Reeves', value: false, zipCode: [79785,79772,79718]},
            {name: 'Refugio', value: false, zipCode: [78340,78393,77990,78377,77950]},
            {name: 'Roberts', value: false, zipCode: [79059]},
            {name: 'Robertson', value: false, zipCode: [77837,77856,76629,77859]},
            {name: 'Rockwall', value: false, zipCode: [75087,75189,75132,75032,75126]},
            {name: 'Runnels', value: false, zipCode: [79566,76861,76875,76821,79567]},
            {name: 'Rusk', value: false, zipCode: [75684,75691,75603,75652,75654,75784,75682,75681]},
            {name: 'Sabine', value: false, zipCode: [75968,75948,75959]},
            {name: 'San Augustine', value: false, zipCode: [75972,75929]},
            {name: 'San Jacinto', value: false, zipCode: [77371,77359,77331,77364]},
            {name: 'San Patricio', value: false, zipCode: [78368,78370,78390,78387,78362,78359,78374,78336,78352]},
            {name: 'San Saba', value: false, zipCode: [76877,76871]},
            {name: 'Schleicher', value: false, zipCode: [76936]},
            {name: 'Scurry', value: false, zipCode: [79517,79526,79549]},
            {name: 'Shackelford', value: false, zipCode: [76430,76464]},
            {name: 'Shelby', value: false, zipCode: [75974,75973,75954,75935,75975]},
            {name: 'Sherman', value: false, zipCode: [79084,79086,73960]},
            {name: 'Smith', value: false, zipCode: [75757,75791,75771,75707,75789,75706,75792,75750,75709,75702,75703,75701,75711,75762]},
            {name: 'Somervell', value: false, zipCode: [76043,76077]},
            {name: 'Starr', value: false, zipCode: [78582,78584,78588,78536,78547,78545,78548,78591]},
            {name: 'Stephens', value: false, zipCode: [76424]},
            {name: 'Sterling', value: false, zipCode: [76951]},
            {name: 'Stonewall', value: false, zipCode: [79502]},
            {name: 'Sutton', value: false, zipCode: [76950]},
            {name: 'Swisher', value: false, zipCode: [79042,79088,79052]},
            {name: 'Tarrant', value: false, zipCode: [76107,76135,76040,75261,76039,76118,76140,76119,76148,76180,76182,76131,76179,76034,76016,76137,76117,76116,76126,76071,76022,76021,76114,76092,76108,76020,76054,76053,76248,76262,76060,76134,76052,76177,76011,76005,76001,76002,76006,76018,76013,76012,76010,76017,76015,76014,76063,76036,76164,76132,76133,76109,76105,76104,76106,76103,76102,76155,76123,76120,76244,76115,76112,76110,76111,76129,76127,76028,76051]},
            {name: 'Taylor', value: false, zipCode: [79602,79563,79603,79536,79562,79561,79508,79530,79607,79605,79699,79601,79606]},
            {name: 'Terrell', value: false, zipCode: [79848]},
            {name: 'Terry', value: false, zipCode: [79378,79316,79345]},
            {name: 'Throckmorton', value: false, zipCode: [76491,76483,76372]},
            {name: 'Titus', value: false, zipCode: [75455,75487,75493]},
            {name: 'Tom Green', value: false, zipCode: [76935,76901,76934,76905,76904,76903,76909,76908]},
            {name: 'Travis', value: false, zipCode: [78645,78725,78724,78738,78747,78610,78640,78616,78732,78748,78652,78734,78641,78653,78746,78749,78741,78742,78745,78744,78717,78712,78719,78617,78739,78735,78736,78730,78731,78733,78705,78704,78701,78703,78702,78758,78759,78752,78753,78750,78751,78756,78757,78754,78723,78722,78721,78727,78726,78729,78728,78669,78660,78621]},
            {name: 'Trinity', value: false, zipCode: [75845,75862]},
            {name: 'Tyler', value: false, zipCode: [75979,75936,75938,77664]},
            {name: 'Upshur', value: false, zipCode: [75683,75647,75755,75645,75644,75604]},
            {name: 'Upton', value: false, zipCode: [79778,79752]},
            {name: 'Uvalde', value: false, zipCode: [78881,78884,78870,78801]},
            {name: 'Val Verde', value: false, zipCode: [78840,78843]},
            {name: 'Van Zandt', value: false, zipCode: [75140,75127,75169,75790,75103,75754,75756,75117,75778]},
            {name: 'Victoria', value: false, zipCode: [77901,77904,77905,77951,77977,77968]},
            {name: 'Walker', value: false, zipCode: [77341,77342,77320,77340,77358,77367]},
            {name: 'Waller', value: false, zipCode: [77446,77445,77484,77423,77466]},
            {name: 'Ward', value: false, zipCode: [79777,79788,79756,79719,79742]},
            {name: 'Washington', value: false, zipCode: [77835,77833]},
            {name: 'Webb', value: false, zipCode: [78045,78043,78046,78040,78041,78044,78369,78371,78344]},
            {name: 'Wharton', value: false, zipCode: [77455,77420,77435,77448,77437,77488]},
            {name: 'Wheeler', value: false, zipCode: [79079,79096,79003,79061]},
            {name: 'Wichita', value: false, zipCode: [76309,76308,76310,76311,76306,76305,76302,76301,76367,76354,76360]},
            {name: 'Wilbarger', value: false, zipCode: [76384,76373,76364]},
            {name: 'Willacy', value: false, zipCode: [78569,78580,78590,78594,78598,78561]},
            {name: 'Williamson', value: false, zipCode: [76527,76578,78626,78674,76537,78641,78633,78628,78642,76574,76530,78634,78681,78664,78665,78717,76511,78615,78613]},
            {name: 'Wilson', value: false, zipCode: [78160,78147,78114,78121]},
            {name: 'Winkler', value: false, zipCode: [79745,79789]},
            {name: 'Wise', value: false, zipCode: [76078,76426,76234,76431,76071,76225,76023,76247,76020,76073]},
            {name: 'Wood', value: false, zipCode: [75765,75773,75497,75410,75494,75783]},
            {name: 'Yoakum', value: false, zipCode: [79355,79323]},
            {name: 'Young', value: false, zipCode: [76450,76374,76460,76372]},
            {name: 'Zapata', value: false, zipCode: [78564,78076,78067,78545]},
            {name: 'Zavala', value: false, zipCode: [78839,78829,78872]},
        ]
    },
    {
        id: 45,
        name: 'Utah',
        stateId: 'UT',
        child: [
            {name: 'Beaver', value: false, zipCode: [84713,84752,84751]},
            {name: 'Box Elder', value: false, zipCode: [84331,84301,84307,84311,84312,84334,84337,84316,84309,84302,84324,84330,84340,84314,84336]},
            {name: 'Cache', value: false, zipCode: [84305,84341,84308,84319,84318,84321,84320,84304,84327,84335,84339,84322,84325,84332,84326,84333,84328,84338]},
            {name: 'Carbon', value: false, zipCode: [84501,84542,84529,84526]},
            {name: 'Daggett', value: false, zipCode: [84046,84023]},
            {name: 'Davis', value: false, zipCode: [84041,84040,84014,84087,84025,84015,84056,84037,84010,84075,84405,84054]},
            {name: 'Duchesne', value: false, zipCode: [84053,84066,84001,84007,84021,84072,84052]},
            {name: 'Emery', value: false, zipCode: [84528,84521,84523,84518,84522,84537,84525,84513,84516]},
            {name: 'Garfield', value: false, zipCode: [84736,84712,84726,84759,84716,84776,84735,84764,84718]},
            {name: 'Grand', value: false, zipCode: [84532,84540]},
            {name: 'Iron', value: false, zipCode: [84721,84720,84761,84742,84772,84714,84760,84719,84756]},
            {name: 'Juab', value: false, zipCode: [84645,84628,84639,84648]},
            {name: 'Kane', value: false, zipCode: [84710,84741,84729,84758,84755]},
            {name: 'Millard', value: false, zipCode: [84656,84624,84638,84644,84649,84635,84640,84636,84637,84631]},
            {name: 'Morgan', value: false, zipCode: [84050]},
            {name: 'Piute', value: false, zipCode: [84750,84743,84723,84740]},
            {name: 'Rich', value: false, zipCode: [84038,84086,84064,84028]},
            {name: 'Salt Lake', value: false, zipCode: [84108,84092,84093,84094,84070,84065,84117,84124,84109,84106,84107,84121,84047,84119,84120,84128,84118,84020,84096,84115,84044,84006,84116,84114,84113,84112,84111,84150,84180,84101,84102,84103,84104,84105,84138,84095,84009,84088,84081,84084,84123,84129]},
            {name: 'San Juan', value: false, zipCode: [86044,84534,84512,84535,84530,84532,84511,84536,84531]},
            {name: 'Sanpete', value: false, zipCode: [84627,84667,84629,84643,84632,84647,84646,84665,84634,84630,84622,84662,84642]},
            {name: 'Sevier', value: false, zipCode: [84657,84754,84711,84739,84701,84724,84652,84654,84620,84730,84744]},
            {name: 'Summit', value: false, zipCode: [84017,84036,84098,84060,84024,84055,84061,84033]},
            {name: 'Tooele', value: false, zipCode: [84083,84074,84029,84022,84069,84071,84080]},
            {name: 'Uintah', value: false, zipCode: [84078,84035,84063,84085,84026,84039,84066]},
            {name: 'Utah', value: false, zipCode: [84062,84651,84664,84660,84606,84604,84602,84601,84626,84004,84003,84013,84663,84042,84653,84005,84043,84097,84057,84058,84059,84045,84633,84655]},
            {name: 'Wasatch', value: false, zipCode: [84032,84082,84049,84036]},
            {name: 'Washington', value: false, zipCode: [84774,84745,84779,84737,84782,84757,84770,84765,84725,84790,84780,84763,84746,84720,84781,84783,84767,84784,84738,84722]},
            {name: 'Wayne', value: false, zipCode: [84715,84773,84747,84775,84734,84749]},
            {name: 'Weber', value: false, zipCode: [84414,84067,84404,84315,84405,84317,84310,84401,84403,84408]},
        ]
    },
    {
        id: 46,
        name: 'Vermont',
        stateId: 'VT',
        child: [
            {name: 'Addison', value: false, zipCode: ['5443','5753','5491','5472']},
            {name: 'Bennington', value: false, zipCode: ['5352','5262','5253','5261','05260','05261','5201','5257','5255','05155','05340']},
            {name: 'Caledonia', value: false, zipCode: ['5851','5866','05851','05850','5832','5871','5862','5867']},
            {name: 'Chittenden', value: false, zipCode: ['5462','5477','5489','5445','5452','05403','05408','5404','5495','5494','05465','05489','05405','05401','5676']},
            {name: 'Essex', value: false, zipCode: ['5906','05906','05904','5902','5846']},
            {name: 'Franklin', value: false, zipCode: ['5459','5478','5488','05448','05441','5450']},
            {name: 'Grand Isle', value: false, zipCode:['5486']},
            {name: 'Lamoille', value: false, zipCode: ['5661','5680','5464','05655','05656']},
            {name: 'Orange', value: false, zipCode:['5081']},
            {name: 'Orleans', value: false, zipCode: ['5857','5830','5842','5859','5860','5855','5829']},
            {name: 'Rutland', value: false, zipCode: ['05701','05759','5735','5764','5765','5739','5775','5701','5737','5761','5757','5751']},
            {name: 'Washington', value: false, zipCode: ['5641','05641','05670','05654','5654','05649','5677','5602','05678']},
            {name: 'Windham', value: false, zipCode: ['05101','05154','5342','5155','5353','5355','5301','5343','5148','5146','5101','5363','05158','5361']},
            {name: 'Windsor', value: false, zipCode: ['5151','5068','5059','5001','05153','05142','05030','05156','05089','05001','05009','05150','5071','5091','05048','05052','05088','5161']},
        ]
    },
    {
        id: 47,
        name: 'Virginia',
        stateId: 'VA',
        child: [
            {name: 'Accomack', value: false, zipCode: [23415,23337,23420,23421,23418,23417,23440,23427,23442,23416,23357,23422,23401,23410,23356,23336,23407,23359,23308,23480,23426,23303,23301,23441,23423,23389,23395,23414]},
            {name: 'Albemarle', value: false, zipCode: [22903,22904,22959,22947,22901,22911,22932,22940,22936,24590,22937]},
            {name: 'Alexandria', value: false, zipCode: [22302,22301,22304,22305,22311,22314]},
            {name: 'Alleghany', value: false, zipCode: [24474,24457,24422,24426,24448]},
            {name: 'Amelia', value: false, zipCode: [23002]},
            {name: 'Amherst', value: false, zipCode: [24521,24572]},
            {name: 'Appomattox', value: false, zipCode: [24522,23958]},
            {name: 'Arlington', value: false, zipCode: [22209,22203,22202,22201,22207,22206,22205,22204,22214,22211,22213]},
            {name: 'Augusta', value: false, zipCode: [22980,24431,24411,24430,22952,24477,24432,24486,24441,22939,24401,24482,24440,24469,24437,24421,24467]},
            {name: 'Bath', value: false, zipCode: [24460,24445]},
            {name: 'Bedford', value: false, zipCode: [24523,24526,24121,24064,24122,24551,24179,24095]},
            {name: 'Bland', value: false, zipCode: [24314,24315,24366]},
            {name: 'Botetourt', value: false, zipCode: [24066,24175,24085,24064,24090,24083,24077,24019]},
            {name: 'Bristol', value: false, zipCode: [24201,24202]},
            {name: 'Brunswick', value: false, zipCode: [23868,23889,23845,23920,23887,23857,23821]},
            {name: 'Buchanan', value: false, zipCode: [24603,24656,24614,24631,24607]},
            {name: 'Buckingham', value: false, zipCode: [23936,23921]},
            {name: 'Buena Vista', value: false, zipCode: [24416]},
            {name: 'Campbell', value: false, zipCode: [24517,24538,24551,24550,24502,24588,24528]},
            {name: 'Caroline', value: false, zipCode: [22546,22535,22427]},
            {name: 'Carroll', value: false, zipCode: [24317,24343,24328]},
            {name: 'Charles City', value: false, zipCode: [23030]},
            {name: 'Charlotte', value: false, zipCode: [23937,23947,23923,23959]},
            {name: 'Charlottesville', value: false, zipCode: [22908,22901,22902,22903]},
            {name: 'Chesapeake', value: false, zipCode: [23325,23324,23323,23322,23321,23320]},
            {name: 'Chesterfield', value: false, zipCode: [23235,23236,23237,23234,23831,23114,23113,23112,23836,23803,23834,23225]},
            {name: 'Clarke', value: false, zipCode: [22620,22611,20135]},
            {name: 'Colonial Heights', value: false, zipCode: [23834]},
            {name: 'Covington', value: false, zipCode: [24426]},
            {name: 'Craig', value: false, zipCode: [24127]},
            {name: 'Culpeper', value: false, zipCode: [22701,22714]},
            {name: 'Cumberland', value: false, zipCode: [23040]},
            {name: 'Danville', value: false, zipCode: [24540,24541]},
            {name: 'Dickenson', value: false, zipCode: [24228,24226,24256]},
            {name: 'Dinwiddie', value: false, zipCode: [23841,23872]},
            {name: 'Emporia', value: false, zipCode: [23847]},
            {name: 'Essex', value: false, zipCode: [22560]},
            {name: 'Fairfax', value: false, zipCode: [22306,22307,22102,22182,22033,20171,22031,22003,22315,22151,22124,22181,22032,22153,22150,22079,22044,22041,20151,22309,22027,22180,22042,22043,20194,20191,20190,22030,22312,22046,22101,22039,20124,20120,20121,22310,20170,22060,22303,22152,22308,22311,22015,22035,22066]},
            {name: 'Falls Church', value: false, zipCode: [22046]},
            {name: 'Fauquier', value: false, zipCode: [20198,20115,22734,22712,20184,22728,20119,20186,20187,20130]},
            {name: 'Floyd', value: false, zipCode: [24091]},
            {name: 'Fluvanna', value: false, zipCode: [23038,22963]},
            {name: 'Franklin', value: false, zipCode: [24184,24121,24101,24151,24065,24176,23851,24137,24088]},
            {name: 'Frederick', value: false, zipCode: [22625,22637,22655,22624,22602,22645]},
            {name: 'Fredericksburg', value: false, zipCode: [22401]},
            {name: 'Galax', value: false, zipCode: [24333]},
            {name: 'Giles', value: false, zipCode: [24150,24124,24093,24147,24086,24136,24134]},
            {name: 'Gloucester', value: false, zipCode: [23062,23072,23061]},
            {name: 'Goochland', value: false, zipCode: [23063]},
            {name: 'Grayson', value: false, zipCode: [24330,24333,24378,24348]},
            {name: 'Greene', value: false, zipCode: [22968,22973]},
            {name: 'Halifax', value: false, zipCode: [24589,24520,24592,24534,24558,24577,24598]},
            {name: 'Hampton', value: false, zipCode: [23665,23651,23681,23605,23661,23668,23663,23666,23667,23664,23669]},
            {name: 'Hanover', value: false, zipCode: [23005,23116,23111,23069]},
            {name: 'Harrisonburg', value: false, zipCode: [22801,22802,22807]},
            {name: 'Henrico', value: false, zipCode: [23228,23060,23059,23222,23223,23233,23231,23150,23250,23229,23238,23294,23230,23227,23075]},
            {name: 'Henry', value: false, zipCode: [24112,24148,24089,24078,24168,24055,24165,24054]},
            {name: 'Highland', value: false, zipCode: [24458,24465]},
            {name: 'Hopewell', value: false, zipCode: [23860]},
            {name: 'Isle of Wight', value: false, zipCode: [23315,23851,23430,23314,23487]},
            {name: 'King and Queen', value: false, zipCode: [23085]},
            {name: 'King George', value: false, zipCode: [22448,22485]},
            {name: 'King William', value: false, zipCode: [23181,23086,23009,23177]},
            {name: 'Lancaster', value: false, zipCode: [22578,22482,22576,22503,22480]},
            {name: 'Lee', value: false, zipCode: [24248,24244,24265,24282,24263,24277,24243]},
            {name: 'Lexington', value: false, zipCode: [24450]},
            {name: 'Loudoun', value: false, zipCode: [20180,20164,20166,20105,20165,20147,20148,20141,20175,20176,20132,20158,20152,20117,20197]},
            {name: 'Louisa', value: false, zipCode: [23093,23117]},
            {name: 'Lunenburg', value: false, zipCode: [23947,23974,23952,23944]},
            {name: 'Lynchburg', value: false, zipCode: [24515,24504,24502,24503,24501]},
            {name: 'Madison', value: false, zipCode: [22727,22715]},
            {name: 'Manassas Park', value: false, zipCode: [20111]},
            {name: 'Manassas', value: false, zipCode: [20110]},
            {name: 'Martinsville', value: false, zipCode: [24112]},
            {name: 'Mathews', value: false, zipCode: [23109,23066]},
            {name: 'Mecklenburg', value: false, zipCode: [23924,23927,23970,23950,23917,23915,23919]},
            {name: 'Middlesex', value: false, zipCode: [23043,23175,23149]},
            {name: 'Montgomery', value: false, zipCode: [24087,24162,24141,24073,24060,24061,24149]},
            {name: 'Nelson', value: false, zipCode: [22958,22949,22967,22920,22969,22922,22971]},
            {name: 'New Kent', value: false, zipCode: [23124]},
            {name: 'Newport News', value: false, zipCode: [23601,23603,23608,23604,23605,23606,23607,23602]},
            {name: 'Norfolk', value: false, zipCode: [23509,23508,23507,23504,23503,23502,23505,23518,23510,23511,23513,23517,23551,23523]},
            {name: 'Northampton', value: false, zipCode: [23310,23316,23347,23486,23350,23413]},
            {name: 'Northumberland', value: false, zipCode: [22473]},
            {name: 'Norton', value: false, zipCode: [24273]},
            {name: 'Nottoway', value: false, zipCode: [23930,23922,23824,23955]},
            {name: 'Page', value: false, zipCode: [22942,22508,22923,22960]},
            {name: 'Patrick', value: false, zipCode: [22835,22849,22851]},
            {name: 'Petersburg', value: false, zipCode: [24133,24171,24120]},
            {name: 'Pittsylvania', value: false, zipCode: [23803,23805]},
            {name: 'Poquoson', value: false, zipCode: [24563,24557,24540,24531,24527]},
            {name: 'Portsmouth', value: false, zipCode: [23662]},
            {name: 'Powhatan', value: false, zipCode: [23709,23708,23704,23707,23701,23703,23702]},
            {name: 'Prince Edward', value: false, zipCode: [23139]},
            {name: 'Prince George', value: false, zipCode: [23943,23901,23909]},
            {name: 'Prince William', value: false, zipCode: [23875,23805,23801,23842]},
            {name: 'Pulaski', value: false, zipCode: [22193,20181,20169,20111,22192,20110,20112,22172,22125,20155,22025,20109,22026,22191,22134,20136]},
            {name: 'Radford', value: false, zipCode: [24347,24141,24058,24324,24301,24132,24084,24129]},
            {name: 'Rappahannock', value: false, zipCode: [24142,24141]},
            {name: 'Richmond', value: false, zipCode: [22747,22740,22640,22623,22627]},
            {name: 'Roanoke', value: false, zipCode: [23222,23223,23298,23284,23220,23221,23226,23227,23224,23225,23230,23235,23234,23219,23173,22572]},
            {name: 'Rockbridge', value: false, zipCode: [24153,24019,24014,24015,24016,24017,24011,24012,24013,24022,24020,24179,24018]},
            {name: 'Rockingham', value: false, zipCode: [24439,24450,24555,24435]},
            {name: 'Russell', value: false, zipCode: [24441,22832,22801,22821,22850,22834,22802,22840,22815,24471,22841,22853,22812,22827]},
            {name: 'Salem', value: false, zipCode: [24260,24224,24225,24266,24237,24272]},
            {name: 'Scott', value: false, zipCode: [24153]},
            {name: 'Shenandoah', value: false, zipCode: [24244,24245,24271,24258,24251,24290]},
            {name: 'Smyth', value: false, zipCode: [22657,22660,22810,22842,22824,22844,22644,22664,22847]},
            {name: 'Southampton', value: false, zipCode: [24354,24311,24319,24370,24375]},
            {name: 'Spotsylvania', value: false, zipCode: [23828,23827,23878,23874,23851,23837,23866,23829]},
            {name: 'Stafford', value: false, zipCode: [22551,22553]},
            {name: 'Staunton', value: false, zipCode: [22134,22406,22405,22554]},
            {name: 'Suffolk', value: false, zipCode: [24401]},
            {name: 'Surry', value: false, zipCode: [23433,23432,23437,23436,23435,23434,23438]},
            {name: 'Sussex', value: false, zipCode: [23883,23899,23888,23839]},
            {name: 'Tazewell', value: false, zipCode: [23882,23884,23890,23867]},
            {name: 'Virginia Beach', value: false, zipCode: [24605,24606,24612,24639,24635,24641,24609,24613,24651,24637,24622,24630]},
            {name: 'Warren', value: false, zipCode: [23459,23451,23453,23452,23455,23457,23456,23454,23460,23461,23462,23464]},
            {name: 'Washington', value: false, zipCode: [22630,22642]},
            {name: 'Waynesboro', value: false, zipCode: [24258,24270,24327,24361,24340,24210,24211,24236]},
            {name: 'Westmoreland', value: false, zipCode: [22980]},
            {name: 'Williamsburg', value: false, zipCode: [22443,22520]},
            {name: 'Winchester', value: false, zipCode: [23185,23188]},
            {name: 'Wise', value: false, zipCode: [22601]},
            {name: 'Wythe', value: false, zipCode: [24230,24216,24219,24246,24283,24293,24279]},
            {name: 'York', value: false, zipCode: [24382,24360,24312,24368,24350]},
            {name: 'Orange', value: false, zipCode: [23690,23665]},
        ]
    },
    {
        id: 48,
        name: 'Washington',
        stateId: 'WA',
        child: [
            {name: 'Adams', value: false, zipCode: [99169,99344,99341,99371,99326]},
            {name: 'Asotin', value: false, zipCode: [99403,99401,99402]},
            {name: 'Benton', value: false, zipCode: [99320,99353,99350,99337,99336,99338,99352,99354]},
            {name: 'Chelan', value: false, zipCode: [98816,98815,98801,98826,98822,98831,98817]},
            {name: 'Clallam', value: false, zipCode: [98326,98382,98331,98363,98362,98357,98381]},
            {name: 'Clark', value: false, zipCode: [98642,98686,98604,98601,98675,98671,98662,98665,98606,98661,98629,98607,98685,98682,98684,98664,98660,98663,98683]},
            {name: 'Columbia', value: false, zipCode: [99328,99359]},
            {name: 'Cowlitz', value: false, zipCode: [98625,98626,98616,98632,98611,98581,98674]},
            {name: 'Douglas', value: false, zipCode: [98802,98830,98858,98850,98813]},
            {name: 'Ferry', value: false, zipCode: [99121,99166,99118,99138,99150,99141,99140,99160]},
            {name: 'Franklin', value: false, zipCode: [99301,99343,99335,99326]},
            {name: 'Garfield', value: false, zipCode: [99347]},
            {name: 'Grant', value: false, zipCode: [98860,99135,98848,98837,99123,98823,99344,99115,99357,98851,98824,99116,99133,99349,98832,98857,99321]},
            {name: 'Grays Harbor', value: false, zipCode: [98571,98562,98587,98520,98595,98563,98537,98559,98541,98547,98569,98552,98550,98583,98557,98331,98526,98566,98568,98535,98536]},
            {name: 'Island', value: false, zipCode: [98277,98278,98236,98260,98239,98249,98282]},
            {name: 'Jefferson', value: false, zipCode: [98325,98368,98339,98365,98376,98358,98320]},
            {name: 'King', value: false, zipCode: [98109,98108,98105,98104,98107,98106,98101,98103,98102,98154,98122,98121,98126,98125,98178,98174,98177,98144,98146,98195,98199,98118,98119,98116,98117,98115,98112,98164,98134,98136,98133]},
            {name: 'Kitsap', value: false, zipCode: [98392,98370,98110,98310,98312,98345,98366,98380,98367,98342,98346,98383,98315,98311,98314,98337,98359,98340]},
            {name: 'Kittitas', value: false, zipCode: [98068,98922,98941,98925,98940,98934,98926,98946,98943]},
            {name: 'Klickitat', value: false, zipCode: [99322,98673,98619,99356,98620,98635,98672,98605,98613,98617,98628,98650]},
            {name: 'Lewis', value: false, zipCode: [98596,98591,98355,98532,98564,98356,98572,98593,98361,98565,98531,98570]},
            {name: 'Lincoln', value: false, zipCode: [99032,99134,99122,99185,99029,99117,99159,99103]},
            {name: 'Mason', value: false, zipCode: [98548,98584,98592,98524,98546,98528]},
            {name: 'Okanogan', value: false, zipCode: [99155,98846,98844,98819,98840,98829,98856,98841,98834,98862,98849,99124,98855,99116,98827,98812]},
            {name: 'Pacific', value: false, zipCode: [98614,98631,98577,98638,98527,98590,98640,98586,98624]},
            {name: 'Pend Oreille', value: false, zipCode: [99153,99139,99119,99180,99156,99152]},
            {name: 'Pierce', value: false, zipCode: [98424,98327,98390,98391,98374,98372,98444,98445,98447,98439,98438,98580,98387,98338,98332,98354,98371,98373,98303,98433,98422,98328,98335,98360,98375,98349,98351,98388,98329,98558,98333,98404,98394,98304,98466,98323,98321,98396,98385,98499,98498,98407,98467,98022,98446,98403,98405,98408,98409,98402,98406,98465,98416,98418,98421,98443]},
            {name: 'San Juan', value: false, zipCode: [98250]},
            {name: 'Skagit', value: false, zipCode: [98255,98284,98274,98267,98232,98237,98263,98221,98233,98273,98257,98229,98238,98283]},
            {name: 'Skamania', value: false, zipCode: [98648,98639,98610]},
            {name: 'Snohomish', value: false, zipCode: [98292,98223,98207,98204,98203,98201,98208,98290,98252,98012,98026,98087,98036,98272,98296,98258,98251,98021,98294,98271,98037,98020,98043,98256,98275,98072,98077,98241,98270]},
            {name: 'Spokane', value: false, zipCode: [99206,99212,99208,99022,99201,99203,99202,99205,99204,99207,99258,99224,99223,99001,99006,99039,99019,99016,99014,99004,99251,99218,99012,99018,99030,99025,99027,99216,99037,99021,99005,99011,99031]},
            {name: 'Stevens', value: false, zipCode: [99109,99110,99148,99141,99181,99026,99114,99157,99151,99173,99101]},
            {name: 'Thurston', value: false, zipCode: [98501,98512,98579,98531,98576,98516,98503,98597,98530,98513,98589,98502,98506]},
            {name: 'Wahkiakum', value: false, zipCode: [98643,98612,98638,98621,98647]},
            {name: 'Walla Walla', value: false, zipCode: [99348,99324,99362,99361,99363,99323,99329,99360]},
            {name: 'Whatcom', value: false, zipCode: [98244,98266,98230,98229,98295,98247,98276,98281,98225,98226,98220,98264,98248,98240]},
            {name: 'Whitman', value: false, zipCode: [99163,99102,99033,99179,99017,99128,99143,99158,99111,99174,99113,99170,99125,99171,99130,99161,99149]},
            {name: 'Yakima', value: false, zipCode: [98942,98947,98903,98923,98901,98935,98952,98938,98953,98930,98937,98908,98902,98951,98921,98939,98936,98948,98932,98944,98933]},
        ]
    },
    {
        id: 49,
        name: 'West Virginia',
        stateId: 'WV',
        child: [
            {name: 'Barbour', value: false, zipCode: [26238,26347,26349,26416,26250,26275]},
            {name: 'Berkeley', value: false, zipCode: [25428,25413,25420,25419,25401,25403,25404,25427]},
            {name: 'Boone', value: false, zipCode: [25130,25053,25165,25206,25209,25204,25049,25148,25193]},
            {name: 'Braxton', value: false, zipCode: [26624,26412,26335,26621,26601]},
            {name: 'Brooke', value: false, zipCode: [26075,26030,26070,26037,26032,26035]},
            {name: 'Cabell', value: false, zipCode: [25504,25702,25537,25510,25705,25703,25701,25704,25755,25559,25541]},
            {name: 'Calhoun', value: false, zipCode: [26147]},
            {name: 'Clay', value: false, zipCode: [25043]},
            {name: 'Doddridge', value: false, zipCode: [26456]},
            {name: 'Fayette', value: false, zipCode: [25119,25152,25090,25173,25812,25846,25917,25040,25840,25031,25139,25085,25904,25901,25855,25907,25880,25002,25879,25057,25136,25126,25938,25854,25186,25118,25161,25976]},
            {name: 'Gilmer', value: false, zipCode: [26430,26351]},
            {name: 'Grant', value: false, zipCode: [26707,26847]},
            {name: 'Greenbrier', value: false, zipCode: [24970,24901,24986,24910,25984,25962,24966,25981]},
            {name: 'Hampshire', value: false, zipCode: [26722,26763,26711,26757]},
            {name: 'Hancock', value: false, zipCode: [26050,26062,26034,26047]},
            {name: 'Hardy', value: false, zipCode: [26836,26851]},
            {name: 'Harrison', value: false, zipCode: [26301,26330,26438,26448,26431,26361,26323,26422,26385,26451,26426,26568,26386,26369]},
            {name: 'Jackson', value: false, zipCode: [25271,26164,25239]},
            {name: 'Jefferson', value: false, zipCode: [25443,25425,25442,25430,25414,25438]},
            {name: 'Kanawha', value: false, zipCode: [25214,25315,25075,25039,25086,25045,25071,25177,25202,25143,25064,25387,25305,25304,25303,25302,25301,25311,25314,25156,25015,25306,25102,25035,25320,25312,25103,25067,25112,25309,25313,25162]},
            {name: 'Lewis', value: false, zipCode: [26452,26378]},
            {name: 'Lincoln', value: false, zipCode: [25524,25571,25506,25202,25003,25523]},
            {name: 'Logan', value: false, zipCode: [25601,25606,25632,25625,25617,25635,25639,25638,25611,25607,25612,25647,25634,25624,25649,25646,25644,25628,25505,25637,25508]},
            {name: 'Marion', value: false, zipCode: [26591,26574,26563,26554,26571,26559,26582,26560,26576,26588,26570,26587]},
            {name: 'Marshall', value: false, zipCode: [26040,26038,26033,26031,26041]},
            {name: 'Mason', value: false, zipCode: [25260,25515,25502,25106,25247,25550,25265,25123]},
            {name: 'McDowell', value: false, zipCode: [24808,24894,24815,24828,24868,24871,24801,24853,24830,24888,24836,24892,24881,24826,24866,24879,24817,24861,24844,24816]},
            {name: 'Mercer', value: false, zipCode: [24701,24737,24739,24736,24715,24724,24747,24712,24740]},
            {name: 'Mineral', value: false, zipCode: [26719,26710,26753,26767,26750,26726,26717]},
            {name: 'Mingo', value: false, zipCode: [25667,25688,25678,24846,25608,25674,24851,25621,25692,25670,25661]},
            {name: 'Monongalia', value: false, zipCode: [26534,26541,26501,26544,26505,26543,26506,26508,26521]},
            {name: 'Monroe', value: false, zipCode: [24983,24963]},
            {name: 'Morgan', value: false, zipCode: [25422,25434,25411]},
            {name: 'Nicholas', value: false, zipCode: [26690,26656,26261,26610,26681,26651,26202,25059,26205,26691]},
            {name: 'Ohio', value: false, zipCode: [26003,26074,26059,26060]},
            {name: 'Pendleton', value: false, zipCode: [26807,26802]},
            {name: 'Pleasants', value: false, zipCode: [26170,26134]},
            {name: 'Pocahontas', value: false, zipCode: [24920,24915,24946,24927,24954,26264,24944]},
            {name: 'Preston', value: false, zipCode: [26547,26520,26525,26542,26764,26537,26519,26410,26705,26444,26425]},
            {name: 'Putnam', value: false, zipCode: [25526,25560,25011,25159,25033,25070,25168,25109,25213]},
            {name: 'Raleigh', value: false, zipCode: [25865,25801,25880,25909,25832,25918,25921,25813,25823,25843,25827,25839,25817,25048,25060,25853,25849,25911,25871,25818,25873,25915]},
            {name: 'Randolph', value: false, zipCode: [26293,26280,26241,26253,26294,26283,26285,26257,26273,26224,26230,26259,26270,26296]},
            {name: 'Ritchie', value: false, zipCode: [26325,26337,26362,26346,26421,26415]},
            {name: 'Roane', value: false, zipCode: [25270,25276]},
            {name: 'Summers', value: false, zipCode: [25951]},
            {name: 'Taylor', value: false, zipCode: [26347,26354]},
            {name: 'Tucker', value: false, zipCode: [26287,26271,26269,26260,26292]},
            {name: 'Tyler', value: false, zipCode: [26175,26149,26146]},
            {name: 'Upshur', value: false, zipCode: [26201,26234,26210]},
            {name: 'Wayne', value: false, zipCode: [25570,25669,25704,25507,25555,25535,25530,25514]},
            {name: 'Webster', value: false, zipCode: [26298,26288,26208,26206]},
            {name: 'Wetzel', value: false, zipCode: [26159,26419,26575,26437,26155,26581,26167,26377]},
            {name: 'Wirt', value: false, zipCode: [26143]},
            {name: 'Wood', value: false, zipCode: [26181,26142,26187,26101,26105,26104,26184,26150]},
            {name: 'Wyoming', value: false, zipCode: [24860,25845,24847,25826,24854,24719,24867,25928,25882,24716,24726,24874,24870,24857,24818]},
        ]
    },
    {
        id: 50,
        name: 'Wisconsin',
        stateId: 'WI',
        child: [
            {name: 'Adams', value: false, zipCode: [54457,53934,54613,53910,53936]},
            {name: 'Ashland', value: false, zipCode: [54806,54527,54855,54559,54546,54514,54861,54517]},
            {name: 'Barron', value: false, zipCode: [54813,54728,54812,54829,54889,54841,54822,54762,54805,54733,54868]},
            {name: 'Bayfield', value: false, zipCode: [54821,54844,54832,54865,54847,54856,54827,54891,54839,54814]},
            {name: 'Brown', value: false, zipCode: [54303,54313,54155,54115,54126,54307,54301,54311,54208,54217,54304,54302,54173,54162,54180]},
            {name: 'Buffalo', value: false, zipCode: [54755,54756,54610,54622,54629]},
            {name: 'Burnett', value: false, zipCode: [54893,54830,54840,54872]},
            {name: 'Calumet', value: false, zipCode: [53061,54952,54169,54130,54915,54129,54110,54123,53014,54160,53088]},
            {name: 'Chippewa', value: false, zipCode: [54745,54729,54703,54732,54757,54724,54748,54768,54727,54726]},
            {name: 'Clark', value: false, zipCode: [54422,54746,54436,54771,54460,54498,54420,54437,54446,54425,54456]},
            {name: 'Columbia', value: false, zipCode: [53955,53578,53555,53954,53960,53969,53911,53923,53901,53935,53928,53925,53932,53965]},
            {name: 'Crawford', value: false, zipCode: [54626,53826,54631,54657,54655,54628,53821,54645,54654]},
            {name: 'Dane', value: false, zipCode: [53559,53572,53531,53532,53598,53716,53713,53590,53705,53515,53562,53597,53523,53558,53527,53704,53593,53528,53508,53529,53517,53589,53575,53719,53711,53706,53703,53726,53792,53718,53715,53714,53717,53560]},
            {name: 'Dodge', value: false, zipCode: [53933,53050,53579,53036,53003,53066,53557,53032,53916,53956,53035,53048,53963,53047,53034,53006,53016,53039,53922,53091,53059,53078]},
            {name: 'Door', value: false, zipCode: [54234,54209,54210,54213,54211,54202,54235]},
            {name: 'Douglas', value: false, zipCode: [54849,54873,54838,54820,54880,54874,54864]},
            {name: 'Dunn', value: false, zipCode: [54751,54734,54739,54755,54730,54772,54763,54749,54725]},
            {name: 'Eau Claire', value: false, zipCode: [54720,54701,54722,54703,54742,54741]},
            {name: 'Florence', value: false, zipCode: [54121,54542]},
            {name: 'Fond du Lac', value: false, zipCode: [54937,54935,53919,53019,54974,53079,54971,53049,53057,53931,53065,54979,53010,53963]},
            {name: 'Forest', value: false, zipCode: [54566,54541,54511,54520]},
            {name: 'Grant', value: false, zipCode: [53809,53807,53808,53569,53811,53518,53573,53554,53820,53801,53806,53804,53817,53810,53816,53818,53805,53812,53813,53827]},
            {name: 'Green Lake', value: false, zipCode: [54968,53946,53939,54923,54941,53947,53926]},
            {name: 'Green', value: false, zipCode: [53520,53566,53550,53522,53570,53574,53502,53521]},
            {name: 'Iowa', value: false, zipCode: [53580,53526,53543,53544,53582,53506,53503,53533,53565,53553,53507]},
            {name: 'Iron', value: false, zipCode: [54547,54525,54550,54559,54536,54534]},
            {name: 'Jackson', value: false, zipCode: [54615,54659,54642,54635,54754,54643,54611]},
            {name: 'Jefferson', value: false, zipCode: [53538,53094,53038,53549,53137,53036,53156,53594,53098,53178,53551,53523]},
            {name: 'Juneau', value: false, zipCode: [53948,54618,54637,53968,53944,54646,53950,53929,53962]},
            {name: 'Kenosha', value: false, zipCode: [53168,53192,53179,53170,53144,53140,53142,53143,53128,53104,53158,53105,53181,53177]},
            {name: 'Kewaunee', value: false, zipCode: [54205,54201,54217,54216]},
            {name: 'La Crosse', value: false, zipCode: [54653,54614,54669,54601,54644,54650,54636,54603]},
            {name: 'Lafayette', value: false, zipCode: [53510,53587,53599,53530,53586,53803,53541,53504,53516]},
            {name: 'Langlade', value: false, zipCode: [54428,54485,54409,54491]},
            {name: 'Lincoln', value: false, zipCode: [54487,54452]},
            {name: 'Manitowoc', value: false, zipCode: [54247,54245,54228,54215,54227,54230,53014,54207,54220,54214,53015,54241,53042,54232]},
            {name: 'Marathon', value: false, zipCode: [54455,54488,54476,54405,54421,54474,54401,54417,54452,54426,54427,54499,54484,54411,54403,54479,54440]},
            {name: 'Marinette', value: false, zipCode: [54177,54161,54157,54151,54112,54143,54119,54125,54102,54114,54156]},
            {name: 'Marquette', value: false, zipCode: [53953,53949,53952,54960,53964,53901,53920,53930]},
            {name: 'Menominee', value: false, zipCode: [54150,54135,54124]},
            {name: 'Milwaukee', value: false, zipCode: [53110,53154,53221,53220,53228,53219,53214,53215,53132,53129,53217,53235,53213,53225,53226,53222,53172,53203,53202,53207,53206,53205,53204,53209,53208,53233,53218,53216,53210,53211,53212,53295,53224,53223,53227,53130]},
            {name: 'Monroe', value: false, zipCode: [54619,54656,54660,54638,54670,54648,54666]},
            {name: 'Oconto', value: false, zipCode: [54175,54149,54101,54154,54153,54139,54174,54171,54124,54138]},
            {name: 'Oneida', value: false, zipCode: [54501,54539,54548,54562,54568]},
            {name: 'Outagamie', value: false, zipCode: [54130,54165,54914,54915,54913,54911,54170,54944,54136,54931,54106,54152,54922,54113,54140]},
            {name: 'Ozaukee', value: false, zipCode: [53004,53021,53074,53024,53092,53080,53097,53012]},
            {name: 'Pepin', value: false, zipCode: [54721,54769,54736,54759]},
            {name: 'Pierce', value: false, zipCode: [54723,54011,54014,54740,54022,54767,54021,54761,54750]},
            {name: 'Polk', value: false, zipCode: [54001,54824,54004,54858,54009,54837,54024,54020,54005,54853,54810]},
            {name: 'Portage', value: false, zipCode: [54921,54467,54407,54406,54458,54473,54423,54481,54909,54482,54443]},
            {name: 'Price', value: false, zipCode: [54556,54555,54537,54515,54459,54552]},
            {name: 'Racine', value: false, zipCode: [53182,53405,53406,53403,53177,53105,53167,53139,53404,53402,53126,53108,53185]},
            {name: 'Richland', value: false, zipCode: [54634,53540,53556,53924,53573,53581,53584]},
            {name: 'Rock', value: false, zipCode: [53548,53537,53525,53536,53511,53576,53534,53563,53545,53546]},
            {name: 'Rusk', value: false, zipCode: [54848,54563,54766,54530]},
            {name: 'Sauk', value: false, zipCode: [53961,53941,53965,53913,53940,53942,53951,53583,53943,53588,53577,53578,53959,53561]},
            {name: 'Sawyer', value: false, zipCode: [54896,54843,54828,54876,54867,54835]},
            {name: 'Shawano', value: false, zipCode: [54948,54166,54409,54450,54499,54416,54128,54111,54124,54162,54107,54486,54137,54408,54414,54950,54427,54928,54127]},
            {name: 'Sheboygan', value: false, zipCode: [53083,53070,53085,53075,53044,53023,53001,53020,53073,53013,53011,53031,53093,53081]},
            {name: 'St. Croix', value: false, zipCode: [54016,54007,54015,54025,54017,54028,54002,54013,54023,54082,54027,54026]},
            {name: 'Taylor', value: false, zipCode: [54470,54490,54451,54480,54766,54447,54433]},
            {name: 'Trempealeau', value: false, zipCode: [54773,54747,54612,54661,54625,54659,54616,54770,54627,54758,54760,54738,54630]},
            {name: 'Vernon', value: false, zipCode: [54621,54652,54658,54632,54623,54651,54639,54664,54665,54634,54624,54667]},
            {name: 'Vilas', value: false, zipCode: [54560,54538,54512,54521]},
            {name: 'Walworth', value: false, zipCode: [53115,53120,53121,53147,53105,53114,53191,53184,53128,53585,53190,53176,53525]},
            {name: 'Washburn', value: false, zipCode: [54876,54801,54859,54871,54817,54888,54875]},
            {name: 'Washington', value: false, zipCode: [53027,53086,53040,53002,53076,53022,53037,53033,53017,53090,53095]},
            {name: 'Waukesha', value: false, zipCode: [53058,53029,53066,53051,53186,53188,53189,53153,53036,53103,53005,53045,53150,53146,53151,53046,53007,53018,53069,53149,53119,53089,53183,53118,53122]},
            {name: 'Waupaca', value: false, zipCode: [54981,54486,54926,54977,54983,54945,54929,54946,54933,54961,54950,54962,54949,54940]},
            {name: 'Waushara', value: false, zipCode: [54940,54970,54965,54943,54984,54982,54930,54966,54967]},
            {name: 'Winnebago', value: false, zipCode: [54956,54952,54964,54963,54980,54985,54904,54901,54902,54986,54947,54927]},
            {name: 'Wood', value: false, zipCode: [54454,54494,54412,54469,54441,54489,54475,54495,54449,54457,54466,54410,54413]},
        ]
    },
    {
        id: 51,
        name: 'Wyoming',
        stateId: 'WY',
        child: [
            {name: 'Albany', value: false, zipCode: [82055,82070,82072,82063,82083]},
            {name: 'Big Horn', value: false, zipCode: [82423,82428,82410,82441,82412,82411,82432,82431,82420,82421,82426]},
            {name: 'Campbell', value: false, zipCode: [82732,82718,82716]},
            {name: 'Carbon', value: false, zipCode: [82325,82327,82331,82329,82324,82334,82301,82321,82323]},
            {name: 'Converse', value: false, zipCode: [82633,82224,82229,82637]},
            {name: 'Crook', value: false, zipCode: [82720,82729,82721]},
            {name: 'Fremont', value: false, zipCode: [82520,82649,82501,82510,82512,82516,82513,82514,82310,82523,82515]},
            {name: 'Goshen', value: false, zipCode: [82212,82244,82240,82221,82223,82217]},
            {name: 'Hot Springs', value: false, zipCode: [82443,82430]},
            {name: 'Johnson', value: false, zipCode: [82834,82639]},
            {name: 'Laramie', value: false, zipCode: [82009,82007,82050,82053,82001,82054,82082,82005]},
            {name: 'Lincoln', value: false, zipCode: [83110,83119,83128,83112,83127,83114,83101,83118,83116,83122,83126,83123,83124,83111,83120]},
            {name: 'Natrona', value: false, zipCode: [82643,82636,82604,82601,82609,82644,82620,82635]},
            {name: 'Niobrara', value: false, zipCode: [82227,82242,82225,82222]},
            {name: 'Park', value: false, zipCode: [82435,82414,82440,82433,82190]},
            {name: 'Platte', value: false, zipCode: [82201,82213,82215,82210,82214]},
            {name: 'Sheridan', value: false, zipCode: [82801,82833,82832,82842,82836,82839,82838,82835,82831]},
            {name: 'Sublette', value: false, zipCode: [83113,82941,82925,83115,82923,82922]},
            {name: 'Sweetwater', value: false, zipCode: [82901,82336,82938,82943,82942,82932,82935,82934,82322,82929,82945]},
            {name: 'Teton', value: false, zipCode: [83414,83001,83014,83011,83025]},
            {name: 'Uinta', value: false, zipCode: [82930,82944,82933,82936,82939,82937]},
            {name: 'Washakie', value: false, zipCode: [82401,82442]},
            {name: 'Weston', value: false, zipCode: [82701,82723,82730]},
        ]
    },
    {
        id: 52,
        name: 'Puerto Rico',
        stateId: 'PR',
        child: [
            {name: 'Adjuntas', value: false, zipCode: ['601']},
            {name: 'Aguada', value: false, zipCode: ['602']},
            {name: 'Aguadilla', value: false, zipCode: ['690','603']},
            {name: 'Aguas Buenas', value: false, zipCode: ['703']},
            {name: 'Aibonito', value: false, zipCode: ['705']},
            {name: 'Anasco', value: false, zipCode: ['610']},
            {name: 'Arecibo', value: false, zipCode: ['612','616','688']},
            {name: 'Arroyo', value: false, zipCode: ['714']},
            {name: 'Barceloneta', value: false, zipCode: ['617']},
            {name: 'Barranquitas', value: false, zipCode: ['794']},
            {name: 'Bayamon', value: false, zipCode: ['00959','00957','00956','00934','00960','00961']},
            {name: 'Cabo Rojo', value: false, zipCode: ['623','622']},
            {name: 'Caguas', value: false, zipCode: ['727','725','00725','00727']},
            {name: 'Camuy', value: false, zipCode: ['627']},
            {name: 'Canovanas', value: false, zipCode: ['729']},
            {name: 'Carolina', value: false, zipCode: ['00979','00987','00982','00983','00985']},
            {name: 'Cataio', value: false, zipCode: ['00949','00962']},
            {name: 'Cayey', value: false, zipCode: ['736']},
            {name: 'Ceiba', value: false, zipCode: ['735']},
            {name: 'Ciales', value: false, zipCode: ['638']},
            {name: 'Cidra', value: false, zipCode: ['00703','00739','00736','739','736']},
            {name: 'Coamo', value: false, zipCode: ['769']},
            {name: 'Comerio', value: false, zipCode: ['782']},
            {name: 'Corozal', value: false, zipCode: ['783']},
            {name: 'Culebra', value: false, zipCode: ['775']},
            {name: 'Dorado', value: false, zipCode: ['646']},
            {name: 'Fajardo', value: false, zipCode: ['738','00740','00738']},
            {name: 'Florida', value: false, zipCode: ['650']},
            {name: 'Guanica', value: false, zipCode: ['647','653','00653','00647']},
            {name: 'Guayama', value: false, zipCode: ['784']},
            {name: 'Guayanilla', value: false, zipCode: ['656']},
            {name: 'Guaynabo', value: false, zipCode: ['00971','00934','00962','00966','00965','00968','00969']},
            {name: 'Gurabo', value: false, zipCode: ['778']},
            {name: 'Hatillo', value: false, zipCode: ['659']},
            {name: 'Hormigueros', value: false, zipCode: ['660']},
            {name: 'Humacao', value: false, zipCode: ['741','791','00771','00791']},
            {name: 'Isabela', value: false, zipCode: ['662']},
            {name: 'Jayuya', value: false, zipCode: ['664']},
            {name: 'Juana Diaz', value: false, zipCode: ['795','00715','00795','00780']},
            {name: 'Juncos', value: false, zipCode: ['777']},
            {name: 'Lajas', value: false, zipCode: ['667']},
            {name: 'Lares', value: false, zipCode: ['669']},
            {name: 'Las Piedras', value: false, zipCode: ['771','00771','00777']},
            {name: 'Loiza', value: false, zipCode: ['772']},
            {name: 'Luquillo', value: false, zipCode: ['773']},
            {name: 'Manati', value: false, zipCode: ['674']},
            {name: 'Maricao', value: false, zipCode: ['606']},
            {name: 'Maunabo', value: false, zipCode: ['707']},
            {name: 'Mayaguez', value: false, zipCode: ['00682','00680']},
            {name: 'Moca', value: false, zipCode: ['676']},
            {name: 'Morovis', value: false, zipCode: ['687']},
            {name: 'Naguabo', value: false, zipCode: ['718']},
            {name: 'Naranjito', value: false, zipCode: ['719']},
            {name: 'Orocovis', value: false, zipCode: ['720']},
            {name: 'Patillas', value: false, zipCode: ['723']},
            {name: 'Penuelas', value: false, zipCode: ['624']},
            {name: 'Ponce', value: false, zipCode: ['624','715','780','731','00728','00717','00716','00730']},
            {name: 'Quebradillas', value: false, zipCode: ['678']},
            {name: 'Rincon', value: false, zipCode: ['677']},
            {name: 'Rio Grande', value: false, zipCode: ['745']},
            {name: 'Sabana Grande', value: false, zipCode: ['637']},
            {name: 'Salinas', value: false, zipCode: ['751','704']},
            {name: 'San German', value: false, zipCode: ['683']},
            {name: 'San Juan', value: false, zipCode: ['00917','00915','00918','00913','00912','00911','00926','00927','00924','00925','00923','00920','00921','00906','00907','00901','00909','00969']},
            {name: 'San Lorenzo', value: false, zipCode: ['754']},
            {name: 'San Sebastian', value: false, zipCode: ['685']},
            {name: 'Santa Isabel', value: false, zipCode: ['757']},
            {name: 'Toa Alta', value: false, zipCode: ['953']},
            {name: 'Toa Baja', value: false, zipCode: ['952','949','00949','00951']},
            {name: 'Trujillo Alto', value: false, zipCode: ['00976','00926']},
            {name: 'Utuado', value: false, zipCode: ['641']},
            {name: 'Vega Alta', value: false, zipCode: ['692']},
            {name: 'Vega Baja', value: false, zipCode: ['693']},
            {name: 'Vieques', value: false, zipCode: ['765']},
            {name: 'Villalba', value: false, zipCode: ['766']},
            {name: 'Yabucoa', value: false, zipCode: ['767']},
            {name: 'Yauco', value: false, zipCode: ['698']},
        ]
    },
];

