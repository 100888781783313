import React from 'react'
import {
    Accordion,
    AccordionButton, AccordionIcon,
    AccordionItem, AccordionPanel,
    Box,
    Button,
    Grid,
    GridItem,
    Flex,
    Text,
} from '@chakra-ui/react'
import {ReactComponent as DownloadIcon} from "../../assets/download.svg";
import moment from "moment/moment";
import {Link} from "react-router-dom";
import {ESTIMATE, INVOICE, ORDER, ORDER_TYPES, RECEIPT, SHIPPING} from "../../constants/orders/orderEnum";

const Fulfilled = ({estimates, loading, downloadPdf}) => {
    return (
        <Box>
            {
                estimates?.filter((filtered) => filtered?.fulfilledByUserEstimateSu)?.length > 0 ? (
                    <Grid fontSize='xl' fontFamily='Titillium Web' mb={3} fontWeight='600' width='full' templateColumns="repeat(6, 1fr)">
                        <GridItem pl={5}>Reference</GridItem>
                        <GridItem pl={2}>Company</GridItem>
                        <GridItem>Project</GridItem>
                        <GridItem>Fulfilled By</GridItem>
                        <GridItem>Fulfillment date</GridItem>
                    </Grid>
                ) : (
                    <Text fontSize='18px' textAlign='center'>
                        No active fulfilled orders at this time - check back later!
                    </Text>
                )
            }

            {
                estimates?.filter((filtered) => filtered?.fulfilledByUserEstimateSu).sort((a: any, b: any) => moment.utc(b.createdAt.date).diff(moment.utc(a.createdAt.date)))?.map((estimate) => (
                    <Accordion key={estimate?.id} allowToggle mb={3}>
                        <AccordionItem borderRadius='10px' border='1px solid #A9A9A9'>
                            <AccordionButton _focus='' bg='white' borderRadius='10px' border='1px solid #A9A9A9'>
                                <Grid textAlign='start' templateColumns="repeat(6, 1fr)" alignItems='center' width='full' justifyContent='space-between'>
                                    <GridItem textDecoration='underline'>
                                        <Link to={`/orders/estimate/su/${estimate.id}/1`} z-index='999'>
                                            {estimate?.reference}
                                        </Link>
                                    </GridItem>
                                    <GridItem>
                                        <Link to={`/orders/estimate/su/${estimate.id}/1`} z-index='999'>
                                            {estimate?.fulfilledByUserEstimateSu?.company?.length > 0 ? estimate?.fulfilledByUserEstimateSu?.company[0]?.name : estimate?.fulfilledByUserEstimateSu?.associatedToCompany?.name}
                                        </Link>
                                    </GridItem>
                                    <GridItem>
                                        <Link to={`/orders/estimate/su/${estimate.id}/1`} z-index='999'>
                                            {estimate?.report?.project?.title}
                                        </Link>
                                    </GridItem>
                                    <GridItem pl={2}>
                                        <Flex alignItems='center'>
                                            <Text mr={2}
                                                bg={
                                                    estimate?.fulfilledByUserEstimateSu?.type === 'Reu' ? '#C956FF' :
                                                        estimate?.fulfilledByUserEstimateSu?.type === 'Su' ? '#FFD339' :
                                                            estimate?.fulfilledByUserEstimateSu?.type === 'Sa' ? '#A6F484' :
                                                                estimate?.fulfilledByUserEstimateSu?.type === 'Au' ? '#ACDAFF' :
                                                                    estimate?.fulfilledByUserEstimateSu?.type === 'Eu' ? '#FF8660' :
                                                                        'red'
                                                }
                                                width='15px'
                                                height='15px'
                                                border='1px solid black'
                                                borderRadius='100%'/>
                                            <Link to={`/orders/estimate/su/${estimate.id}`} z-index='999'>
                                                {estimate?.fulfilledByUserEstimateSu?.firstName + ' ' + estimate?.fulfilledByUserEstimateSu?.lastName}
                                            </Link>
                                        </Flex>
                                    </GridItem>
                                    <GridItem pl={5}>
                                        <Link to={`/orders/estimate/su/${estimate.id}`} z-index='999'>
                                            {moment(estimate?.createdAt.date).format('MMM DD, YYYY')}
                                        </Link>
                                    </GridItem>
                                    <GridItem>
                                        <Button width='110px' height='30px' leftIcon={<DownloadIcon/>}>
                                            Download All
                                        </Button>
                                    </GridItem>
                                </Grid>
                                <AccordionIcon/>
                            </AccordionButton>
                            <AccordionPanel pr={9} pb={0}>
                                <Grid mb={2} minH='57px' display={estimate?.fulfilledByUserEstimateSu ? 'grid' : 'none'} textAlign='start' templateColumns="repeat(6, 1fr)" alignItems='center' width='full' justifyContent='space-between'>
                                    <GridItem>Estimate</GridItem>
                                    <GridItem>
                                        {estimate?.fulfilledByUserEstimateSu?.company?.length > 0 ? estimate?.fulfilledByUserEstimateSu?.company[0]?.name : estimate?.fulfilledByUserEstimateSu?.associatedToCompany?.name}
                                    </GridItem>
                                    <GridItem>{estimate?.report?.project?.title}</GridItem>
                                    <GridItem pl={2}>
                                        <Flex alignItems='center' display='flex'>
                                            <Text mr={2}
                                                bg={
                                                    estimate?.fulfilledByUserEstimateSu?.type === 'Reu' ? '#C956FF' :
                                                        estimate?.fulfilledByUserEstimateSu?.type === 'Su' ? '#FFD339' :
                                                            estimate?.fulfilledByUserEstimateSu?.type === 'Sa' ? '#A6F484' :
                                                                estimate?.fulfilledByUserEstimateSu?.type === 'Au' ? '#ACDAFF' :
                                                                    estimate?.fulfilledByUserEstimateSu?.type === 'Eu' ? '#FF8660' :
                                                                        'red'
                                                }
                                                width='15px'
                                                height='15px'
                                                border='1px solid black'
                                                borderRadius='100%'/>
                                            {estimate?.fulfilledByUserEstimateSu?.firstName + ' ' + estimate?.fulfilledByUserEstimateSu?.lastName}
                                        </Flex>
                                    </GridItem>
                                    <GridItem pl={5}>
                                        {moment(estimate?.createdAt?.date).format('MMM DD, YYYY')}
                                    </GridItem>
                                    <GridItem>
                                        <Button onClick={() => downloadPdf(estimate, ESTIMATE)} isLoading={loading[estimate.reference]}  width='110px' height='30px'>
                                            <DownloadIcon/>
                                        </Button>
                                    </GridItem>
                                </Grid>
                                <Grid display={estimate?.fulfilledByUserOrderSu ? 'grid' : 'none'} mb={2} minH='57px' textAlign='start' templateColumns="repeat(6, 1fr)" alignItems='center' width='full' borderTop='1px solid #A9A9A9' justifyContent='space-between'>
                                    <GridItem>Order</GridItem>
                                    <GridItem>
                                        {estimate?.fulfilledByUserOrderSu?.company?.length > 0 ? estimate?.fulfilledByUserOrderSu?.company[0]?.name : estimate?.fulfilledByUserOrderSu?.associatedToCompany?.name}
                                    </GridItem>
                                    <GridItem>{estimate?.report?.project?.title}</GridItem>
                                    <GridItem pl={2}>
                                        <Flex alignItems='center' display='flex'>
                                            <Text
                                                mr={2}
                                                bg={
                                                    estimate?.fulfilledByUserOrderSu?.type === 'Reu' ? '#C956FF' :
                                                        estimate?.fulfilledByUserOrderSu?.type === 'Su' ? '#FFD339' :
                                                            estimate?.fulfilledByUserOrderSu?.type === 'Sa' ? '#A6F484' :
                                                                estimate?.fulfilledByUserOrderSu?.type === 'Au' ? '#ACDAFF' :
                                                                    estimate?.fulfilledByUserOrderSu?.type === 'Eu' ? '#FF8660' :
                                                                        'red'
                                                }
                                                width='15px'
                                                height='15px'
                                                border='1px solid black'
                                                borderRadius='100%'/>
                                            {estimate?.fulfilledByUserOrderSu?.firstName + ' ' + estimate?.fulfilledByUserOrderSu?.lastName}
                                        </Flex>
                                    </GridItem>
                                    <GridItem pl={5}>
                                        {moment(estimate?.orderDateCreated?.date).format('MMM DD, YYYY')}
                                    </GridItem>
                                    <GridItem>
                                        <Button onClick={() => downloadPdf(estimate, ORDER)} isLoading={loading[estimate.reference]}  width='110px' height='30px'>
                                            <DownloadIcon/>
                                        </Button>
                                    </GridItem>
                                </Grid>
                                <Grid display={estimate?.fulfilledByUserShippingSu ? 'grid' : 'none'} mb={2} minH='57px' textAlign='start' borderTop='1px solid #A9A9A9' templateColumns="repeat(6, 1fr)" alignItems='center' width='full' justifyContent='space-between'>
                                    <GridItem>Shipping</GridItem>
                                    <GridItem>
                                        {estimate?.fulfilledByUserShippingSu?.company?.length > 0 ? estimate?.fulfilledByUserShippingSu?.company[0]?.name : estimate?.fulfilledByUserShippingSu?.associatedToCompany?.name}
                                    </GridItem>
                                    <GridItem>{estimate?.report?.project?.title}</GridItem>
                                    <GridItem pl={2}>
                                        <Flex alignItems='center' display='flex'>
                                            <Text
                                                mr={2}
                                                bg={
                                                    estimate?.fulfilledByUserShippingSu?.type === 'Reu' ? '#C956FF' :
                                                        estimate?.fulfilledByUserShippingSu?.type === 'Su' ? '#FFD339' :
                                                            estimate?.fulfilledByUserShippingSu?.type === "Sa" ? '#A6F484' :
                                                                estimate?.fulfilledByUserShippingSu?.type === 'Au' ? '#ACDAFF' :
                                                                    estimate?.fulfilledByUserShippingSu?.type === 'Eu' ? '#FF8660' :
                                                                        'red'
                                                }
                                                width='15px'
                                                height='15px'
                                                border='1px solid black'
                                                borderRadius='100%'/>
                                            {estimate?.fulfilledByUserShippingSu?.firstName + ' ' + estimate?.fulfilledByUserShippingSu?.lastName}
                                        </Flex>
                                    </GridItem>
                                    <GridItem pl={4}>
                                        {moment(estimate?.shippingDateCreated?.date).format('MMM DD, YYYY')}
                                    </GridItem>
                                    <GridItem>
                                        <Button onClick={() => downloadPdf(estimate, SHIPPING)} isLoading={loading[estimate.reference]}  width='110px' height='30px'>
                                            <DownloadIcon/>
                                        </Button>
                                    </GridItem>
                                </Grid>
                                <Grid display={estimate?.fulfilledByUserInvoiceSu ? 'grid' : 'none'} mb={2} minH='57px' textAlign='start' borderTop='1px solid #A9A9A9' templateColumns="repeat(6, 1fr)" alignItems='center' width='full' justifyContent='space-between'>
                                    <GridItem>Invoice</GridItem>
                                    <GridItem>
                                        {estimate?.fulfilledByUserInvoiceSu?.company?.length > 0 ? estimate?.fulfilledByUserInvoiceSu?.company[0]?.name : estimate?.fulfilledByUserInvoiceSu?.associatedToCompany?.name}
                                    </GridItem>
                                    <GridItem>{estimate?.report?.project?.title}</GridItem>
                                    <GridItem pl={2}>
                                        <Flex alignItems='center' display='flex'>
                                            <Text mr={2}
                                                bg={
                                                    estimate?.fulfilledByUserInvoiceSu?.type === 'Reu' ? '#C956FF' :
                                                        estimate?.fulfilledByUserInvoiceSu?.type === 'Su' ? '#FFD339' :
                                                            estimate?.fulfilledByUserInvoiceSu?.type === 'Sa' ? '#A6F484' :
                                                                estimate?.fulfilledByUserInvoiceSu?.type === 'Au' ? '#ACDAFF' :
                                                                    estimate?.fulfilledByUserInvoiceSu?.type === 'Eu' ? '#FF8660' :
                                                                        'red'
                                                }
                                                width='15px'
                                                height='15px'
                                                border='1px solid black'
                                                borderRadius='100%'/>
                                            {estimate?.fulfilledByUserInvoiceSu?.firstName + ' ' + estimate?.fulfilledByUserInvoiceSu?.lastName}
                                        </Flex>
                                    </GridItem>
                                    <GridItem pl={4}>
                                        {moment(estimate?.invoiceDateCreated?.date).format('MMM DD, YYYY')}
                                    </GridItem>
                                    <GridItem>
                                        <Button onClick={() => downloadPdf(estimate, INVOICE)} isLoading={loading[estimate.reference]}  width='110px' height='30px'>
                                            <DownloadIcon/>
                                        </Button>
                                    </GridItem>
                                </Grid>
                                <Grid display={estimate?.fulfilledByUserReceiptSu ? 'grid' : 'none'} mb={2} minH='57px' textAlign='start' borderTop='1px solid #A9A9A9' templateColumns="repeat(6, 1fr)" alignItems='center' width='full' justifyContent='space-between'>
                                    <GridItem>Receipt</GridItem>
                                    <GridItem>
                                        {estimate?.fulfilledByUserReceiptSu?.company?.length > 0 ? estimate?.fulfilledByUserReceiptSu?.company[0]?.name : estimate?.fulfilledByUserReceiptSu?.associatedToCompany?.name}
                                    </GridItem>
                                    <GridItem>{estimate?.report?.project?.title}</GridItem>
                                    <GridItem pl={2}>
                                        <Flex alignItems='center' display='flex'>
                                            <Text mr={2}
                                                bg={
                                                    estimate?.fulfilledByUserReceiptSu?.type === 'Reu' ? '#C956FF' :
                                                        estimate?.fulfilledByUserReceiptSu?.type === 'Su' ? '#FFD339' :
                                                            estimate?.fulfilledByUserReceiptSu?.type === 'Sa' ? '#A6F484' :
                                                                estimate?.fulfilledByUserReceiptSu?.type === 'Au' ? '#ACDAFF' :
                                                                    estimate?.fulfilledByUserReceiptSu?.type === 'Eu' ? '#FF8660' :
                                                                        'red'
                                                }
                                                width='15px'
                                                height='15px'
                                                border='1px solid black'
                                                borderRadius='100%'/>
                                            {estimate?.fulfilledByUserReceiptSu?.firstName + ' ' + estimate?.fulfilledByUserReceiptSu?.lastName}
                                        </Flex>
                                    </GridItem>
                                    <GridItem pl={4}>
                                        {moment(estimate?.receiptDateCreated?.date).format('MMM DD, YYYY')}
                                    </GridItem>
                                    <GridItem>
                                        <Button onClick={() => downloadPdf(estimate, RECEIPT)} isLoading={loading[estimate.reference]} width='110px' height='30px'>
                                            <DownloadIcon/>
                                        </Button>
                                    </GridItem>
                                </Grid>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                ))
            }
        </Box>
    )
}

export default Fulfilled