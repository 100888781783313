import {Box, Flex, Heading, Text, Input, Divider, Button, Image, Grid, GridItem} from "@chakra-ui/react";
import moment from "moment";
import {InputLabel, Textarea} from "../../../components/Theme";
import {ReactComponent as SignatureIcon} from "../../../assets/signatureIcon.svg";
import {ReactComponent as AuthIcon} from "../../../assets/okIcon.svg";
import Luminaires from "../parts/Luminaires";
import Loading from "../../../components/Loading";
import {ReactComponent as DoneIcon} from "../../../assets/doneIcon.svg";
import {SU} from "../../../constants/user/userEnum";

const EstimateSuTab = ({refererEstimate, estimate, luminaires, onOpenSignature, currentDate, user, imageURL, subTotal, notes, approveEstimate, setNotes, salesTax, shipping, setSalesTax, setShipping, loading, total, isLoading,}) => {
    if (isLoading) return <Loading/>

    return (
        <Box ref={refererEstimate} display={estimate?.createdAt ? 'block' : 'none'} backgroundColor='white' p={9}>
            <Flex justifyContent='space-between'>
                <img src="/images/LogoIcon.svg" alt=""/>
                <Flex flexDirection='column'>
                    <Heading m={0} textAlign='end'>Estimate</Heading>
                    <Text>Reference # : {estimate?.reference}</Text>
                    <Text textAlign='end'>Date : {moment(estimate?.createdAt?.date)?.format('MM/DD/YYYY')}</Text>
                </Flex>
            </Flex>
            <Flex mt={4} justifyContent='space-between' fontSize='18px'>
                <Flex width='49%' borderRight='2px solid #9C9C9C' flexDirection='column'>
                    <Input readOnly border='none' backgroundColor='#EEEEEE' mb={3} width='98%' fontSize='18px'
                           fontWeight='700'
                           value='Billing Information:' color='#222222' fontFamily='Titillium Web'/>
                    <Text>Focus Earth</Text>
                    <Text>Attn: Pete Musser</Text>
                    <Text>1070 Tunnel Road</Text>
                    <Text>Building 1, Suite 10, #202</Text>
                    <Text>Asheville, NC 28805</Text>
                    <Text>USA</Text>
                    <Text>(925) 323-0053</Text>
                </Flex>
                <Flex width='50%' flexDirection='column'>
                    <Input readOnly border='none' backgroundColor='#EEEEEE' width='100%' fontSize='18px'
                           mb={3} value='Shipping Information:' color='#222222' fontWeight='700' fontFamily='Titillium Web'/>
                    <Text>{estimate?.userReu?.company[0]?.name}</Text>
                    <Text>Attn : {estimate?.userReu?.firstName + ' ' + estimate?.userReu?.lastName}</Text>
                    <Text>{estimate?.report?.project?.address}</Text>
                    <Text>{estimate?.report?.project?.address2}</Text>
                    <Text>{estimate?.report?.project?.city + ', ' + estimate?.report?.project?.state + ', ' + estimate?.report?.project?.zipCode}</Text>
                    <Text>USA</Text>
                    <Text>{estimate?.userReu?.phone}</Text>
                </Flex>
            </Flex>
            <Input readOnly border='none' backgroundColor='#8FCF74' width='100%' fontSize='18px' fontWeight='700'
                   color='#222222' value='Order Information' mt={10} fontFamily='Titillium Web'/>
            <Grid templateColumns='repeat(7, 1fr)' my={5} px={4}>
                <GridItem colSpan={3} as='h2' m={0}>
                    Description
                </GridItem>
                <GridItem colSpan={1} as='h2' m={0} textAlign='center'>
                    Part Number
                </GridItem>
                <GridItem colSpan={1} as='h2' m={0} textAlign='center'>
                    QTY
                </GridItem>
                <GridItem colSpan={1} as='h2' m={0} textAlign='center'>
                    Price Per Unit
                </GridItem>
                <GridItem colSpan={1} as='h2' m={0} textAlign='center'>
                    Amount
                </GridItem>
            </Grid>
            <Luminaires luminaires={luminaires}/>
            <Flex justifyContent='end' mt={7}>
                <Flex width='50%' fontWeight='700' border='2px solid #8FCF74' height='64px' alignItems='center' justifyContent='space-between' pl={6} pr={2} borderRadius='6px'>
                    <Input readOnly fontWeight='700' border='none' value='Sub Total:' fontSize='16px' fontFamily='Titillium Web'/>
                    <Flex justifyContent='end'>
                        <Input readOnly border='none' fontSize='14px' fontWeight='700' value={subTotal ? '$' + subTotal.toLocaleString('en-US') : 0}/>
                    </Flex>
                </Flex>
            </Flex>
            <Flex fontSize='18px !important' justifyContent='space-between'>
                <Flex width='50%' flexDirection='column'>
                    <Text color='#2C628D' mb={2} fontWeight='700'>Notes:</Text>
                    <Textarea value={notes} onChange={(e) => setNotes(e.target.value)} minH='130px'/>
                </Flex>
                <Flex flexDirection='column' color='#2C628D' mt={7}>
                    <Flex alignItems='center'>
                        <Text>Sales Tax:</Text>
                        <InputLabel
                            onChange={(e) => setSalesTax(e.target.value.slice(1))}
                            value={salesTax ? '$' + salesTax.toLocaleString('en-US') : '$'}
                            ml={5}
                            maxW='137px'/>
                    </Flex>
                    <Flex alignItems='center'>
                        <Text>Shipping:</Text>
                        <InputLabel
                            onChange={(e) => setShipping(e.target.value.slice(1))}
                            value={shipping ? '$' + shipping.toLocaleString('en-US') : '$'}
                            ml={5}
                            maxW='137px'/>
                    </Flex>
                    <Flex alignItems='center'>
                        <Text ml={7} fontWeight='700'>Total:</Text>
                        <InputLabel
                            readOnly
                            value={'$' + total.toLocaleString('en-US')}
                            ml={5}
                            style={{'border': '2px solid #8FCF74'}}
                            maxW='137px'/>
                    </Flex>
                </Flex>
            </Flex>
            <Flex justifyContent='space-between' alignItems='center' mt={10}>
                <Divider borderColor="#222222" border="2px solid" width='35%' orientation="horizontal"/>
                <Input readOnly border='none' fontSize='16px' value='SUPPLIER AUTHORIZATION' width='250px'/>
                <Divider width='35%' borderColor="#222222" border="2px solid" orientation="horizontal"/>
            </Flex>
            <Flex justifyContent='space-between' mt={10} fontWeight='700'>
                <Flex width='33%' alignItems='center'>
                    <Input border='none' readOnly fontSize='16px' fontWeight='600' width='120px' value='Print Name:' color='#2C628D'/>
                    <Input readOnly width='174px' border='1px solid #A9A9A9 !important' value={estimate?.userSu?.firstName + ' ' + estimate?.userSu?.lastName} fontWeight='700'/>
                </Flex>
                <Flex alignItems='center' width='33%' justifyContent='center' fontWeight='700'>
                    <Input border='none' readOnly fontSize='16px' fontWeight='600' width='120px' value='Signature:' color='#2C628D'/>
                    <Flex pr={2} pl={2} width='207px' alignItems='center' height='40px' justifyContent='space-between' borderRadius='6px' border='1px solid #A9A9A9'>
                        <Text maxW='200px' p={4}>
                            {imageURL ? (<Image objectFit='contain' src={imageURL} alt="Electronic signature"/>) : ('')}
                        </Text>
                        <Text onClick={estimate?.suApproved ? '' : onOpenSignature} as='span' cursor='pointer'>
                            <SignatureIcon/>
                        </Text>
                    </Flex>
                </Flex>
                <Flex alignItems='center' width='33%' justifyContent='end'>
                    <Input border='none' readOnly fontSize='16px' fontWeight='600' width='80px' value='Date:' color='#2C628D'/>
                    <Input fontWeight='700' readOnly width='174px' border='1px solid #A9A9A9 !important' value={estimate?.suApproved ? estimate.suSignatureDate : currentDate}/>
                </Flex>
            </Flex>
            <Flex display={user?.type === SU ? 'flex' : 'none'} mt={4} justifyContent='end'>
                <Button
                    isLoading={loading}
                    onClick={approveEstimate}
                    fontSize='14px'
                    leftIcon={estimate?.suApproved ? <DoneIcon/> : <AuthIcon/>}
                    width='170px'
                    color={estimate?.suApproved ? 'black' : 'white'}
                    backgroundColor={estimate?.suApproved ? '#80CC5F' : '#246B06'}>
                    <Input
                        p={0}
                        width='70px'
                        fontSize='14px'
                        cursor='pointer'
                        readOnly
                        border='none'
                        value={estimate?.suApproved ? 'Authorized' : 'Authorize'}/>
                </Button>
            </Flex>
        </Box>
    )
}

export default EstimateSuTab