import {ReactComponent as DoneIcon} from "../../../assets/Icons/DoneIcon.svg";
import {Button} from '@chakra-ui/react'

const DoneButton = ({isLoading}) => (
    <Button
        width='135px'
        bg='#C2EF62'
        color='black'
        type='submit'
        primary='true'
        isLoading={isLoading}
        leftIcon={<DoneIcon />}>
        Done
    </Button>
)

export default DoneButton