const companyUseCase = (companyRepo) => {
    const createCompany = (input) => companyRepo.createCompany(input) // NO VALIDATIONS NEED
    const updateCompany = (input) => {
        if (!input?.id) {
            throw Error('ID is required')
        }
        return companyRepo.updateCompany(input)
    }

    const deleteCompany = (id) => {
        if (!id) return
        return companyRepo.deleteCompany(id)
    }

    const removeUserFromCompany = (input) => {
        if (!input?.id) {
            throw Error('ID is required')
        }
        return companyRepo.removeUserFromCompany(input)
    }

    const getCompany = (id) => {
        if (!id) return
        return companyRepo.getCompany(id)
    }

    const confirmCompanySync = (input) => {
        if (!input.id) {
            throw Error('ID is required')
        }
        return companyRepo.confirmCompanySync(input)
    }

    const getCompanyChanges = (id) => {
        if (!id) {
            throw Error('ID is required')
        }
        return companyRepo.getCompanyChanges(id)
    }

    const listCompanys = (variables) => companyRepo.listCompanys(variables)

    const syncListCompanys = (variables) => companyRepo.syncListCompanys(variables)


    return {
        syncListCompanys,
        confirmCompanySync,
        removeUserFromCompany,
        createCompany,
        updateCompany,
        getCompany,
        getCompanyChanges,
        listCompanys,
        deleteCompany,
    }
}

export default companyUseCase
