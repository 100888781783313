import { useQuery } from 'react-query'
import { productUseCase } from '../../factories'

const useGetProduct = (id) => {
  const {
    refetch, isLoading, data, error,
  } = useQuery(['test', id], () => productUseCase.getProduct(id))

  return {
    refetch,
    isLoading,
    error,
    data,
  }
}

export default useGetProduct
