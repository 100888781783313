import {useState} from 'react'
import {Tr, Td, Button, Text, Flex} from '@chakra-ui/react'
import moment from 'moment'
import httpRequest from "../../core/services/http-request";
import {ReactComponent as AcceptIcon} from "../../assets/acceptNotifIcon.svg";
import {Link, useHistory} from 'react-router-dom'
import {NOTIF_TO_ACTIVITY_TYPES, NOTIFICATION_TYPES} from "../../constants/notification/index";
import {useNotificationToActivity, useUser} from "../../core/hooks";
import {SU} from "../../constants/user/userEnum";

const Notification = ({product, onOpen, setNotifViewMessage, getUse}) => {
    const history = useHistory()
    const {user} = useUser()
    const [loading, setLoading] = useState(false)
    const {notificationToActivity} = useNotificationToActivity()
    const {post} = httpRequest()

    const addUserToCompany = () => {
        if (product.company) {
            setLoading(true)
            const data = {
                notificationId: product.id,
                companyId: product.company.id,
            }
            post('accept-company-invite', data).then(() => {
                setLoading(false)
                history.push(`/profile`)
                window.location.reload()
            })
        }
    }

    const addUserToProject = async () => {
        setLoading(true)
        post('accept-invite-collegue', product?.project?.id).then((e) => {
            history.push(`/project/${e.data.data.id}`)
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })

    }

    const acceptProjectForBid = () => {
        setLoading(true)
        const data = {
            notificationId: product.id,
            projectId: product.project.id,
        }
        post('accept-project-for-bid-by-eu', data).then(() => {
            setLoading(false)
            window.location.reload()
            history.push(`/project/${product.project.id}`)
        })
    }

    const addAdminToCompany = () => {
        setLoading(true)
        if (user?.type === 'Reu'){
            return addUserToCompany()
        }
        const data = {
            notificationId: product.id,
            compId: product.company.id,
        }
        post('accept-company-by-admin', data).then(() => {
            setLoading(false)
            history.push(`/project/${product.project.id}`)
        })
    }

    const viewCompany = () => {
        notificationToActivity({
            type: NOTIF_TO_ACTIVITY_TYPES.COMPANY,
            id: product.id,
        }, {
            onSuccess: () => history.push(`/companies/${product.company.id}`)
        })
    }

    const viewReport = () => {
        notificationToActivity({
            type: NOTIF_TO_ACTIVITY_TYPES.REPORT,
            id: product.id,
        }, {
            onSuccess: () => history.push(`/projects/proposal/${product?.report?.proposalName}/${product?.project?.id}/${product?.report?.id}`)
        })
    }

    const viewProject = () => {
        notificationToActivity({
            type: NOTIF_TO_ACTIVITY_TYPES.PROJECT,
            id: product.id,
        }, {
            onSuccess: () => history.push(`/project/${product?.project?.id}`)
        })
    }

    const acceptProjectForReportBySales = () => {
        setLoading(true)
        const data = {
            notificationId: product.id,
            projectId: product.project.id
        }

        post('accept-project-for-sales', data).then(() => {
            setLoading(false)
            getUse()
            history.push(`/project/${product.project.id}`)
        })
    }

    const viewContactMessage = () => {
        post('disable-notif',product.id).then(() => getUse())
        setNotifViewMessage(product)
        onOpen()
    }

    const customClick = (status) => {
        switch (status){
            case NOTIFICATION_TYPES.COMPANY_INVATION:
                addUserToCompany()
                break
            case NOTIFICATION_TYPES.CUSTOM_PRICE_INVITE:
                notificationToActivity({
                    type: NOTIF_TO_ACTIVITY_TYPES.PROJECT,
                    reportId: product?.report?.id,
                    id: product.id,
                }, {
                    onSuccess: () => {
                        if (user?.type === SU){
                            history.push(`/project-supplier/${product?.project?.id}`)
                        } else {
                            history.push(`/project/${product?.project?.id}`)
                        }
                    }
                })
                break
            case NOTIFICATION_TYPES.PROJECT_INVATION:
                addUserToProject()
                break
            case NOTIFICATION_TYPES.PROJECT_INVATE_TO_BID:
                acceptProjectForBid()
                break
            case NOTIFICATION_TYPES.ADMIN_INVITE:
                acceptProjectForReportBySales()
                break
            case NOTIFICATION_TYPES.USER_SYNCED_BY_ADMIN:
                addAdminToCompany()
                break
            case NOTIFICATION_TYPES.PROJECT_ACCEPTED:
                viewProject()
                break
            case NOTIFICATION_TYPES.BID_ACCEPTED:
                viewProject()
                break
            case NOTIFICATION_TYPES.PROPOSAL_APPROVED:
                viewProject()
                break
            case NOTIFICATION_TYPES.COMPANY_ACCEPTED:
                viewCompany()
                break
            case NOTIFICATION_TYPES.UPDATED_COMPANY:
                viewCompany()
                break
            case NOTIFICATION_TYPES.COMPANY_SYNC_USER:
                post('disable-notif',product.id).then(() => getUse())
                history.push({
                    pathname: 'profile',
                    search: '?company=MyCompany'
                })
                break
            case NOTIFICATION_TYPES.ESTIMATE_INVITE_SU:
                post('disable-notif',product.id).then(() => getUse())
                history.push(`/orders/estimate/su/${product?.estimate?.id}/${status === NOTIFICATION_TYPES.CREATED_SHIPPING ? 3 : status === NOTIFICATION_TYPES.ORDER_AUTHORIZED ?  2 : 1}`)
                break
            case NOTIFICATION_TYPES.CREATED_SHIPPING:
                post('disable-notif',product.id).then(() => getUse())
                history.push(`/orders/estimate/su/${product?.estimate?.id}/${status === NOTIFICATION_TYPES.CREATED_SHIPPING ? 3 : status === NOTIFICATION_TYPES.ORDER_AUTHORIZED ?  2 : 1}`)
                break
            case NOTIFICATION_TYPES.ORDER_AUTHORIZED:
                post('disable-notif',product.id).then(() => getUse())
                history.push(`/orders/estimate/su/${product?.estimate?.id}/${status === NOTIFICATION_TYPES.CREATED_SHIPPING ? 3 : status === NOTIFICATION_TYPES.ORDER_AUTHORIZED ?  2 : 1}`)
                break
            case NOTIFICATION_TYPES.ESTIMATE_APPROVE_REU:
                post('disable-notif',product.id).then(() => getUse())
                history.push(`/orders/estimate/reu/${product?.estimateReu?.id}/${status === NOTIFICATION_TYPES.ESTIMATE_APPROVE_REU || status === NOTIFICATION_TYPES.ORDER_AUTHORIZED_REU  ?  2 : 1}`)
                break
            case NOTIFICATION_TYPES.ORDER_AUTHORIZED_REU:
                post('disable-notif',product.id).then(() => getUse())
                history.push(`/orders/estimate/reu/${product?.estimateReu?.id}/${status === NOTIFICATION_TYPES.ESTIMATE_APPROVE_REU || status === NOTIFICATION_TYPES.ORDER_AUTHORIZED_REU  ?  2 : 1}`)
                break
            case NOTIFICATION_TYPES.CREATED_COMPANY:
                post('disable-notif',product.id).then(() => getUse())
                history.push(`/companies/${product.company.id}`)
                break
            case NOTIFICATION_TYPES.COMPANY_INVATION_FOR_ADMIN:
                history.push(`/companies/${product.company.id}`)
                break
            case NOTIFICATION_TYPES.COMPANY_CONTACT:
                viewContactMessage()
                break
            case NOTIFICATION_TYPES.REPORT_DONE:
                viewReport()
                break
        }
    }

    return (
        <Tr display={product?.status === 'declined' ? 'none' : ''} bg="transparent" borderRadius="10px" boxShadow="2px 4px 10px rgba(0, 0, 0, 0.1)" bgColor='white'>
            <Td border="none" borderRadius="10px 0 0 10px">
                <Flex>
                    <img src="../icons/invite.svg" alt="invite_icon"/>
                    <Text ml={6}>
                        {product?.description}&nbsp;
                        {
                            (product?.project || product?.company) && (
                                <Link style={{fontWeight: '600', fontSize: '16px'}}
                                    onClick={() => {
                                        if (product.status === NOTIFICATION_TYPES.REPORT_DONE){
                                            return history.push(`/projects/proposal/${product?.report?.proposalName}/${product?.project?.id}/${product?.report?.id}`)
                                        }
                                        if (product.project) {
                                            history.push(`/project/project-details/${product.project.id}`)
                                        } else {
                                            history.push(`/companies/company-details/${product.company.id}`)
                                        }
                                    }}>
                                    {product.address}
                                </Link>
                            )
                        }
                        {product?.status === NOTIFICATION_TYPES.ADMIN_INVITE && ' to you!'}
                    </Text>
                </Flex>
            </Td>
            <Td border="none" borderRadius="0 10px 10px 0" isNumeric>
                <Text mr={6} display="inline-block">
                    {moment(product?.createdAt.date).format('MMM DD, YYYY')}
                </Text>
                <Button
                    ml="4"
                    isLoading={loading}
                    width="120px"
                    leftIcon={<AcceptIcon/>}
                    height="30px"
                    backgroundColor="#8FCF74"
                    color="black"
                    onClick={() => customClick(product.status)}>
                    {
                        product.status === NOTIFICATION_TYPES.PROJECT_INVATION || product.status === NOTIFICATION_TYPES.ADMIN_USER_INVITE ? (
                            'Accept'
                        ) : product.status === NOTIFICATION_TYPES.COMPANY_INVATION  || product.status === NOTIFICATION_TYPES.COMPANY_SYNC_USER  || product.status === NOTIFICATION_TYPES.USER_SYNCED_BY_ADMIN || product.status === NOTIFICATION_TYPES.COMPANY_ACCEPTED || product.status === NOTIFICATION_TYPES.CREATED_COMPANY || product.status === NOTIFICATION_TYPES.COMPANY_INVATION_FOR_ADMIN || product.status === NOTIFICATION_TYPES.UPDATED_COMPANY ? (
                            'View Company'
                        ) : product.status === NOTIFICATION_TYPES.ESTIMATE_INVITE_SU || product.status === NOTIFICATION_TYPES.ESTIMATE_APPROVE_REU || product.status === NOTIFICATION_TYPES.ORDER_AUTHORIZED || product.status === NOTIFICATION_TYPES.ORDER_AUTHORIZED_REU || product.status === NOTIFICATION_TYPES.CREATED_SHIPPING ? (
                            'View Order'
                        ) : product.status === NOTIFICATION_TYPES.ADMIN_INVITE || product.status === NOTIFICATION_TYPES.CUSTOM_PRICE_INVITE || product.status === NOTIFICATION_TYPES.PROJECT_INVATE_TO_BID || product.status === NOTIFICATION_TYPES.COMPANY_DECLINED || product.status === NOTIFICATION_TYPES.PROJECT_ACCEPTED || product.status === NOTIFICATION_TYPES.BID_ACCEPTED || product.status === NOTIFICATION_TYPES.PROPOSAL_APPROVED ? (
                            'View Project'
                        ) :product.status === NOTIFICATION_TYPES.COMPANY_CONTACT ? (
                            'View Message'
                        ) :product.status === NOTIFICATION_TYPES.REPORT_DONE  ? (
                            'View Report'
                        ) : (
                            ''
                        )
                    }
                </Button>
            </Td>
        </Tr>
    )
}

export default Notification
