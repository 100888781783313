import {useEffect, useState} from 'react'
import {Box, Heading, Text, Flex, List, ListItem,} from '@chakra-ui/react'
import {ChartForecast, CustomDivider} from '../../components/Theme'
import TableProjectFinancing from "../../components/Theme/TableProjectFinancing";

const Financing = ({project, proposal, projectTotal,totalLoan,setTotalLoan, energySaved, exisiting, led, setTotalLoanPdf, setFinanced, yearOneLed, yearOneExisting}) => {
    const [ledFinanced, setLedFinanced] = useState([])

    useEffect(() => {
        if (projectTotal === 0) return
        let total = 0
        let arr = []
        total = (((projectTotal - project.depositAmount) / 100) * project.interestRate) + (projectTotal - project.depositAmount)
        setTotalLoan(total)
        setTotalLoanPdf(total)
        let principalLoanAmount = projectTotal - Number(project.depositAmount)
        let interestDue = principalLoanAmount * Number(project.interestRate)/100
        let totalLoanAmount = principalLoanAmount + interestDue
        let durationYears = Number(project.loanPeriod) / 12
        let annualAmountPaidYear = (totalLoanAmount / durationYears)
        arr.push(project.depositAmount)
        for (let i = 1; i <= 10; i++) {
            if (i < durationYears){
                arr.push(Math.round((yearOneLed+annualAmountPaidYear)*i))
            } else {
                arr.push(Math.round(yearOneLed*i))
            }
        }
        setLedFinanced(arr)
        setFinanced(arr)
    }, [projectTotal])

    return (
        <>
            <Box>
                <Heading fontSize="3xl">Financing Estimates</Heading>
                <Text fontSize="md">
                    Loan estimates are based on approximated rates in order to demonstrate alternative payment methods.
                    Exact details regarding any financing arrangement must be approved by a lending entity.
                </Text>
            </Box>
            <CustomDivider border='2px'/>
            <Flex flexWrap="wrap" alignItems="center" justifyContent="space-between">
                <List>
                    <ListItem fontSize="sm" display="flex" alignItems='center' justifyContent="flex-end">
                        Investment Total:
                        <Flex ml={2} alignItems="center" justifyContent="center" height="23px" width="83px" bg="#FFE68D">
                            ${projectTotal?.toLocaleString('en-US')}
                        </Flex>
                    </ListItem>
                    <ListItem fontSize="sm" alignItems='center' display="flex" justifyContent="flex-end">
                        Deposit:
                        <Flex ml={2} alignItems="center" justifyContent="center" height="23px" width="83px" bg="rgba(255, 230, 141, 0.37)">
                            ${project?.depositAmount?.toLocaleString('en-US')}
                        </Flex>
                    </ListItem>
                    <ListItem fontSize="sm" alignItems='center' display="flex" justifyContent="flex-end">
                        Principle Loan Amount:
                        <Flex ml={2} alignItems="center" justifyContent="center" height="23px" width="83px" bg="#FFE68D">
                            ${(projectTotal - (project?.depositAmount ? project?.depositAmount : 0)).toLocaleString('en-US')}
                        </Flex>
                    </ListItem>
                    <ListItem fontSize="sm" alignItems='center' display="flex" justifyContent="flex-end">
                        Rate:
                        <Flex ml={2} alignItems="center" justifyContent="center" height="23px" width="83px" bg="rgba(255, 230, 141, 0.37)">
                            {project?.interestRate} %
                        </Flex>
                    </ListItem>
                    <ListItem fontSize="sm" alignItems='center' display="flex" justifyContent="flex-end">
                        Duration:
                        <Flex ml={2} alignItems="center" justifyContent="center" height="23px" width="83px" bg="#FFE68D">
                            {project?.loanPeriod} mo
                        </Flex>
                    </ListItem>
                    <ListItem fontSize="sm" alignItems='center' display="flex" justifyContent="flex-end">
                        Total Loan Amount:
                        <Flex fontWeight='600' ml={2} alignItems="center" justifyContent="center" height="23px" width="83px" bg="green.100">
                            ${totalLoan?.toLocaleString('en-US')}
                        </Flex>
                    </ListItem>
                </List>
                <TableProjectFinancing
                    width="572px"
                    led={led}
                    yearOneLed={yearOneLed}
                    yearOneExisting={yearOneExisting}
                    totalLoan={totalLoan}
                    project={project}
                    proposal={proposal}
                    energySaved={energySaved}/>
            </Flex>
            <CustomDivider border='2px'/>
            <Box>
                <Heading fontSize="lg">Financing Forecast</Heading>
                <Flex height="100%" width="100%" alignItems="center" flexDirection="column">
                    <ChartForecast
                        ledFinanced={ledFinanced}
                        existing={exisiting}
                        led={led}
                        projectTotal={projectTotal}/>
                    <Heading fontSize='md' m={0}>
                        Years
                    </Heading>
                    <Text>
                        Note: Loan forecasts represent estimates calculated to better facilitate illumination system
                        upgrades.
                    </Text>
                </Flex>
            </Box>
        </>
    )
}

export default Financing
