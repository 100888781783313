import {
    Box,
    Flex,
    Text,
    Button,
    Modal,
    ModalOverlay,
    Checkbox,
    ModalContent,
    ModalHeader,
    ModalBody,
    Divider,
} from '@chakra-ui/react'
import {AREAS_STATES} from "../../../constants/serviceableAreas"
import React from "react";
import Icon from "../../../components/Theme/Icon";
import IconButton from "../../../components/Theme/IconButton";
import CustomSelectAreas from "./CustomSelectAreas";

const ServiceableAreas = ({isOpen, onClose, onOpen, serviceArea, setServiceArea,}) => (
    <Box>
        <Modal
            isCentered
            isOpen={isOpen}
            onClose={onClose}>
            <ModalOverlay/>
            <ModalContent
                overflow="hidden"
                borderRadius="10px"
                minW="587px">
                <ModalHeader
                    backgroundColor="#FFE68D"
                    height="70px"
                    textAlign="center">
                    <Text
                        fontWeight="700"
                        fontSize="24px"
                        m={0}
                        as="h2">
                        Serviceable Areas
                    </Text>
                </ModalHeader>
                <IconButton
                    height="unset"
                    onClick={onClose}
                    _hover={{
                        bg: 'none',
                    }}
                    _active={{
                        bg: 'none',
                    }}
                    bg="none"
                    aria-label="close button"
                    position="absolute"
                    top="1.5rem"
                    right="0.75rem"
                    icon={<Icon type="darkClose"/>}/>
                <ModalBody
                    overflow="scroll"
                    maxH="600px">
                    <Flex
                        mt={6}
                        justifyContent="flex-end">
                        <Button
                            type="submit"
                            onClick={onClose}
                            w="135px"
                            h="40px"
                            backgroundColor="#8FCF74">
                            <Text
                                fontWeight="700"
                                fontSize="12px"
                                ml={1}
                                color="black"
                                mt="2px">
                                SAVE
                            </Text>
                        </Button>
                    </Flex>
                    <Text
                        as="h2"
                        color="#2C628D">
                        Serviceable Areas - Counties
                    </Text>
                    <Box
                        minH='450px'
                        border="1px solid"
                        borderRadius="6px 6px 0px 0px"
                        p="20px"
                        borderColor="#A9A9A9">
                        <Box
                            mb={3}>
                            <Checkbox
                                onChange={(e) => {
                                    for (let i = 0; i < AREAS_STATES.length; i++) {
                                        for (let j = 0; j < AREAS_STATES[i].child.length; j++) {
                                            AREAS_STATES[i].child[j].value = e.target.checked
                                        }
                                    }
                                    setServiceArea(AREAS_STATES)
                                }}>
                                Select All Counties For All States
                            </Checkbox>
                        </Box>
                        <CustomSelectAreas
                            repeat={3}
                            value={serviceArea}
                            options={AREAS_STATES}
                            setValue={setServiceArea}
                            label="Select Areas"/>
                        {
                            serviceArea?.length > 0 && serviceArea.map((item) => (
                                <>
                                    {
                                        <>
                                            <Flex mt={5}>
                                                <Checkbox
                                                    onChange={(e) => {
                                                        item.isClose = e.target.checked
                                                        serviceArea = serviceArea.concat([])
                                                        setServiceArea(serviceArea)
                                                    }}>
                                                    <Text
                                                        w='max-content'
                                                        fontWeight="700"
                                                        fontSize="18px">
                                                        {item.name}
                                                    </Text>
                                                </Checkbox>
                                                <Divider
                                                    mt={3}
                                                    ml={5}
                                                    borderColor="#000000"
                                                    border="1px solid"
                                                    orientation="horizontal"/>
                                            </Flex>
                                            <Flex
                                                display={item.isClose ? 'none' : 'flex'}>
                                                <Divider
                                                    mt={3}
                                                    orientation="vertical"
                                                    height='auto'
                                                    borderColor="#000000"
                                                    borderLeft="2px solid"/>
                                                <Flex
                                                    ml={5}
                                                    mt={3}
                                                    flexDirection="column"
                                                    justifyContent="space-between">
                                                    <Checkbox
                                                        value={item.name}
                                                        onChange={(e) => {
                                                            for (let i = 0; i < item.child.length; i++) {
                                                                item.child[i].value = e.target.checked
                                                            }
                                                            serviceArea = serviceArea.concat([])
                                                            setServiceArea(serviceArea)
                                                        }}>
                                                        Select All Counties For {item.name}
                                                    </Checkbox>
                                                    {
                                                        item.child.map((names, idx) => (
                                                            <>
                                                                <Checkbox
                                                                    key={idx}
                                                                    name={idx}
                                                                    id={idx}
                                                                    value={names.name}
                                                                    isChecked={names.value}
                                                                    onChange={(e) => {
                                                                        names.value = e.target.checked
                                                                        serviceArea = serviceArea.concat([])
                                                                        setServiceArea(serviceArea)
                                                                    }}>
                                                                    {names.name}
                                                                </Checkbox>
                                                            </>
                                                        ))
                                                    }
                                                </Flex>
                                            </Flex>
                                        </>
                                    }
                                </>
                            ))
                        }
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    </Box>
)

export default ServiceableAreas