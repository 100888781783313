import {Box, Button, Flex, Text, Heading} from "@chakra-ui/react";
import CompanyModal from "../../EditCompany/Modals/CompanyModal";
import {ReactComponent as Edit} from "../../../assets/edit.svg";
import {ReactComponent as SyncIcon} from "../../../assets/Icons/syncIconWhite.svg";
import {CustomDivider, FileComponent, InputLabel, Specialities} from "../../../components/Theme";
import ChartCompany from "../../../components/Theme/ChartCompany";
import CompanyViewHeader from "./CompanyViewHeader";
import CompanyViewDetails from "./CompanyViewDetails";
import {AU, SA} from "../../../constants/user/userEnum";


const CompanyTab = ({company, onOpen,confirmSyncCompany, scoreCompany, user, areas, companySyncType, isLoading}) => (
    <Box w='full'>
        <Flex
            alignItems="center"
            position="absolute"
            right="0"
            display={company?.status === 1 ? 'none' : 'flex'}
            top="-90px">
            {
                companySyncType ? (
                    <Button
                        isLoading={isLoading}
                        onClick={confirmSyncCompany}
                        leftIcon={<SyncIcon fill='white'/>}
                        w="135px"
                        display={user?.type === AU || user?.type === SA ? 'none' : 'flex'}
                        bg={'#2C790B'}
                        mr={5}>
                        Confirm Sync
                    </Button>
                ) : <CompanyModal
                    display={user?.type === AU || user?.type === SA ? 'none' : 'flex'}
                    company={company}/>
            }
            <Button
                onClick={onOpen}
                leftIcon={<Edit/>}
                w="135px"
                display={user?.type === AU || user?.type === SA ? 'none' : 'flex'}
                bg={companySyncType ? '#CFCFCF' : '#FF7F56AB'}
                color='black'
                disabled={companySyncType}>
                Edit Company
            </Button>
        </Flex>
        <CompanyViewHeader
            company={company}/>
        <CustomDivider/>
        <CompanyViewDetails
            company={company}/>
        <CustomDivider/>
        <Heading fontSize="xl">Property Types</Heading>
        <Flex
            alignItems="center"
            justifyContent="space-between">
            <Specialities
                plus={false}
                score={scoreCompany}/>
            <ChartCompany
                score={scoreCompany}/>
        </Flex>
        <CustomDivider/>
        <Box
            display={user.type === 'Eu' ? 'block' : 'none'}>
            <Text
                as="h2"
                display={user.type === 'Eu' ? 'inline-flex' : 'none'}
                fontWeight="600"
                mb={10}
                fontSize="18px">
                Serviceable Areas:
            </Text>
        </Box>
        <Text
            display={user.type === 'Eu' ? 'inline-flex' : 'none'}
            color="#2C628D">
            Serviceable Areas - States:
        </Text>
        <InputLabel
            mb={20}
            display={user.type === 'Eu' ? 'inline-flex' : 'none'}
            disabled
            value={areas?.map((item) => item.name)}/>
        {
            company?.files?.length > 0 &&
            <FileComponent
                downloads={company?.files}/>
        }
    </Box>
)

export default CompanyTab