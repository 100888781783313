import {useQuery} from 'react-query'
import { reportUseCase } from '../../factories'

const useGetReport = (id) => {
    const {
        refetch, isLoading, data, error,
    } = useQuery(['report', id], () => reportUseCase.getReport(id))

    return {
        refetch,
        isLoading,
        error,
        data,
    }
}

export default useGetReport
