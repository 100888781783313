import {Grid, GridItem} from "@chakra-ui/react";

const Luminaires = ({luminaires}) => (
    luminaires?.length > 0 && luminaires?.map((item, index) =>
        <Grid templateColumns='repeat(7, 1fr)' key={index} px={4} border='1px solid #A9A9A9' borderRadius='6px' minH='64px' alignItems='center' mb={5}>
            <GridItem colSpan={3}>
                {item?.lampRecomendedAirtable?.length > 0 ? JSON.parse(item.lampRecomendedAirtable)['Description'] : JSON.parse(item.fixtureRecomendedAirtable)['FE Product Description']}
            </GridItem>
            <GridItem colSpan={1} textAlign='center'>
                {item?.lampRecomendedAirtable?.length > 0 ? JSON.parse(item.lampRecomendedAirtable)['FE Order Code'] : JSON.parse(item.fixtureRecomendedAirtable)['FE Order Code']}
            </GridItem>
            <GridItem colSpan={1} textAlign='center'>
                {((item.recomendedLampQty ? Number(item.recomendedLampQty) : (item.lampPerFixture ? item.lampPerFixture : 1)) * (item.recomendedFixtureQty ? Number(item.recomendedFixtureQty) : (item.qty ? Number(item.qty) : 1))).toLocaleString('en-US')}
            </GridItem>
            <GridItem colSpan={1} textAlign='center'>
                {
                    item?.lampRecomendedAirtable?.length > 0 && JSON.parse(item.lampRecomendedAirtable)['SU PPU ($) Custom FE'] ? '$'+JSON.parse(item.lampRecomendedAirtable)['SU PPU ($) Custom FE'] :  item.fixtureRecomendedAirtable && JSON.parse(item.fixtureRecomendedAirtable)['SU PPU ($) Custom FE'] ? '$'+JSON.parse(item.fixtureRecomendedAirtable)['SU PPU ($) Custom FE'] :
                    ((item.recomendedLampQty ? Number(item.recomendedLampQty) : (item.lampPerFixture ? item.lampPerFixture : 1)) * (item.recomendedFixtureQty ? Number(item.recomendedFixtureQty) : (item.qty ? Number(item.qty) : 1))) >= 100 &&
                    ((item.recomendedLampQty ? Number(item.recomendedLampQty) : (item.lampPerFixture ? item.lampPerFixture : 1)) * (item.recomendedFixtureQty ? Number(item.recomendedFixtureQty) : (item.qty ? Number(item.qty) : 1))) < 200 ?
                        (item?.lampRecomendedAirtable?.length > 0 ? '$' + (JSON.parse(item.lampRecomendedAirtable)['FE PPU Vol1 ($)'] ? JSON.parse(item.lampRecomendedAirtable)['FE PPU Vol1 ($)']?.replace(/[^\d.]/g, ''): 1) : '$' + (JSON.parse(item.fixtureRecomendedAirtable)['FE PPU Vol1 ($)'] ? JSON.parse(item.fixtureRecomendedAirtable)['FE PPU Vol1 ($)']?.replace(/[^\d.]/g, '') : 1))
                        : ((item.recomendedLampQty ? Number(item.recomendedLampQty) : (item.lampPerFixture ? item.lampPerFixture : 1)) * (item.recomendedFixtureQty ? Number(item.recomendedFixtureQty) : (item.qty ? Number(item.qty) : 1))) >= 200 ?
                            (item?.lampRecomendedAirtable?.length > 0 ? '$' + (JSON.parse(item.lampRecomendedAirtable)['FE PPU Vol2 ($)'] ? JSON.parse(item.lampRecomendedAirtable)['FE PPU Vol1 ($)']?.replace(/[^\d.]/g, ''): 1) : '$' +  (JSON.parse(item.fixtureRecomendedAirtable)['FE PPU Vol2 ($)'] ? JSON.parse(item.fixtureRecomendedAirtable)['FE PPU Vol2 ($)']?.replace(/[^\d.]/g, '') : 1))
                            : item?.lampRecomendedAirtable?.length > 0 ? '$'+parseFloat(JSON.parse(item.lampRecomendedAirtable)['FE PPU ($)']?.toString()?.replace(/[^\d.]/g, ''))?.toFixed(2) : '$'+parseFloat(JSON.parse(item.fixtureRecomendedAirtable)['FE PPU ($)']?.toString().replace(/[^\d.]/g, ''))?.toFixed(2)
                }
            </GridItem>
            <GridItem colSpan={1} textAlign='center'>
                {'$' + Math.round(Number(
                        item?.lampRecomendedAirtable?.length > 0 && JSON.parse(item.lampRecomendedAirtable)['SU PPU ($) Custom FE'] ? JSON.parse(item.lampRecomendedAirtable)['SU PPU ($) Custom FE'] :  item.fixtureRecomendedAirtable && JSON.parse(item.fixtureRecomendedAirtable)['SU PPU ($) Custom FE'] ? JSON.parse(item.fixtureRecomendedAirtable)['SU PPU ($) Custom FE'] :
                        ((item.recomendedLampQty ? Number(item.recomendedLampQty) : (item.lampPerFixture ? item.lampPerFixture : 1)) * (item.recomendedFixtureQty ? Number(item.recomendedFixtureQty) : (item.qty ? Number(item.qty) : 1))) >= 100 &&
                        ((item.recomendedLampQty ? Number(item.recomendedLampQty) : (item.lampPerFixture ? item.lampPerFixture : 1)) * (item.recomendedFixtureQty ? Number(item.recomendedFixtureQty) : (item.qty ? Number(item.qty) : 1))) < 200 ?
                            (item?.lampRecomendedAirtable?.length > 0 ? (JSON.parse(item.lampRecomendedAirtable)['FE PPU Vol1 ($)'] ? JSON.parse(item.lampRecomendedAirtable)['FE PPU Vol1 ($)']?.replace(/[^\d.]/g, ''): 1) : (JSON.parse(item.fixtureRecomendedAirtable)['FE PPU Vol1 ($)'] ? JSON.parse(item.fixtureRecomendedAirtable)['FE PPU Vol1 ($)']?.replace(/[^\d.]/g, '') : 1))
                            : ((item.recomendedLampQty ? Number(item.recomendedLampQty) : (item.lampPerFixture ? item.lampPerFixture : 1)) * (item.recomendedFixtureQty ? Number(item.recomendedFixtureQty) : (item.qty ? Number(item.qty) : 1))) >= 200 ?
                                (item?.lampRecomendedAirtable?.length > 0 ? (JSON.parse(item.lampRecomendedAirtable)['FE PPU Vol2 ($)'] ? JSON.parse(item.lampRecomendedAirtable)['FE PPU Vol1 ($)']?.replace(/[^\d.]/g, ''): 1) : (JSON.parse(item.fixtureRecomendedAirtable)['FE PPU Vol2 ($)'] ? JSON.parse(item.fixtureRecomendedAirtable)['FE PPU Vol2 ($)']?.replace(/[^\d.]/g, '') : 1))
                                : item?.lampRecomendedAirtable?.length > 0 ? parseFloat(JSON.parse(item.lampRecomendedAirtable)['FE PPU ($)']?.toString()?.replace(/[^\d.]/g, ''))?.toFixed(2) : parseFloat(JSON.parse(item.fixtureRecomendedAirtable)['FE PPU ($)']?.toString().replace(/[^\d.]/g, ''))?.toFixed(2)
                    )
                    * (item.recomendedLampQty ? Number(item.recomendedLampQty) : (item.lampPerFixture ? item.lampPerFixture : 1)) * (item.recomendedFixtureQty ? Number(item.recomendedFixtureQty) : (item.qty ? Number(item.qty) : 1))).toLocaleString('en-US')}
            </GridItem>
        </Grid>
    )
)

export default Luminaires