import React, {useEffect, useMemo, useState} from 'react'
import {Link, useHistory, useLocation} from 'react-router-dom'
import {
    Text,
    Heading,
    SimpleGrid,
    GridItem,
    Flex,
    Button,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverBody,
    Box,
    InputGroup,
    InputLeftElement,
    Input,
} from '@chakra-ui/react'
import {useListProducts} from '../../core/hooks'
import {Card, Checkbox, Icon,} from '../../components/Theme'
import {ReactComponent as Search} from "../../assets/search.svg";
import Navbar from "../../components/Navbar";
import Loading from "../../components/Loading";
import httpRequest from "../../core/services/http-request";

const FILTERS = [
    'Fixture',
    'Lamp',
    'Power-Supply',
    'Control',
    'Accessory',
]

const AllProducts = () => {
    const [allProducts, setAllProducts] = useState()
    const [filter, setFilter] = useState([])
    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState(false)
    const location = useLocation()
    const {post} = httpRequest()
    const history = useHistory()

    const getLists = () => {
        let condition = {
            condition: {
                table: filter.length === 0 ? 'All' : filter,
                filter: search,
            }
        }
        setLoading(true)
        post('product-list', condition).then((res) => {
            setAllProducts(res.data.data)
            setLoading(false)
        }).catch(() => {
            setLoading(false)
            return []
        })
    }

    useMemo(() => {
        if (search?.length > 2 || search.length === 0){
            setTimeout(() => {
                getLists()
            },1000)
        }
    },[search])

    const handleCheckbox = (e) => {
        if (filter.includes(e)) {
            return setFilter(filter.filter((name) => name !== e))
        }
        setFilter([...filter, e])
    }

    useEffect(() => {
        getLists()
    }, [filter])

    return (
        <>
            <Navbar title={(
                <Flex>
                    <Link as='h2' m={0} to="/catalogues">Catalogues</Link>
                    <Text as='h2' m={0} textTransform="capitalize">&nbsp;/{' '}All Products</Text>
                </Flex>
            )}>
                <Flex>
                    {location.pathname === '/catalogues/all-products' && (
                        <Popover>
                            <PopoverTrigger>
                                <Button leftIcon={<Icon type="filter"/>} mr={12} variant="lightBlue" size="sm"
                                        minW="139px">
                                    <Text ml={2} fontSize="sm">Filter</Text>
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent bg="white" zIndex="500" p={6} w="190px" pr={0} flexDirection="row" _focus={{outline: 'none'}}>
                                <PopoverArrow/>
                                <PopoverBody
                                    display="flex"
                                    flexDirection="column">
                                    {FILTERS.map((item) => (
                                        <Checkbox key={item} isDisabled={loading} onChange={(e) => handleCheckbox(e.target.name)} name={item} colorScheme="black" iconColor="black">
                                            <Box ml={2} fontSize="sm" as="span">{item}</Box>
                                        </Checkbox>
                                    ))}
                                </PopoverBody>
                            </PopoverContent>
                        </Popover>
                    )}
                    <InputGroup alignItems="center" height="29px" borderRadius="5px" _focus={{borderColor: 'blue.200',}} _hover={{borderColor: 'blue.200',}} borderColor="blue.200" bg="blue.200">
                        <InputLeftElement height="29px" pointerEvents="none" children={(<Search height="16px" width="16px"/>)}/>
                        <Input onChange={(e) => setSearch(e.target.value)} _focus={{borderColor: 'blue.200',}} _hover={{borderColor: 'blue.200',}} height="29px" color="white.100" placeholder="Search"/>
                    </InputGroup>
                </Flex>
            </Navbar>
                <SimpleGrid p="12" w="full" columns={{base: 1, sm: 2, md: 3, lg: 4,}} spacing="8">
                    {allProducts?.map((product, index) => (
                        <GridItem
                            onClick={() => history.push(product.type === 'Accessories' || product.type === 'Controls' || product.type === 'Power Supplies' ? `/catalogues/accessory/${product.type}/${product.id}` : `/catalogues/product/${product.type}/${product.id}`)}
                            colSpan={1}
                            key={index}>
                            <Card
                                image={product['fields']['Image'] ? product['fields']['Image'][0]?.url : ''}>
                                <Heading
                                    fontSize="md"
                                    as="h3">
                                    {product['fields']['Manufacturer']}
                                </Heading>
                                <Text
                                    fontSize="sm">
                                    {product['fields']['FE Description'] ? product['fields']['FE Description'] : product['fields']['Description']}
                                </Text>
                            </Card>
                        </GridItem>
                    ))}
                </SimpleGrid>
        </>
    )
}

export default AllProducts
