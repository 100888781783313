import React, {useState, useEffect, forwardRef} from "react";
import {
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    Flex,
    Button,
    Divider,
    Box,
} from "@chakra-ui/react";
import IconButton from "../../../components/Theme/IconButton";
import Icon from "../../../components/Theme/Icon";
import {InputLabel} from "../../../components/Theme";
import httpRequest from "../../../core/services/http-request";
import CustomSpinner from '../../../components/Spinner/CustomSpinner';
import styles from "./styles";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, {CalendarContainer} from "react-datepicker";
import {useUser} from "../../../core/hooks";
import {ReactComponent as ClearDate} from "../../../assets/clearDateIcon.svg";
import {ReactComponent as LeftArrow} from "../../../assets/leftArrowIcon.svg";
import {ReactComponent as RightArrow} from "../../../assets/rightArrowIcon.svg";
import {ReactComponent as CalendarIcon} from "../../../assets/calendarIconSmall.svg";
import {getMonth, getYear} from "date-fns";
import moment from "moment/moment";

const InstallationBidModal = ({isOpen, onClose, project, reportId, item}) => {
    const {user} = useUser()
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const {post} = httpRequest()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [labor, setLabor] = useState('')
    const [materials, setMaterials] = useState('')
    const [euBidId, setEuBidId] = useState('')
    const [permits, setPermits] = useState('')
    const [tax, setTax] = useState('')
    const [disposal, setDisposal] = useState('')
    const [total, setTotal] = useState('')
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December",];

    const RenderCustomInput = forwardRef(({value, onClick}, ref) => (
        <Flex
            border='1px solid black'
            onClick={onClick}
            ref={ref}
            position='relative'
            backgroundColor='white'
            _active=''
            _focus=''
            w='full'
            borderColor='#A9A9A9'
            mt={2}
            cursor='auto'
            height='40px'
            borderRadius='6px'
            alignItems='center'>
            {
                item?.euBids.filter((filtered) => filtered.user.id === user.id) && !value ? (
                    item?.euBids.filter((filtered) => filtered.user.id === user.id)[0] ? item?.euBids.filter((filtered) => filtered.user.id === user.id)[0].availability[0] + '-' + item?.euBids.filter((filtered) => filtered.user.id === user.id)[0].availability[1] : item.availability
                ) : (value)
            }
            <Text
                position='absolute'
                right='10px'>
                <CalendarIcon/>
            </Text>
        </Flex>
    ));

    function pad(d) {
        return (d < 10) ? '0' + d.toString() : d;
    }

    const renderCustomDays = e => pad(e)

    const renderCustomContainer = ({className, children}) => {
        return (
            <Flex
                width='320px'
                justifyContent='center'
                css={styles.calendarContainer}>
                <CalendarContainer className={className}>
                    <Flex>
                        <Text
                            fontSize='18px'
                            fontWeight='700'>
                            Select Date(s) Below
                        </Text>
                        <Button
                            leftIcon={<ClearDate/>}
                            onClick={() => setDateRange([null, null])}
                            height='30px'
                            backgroundColor='#D1D1D1'
                            color='black'
                            fontSize='12px'>
                            Clear Dates
                        </Button>
                    </Flex>
                    <Box
                        position='relative'
                        p='15px'
                        width='320px'>
                        {children}
                    </Box>
                </CalendarContainer>
            </Flex>
        );
    };

    useEffect(() => {
        // Calculate Total Price
        const timer = setTimeout(() => {
            setTotal((labor ? parseInt(labor) : 0) + (materials ? parseInt(materials) : 0) + (permits ? parseInt(permits) : 0) + (tax ? parseInt(tax) : 0) + (disposal ? parseInt(disposal) : 0))
        }, 1000);
        return () => clearTimeout(timer);
    }, [labor, materials, permits, tax, disposal])

    const saveTotal = () => {
        const id = project.id
        if (!total) return setError('Set all prices')
        if (!startDate && !endDate) return setError('Set date')
        setError('')
        let aDay = Number(moment(dateRange[0]).utc().format('D')) + 1
        let aMonth = moment(dateRange[0]).utc().format('MM')
        let aYear = moment(dateRange[0]).utc().format('YYYY')
        let startDatA = aMonth + '.' + aDay + '.' + aYear
        let bDay = Number(moment(dateRange[1]).utc().format('D')) + 1
        let bMonth = moment(dateRange[1]).utc().format('MM')
        let bYear = moment(dateRange[1]).utc().format('YYYY')
        let startDatB = bMonth + '.' + bDay + '.' + bYear
        let arr = [startDatA, startDatB]
        setLoading(true)
        const data = {
            bidId: euBidId ? euBidId : '',
            projectId: id,
            euId: user.id,
            labor: labor.toString(),
            materials: materials.toString(),
            permits: permits.toString(),
            tax: tax.toString(),
            disposal: disposal.toString(),
            total: total.toString(),
            reportId: reportId,
            date: arr,
            companyName: user?.company[0]?.name
        }
        post('send-bid-from-eu-to-admin', data).then(function () {
            setLoading(false)
            window.location.reload()
            onClose()
        })
    }
    useEffect(() => {
        if (item && item.euBids && item.euBids.length > 0) {
            const euB = item.euBids.find(item => item.user.id === user.id);
            if (euB) {
                setEuBidId(euB.id)
                setLabor(euB.labor)
                setMaterials(euB.materials)
                setPermits(euB.permits)
                setTax(euB.tax)
                setDisposal(euB.disposal)
            }
        } else {
            setLabor(null)
            setMaterials(null)
            setPermits(null)
            setTax(null)
            setDisposal(null)
        }
    }, [item])

    return (
        <>
            <Modal
                isCentered
                isOpen={isOpen}
                onClose={onClose}>
                <ModalOverlay/>
                <ModalContent
                    borderRadius="10px"
                    minW="992px"
                    pb={8}>
                    <ModalHeader
                        bg='#FFE68D'
                        fontWeight="700"
                        fontStyle='normal'
                        fontSize="24px"
                        textAlign="center">
                        Installation Bid:
                    </ModalHeader>
                    <IconButton
                        height="unset"
                        onClick={onClose}
                        _hover={{
                            bg: 'none',
                        }}
                        _active={{
                            bg: 'none',
                        }}
                        bg="none"
                        aria-label="close button"
                        position="absolute"
                        top="1.5rem"
                        right="0.75rem"
                        icon={<Icon type="darkClose"/>}/>
                    <ModalBody
                        pt='50px'
                        pl='60px'
                        pr='60px'
                        pb='60px'
                        overflow="scroll"
                        maxH="600px">
                        <Text
                            fontSize='18px'>
                            Add information (below) to create an installation bid for this project:
                        </Text>
                        <Flex
                            justifyContent='space-between'
                            mt={10}>
                            <InputLabel
                                maxW='257px'
                                label='Labor'
                                type='number'
                                placeholder='0'
                                value={labor}
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                onChange={(e) => setLabor(e.target.value)}
                                leftAddon="$"/>
                            <InputLabel
                                maxW='257px'
                                leftAddon="$"
                                placeholder='0'
                                value={materials}
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                onChange={(e) => setMaterials(e.target.value)}
                                type='number'
                                label='Materials'/>
                            <InputLabel
                                maxW='257px'
                                placeholder='0'
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                value={permits}
                                type='number'
                                min='1'
                                onChange={(e) => setPermits(e.target.value)}
                                leftAddon="$"
                                label='Permits'/>
                        </Flex>
                        <Flex
                            justifyContent='space-between'
                            mt={7}>
                            <InputLabel
                                maxW='257px'
                                placeholder='0'
                                leftAddon="$"
                                value={tax}
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                type='number'
                                onChange={(e) => setTax(e.target.value)}
                                label='Tax'/>
                            <InputLabel
                                placeholder='0'
                                maxW='257px'
                                type='number'
                                value={disposal}
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                leftAddon="$"
                                onChange={(e) => setDisposal(e.target.value)}
                                label='Recycling/Disposal'/>
                            <Box
                                w='257px'>
                                <Text
                                    as='h2'
                                    m={0}
                                    fontWeight='600'
                                    fontSize='14px'
                                    color='blue.200'>
                                    Availability
                                </Text>
                                <Box
                                    width='257px'>
                                    <DatePicker
                                        selectsRange={true}
                                        useWeekdaysShort={true}
                                        startDate={startDate}
                                        endDate={endDate}
                                        onChange={(update) => {
                                            setDateRange(update);
                                        }}
                                        renderDayContents={renderCustomDays}
                                        renderCustomHeader={({
                                                                 date,
                                                                 decreaseMonth,
                                                                 increaseMonth,
                                                                 increaseYear,
                                                                 decreaseYear,
                                                                 prevMonthButtonDisabled,
                                                                 nextMonthButtonDisabled,
                                                                 prevYearButtonDisabled,
                                                                 nextYearButtonDisabled,
                                                             }) => (
                                            <Flex
                                                justifyContent='space-between'>
                                                <Box
                                                    pl={3}>
                                                    <Text
                                                        fontSize='18px'>
                                                        <button
                                                            onClick={decreaseMonth}
                                                            disabled={prevMonthButtonDisabled}
                                                            style={{'margin-right': '5px'}}>
                                                            <LeftArrow/>
                                                        </button>
                                                        <Text
                                                            as='span'
                                                            textDecoration='underline'>
                                                            {months[getMonth(date)]}
                                                        </Text>
                                                        <button
                                                            onClick={increaseMonth}
                                                            disabled={nextMonthButtonDisabled}
                                                            style={{'margin-left': '5px'}}>
                                                            <RightArrow/>
                                                        </button>
                                                    </Text>
                                                </Box>
                                                <Box
                                                    pr={3}>
                                                    <Text
                                                        fontSize='18px'>
                                                        <button
                                                            onClick={decreaseYear}
                                                            disabled={prevYearButtonDisabled}
                                                            style={{'margin-right': '5px'}}>
                                                            <LeftArrow/>
                                                        </button>
                                                        <Text
                                                            as='span'
                                                            textDecoration='underline'>
                                                            {getYear(date)}
                                                        </Text>
                                                        <button
                                                            onClick={increaseYear}
                                                            disabled={nextYearButtonDisabled}
                                                            style={{'margin-left': '5px'}}>
                                                            <RightArrow/>
                                                        </button>
                                                    </Text>
                                                </Box>
                                            </Flex>
                                        )}
                                        customInput={<RenderCustomInput/>}
                                        calendarContainer={renderCustomContainer}/>
                                </Box>
                            </Box>
                        </Flex>
                        <Divider
                            my="3.25rem"
                            borderColor="#DADADA"
                            border="3px solid"
                            orientation="horizontal"/>
                        <Flex
                            pb={10}>
                            <InputLabel
                                disabled
                                leftAddon="$"
                                type='number'
                                value={total ? total : ''}
                                width='556px'
                                label='Total'/>
                            <Button
                                width='254px'
                                height='40px'
                                z-index='1'
                                disabled={loading}
                                mt={7}
                                onClick={saveTotal}
                                fontWeight='700'
                                fontSize='18px'>
                                {loading === true ? <CustomSpinner/> : 'Submit'}
                            </Button>
                        </Flex>
                        {
                            error && (
                                <Text
                                    mb='5px !important'
                                    textAlign='center'
                                    color="red.100"
                                    fontSize="sm">
                                    {error}
                                </Text>
                            )
                        }
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default InstallationBidModal