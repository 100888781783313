import {
    authRepo,
    userRepo,
    uploadRepo,
    productRepo,
    accessoryRepo,
    projectRepo,
    uploadHistoryRepo,
    activityRepo,
    notificationRepo,
    companyRepo,
    airtableRepo,
    reportRepo,
    orderRepo, dataRepo,
} from '../repositories'
import {
    authService,
    uploadHistoryService,
    userService,
    uploadService,
    productService,
    projectService,
    accessoryService,
    activityService,
    notificationService,
    reportService,
    companyService,
    airtableService,
    orderService,
} from '../services'
import auth from './authUseCase'
import uploadHistory from './uploadHistoryUseCase'
import user from './userUseCase'
import upload from './uploadUseCase'
import product from './productUseCase'
import accessory from './accessoryUseCase'
import project from './projectUseCase'
import activity from './activityUseCase'
import notification from './notificationUseCase'
import company from './companyUseCase'
import airtable from './airtableUseCase'
import order from './orderUseCase'
import report from './reportUseCase'
import data from './dataUseCase'
import dataService from "../services/dataService";

export const airtableUseCases = airtable(
    airtableRepo(
        airtableService()
    )
)
export const authUseCase = auth(
    authRepo(
        authService()
    )
)
export const userUseCase = user(
    userRepo(
        userService()
    )
)
export const uploadUseCase = upload(
    uploadRepo(
        uploadService()
    )
)
export const productUseCase = product(
    productRepo(
        productService()
    )
)
export const accessoryUseCase = accessory(
    accessoryRepo(
        accessoryService()
    )
)
export const projectUseCase = project(
    projectRepo(
        projectService()
    )
)
export const uploadHistoryUseCase = uploadHistory(
    uploadHistoryRepo(
        uploadHistoryService()
    )
)
export const activityUseCase = activity(
    activityRepo(
        activityService()
    )
)
export const notificationUseCase = notification(
    notificationRepo(
        notificationService()
    )
)
export const companyUseCase = company(
    companyRepo(
        companyService()
    )
)
export const reportUseCase = report(
    reportRepo(
        reportService()
    )
)
export const orderUseCase = order(
    orderRepo(
        orderService()
    )
)
export const dataUseCase = data(
    dataRepo(
        dataService()
    )
)