import { useMutation } from 'react-query'
import { uploadHistoryUseCase } from '../../factories'

const useCreateUploadHistory = () => {
  const {
    mutate: createUploadHistory, isLoading, data, error,
  } = useMutation(uploadHistoryUseCase.createUploadHistory)

  return {
    createUploadHistory,
    isLoading,
    error,
    data,
  }
}

export default useCreateUploadHistory
