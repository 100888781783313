const companyRepo = (companyService) => {
  const createCompany = (input) => companyService.createCompany(input)

  const updateCompany = (input) => companyService.updateCompany(input)

  const listCompanys = (variables) => companyService.listCompanys(variables)

  const syncListCompanys = (variables) => companyService.syncListCompanys(variables)

  const getCompany = (id) => companyService.getCompany(id)

  const getCompanyChanges = (id) => companyService.getCompanyChanges(id)

  const deleteCompany = (id) => companyService.deleteCompany(id)

  const removeUserFromCompany = (input) => companyService.removeUserFromCompany(input)

  const confirmCompanySync = (input) => companyService.confirmCompanySync(input)

  return {
    createCompany,
    confirmCompanySync,
    syncListCompanys,
    getCompanyChanges,
    updateCompany,
    listCompanys,
    getCompany,
    deleteCompany,
    removeUserFromCompany,
  }
}

export default companyRepo
