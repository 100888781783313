import {useQuery} from 'react-query'
import {productUseCase} from '../../factories'

const useGetCustomBids = (variables) => {
    const {
        refetch, isLoading, data, error
    } = useQuery('customBids', () => productUseCase.getCustomBids(variables),{enabled: !!variables.condition.projectId})

    return {
        refetch,
        isLoading,
        error,
        data
    }
}

export default useGetCustomBids
