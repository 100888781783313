
export const PDF_PAGES = [
    'Executive Summary',
    'Investment Overview',
    'Energy Analysis',
    'Eco Incentive',
    'Luminaires Upgrades',
    'Financing Estimate',
    'Order Details',
    'Company Highlights',
]
// Product and area selects

//All products
export const CERTIFICATIONS = [
    'UL Listed',
    'DLC Approved',
    'Energy Star',
    'FCC',
    'ETL',
    'RoHS',
    'CE Marketing',
    'Other',
]

export const BUY_AMERICA = [
    'Yes',
    'No',
]

// Fixture
export const FIXTURE_STYLE = [
    'Accent',
    'Area/Flood',
    'Can',
    'Garage',
    'Landscape',
    'Lowbay/Highbay',
    'Parking/Roadway',
    'Task',
    'Troffer',
    'Other'
]

export const FIXTURE_MOUNT = [
    'Arm',
    'Cove',
    'Pole',
    'Post Top',
    'Pendant',
    'Recessed',
    'Surface',
    'Suspended',
    'Track',
    'Wall',
    'Other',
]

export const FIXTURE_SHAPE = [
    'Circle',
    'Oval',
    'Rectangle',
    'Square',
    'String',
    'Other',
]

export const FIXTURE_LENS_TYPE = [
    'None',
    'Acryllic',
    'Clear',
    'Frosted',
    'Parabolic',
    'Other'
]

export const FIXTURE_TRIM = [
    'Edgeless',
    'Trimless',
    'Trimmed',
    'Other',
]

export const FIXTURE_COLOR = [
    'Black',
    'Brown',
    'Bronze',
    'Silver',
    'White',
    'Other',
]

export const APPLICATION = [
    'Indoor',
    'Garage',
    'Outdoor',
    'Other',
]

export const INGRESS_PROTECTION = [
    'IP 22',
    'IP 44',
    'IP 54',
    'IP 55',
    'IP 65',
    'IP 66',
    'IP 67',
    'IP 68',
    'Other',
]

export const DIMMABILITY = [
    'None',
    '1% Dimmable',
    '5% Dimmable',
    '10% Dimmable',
    'Leading Edge (TRIAC)',
    'Trailing Edge (Electronics)',
    '1-10V Dimmable',
    '0-10V Dimmable',
    'Other',
]

export const COLOR_TUNABLE = [
    'Yes',
    'No'
]

export const CCT_COLORS = [
    '2000K',
    '2100K',
    '2200K',
    '2300K',
    '2400K',
    '2500K',
    '2600K',
    '2700K',
    '2800K',
    '2900K',
    '3000K',
    '3100K',
    '3200K',
    '3300K',
    '3400K',
    '3500K',
    '3600K',
    '3700K',
    '3800K',
    '3900K',
    '4000K',
    '4100K',
    '4200K',
    '4300K',
    '4400K',
    '4500K',
    '4600K',
    '4700K',
    '4800K',
    '4900K',
    '5000K',
    '5100K',
    '5200K',
    '5300K',
    '5400K',
    '5500K',
    '5600K',
    '5700K',
    '5800K',
    '5900K',
    '6000K',
    '6100K',
    '6200K',
    '6300K',
    '6400K',
    '6500K',
    'Other',
]

// Lamp
export const LAMP_CATEGORY = [
    "A Series (Arbitrary)",
    "B Series (Bulged)",
    "BR Series (Bulged Reflector)",
    "C Series (Candelabra)",
    "CA Series (Conical Angular)",
    "F Series (Flame)",
    "G Series (Globular)",
    "GU Series",
    "Led",
    "Mini Reflector (MR)",
    "PAR Series (Parabolic Aluminized Reflector)",
    "R Series (Reflector)",
    "T Series",
    "S Series",
    "CFL (Compact Fluorescent)",
    "Other",
]

export const LAMP_CATEGORY_TYPE = {
    "A Series (Arbitrary)": [
        "A15",
        "A19",
        "A20",
        "A21",
        "A23",
        "A25",
    ],
    "B Series (Bulged)": [
        "B8",
        "B10",
        "B11",
        "B13",
    ],
    "BR Series (Bulged Reflector)" : [
        "BR25",
        "BR20",
        "BR30",
        "BR40",
    ],
    "C Series (Candelabra)" : [
        "C6",
        "C7",
        "C9",
        "C11",
        "C15",
    ],
    "CA Series (Conical Angular)" : [
        "CA10",
        "CA5",
        "CA7",
        "CA8",
        "CA11",
    ],
    "G Series (Globular)" : [
        "G11",
        "G14",
        "G16",
        "G25",
        "G30",
        "G50",
        "G60",
        "G80",
    ],
    "F Series (Flame)" : [
        "F10",
        "F15",
        "F20",
    ],
    "GU Series" : [
        "GU10",
    ],
    "Mini Reflector (MR)" : [
        "MR11",
        "MR6",
    ],
    "PAR Series (Parabolic Aluminized Reflector)": [
        "PAR14",
        "PAR16",
        "PAR20",
        "PAR30",
        "PAR36",
        "PAR38",
    ],
    "R Series (Reflector)" : [
        "R12",
        "R14",
        "R16",
        "R20",
        "R25",
        "R30",
        "R40",
    ],
    "T Series" : [
        "T2",
        "T4",
        "T5",
        "T8",
        "T12",
    ],
    "Led" : [
        "A15",
        "A19",
        "A20",
        "A21",
        "A23",
        "A25",
        "B8",
        "B10",
        "B11",
        "B13",
        "BR25",
        "BR20",
        "BR30",
        "BR40",
        "C6",
        "C7",
        "C9",
        "C11",
        "C15",
        "CA10",
        "CA5",
        "CA7",
        "CA8",
        "CA11",
        "G11",
        "G14",
        "G16",
        "G25",
        "G30",
        "G50",
        "G60",
        "G80",
        "GU10",
        "F10",
        "F15",
        "F20",
        "MR11",
        "MR6",
        "PAR14",
        "PAR16",
        "PAR20",
        "PAR30",
        "PAR36",
        "PAR38",
        "R12",
        "R14",
        "R16",
        "R20",
        "R25",
        "R30",
        "R40",
        "T2",
        "T3",
        "T4",
        "T5",
        "T8",
        "T12",
        "S6",
        "S8",
        "S11",
        "S14",
        "ST18",
        "Area + Flood",
        "Low + High Bay",
        "Parking + Roadway",
        "Garage",
        "Task",
        "Accent",
        "Landscape",
        "Security",
        "Mercury Vapor",
        "Metal Halide",
        "High Pressure Sodium",
        "(BT) Blown Tubular",
        "CW (Candle Twisted)",
        "CP (Crystalline Pear)",
        "CE (Candle Extended)",
        "E (Ellipsoidal)",
        "ED (Ellipsoidal Dimple)",
        "ER (Ellipsoidal Reflector)",
        "GA (Decorator)",
        "K (Krypton)",
        "P (Pear)",
        "HX (Hexagonal Candle)",
        "PC (Ogive)",
        "PS (Pear Straight)",
        "NR (Double Reflector)",
        "ST (Straight Tubular)",
        "T (Tubular)",
        "TA (Tubular Angular)",
        "Other",
    ],
    "S Series" : [
        "S6",
        "S8",
        "S11",
        "S14",
        "ST18",
    ],
    "CFL (Compact Fluorescent)" : [
        "T2",
        "T3",
        "T4",
    ],
    "Other" : [
        "Area + Flood",
        "Low + High Bay",
        "Parking + Roadway",
        "Garage",
        "Task",
        "Accent",
        "Landscape",
        "Security",
        "Mercury Vapor",
        "Metal Halide",
        "High Pressure Sodium",
        "(BT) Blown Tubular",
        "CW (Candle Twisted)",
        "CP (Crystalline Pear)",
        "CE (Candle Extended)",
        "E (Ellipsoidal)",
        "ED (Ellipsoidal Dimple)",
        "ER (Ellipsoidal Reflector)",
        "GA (Decorator)",
        "K (Krypton)",
        "P (Pear)",
        "HX (Hexagonal Candle)",
        "PC (Ogive)",
        "PS (Pear Straight)",
        "NR (Double Reflector)",
        "ST (Straight Tubular)",
        "T (Tubular)",
        "TA (Tubular Angular)",
        "Other",
    ],
}

export const LAMP_LENS_TYPE = [
    "Clear",
    "Frosted",
    "Other",
]

export const LAMP_BASE_CATEGORY = [
    "Bi Pin",
    "CFL Plug In",
    "Fluorescent Pin",
    "Screw",
    "Specialty",
    "Twist Lock",
]

export const LAMP_BASE_CATEGORY_TYPE = {
    "Screw": [
        'E-10',
        'E-11',
        'E-12',
        'E-26',
        'E-27',
        'E-39',
        'EX-39',
        'E-40',
    ],
    "Bi Pin" : [
        'G4',
        'GU4',
        'GU5.3',
        'GY6.35',
        'GU8',
        'GY8',
        'GY8.6',
        'G9',
        'G12',

    ],
    "Twist Lock": [
        'GU10',
        'GU24',

    ],
    "Fluorescent Pin": [
        'Axial',
        'G10q 4-Pin',
        'Mini Bipin',
        'Medium Bipin',
        'Single Bipin',
        'Recessed D.C.',
        '2Gx13',
    ],
    "CFL Plug In": [
        'G24q-1',
        'G-24q-2',
        'G-24q-3',
        'GX10q-4',
        'GX24q-2',
        'GX24q-3',
        'GX24q-4',
        'GX24q-5',
        'GX32d-2',
        'GX32d-3',
        'G-23',
        'G23-2',
        'GX23',
        'GX23-2',
        'G24d-1',
        'G24d-2',
        'G24d-3',
        '2G7',
        '2GX7',
        '2G11',
    ],
    "Specialty": [
        'E-14',
        'Medium Side Prong',
        'End Prong',
        'Screw Terminal M-P',
        'S-14',
        'Can DC Bay',
        'Can SC Bay B-15',
        'Slide',
        'Rigid Loop',
        'Festoon Cap',
        'Wedge',
        'Wedge D.F.',
        'Wedge S.F.',
        'Wedge Subminiature',
        'RX7s',
        'Single Contact SC',
        'Recessed Single Contact R-7',
        'Miniature Bayonet',
        'Index Double Contact Bayonet',
        'Single Contact Bayonet BA15',
        'Double Contact Boynet BA15d',
        'GX8.5',
        'GX10',
        'GZ4',
        'GX5.3',
        'GY5.3',
        'G6.35',
        'GZ6.35',
        'GU7.9',
        'G8.5',
        'G9',
        'G9.5',
        'GY9.5',
        'GZ9.5',
        'Other',
    ],
}

// Power Supply

export const POWER_SUPPLY_TYPE = [
    "Emergency",
    "Standard",
    "Other",
]

// Control

export const CONTROL_TYPE = [
    "Daylight Sensor",
    "Occupancy Sensor",
    "Timer",
    "Wall Switch",
    "Other",
]
