import {useEffect, useState} from 'react'
import {Link, useHistory, useParams} from 'react-router-dom'
import {Flex, Text, Box, Button, Heading} from '@chakra-ui/react'
import Navbar from '../../components/Navbar'
import {useGetCompany, useGetCompanyChanges, useUpdateCompany, useUser,} from '../../core/hooks'
import styles from './styles'
import moment from "moment/moment";
import {ReactComponent as Download} from "../../assets/download.svg";
import {InitialScore} from "../../constants/chartDatas";
import downloadFile from "../../constants/mainFunctions";
import mainStyles from "../../styles/mainStyles";
import {COMPANY_REVIEW_STATUS, ELECTRICAL_TYPE} from "../../constants/company/companyEnum";
import CustomDivider from "../../components/Theme/CustomDivider";
import Header from "./Parts/Header";
import Details from "./Parts/Details";
import CompanyPropertyTypes from "../../components/Theme/DataChartsParts/CompanyPropertyTypes";
import {REU} from "../../constants/user/userEnum";

const CompanyDetail = () => {
    const {id} = useParams()
    const {company: companyResponse, refetch} = useGetCompany(id)
    const {company: companyChangesResponse} = useGetCompanyChanges(id)
    const {mutate: updateCompany, isLoading} = useUpdateCompany()
    const [company, setCompany] = useState()
    const [companyChanges, setCompanyChanges] = useState([])
    const [score, setScore] = useState(InitialScore)
    const {user} = useUser()
    const history = useHistory()

    useEffect(() => {
        if (!companyResponse) return
        setCompany(companyResponse?.data?.data)
        setScore(JSON.parse(companyResponse?.data?.data?.propertyPriorities[0]))
    }, [companyResponse])

    useEffect(() => {
        if (!companyChangesResponse) return
        setCompanyChanges(companyChangesResponse.data.data[0])
    }, [companyChangesResponse])

    return (
        <>
            <Navbar minW="1240px" title={(
                <Flex>
                    <Link as='h2' m={0} to="/companies">Companies</Link>
                    <Text as='h2' m={0}>&nbsp;/&nbsp;{company?.name}</Text>
                </Flex>
            )}/>
            <Box css={mainStyles.container} px={14} pt={8} pb={16}>
                {company?.status === COMPANY_REVIEW_STATUS && (
                    <Flex mb={5}>
                        <Button
                            isLoading={isLoading}
                            onClick={() => updateCompany({
                                receiverId: company?.owner?.id,
                                id: company.id,
                                type: 4
                            }, {onSuccess: () => refetch()})}
                            mr="6"
                            variant="green">
                            Accept
                        </Button>
                        <Button
                            isLoading={isLoading}
                            onClick={() => updateCompany({
                                id: company?.id,
                                type: 5,
                                receiverId: company?.owner?.id,
                            },{onSuccess: () => history.push('/companies')})}
                            variant="red">
                            Decline
                        </Button>
                    </Flex>
                )}
                <Header
                    company={company}/>
                <CustomDivider/>
                <Details
                    company={company}
                    companyChanges={companyChanges}/>
                <CustomDivider/>
                <Heading mb={10} fontSize="xl" as="h2">
                    {user?.type === REU ? ('Property Types:') : ('Serviceable Property Types:')}
                </Heading>
                <CompanyPropertyTypes
                    plus={false}
                    score={score}
                    setScore={setScore}/>
                <CustomDivider/>
                <Box w='full' display={company?.type === ELECTRICAL_TYPE ? 'block' : 'none'}>
                    <Flex w="100%">
                        <Text as='h2' mt={0} fontWeight='600' mb={5} fontSize='18px'>Serviceable Areas:</Text>
                    </Flex>
                    <Flex w="100%">
                        <Text as='h2' m={0} fontWeight='600' fontSize='14px' color="#2C628D">Serviceable Areas - States & Counties</Text>
                    </Flex>
                    <Flex w="100%" border="1px solid #C4C4C4" borderRadius="5px" justifyContent="space-between" p="10px" mb={10}>
                        <Text fontWeight='400' fontSize='18px'>
                            {(() => {
                                if (company?.serviceAreas?.length > 0 && company?.type === ELECTRICAL_TYPE) {
                                    let serviceAreas =  JSON.parse(company?.serviceAreas[0])
                                    let a = serviceAreas?.map((item) => item.name + '(' + item.child.filter((i) => i.value === true).map((e) => e.name) + ')-')
                                    let res = a[a.length - 1]?.slice(0, -1)
                                    a.pop()
                                    a.push(res)
                                    return a?.toString()?.replace(/(.{100})..+/, "$1…")
                                }
                            })()}
                        </Text>
                    </Flex>
                </Box>
                <Flex alignItems='center' justifyContent='space-between'>
                    <Heading width='40%' fontSize='xl'>Uploaded Files</Heading>
                    <Heading width='20%' fontSize='xl'>Date</Heading>
                    <Heading width='40%' fontSize='xl'></Heading>
                </Flex>
                {company?.files?.map((value) => (
                    <Flex justifyContent='space-between' width='full' position='relative' cursor='pointer' css={styles.file} key={value?.file?.files?.key}>
                        <Text width='40%' fontWeight="500">{value?.file?.name?.replace(/(.{30})..+/, "$1…")}</Text>
                        <Text width='20%' color='#A9A9A9' fontWeight="700">{moment(value?.file?.createdAt?.date).format('MMM DD, YYYY')}</Text>
                        <Flex justifyContent='end' width='40%'>
                            <Button onClick={e => downloadFile(e, value?.file?.name)} mr={8} leftIcon={<Download/>} bg="#2C628D" color="white" height="30px" fontSize="12px">
                                Download
                            </Button>
                        </Flex>
                    </Flex>
                ))}
            </Box>
        </>
    )
}

export default CompanyDetail
