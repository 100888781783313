import {Box, Flex, Heading, Image, Text} from "@chakra-ui/react";
import {S3_BUCKET} from "../../../constants";
import CompanyChart from "../DataCharts/CompanyChart";
import ChartProjects from "../DataCharts/ChartProjects";
import ActiveUsersUsageChart from "../DataCharts/ActiveUsersUsageChart";
import Loading from "../../../components/Loading";

const Admin = ({proposals,isLoadingTypeData, activeOrders, companyFootage, adminUserCompaniesManaged, companyColors, usage}) => {
    if (isLoadingTypeData) return <Loading />
    return (
        <Box>
            <Flex borderRadius='10px' pl={4} alignItems='center' minH='57px' bg='#E6E6E6'>
                <Heading m={0} fontSize='24px'>Total Companies Managed</Heading>
            </Flex>
            <Flex mt={9} justifyContent='space-between'>
                <Flex flexDirection='column' width='594px'>
                    <Flex pl={4} justifyContent='space-between'>
                        <Heading m={0} fontSize='16px' width='10%'>Color</Heading>
                        <Heading m={0} fontSize='16px' pl={3} width='50%'>Administrators</Heading>
                        <Heading m={0} fontSize='16px' pl={3} width='40%'>Total Companies</Heading>
                    </Flex>
                    {adminUserCompaniesManaged?.map((user, idx) => (
                        <Flex width='594px' fontSize='18px' key={idx} mt={4} pl={4} alignItems='center' minH='57px'
                              bg='#FFFFFF' boxShadow='2px 4px    10px rgba(0, 0, 0, 0.25)' borderRadius='10px'
                              justifyContent='space-between'>
                            <Flex width='10%' pl={3} alignItems='center' height='100%'
                                  borderLeft={`12px solid ${companyColors[idx]}`}></Flex>
                            <Flex pl={3} alignItems='center' width='50%'>
                                <Image width='50px' height='40px' objectFit='cover' mr={2}
                                       src={user[0]?.avatar ? S3_BUCKET + user[0]?.avatar : ' '}/>
                                {(user[0]?.firstName + ' ' + user[0].lastName)?.replace(/(.{15})..+/, "$1…")}
                            </Flex>
                            <Flex pl={3} width='40%'>{Number(user?.totalSize)?.toLocaleString()}</Flex>
                        </Flex>
                    ))}
                    <Flex mt={4} pl={4} alignItems='center' minH='57px' border='2px solid #2E8A07' bg='#FFFFFF'
                          boxShadow='2px 4px 10px rgba(0, 0, 0, 0.25)' borderRadius='10px' fontWeight='700'
                          justifyContent='space-between'>
                        <Flex color='#2E8A07' width='60%' pl={3} alignItems='center' height='100%'>
                            Total
                        </Flex>
                        <Flex pl={3} width='40%'>
                            {adminUserCompaniesManaged?.length > 0 && adminUserCompaniesManaged.reduce((total, currentValue) => (total + currentValue?.totalSize), 0)}
                        </Flex>
                    </Flex>
                </Flex>
                <Flex alignItems='center' flexDirection='column'>
                    <Heading fontSize='16px' m={0}>Companies Managed by Administrator</Heading>
                    <CompanyChart
                        width='519px'
                        height='519px'
                        colors={companyColors}
                        score={adminUserCompaniesManaged}/>
                </Flex>
            </Flex>
            <Flex mt={8} borderRadius='10px' pl={4} alignItems='center' minH='57px' bg='#E6E6E6'>
                <Heading m={0} fontSize='24px'>Total Active Orders</Heading>
            </Flex>
            <Flex mt={6} height="100%" alignItems="center" justifyContent="space-evenly">
                <Flex fontFamily='Titillium Web' fontSize='16px' style={{writingMode: 'tb-rl'}} justifyContent='center'
                      alignItems='center' transform='rotate(-180deg)'>
                    Dollars
                </Flex>
                <ChartProjects projects={activeOrders}/>
            </Flex>
            <Flex mt='60px' borderRadius='10px' pl={4} alignItems='center' minH='57px' bg='#E6E6E6'>
                <Heading m={0} fontSize='24px'>Total Products Managed</Heading>
            </Flex>
            <Flex mt={9} justifyContent='space-between'>
                <Flex flexDirection='column' width='594px'>
                    <Flex pl={4} fontWeight='700' justifyContent='space-between'>
                        <Heading m={0} fontSize='16px' width='10%'>Color</Heading>
                        <Heading m={0} fontSize='16px' pl={3} width='50%'>Administrators</Heading>
                        <Heading m={0} fontSize='16px' pl={3} width='40%'>Total Companies</Heading>
                    </Flex>
                    {companyFootage?.map((company, idx) => (
                        <Flex width='594px' fontSize='18px' key={idx} mt={4} pl={4} alignItems='center' minH='57px'
                              bg='#FFFFFF'
                              boxShadow='2px 4px 10px rgba(0, 0, 0, 0.25)' borderRadius='10px'
                              justifyContent='space-between'>
                            <Flex width='10%' pl={3} alignItems='center' height='100%'
                                  borderLeft={`12px solid ${companyColors[idx]}`}></Flex>
                            <Flex pl={3} alignItems='center' width='50%'>
                                <Image width='50px' height='40px' mr={2}
                                       src={company[0]?.image ? S3_BUCKET + company[0]?.image : ' '}/>
                                {company[0]?.name.replace(/(.{15})..+/, "$1…")}
                            </Flex>
                            <Flex pl={3} width='40%'>{Number(company?.totalSize)?.toLocaleString()}</Flex>
                        </Flex>
                    ))
                    }
                </Flex>
                <Flex alignItems='center' flexDirection='column'>
                    <Heading fontSize='16px' m={0}>Companies Managed by Administrator</Heading>
                    <CompanyChart
                        width='519px'
                        height='519px'
                        colors={companyColors}
                        score={companyFootage}/>
                </Flex>
            </Flex>
            <Flex mt={8} borderRadius='10px' pl={4} alignItems='center' minH='57px' bg='#E6E6E6'>
                <Heading m={0} fontSize='24px'>Communications Outreach</Heading>
            </Flex>
            <Flex mt={6} height="100%" alignItems="center" justifyContent="space-evenly">
                <Flex fontFamily='Titillium Web' fontSize='16px' style={{writingMode: 'tb-rl'}} justifyContent='center'
                      alignItems='center' transform='rotate(-180deg)'>
                    Messages Received
                </Flex>
                <ChartProjects projects={proposals}/>
            </Flex>
            <Flex mt={10} borderRadius='10px' pl={4} alignItems='center' minH='57px' bg='#E6E6E6'>
                <Heading m={0} fontSize='24px'>Most Active Users</Heading>
            </Flex>
            <Flex mt={9} justifyContent='space-between'>
                <Flex flexDirection='column'>
                    <Flex pl={4} fontWeight='700' justifyContent='space-between'>
                        <Heading m={0} fontSize='16px' width='10%'>Color</Heading>
                        <Heading m={0} fontSize='16px' pl={3} width='20%'>Company</Heading>
                        <Heading m={0} fontSize='16px' pl={3} width='30%'>Users</Heading>
                        <Heading m={0} fontSize='16px' pl={3} width='30%'>Active Hours</Heading>
                    </Flex>
                    {usage?.map((company, idx) => (
                        <Flex width='594px' fontSize='18px' key={idx} mt={4} pl={4} alignItems='center' minH='57px' bg='#FFFFFF'
                              boxShadow='2px 4px 10px rgba(0, 0, 0, 0.25)' borderRadius='10px'
                              justifyContent='space-between'>
                            <Flex width='10%' pl={3} alignItems='center' height='100%'
                                  borderLeft={`12px solid ${companyColors[idx]}`}></Flex>
                            <Flex pl={3} alignItems='center' width='20%'>
                                <Image width='50px' height='40px' objectFit='cover' mr={2}
                                       src={company?.companyLogo ? S3_BUCKET + company?.companyLogo : ' '}/>
                            </Flex>
                            <Flex alignItems='center' pl={3} width='30%'>
                                {company?.userName?.replace(/(.{15})..+/, "$1…")}
                            </Flex>
                            <Flex pl={3} width='30%'>{(Number(company?.total) / 60).toFixed(2)}</Flex>
                        </Flex>
                    ))
                    }
                    <Flex width='594px' mt={4} pl={4} alignItems='center' minH='57px' border='2px solid #2E8A07'
                          bg='#FFFFFF'
                          boxShadow='2px 4px 10px rgba(0, 0, 0, 0.25)' borderRadius='10px' fontWeight='700'
                          justifyContent='space-between'>
                        <Heading m={0} color='#2E8A07' width='70%' pl={3} fontSize='16px'>
                            Total
                        </Heading>
                        <Heading m={0} pl={3} fontSize='16px' width='30%'>
                            {(() => {
                                let countUsersCompany = 0
                                if (usage?.length > 0) {
                                    usage?.map((a) => {
                                        countUsersCompany += (Number(a.total) / 60)
                                    })
                                    return countUsersCompany.toFixed(2)
                                }
                            })()}
                        </Heading>
                    </Flex>
                </Flex>
                <Flex alignItems='center' flexDirection='column'>
                    <Heading fontSize='16px' m={0}>Most Active Users</Heading>
                    {usage && (
                        <ActiveUsersUsageChart
                            width='519px'
                            height='519px'
                            colors={companyColors}
                            score={usage}/>
                    )}
                </Flex>
            </Flex>
        </Box>

    )
}

export default Admin