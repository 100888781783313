import httpRequest from "./http-request";

const CompanyService = () => {
    const {post} = httpRequest()
    const {put} = httpRequest()
    const {get} = httpRequest()

    const createCompany = (input) => {
        return post('create-company', input)
    }

    const updateCompany = (input) => {
        return post('update-company', {input})
    }

    const deleteCompany = (id) => {
        return put(`delete-company/${id}`)
    }

    const getCompany = (id) => {
        return get(`get-company/${id}`)
    }

    const getCompanyChanges = (id) => {
        return get(`get-company-changes/${id}`)
    }

    const listCompanys = (variables) => {
        return post('company-list', {variables})
    }

    const syncListCompanys = (variables) => {
        return post('company-list-for-sync', {variables})
    }

    const removeUserFromCompany = (input) => {
        return post(`remove-user-from-company/${input.id}`, {input})
    }

    const confirmCompanySync = (input) => {
        return post(`confirm-company-sync/${input.id}`)
    }

    return {
        syncListCompanys,
        removeUserFromCompany,
        createCompany,
        updateCompany,
        getCompany,
        getCompanyChanges,
        listCompanys,
        deleteCompany,
        confirmCompanySync,
    }
}

export default CompanyService
