import { useQuery } from 'react-query'
import { dataUseCase } from '../../factories'

const useGetDataWithType = (type) => {
  const {
    refetch, isLoading, data: dataWithType, error,
  } = useQuery('dataWithType', () => dataUseCase.getDataWithType(type))

  return {
    refetch,
    isLoading,
    error,
    dataWithType,
  }
}

export default useGetDataWithType
