import  {useEffect, useMemo, useRef, useState} from 'react'
import Navbar from '../../components/Navbar/Navbar'
import {useHistory} from 'react-router-dom'
import {useForm} from 'react-hook-form'
import {Flex, Text, Box, Button, Divider, Grid, GridItem, Heading, HStack, Tab, TabList, TabPanel, TabPanels, Tabs, Stack, Image, Button as ChakraButton, useDisclosure,} from '@chakra-ui/react'
import {InputLabel, TextareaLabel, Incrementer, ChakraSelectLabel as Select, ChartUserComplete} from '../../components/Theme'
import {S3_BUCKET, SPECIALITIES} from '../../constants'
import styles from './styles'
import {
    useCreateActivity,
    useGetUser,
    useUpdateCompany,
    useUploadMultiple,
    useUser,
    useUserProgress
} from '../../core/hooks'
import Header from './Header'
import ChartCompany from '../../components/Theme/ChartCompany'
import {STATES} from "../../constants/serviceableAreas"
import {ReactComponent as Search} from "../../assets/searchWhite.svg";
import moment from "moment";
import {ReactComponent as Download} from "../../assets/download.svg";
import fileSaver from "file-saver/dist/FileSaver";
import httpRequest from "../../core/services/http-request";
import {ReactComponent as DeleteIcon} from "../../assets/del.svg";
import DeleteFileModal from "./Modals/DeleteFileModal";
import {InitialScore} from "../../constants/chartDatas";
import {ACTIVITY_ENUM} from "../../constants/activityTypes";
import {SUPERADMIN} from "../../constants/user/userEnum";
import DoneButton from "../../components/Theme/Buttons/DoneButton";
import mainStyles from "../../styles/mainStyles";
import {COMPANY_OBJECT} from "../../constants/company/companyEnum";


const EditCompany = () => {
    const {user, setUser} = useUser()
    const {post} = httpRequest()
    const {getUser} = useGetUser()
    const [avatar] = useState(user?.avatar ? S3_BUCKET + user.avatar : '')
    const [complete, setComplete] = useState([])
    const [fileForDelete, setFileForDelete] = useState()
    const {isOpen, onOpen, onClose} = useDisclosure()
    const {mutate: updateCompany, isLoading} = useUpdateCompany()
    const history = useHistory()
    const newArray = useUserProgress(user)
    const {formState: {errors}, register, handleSubmit, setValue, reset, watch,} = useForm({
        defaultValues: {
            COMPANY_OBJECT
        }
    })
    const {createActivity} = useCreateActivity()
    const inputRef = useRef()
    const [score, setScore] = useState(InitialScore)
    const image = watch('image', '')
    const {upload, isLoading: isLoadingUpload, error,} = useUploadMultiple()
    const [files, setFiles] = useState(user?.company[0]?.files ? user?.company[0]?.files : [])
    const [success, setSuccess] = useState(false)

    const handleImage = (e) => {
        const filesToUpload = []
        const dataToPreview = []
        const activityInput = {
            userName: `${user?.firstName} ${user?.lastName}`,
            userType: user?.type,
            projectTitle: user?.company?.length > 0 ? user?.company[0]?.name : user?.associatedToCompany?.name,
            actionId: user?.company?.length > 0 ? user?.company[0]?.id : user?.associatedToCompany?.id,
            action: ACTIVITY_ENUM.UPLOAD_FILE_COMPANY,
        }
        Object.values(e.target.files).forEach(
            (file) => {
                const type = file.type.split('/')[1] || 'jpeg'
                const name = `${new Date().getTime() * Math.random()}.${type}`
                dataToPreview.push({
                    name: file.name,
                    key: name,
                    sortId: files.length,
                    createdAt: new Date().toISOString(),
                })
                filesToUpload.push({name, file, type,})
            },
        )
        upload(
            filesToUpload,
            {
                onSuccess: (e) => {
                    post('create-uploads',[e[0].data.data, dataToPreview, 2, user?.company?.length > 0 ? user?.company[0]?.id : user?.associatedToCompany?.id]).then(() => {
                        createActivity(activityInput)
                        takeUser()
                    }).catch((e) => {
                    })
                    setSuccess(true)
                },
            },
        )
    }

    function downloadFile(e, key) {
        fileSaver.saveAs(S3_BUCKET + key, key);
    }

    const takeUser = () => {
        getUser(
            localStorage.getItem("token"),
            {
                onSuccess: (userResponse) => {
                    setUser(userResponse.data.data)
                    if (userResponse.data.data?.company.length > 0){
                        setFiles(userResponse.data.data?.company[0]?.files ? userResponse.data.data?.company[0]?.files : [])
                    } else {
                        setFiles(userResponse.data.data?.associatedToCompany?.files ? userResponse.data.data?.associatedToCompany?.files : [])
                    }
                },
            },
        )
    }

    const onSubmit = (data) => {
        let eventType
        if (user.type === SUPERADMIN) {
            eventType = 6
        } else {
            eventType = 7
        }
        const input = {
            ...data,
            eventType,
            files: files,
            propertyPriorities: [JSON.stringify(score)],
        }
        const {createdAt, updatedAt, ...rest} = input
        updateCompany(
            rest,
            {
                onSuccess: () => {
                    history.push('/profile')
                    window.location.reload()
                },
            },
        )
    }


    useMemo(() => {
        if (!user) return
        setScore(JSON.parse(user?.company.length > 0 ? user?.company[0]?.propertyPriorities[0] : user?.associatedToCompany?.propertyPriorities[0]))
        if (user?.company?.length > 0){
            reset({...user?.company[0]})
            if (user?.company[0]?.files?.length > 0){
                setFiles(user?.company[0]?.files)
            }
        } else {
            reset({...user?.associatedToCompany})
            if (user?.associatedToCompany?.files?.length > 0){
                setFiles(user?.associatedToCompany?.files)
            }
        }
    }, [user])

    useEffect(() => {
        if (!user) return
        setComplete(newArray)
    }, [user])

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DeleteFileModal
                getUser={takeUser}
                fileId={fileForDelete}
                onClose={onClose}
                isOpen={isOpen}/>
            <Navbar
                minW="1240px"
                title='Edit Company'>
                <DoneButton isLoading={isLoading}/>
            </Navbar>
            <Flex
                css={mainStyles.container}>
                <Flex
                    borderBottom="1px solid #DBDADA"
                    justifyContent="space-between"
                    alignItems="center"
                    px={12}
                    h="139px"
                    w="100%">
                    <Flex
                        alignItems="center">
                        <Flex
                            alignItems="center"
                            justifyContent="center"
                            overflow="hidden"
                            border="1px solid"
                            borderColor="grey.200"
                            borderRadius="100%"
                            width="85px"
                            height="85px">
                            {
                                avatar && (
                                    <Image
                                        src={avatar}
                                        alt="user avatar"
                                        height="100%"
                                        width="100%"
                                        objectFit="cover"/>
                                )
                            }
                        </Flex>
                        <Box
                            ml={8}>
                            <Heading
                                m="0"
                                mb="2"
                                fontSize="3xl"
                                as="h2">
                                {user?.firstName}{' '}{user?.lastName}
                            </Heading>
                        </Box>
                    </Flex>
                    <Flex
                        alignItems="center">
                        <Text
                            mr={4}
                            fontSize="xl">
                            {complete[0]}% Completed
                        </Text>
                        <ChartUserComplete
                            complete={complete}/>
                    </Flex>
                </Flex>
                <Tabs mx='-1px'>
                    <TabList css={mainStyles.tabNames}>
                        <Tab>Company</Tab>
                    </TabList>
                    <TabPanels css={mainStyles.tabPanels}>
                        <TabPanel className='tab-panel'>
                            <Stack w="full" alignItems="flex-end" spacing="8">
                                <HStack w="full">
                                    <Header
                                        setValue={setValue}
                                        image={image}/>
                                    <InputLabel width="510px" mb={20} fontSize="24px !important" fontWeight="700" isInvalid={!!errors.name}
                                        {...register('name', {required: true})}/>
                                    <Box>
                                        <Text mb={20} width="110px" marginLeft={1} marginRight={1} marginTop={2}>
                                            Company Type:
                                        </Text>
                                    </Box>
                                    <InputLabel mb={20} width="320px" disabled isInvalid={!!errors.type}
                                        {...register('type')}/>
                                </HStack>
                                <Divider my="4.25rem" borderColor="#DADADA" border="3px solid" orientation="horizontal"/>
                                <HStack w="full" spacing="6">
                                    <InputLabel isInvalid={!!errors.address} {...register('address', {required: true})} label="Address:"/>
                                    <InputLabel {...register('address2')} ml={7} label="Address Line 2:"/>
                                </HStack>
                                <HStack w="full" spacing="6" alignItems="center" justifyContent="space-between">
                                    <HStack spacing="6" alignItems="flex-end" flex="1">
                                        <InputLabel isInvalid={!!errors.city} {...register('city', {required: true})} label="City:"/>
                                        <Select isInvalid={!!errors.state} {...register('state', {required: true})} label="State:" maxW="100px">
                                            {STATES.map((item) => (<option key={item} value={item}>{item}</option>))}
                                        </Select>
                                        <InputLabel isInvalid={!!errors.zipCode} type="number" {...register('zipCode', {required: true})} label="Zip Code:"/>
                                    </HStack>
                                    <Box flex="1">
                                        <InputLabel disabled {...register('country')} label="Country:"/>
                                    </Box>
                                </HStack>
                                <Flex w="100%">
                                    <InputLabel disabled label="Associated Users:"/>
                                    <InputLabel {...register('website')} ml={7} label="Website:"/>
                                </Flex>
                            </Stack>
                            <TextareaLabel {...register('notes')} label="Notes:"/>
                            <Divider my="2.25rem" borderColor="#DADADA" border="3px solid" orientation="horizontal"/>
                            <Heading mb={10} fontSize="xl" as="h2">
                                {user?.type === 'Reu' ? ('Property Types:') : ('Serviceable Property Types:')}
                            </Heading>
                            <Flex pb="60px" alignItems="center" justifyContent="space-between">
                                <Grid templateColumns="repeat(2, 1fr)" gap={5}>
                                    {SPECIALITIES.map((interest) => (
                                            <GridItem key={interest.title} colSpan={1}>
                                                <Incrementer
                                                    score={score}
                                                    setScore={setScore}
                                                    title={interest.title}
                                                    color={interest.active}/>
                                            </GridItem>
                                        ))}
                                </Grid>
                                <ChartCompany score={score}/>
                            </Flex>
                            <Divider mb="3.25rem" borderColor="#DADADA" border="3px solid" display={user.type === 'El' ? 'inline-flex' : 'none'} orientation="horizontal"/>
                            <Box w='full' display={user.type === 'El' ? 'inline-flex' : 'none'}>
                                <Flex w="100%">
                                    <Text mb={5} fontWeight='600' fontSize='18px'>Serviceable Areas:</Text>
                                </Flex>
                                <Flex w="100%">
                                    <Text fontWeight='600' fontSize='14px' color="#2C628D">Serviceable Areas - States & Counties</Text>
                                </Flex>
                                <Flex w="100%" border="1px solid #C4C4C4" borderRadius="5px" p="10px" mb={20}>
                                    <Text fontWeight='400' fontSize='18px'>North Carolina (Buncombe, Raleigh, Charlotte) - Alabama (Make, Believe, Stock) - Washington (Theureo, Jokely, What)</Text>
                                </Flex>
                            </Box>
                            <Divider my={20} borderColor="#DADADA" border="3px solid" orientation="horizontal"/>
                            <Flex width='full' mb={10} alignItems='center' justifyContent='space-between'>
                                <Text fontWeight='600' fontSize='18px'>Uploaded Files</Text>
                                <Text width='170px' fontWeight='600' fontSize='18px'>Date</Text>
                                <Box maxW='254px'>
                                    <input onChange={handleImage} type="file" multiple style={{display: 'none'}} ref={inputRef}/>
                                    <ChakraButton fontSize='12px' leftIcon={<Search width='14px'/>} onClick={() => inputRef.current.click()} minW="160px" maxH='30px' bg='#153046'>
                                        Browse Files
                                    </ChakraButton>
                                </Box>
                            </Flex>
                            {files?.map((value) => (
                                    <Flex justifyContent='space-between' width='full' position='relative' cursor='pointer' css={styles.file} key={value?.file?.files?.key}>
                                        <Flex p={0} alignItems='center' justifyContent='center' position='absolute' bg='#FB7575' left='-8px' top='-10px' cursor='pointer' borderRadius='5px' width="20px" height="20px"
                                              onClick={() => {
                                                  setFileForDelete(value.id)
                                                  onOpen()
                                              }}>
                                            <DeleteIcon width='10px' height='10px' fill='black'/>
                                        </Flex>
                                        <Text width='40%' fontWeight="500">{value?.file?.name?.replace(/(.{30})..+/, "$1…")}</Text>
                                        <Text width='20%' color='#A9A9A9' fontWeight="700">{moment(value?.file?.createdAt?.date).format('MMM DD, YYYY')}</Text>
                                        <Flex justifyContent='end' width='40%'>
                                            <Button onClick={e => downloadFile(e, value?.file?.name)} mr={8} leftIcon={<Download/>} bg="#2C628D" color="white" height="30px" fontSize="12px">
                                                Download
                                            </Button>
                                        </Flex>
                                    </Flex>
                                ))
                            }
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Flex>
        </form>
    )
}

export default EditCompany
