import React from 'react'
import { Pie } from 'react-chartjs-2'
import { Flex } from '@chakra-ui/react'

const CompanyChart = ({ score, width, height, colors }) => {
    let companyNames = score?.map((item) => item[0]?.name ? item[0].name : item[0]?.firstName + ' ' + item[0]?.lastName)
    let size = score?.map((item) => item.totalSize ? item.totalSize : '0')

    const options = {
        maintainAspectRatio: false,
        animation: false,
        responsive: true,
        tooltips: {
            callbacks: {
                title(tooltipItem) {
                    return companyNames[tooltipItem[0].index]
                },
                label(tooltipItem, newLabel) {
                    return `${newLabel['datasets'][0]['data'][tooltipItem['index']]}`
                },
            },
        },
        hover: {mode: null},
    }
    const data = {
        datasets: [
            {
                labels: 'Company',
                data:  size,
                backgroundColor: colors,
                borderWidth: 0,
            },
        ],
    }

    return (
        <Flex
            width={width ? width : "288px"}
            height={height ? height : "288px"}>
            <Pie
                width={1000}
                height={1000}
                options={options}
                data={data}
                redraw={true} />
        </Flex>
    )
}

export default CompanyChart
