import {Box, Flex, Heading, Text, Divider, IconButton, Checkbox, useDisclosure, useToast} from "@chakra-ui/react";
import moment from "moment/moment";
import {InputLabel, TextareaLabel} from "../../../../components/Theme";
import React, {useEffect, useState} from "react";
import httpRequest from "../../../../core/services/http-request";
import DeleteShippingDetailModal from "../../../EstimateSu/EstimateModals/deleteShippingDetailModal";
import {ReactComponent as DeleteLum} from "../../../../assets/del.svg";
import InputMask from "react-input-mask";
import styles from "../../../EstimateSu/EstimateTabs/ShippingPages/styles";
import {ReactComponent as SendMessage} from "../../../../assets/unfinishedIcon.svg";
import {useUser} from "../../../../core/hooks";

const ShippingViewDetails = ({estimate, trackId, callback,edit, setEdit,setShippingPageStatus, setLoading, setEditShippingAllow, getEstimate, editShippingAllow}) => {
    const {post} = httpRequest()
    const {user} = useUser()
    const [luminairesForDetails, setLuminairesForDetails] = useState([])
    const [luminaires, setLuminaires] = useState([])
    const [info, setInfo] = useState({})
    const [error, setError] = useState(false)
    const {isOpen, onOpen, onClose} = useDisclosure()
    const toast = useToast()

    useEffect(() => {
        post('get-shipping-details', trackId).then((e) => {
            setLuminairesForDetails(e.data.data)
            setLuminaires(e.data.data[0]?.luminaires)
            setInfo({
                senderCompanyName: e.data.data[0]?.senderCompanyName,
                senderTrackingLink: e.data.data[0]?.senderTrackingLink,
                senderAddress1: e.data.data[0]?.senderAddress1,
                senderAddress2: e.data.data[0]?.senderAddress2,
                senderCity: e.data.data[0]?.senderCity,
                senderState: e.data.data[0]?.senderState,
                senderZipCode: e.data.data[0]?.senderZipCode,
                senderPhone: e.data.data[0]?.senderPhone,
                receiverCompanyName: e.data.data[0]?.receiverCompanyName,
                receiverAddress1: e.data.data[0]?.receiverAddress1,
                receiverAddress2: e.data.data[0]?.receiverAddress2,
                receiverCity: e.data.data[0]?.receiverCity,
                receiverState: e.data.data[0]?.receiverState,
                receiverZipCode: e.data.data[0]?.receiverZipCode,
                receiverPhone: e.data.data[0]?.receiverPhone,
                receiverName: e.data.data[0]?.receiverName,
            })
        })
    }, [])

    useEffect(() => {
        if (editShippingAllow){
            setLoading(true)
            let data = {
                estimateId: estimate.id,
                trackId: trackId,
                luminaires: luminaires,
                data: info,
            }
            post('edit-shipping-detail', data).then(() => {
                setShippingPageStatus(1)
                getEstimate()
                setLoading(false)
                toast({
                    render: () => (

                        <Flex
                            overflow='hidden'
                            position='relative'
                            justifyContent='center'
                            flexDirection='column'
                            borderRadius='4px 0px 0px 4px'
                            marginTop='80px'
                            color='black'
                            bg='#8FCF74'
                            pl={3}
                            width='530px'
                            fontWeight='600'
                            fontSize='14px'
                            height='40px'>
                            <Flex alignItems='center'>
                                <SendMessage/>
                                <Text
                                    mt={1}
                                    ml={2}>
                                    Shipping Detail Edited!
                                </Text>
                            </Flex>
                            <Box
                                css={styles.toast}
                                position='absolute'
                                left='5px'
                                bottom='0'
                                mt={1}
                                borderRadius='6px'
                                backgroundColor='#D5D5D5'
                                height='3px'
                                width='40px'>
                            </Box>
                        </Flex>
                    ),
                    duration: 4000,
                    isClosable: true,
                    position: "top-right"
                })
            }).catch((e) => {
                setLoading(false)
            })
            setEditShippingAllow(false)
            setEdit(false)
        }
    },[editShippingAllow])

    return (

            luminairesForDetails?.length > 0 && (
                <>

                    <Flex position='relative' justifyContent='space-between'>
                        <DeleteShippingDetailModal
                            getEstimate={getEstimate}
                            setEdit={setEdit}
                            trackId={trackId}
                            setShippingPageStatus={setShippingPageStatus}
                            onClose={onClose}
                            isOpen={isOpen}/>
                        <IconButton
                            display={edit ? 'flex' : 'none'}
                            onClick={onOpen}
                            height="30px"
                            minW="30px"
                            position="absolute"
                            top="-45px"
                            left="-45px"
                            borderRadius="5px"
                            bg="#FB7575"
                            aria-label="del"
                            icon={<DeleteLum width="15px" height="15px"/>}/>
                        <img
                            src="/images/LogoIcon.svg"
                            alt=""/>
                        <Flex
                            flexDirection='column'>
                            <Heading
                                m={0}
                                textAlign='end'>
                                Shipping Detail
                            </Heading>
                            <Text textAlign='end'>
                                Reference #: {estimate?.reference}
                            </Text>
                            <Text
                                textAlign='end'>
                                Date : {moment(estimate?.shippingDateCreated?.date).format('MM/DD/YYYY')}
                            </Text>
                        </Flex>
                    </Flex>
                    <Flex
                        justifyContent='space-between'>
                        <Flex
                            width='49%'
                            borderRight='2px solid #9C9C9C'
                            flexDirection='column'>
                            <Box
                                backgroundColor='#EEEEEE'
                                width='98%'
                                borderRadius='5px'
                                fontSize='18px'
                                pl={4}
                                pt={2}
                                pb={2}
                                color='#222222'
                                fontWeight='700'>
                                Sender Information:
                            </Box>
                            <InputLabel
                                placeholder='Company Name'
                                readOnly={!edit}
                                onChange={(e) => setInfo({...info, senderCompanyName: e.target.value})}
                                border={edit ? '1px solid #A9A9A9 !important' : 'none !important'}
                                maxW='320px'
                                defaultValue={user?.type === 'Reu' ? 'Focus Earth' : luminairesForDetails[0]?.senderCompanyName}/>
                            {
                                edit ? (
                                    <InputLabel
                                        placeholder='Tracking Link'
                                        onChange={(e) => setInfo({...info, senderTrackingLink: e.target.value})}
                                        border={edit ? '1px solid #A9A9A9 !important' : 'none !important'}
                                        readOnly={!edit}
                                        maxW='320px'
                                        defaultValue={luminairesForDetails[0]?.senderTrackingLink}/>
                                ) : (
                                    <Text pl={4} my={5}>
                                        <a href={(luminairesForDetails[0]?.senderTrackingLink && !luminairesForDetails[0]?.senderTrackingLink.startsWith("https://")) ? `https://${luminairesForDetails[0]?.senderTrackingLink}` : luminairesForDetails[0]?.senderTrackingLink} target='_blank'>
                                            {luminairesForDetails[0]?.senderTrackingLink}
                                        </a>
                                    </Text>
                                )
                            }

                            <InputLabel
                                placeholder='Address Line 1'
                                onChange={(e) => setInfo({...info, senderAddress1: e.target.value})}
                                border={edit ? '1px solid #A9A9A9 !important' : 'none !important'}
                                readOnly={!edit}
                                maxW='320px'
                                defaultValue={user?.type === 'Reu' ? '1070 Tunnel Road, Building 1, Suite 10, #202' : luminairesForDetails[0]?.senderAddress1}/>
                            <InputLabel
                                placeholder='Address Line 2'
                                onChange={(e) => setInfo({...info, senderAddress2: e.target.value})}
                                border={edit ? '1px solid #A9A9A9 !important' : 'none !important'}
                                readOnly={!edit}
                                maxW='320px'
                                defaultValue={user?.type === 'Reu' ? '' : luminairesForDetails[0]?.senderAddress2}/>
                            <Flex
                                justifyContent='space-between'
                                width='320px'>
                                <InputLabel
                                    placeholder='City'
                                    onChange={(e) => setInfo({...info, senderCity: e.target.value})}
                                    border={edit ? '1px solid #A9A9A9 !important' : 'none !important'}
                                    readOnly={!edit}
                                    maxW='150px'
                                    defaultValue={user?.type === 'Reu' ? 'Asheville' : luminairesForDetails[0]?.senderCity}/>
                                <InputLabel
                                    placeholder='State'
                                    onChange={(e) => setInfo({...info, senderState: e.target.value})}
                                    border={edit ? '1px solid #A9A9A9 !important' : 'none !important'}
                                    readOnly={!edit}
                                    maxW='150px'
                                    defaultValue={user?.type === 'Reu' ? 'NC': luminairesForDetails[0]?.senderState}/>
                            </Flex>
                            <Flex
                                justifyContent='space-between'
                                width='320px'>
                                <InputLabel
                                    placeholder='Zip Code'
                                    onChange={(e) => setInfo({...info, senderZipCode: e.target.value})}
                                    border={edit ? '1px solid #A9A9A9 !important' : 'none !important'}
                                    readOnly={!edit}
                                    maxW='150px'
                                    defaultValue={user?.type === 'Reu' ? '28805' : luminairesForDetails[0]?.senderZipCode}/>
                                <InputLabel
                                    border={edit ? '1px solid #A9A9A9 !important' : 'none !important'}
                                    readOnly
                                    maxW='150px'
                                    value={luminairesForDetails[0]?.senderCountry}/>
                            </Flex>
                            <InputLabel
                                placeholder='Phone'
                                onChange={(e) => setInfo({...info, senderPhone: e.target.value})}
                                border={edit ? '1px solid #A9A9A9 !important' : 'none !important'}
                                mask="999-999-9999"
                                maskChar=""
                                autoComplete="on"
                                as={InputMask}
                                formatChars={{'9': '[0-9]'}}
                                readOnly={!edit}
                                maxW='320px'
                                defaultValue={user?.type === 'Reu' ? '(925) 323-0053' : luminairesForDetails[0]?.senderPhone}/>
                        </Flex>
                        <Flex
                            width='50%'
                            flexDirection='column'>
                            <Box
                                backgroundColor='#EEEEEE'
                                width='100%'
                                borderRadius='5px'
                                fontSize='18px'
                                pl={4}
                                pt={2}
                                pb={2}
                                color='#222222'
                                fontWeight='700'>
                                Recipient Information:
                            </Box>
                            <InputLabel
                                placeholder='Company Name'
                                onChange={(e) => setInfo({...info, receiverCompanyName: e.target.value})}
                                border={edit ? '1px solid #A9A9A9 !important' : 'none !important'}
                                readOnly={!edit}
                                maxW='320px'
                                defaultValue={luminairesForDetails[0]?.receiverCompanyName}/>
                            <InputLabel
                                placeholder='Receiver Name'
                                onChange={(e) => setInfo({...info, receiverName: e.target.value.slice(7)})}
                                border={edit ? '1px solid #A9A9A9 !important' : 'none !important'}
                                readOnly={!edit}
                                maxW='320px'
                                defaultValue={`Attn : ${luminairesForDetails[0]?.receiverName}`}/>
                            <InputLabel
                                placeholder='Address Line 1'
                                onChange={(e) => setInfo({...info, receiverAddress1: e.target.value})}
                                border={edit ? '1px solid #A9A9A9 !important' : 'none !important'}
                                readOnly={!edit}
                                maxW='320px'
                                defaultValue={luminairesForDetails[0]?.receiverAddress1}/>
                            <InputLabel
                                placeholder='Address Line 2'
                                onChange={(e) => setInfo({...info, receiverAddress2: e.target.value})}
                                border={edit ? '1px solid #A9A9A9 !important' : 'none !important'}
                                readOnly={!edit}
                                maxW='320px'
                                defaultValue={luminairesForDetails[0]?.receiverAddress2}/>
                            <Flex
                                justifyContent='space-between'
                                width='320px'>
                                <InputLabel
                                    placeholder='City'
                                    onChange={(e) => setInfo({...info, receiverCity: e.target.value})}
                                    border={edit ? '1px solid #A9A9A9 !important' : 'none !important'}
                                    readOnly={!edit}
                                    maxW='150px'
                                    defaultValue={luminairesForDetails[0]?.receiverCity}/>
                                <InputLabel
                                    placeholder='State'
                                    onChange={(e) => setInfo({...info, receiverState: e.target.value})}
                                    border={edit ? '1px solid #A9A9A9 !important' : 'none !important'}
                                    readOnly={!edit}
                                    maxW='150px'
                                    defaultValue={luminairesForDetails[0]?.receiverState}/>
                            </Flex>
                            <Flex
                                justifyContent='space-between'
                                width='320px'>
                                <InputLabel
                                    placeholder='Zip Code'
                                    onChange={(e) => setInfo({...info, receiverZipCode: e.target.value})}
                                    readOnly={!edit}
                                    border={edit ? '1px solid #A9A9A9 !important' : 'none !important'}
                                    maxW='150px'
                                    defaultValue={luminairesForDetails[0]?.receiverZipCode}/>
                                <InputLabel
                                    border={edit ? '1px solid #A9A9A9 !important' : 'none !important'}
                                    readOnly
                                    maxW='150px'
                                    value={luminairesForDetails[0]?.receiverCountry}/>
                            </Flex>
                            <InputLabel
                                placeholder='Phone'
                                border={edit ? '1px solid #A9A9A9 !important' : 'none !important'}
                                readOnly={!edit}
                                mask="999-999-9999"
                                maskChar=""
                                autoComplete="on"
                                as={InputMask}
                                formatChars={{'9': '[0-9]'}}
                                onChange={(e) => setInfo({...info, receiverPhone: e.target.value})}
                                maxW='320px'
                                defaultValue={luminairesForDetails[0]?.receiverPhone}/>
                        </Flex>
                    </Flex>
                    <Box
                        backgroundColor='#FF9A7A'
                        width='100%'
                        fontSize='18px'
                        fontWeight='700'
                        pl={4}
                        pt={2}
                        pb={2}
                        borderRadius='5px'
                        mt={10}>
                        Shipping Information
                    </Box>
                    <Flex
                        mt={7}
                        mb={7}
                        fontWeight='600'>
                        <Text
                            display={edit ? 'block' : 'none'}
                            width='10%'>
                            Select
                        </Text>
                        <Text
                            ml={2}
                            width={edit ? '40%' : '50%'}>
                            Description
                        </Text>
                        <Text
                            width='30%'>
                            Part Number
                        </Text>
                        <Text
                            width='20%'>
                            QTY
                        </Text>
                    </Flex>
                    {
                        luminaires?.map((item, index) =>
                            <Flex
                                key={item.id}
                                backgroundColor={edit ? '#EEEEEE' : '#FAFAFA'}
                                border={edit ? '1px solid #A9A9A9' : 'none'}
                                borderRadius='6px'
                                minH='64px'
                                alignItems='center'>
                                <Text
                                    display={edit ? 'block' : 'none'}
                                    width='10%'>
                                    <Checkbox
                                        css={styles.checkbox}
                                        onChange={(e) => {
                                            luminairesForDetails[index].isChecked = e.target.checked
                                            setLuminairesForDetails(luminairesForDetails)
                                            callback(luminairesForDetails)
                                        }}
                                        size='lg'
                                        iconColor='black'/>
                                </Text>
                                <Text
                                    ml={2}
                                    width={edit ? '40%' : '50%'}>
                                    {item?.lampRecomendedAirtable?.length > 0 ? JSON.parse(item.lampRecomendedAirtable)['Description'] : item?.fixtureRecomendedAirtable?.length > 0 ? JSON.parse(item.fixtureRecomendedAirtable)['FE Product Description'] : ''}
                                </Text>
                                <Text
                                    width='30%'>
                                    {item?.lampRecomendedAirtable?.length > 0 ? JSON.parse(item.lampRecomendedAirtable)['FE Order Code'] : item?.fixtureRecomendedAirtable?.length > 0 ? JSON.parse(item.fixtureRecomendedAirtable)['FE Order Code'] : ''}
                                </Text>
                                <Box
                                    mb={2}
                                    width='20%'>
                                    <InputLabel
                                        maxW='50%'
                                        backgroundColor={edit ? 'white !important' : 'transparent !important'}
                                        border={edit ? '1px solid #A9A9A9 !important' : 'none !important'}
                                        readOnly={!edit}
                                        type='number'
                                        isInvalid={error}
                                        onChange={(e) => {
                                            let maxQty = Number(item.recomendedLampQty) * (item.recomendedFixtureQty ? Number(item.recomendedFixtureQty) : Number(item.qty))
                                            let qty = e.target.value
                                            if (qty <= maxQty && qty >= 0) {
                                                setError(false)
                                                luminairesForDetails[index].qtyShippingDetails = qty;
                                                setLuminairesForDetails(luminairesForDetails)
                                                return callback(luminairesForDetails)
                                            }
                                            setError(true)
                                        }}
                                        defaultValue={item.qtyShippingDetails ? item.qtyShippingDetails : Number(item.recomendedLampQty) * (item.recomendedFixtureQty ? Number(item.recomendedFixtureQty) : Number(item.qty))}/>
                                </Box>
                            </Flex>
                        )
                    }
                    <TextareaLabel
                        minH='56px'
                        backgroundColor='#EEEEEE !important'
                        disabled
                        label='Notes'/>
                    <Divider
                        my={10}
                        borderColor="#222222"
                        border="2px solid"
                        orientation="horizontal"/>
                </>
            )

    )
}

export default ShippingViewDetails