import {Box} from '@chakra-ui/react'
import {Bar} from 'react-chartjs-2'

const ChartElement = ({CRI, lumens}) => {

    const data = {
        labels: CRI?.map((item) => item.name),
        datasets: [
            {
                label: 'Improved CRI',
                data: CRI?.map((item) => item.count),
                fill: '+1',
                borderColor: '#9CBDD8',
                backgroundColor: '#9CBDD8',
                pointBorderColor: '#9CBDD8',
                pointBackgroundColor: '#9CBDD8',
                pointHoverBackgroundColor: '#9CBDD8',
                pointHoverBorderColor: '#9CBDD8',
            },
            {
                label: 'Improved Directional Lumens',
                data: lumens?.map((item) => item.count),
                fill: false,
                backgroundColor: '#FFD439',
                borderColor: '#FFD439',
                hoverBackgroundColor: '#FFD439',
                hoverBorderColor: '#FFD439',
            },
        ],
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            gridLines: {
                display: false,
            },
            xAxes: [{
                barThickness: 30,
                gridLines: {
                    display: false,
                },

            }],
            yAxes: [{
                display: false,
                ticks: {
                    suggestedMin: 0
                },
            }],
        },
        legend: {
            position: 'bottom',
        },
        tooltips: {
            callbacks: {
                label(tooltipItem) {
                    return `${tooltipItem.yLabel}%`
                },
            },
        },
    }

    return (
        <Box
            height="200px"
            width="400px">
            <Bar
                redraw={true}
                plugins={[
                    {
                        id: 'pluginId',
                        afterDatasetsDraw(chart) {
                            const ctx = chart.ctx;
                            chart.data.datasets.forEach(function (dataset, i) {
                                const meta = chart.getDatasetMeta(i);
                                meta.data.forEach(function (bar, index) {
                                    const data = dataset.data[index];
                                    const total = chart.data.datasets[i].data.reduce((a, b) => a + b, 0);
                                    const percentage = ((data / total) * 100).toFixed(2);

                                    // Draw percentage labels on top of the bars
                                    ctx.fillStyle = dataset.backgroundColor;
                                    ctx.font = 'bold 12px Arial';
                                    ctx.textAlign = 'center';
                                    ctx.fillText(`${percentage}%`, bar.x, bar.y - 5);
                                });
                            });
                        },
                    },
                ]}
            options={options}
            data={data}/>
        </Box>
    )
}

export default ChartElement