import React, {useState} from "react";
import {
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    Flex,
    Button,
    useDisclosure,
    useToast,
} from "@chakra-ui/react";
import IconButton from "../../Theme/IconButton";
import Icon from "../../Theme/Icon";
import httpRequest from "../../../core/services/http-request";
import BiddingOpenModalAlert from "./BiddingOpenModal";
import {ReactComponent as SendMessage} from "../../../assets/installationBidOpennedIcon.svg";
import {useCalculator} from "../../../core/hooks";
import {useHistory} from "react-router-dom";
import BiddingErrorModal from "./BiddingErrorModal";
import ToastComponent from "../../toastComponent/ToastComponent";

const BiddingOpenAcceptModal = ({area, isOpen, report, onClose, project, recentProposal, selectedBid, refetch}) => {
    const {post} = httpRequest()
    const history = useHistory()
    const toast = useToast()
    const {calculate} = useCalculator()
    const [loading, setLoading] = useState(false)
    const {isOpen: isOpenBidding, onOpen: onOpenBidding} = useDisclosure()
    const {isOpen: isOpenBiddingError, onOpen: onOpenBiddingError, onClose: onCloseBiddingError} = useDisclosure()
    let canBid = false;

    const sendBiddToElectricians = () => {
        for (let i = 0; i < area.length; i++) {
            for (let j = 0; j < area[i].luminaires.length; j++) {
                if (area[i].luminaires[j].lampRecomendedAirtable || area[i].luminaires[j].fixtureRecomendedDescription
                    || area[i].luminaires[j].powerSupplyRecomendedDescription || area[i].luminaires[j].controlRecomendedDescription || area[i].luminaires[j].accessoryRecomendedDescription) {
                    canBid = true
                    break
                }
            }
        }
        if (!canBid || report.length === 0) {
            return onOpenBiddingError()
        }
        setLoading(true)
        post('open-bid-check-zipCode', project?.zipCode).then(function (res) {
            setLoading(false)
            if (res.data.data === 'No Electricians for this area' || res.data.data === 'There is no Companies') {
                onOpenBidding()
            } else {
                if (recentProposal.id) {
                    post('send-bidd-to-electricians', recentProposal.id).then(function () {
                        setLoading(false)
                        onClose()
                        toast({
                            render: () => (
                                <ToastComponent bg='#8FCF74' icon={<SendMessage/>} title='Installation Bidding Opened!'/>
                            ),
                            duration: 4000,
                            isClosable: true,
                            position: "top-right"
                        })
                        refetch()
                    })
                } else {
                    const input = {
                        projectId: project.id,
                        proposalName: report.proposal !== '' ? report.proposal : recentProposal.proposalName,
                        proposal: area ? area : '',
                        installation: selectedBid ? selectedBid : '',
                        notes: report?.notes ? report?.notes : '',
                    }
                    calculate(
                        input,
                        {
                            onSuccess: (res) => {
                                post('send-bidd-to-electricians', res.data.data.id).then(function () {
                                    setLoading(false)
                                    onClose()
                                    toast({
                                        render: () => (
                                            <ToastComponent bg='#8FCF74' icon={<SendMessage/>} title='Installation Bidding Opened!'/>
                                        ),
                                        duration: 4000,
                                        isClosable: true,
                                        position: "top-right"
                                    })
                                    refetch()
                                    history.push(`/report/${project.id}/${res.data.data.id}`)
                                })
                            },
                        },
                    )
                }
            }
        })
    }

    return (
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
            <BiddingErrorModal onCloseBiddingError={onCloseBiddingError} isOpenBiddingError={isOpenBiddingError}/>
            <BiddingOpenModalAlert isOpen={isOpenBidding} onClose={onClose}/>
            <ModalOverlay/>
            <ModalContent overflow="hidden" borderRadius="10px" minW="853px">
                <ModalHeader bg='#FFE68D' fontWeight="700" fontSize="24px" textAlign="center">
                    Open Bidding Process?
                </ModalHeader>
                <IconButton height="unset" onClick={onClose} _hover={{bg: 'none',}} _active={{bg: 'none',}} bg="none" aria-label="close button" position="absolute" top="1.5rem" right="0.75rem" icon={<Icon type="darkClose"/>}/>
                <ModalBody pt='50px' pl='120px' pr='120px' fontSize='24px' textAlign='center' overflow="scroll" maxH="600px">
                    <Text>
                        Are you sure you're ready to open the bidding process?
                        This will notify all EUs in serviceable area...
                    </Text>
                    <Flex pb='80px' alignItems='center' flexDirection='column'>
                        <Button mt={10} mb={3} isLoading={loading} onClick={sendBiddToElectricians} width='254px' height='51px' fontWeight='700' fontSize='18px' backgroundColor='#2C628D'>
                            Yes, Open Bidding
                        </Button>
                        <Button width='254px' height='51px' onClick={onClose} color='black' fontWeight='700' fontSize='18px' border='1px solid black' backgroundColor='white'>
                            No, Go Back
                        </Button>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default BiddingOpenAcceptModal