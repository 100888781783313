import {InviteModal} from "../../../components/Theme";
import {
    Button,
    Flex,
    HStack,
    Text,
    Box,
    MenuButton,
    MenuList,
    MenuItemOption,
    MenuOptionGroup,
    Menu
} from "@chakra-ui/react";
import {ReactComponent as ArrowDown} from "../../../assets/Icons/downIconSync.svg";
import React, {useEffect, useState} from "react";
import useListCompanysForSync from "../../../core/hooks/company/useListCompanysForSync";
import styles from "../styles";
import httpRequest from "../../../core/services/http-request";

const SyncCompanyModal = ({isOpen, onClose, company, history}) => {
    const {post} = httpRequest()
    const [companies, setCompanies] = useState([])
    const [companyToSync, setCompanyToSync] = useState()
    const [error, setError] = useState()
    const [isOpenList, setIsOpenList] = useState(false)
    const {data} = useListCompanysForSync({companyId: company?.id})

    useEffect(() => {
        if (!data) return
        setCompanies(data.data.data)
    }, [data])


    const syncCompany = () => {
        if (!companyToSync){
            return setError('Select company for sync')
        }
        setError('')
        let data = {
            oldCompany: company.id,
            newCompanySync: companyToSync.id,
        }
        post('sync-company-to-company', data).then(() => {
            return history.push('/companies')
        })
    }

    function CustomRadioButton({ isChecked }) {
        return (
            <Box
                as="span"
                display="inline-block"
                w="18px"
                h="18px"
                border="2px solid"
                borderColor={"#A9A9A9"}
                borderRadius="6px"
                bg={isChecked ? "#FFE68D" : "white"}
                mr="16px"
                position="relative"
                cursor="pointer"
            />
        );
    }

    function splitFieldText(text, similarityInfo) {
        let parts = [];
        let currentIndex = 0;

        text = String(text);

        similarityInfo.forEach(similarity => {
            const start = text.indexOf(similarity.similarChars, currentIndex);
            if (start !== -1) {
                if (start > currentIndex) {
                    parts.push({ text: text.substring(currentIndex, start), similar: false });
                }
                const end = start + similarity.similarChars.length;
                parts.push({ text: text.substring(start, end), similar: true });
                currentIndex = end;
            }
        });

        if (currentIndex < text.length) {
            parts.push({ text: text.substring(currentIndex), similar: false });
        }

        return parts;
    }

    function renderCompanyInfo(company, similarityInfo) {
        const fields = ['name', 'website', 'address', 'address2', 'city', 'state', 'zipCode'];
        return fields.map((field, fieldIndex) => (
            company[field] && (
                <React.Fragment key={fieldIndex}>
                    {splitFieldText(company[field], similarityInfo).map((part, partIndex) => (
                        <span key={partIndex} style={{ backgroundColor: part.similar ? '#FFE68D' : '' }}>
                        {part.text}
                    </span>
                    ))}
                    {fieldIndex < fields.length - 1 && ' , '}
                </React.Fragment>
            )
        ));
    }

    return (
        <InviteModal
            isOpen={isOpen}
            onClose={() => {
                setCompanyToSync('')
                onClose()
            }}
            maxHBody='900px'
            bg="#FFE68D"
            title="Sync Companies">
            <Box py={8} maxW='713px' m='auto'  css={styles.menuList}>
                <Text fontSize='3xl' textAlign='center'>
                    Submitted Company Information
                </Text>
                <Flex
                    mt='40px'
                    minH='70px' border='1px solid #A9A9A9' borderRadius='6px' w='100%' fontSize='16px'
                    fontFamily='Titillium Web' alignItems='center' color='#5A5A5ADE' px={3}>
                    {company?.name + ' | ' + company?.website + ' | ' + company?.address + ' , ' + company?.address2 + ' , ' + company?.city + ' , ' + company?.state + ' , ' + company?.zipCode}
                </Flex>
                <Text fontSize='3xl' textAlign='center'
                      my='40px'>
                    Existing Companies
                </Text>
                <Menu
                    onOpen={() => setIsOpenList(true)}
                    width='713px'
                    p={0}
                    onClose={() => setIsOpenList(false)}
                    isLazy>
                    <MenuButton
                        color='#5A5A5ADE'
                        as={Button}
                        minH='70px'
                        width='713px'
                        rightIcon={<ArrowDown />}
                        border="1px solid"
                        whiteSpace='wrap'
                        fontFamily='Titillium Web'
                        textAlign='start'
                        borderBottom={isOpenList && 'none'}
                        _focus=''
                        _active=''
                        _hover=''
                        pl='60px'
                        borderBottomLeftRadius={isOpenList && '0'}
                        borderBottomRightRadius={isOpenList && '0'}
                        position='relative'
                        fontSize='16px'
                        borderColor="rgba(169, 169, 169, 0.87)"
                        borderRadius="6px"
                        bg="white.100">
                        {companyToSync ? companyToSync?.name + ' | ' + companyToSync?.website + ' | ' + companyToSync?.address + ' , ' + companyToSync?.address2 + ' , ' + companyToSync?.city + ' , ' + companyToSync?.state + ' , ' + companyToSync?.zipCode : 'Select'}
                    </MenuButton>
                    <MenuList
                        mt={0}
                        transform="translateY(0)"
                        position="absolute"
                        inset='-10px auto auto 0px'
                        borderTop='none'
                        borderColor='#A9A9A9'
                        borderTopLeftRadius='0'
                        borderTopRightRadius='0'
                        width='713px' overflowY="auto" maxHeight="210px">
                        <MenuOptionGroup
                            sx={{ padding: 0 }}
                            onChange={(e) => {
                                if (e){
                                    setCompanyToSync(e)
                                    setError('')
                                }
                            }}
                            type='radio'>
                            {
                                companies?.length > 0 && companies?.map((item, index) => (
                                        <MenuItemOption
                                            position='relative'
                                            color='#5A5A5ADE'
                                            fontFamily='Titillium Web'
                                            icon={<></>}
                                            value={item['company']}
                                            minH='70px'
                                            _after={index !== companies?.length -1  &&{
                                                content: '""',
                                                position: 'absolute',
                                                bottom: 0,
                                                left: '2%',
                                                width: '95%',
                                                borderBottom: '1px solid #A9A9A9',
                                            }}
                                            _before={ index === 0 && {
                                                content: '""',
                                                position: 'absolute',
                                                top: 0,
                                                left: '2%',
                                                width: '95%',
                                                borderBottom: '1px solid #A9A9A9',
                                            }}
                                        >
                                            <Flex align="center">
                                                <CustomRadioButton isChecked={companyToSync?.id === item['company']?.id} />
                                                {renderCompanyInfo(item['company'], item['similarityInfo'])}
                                            </Flex>
                                        </MenuItemOption>
                                ))
                            }
                        </MenuOptionGroup>
                    </MenuList>
                </Menu>
                {error && (<Text as='h2' my={2} textAlign="center" color="red.100" fontSize="m">{error}</Text>)}
                <HStack pb={8} justifyContent='center' w='full' gap={5}
                        mt={isOpenList && companies?.length > 2 ? '240px' : isOpenList && companies?.length === 1 ? '120px' : isOpenList && companies?.length === 2 ? '190px' : '40px'}>
                    <Button
                        onClick={() => {
                            setCompanyToSync('')
                            onClose()
                        }}
                        height='40px' fontSize='12px' width='173px' color='black' bg='#8AB8DC'>
                        Nevermind... Go Back
                    </Button>
                    <Button onClick={syncCompany} height='40px' fontSize='12px' width='173px' color='black' bg='#FFE68D'>
                        Yes! Sync Company
                    </Button>
                </HStack>
            </Box>
        </InviteModal>
    )
}
export default SyncCompanyModal