import {useEffect, useState} from 'react'
import {Box} from '@chakra-ui/react'
import {Bar} from 'react-chartjs-2'
import {S3_BUCKET} from "../../../constants";

const ChartLuminaires = ({projects,}) => {
    const [images, setImages] = useState([])

    useEffect(() => {
        if (!projects) return
        let image = []
        for (let i = 0; i < projects.length; i++) {
            if (projects[i][0]?.image) {
                image.push(S3_BUCKET + projects[i][0]?.image)
            } else if (projects[i][0].avatar){
                image.push(S3_BUCKET + projects[i][0]?.avatar)
            } else {
                image.push(projects[i][0].name ? projects[i][0].name : projects[i][0].firstName + ' ' + projects[i][0].lastName)
            }
        }
        setImages(image)
    }, [projects])

    const data = {
        labels: ['','','','','',''],
        datasets: [
            {
                label: 'LED system',
                data: [
                    projects?.length > 0 && projects[0]?.total,
                    projects?.length > 0 && projects[1]?.total,
                    projects?.length > 0 && projects[2]?.total,
                    projects?.length > 0 && projects[3]?.total,
                    projects?.length > 0 && projects[4]?.total,
                    projects?.length > 0 && projects[5]?.total,
                ],
                fill: false,
                backgroundColor: [
                    '#FF3939',
                    '#93D500',
                    '#FCFF56',
                    '#56FFE1',
                    '#5671FF',
                    '#193C5B',
                ],
                borderColor: [
                    '#FF3939',
                    '#93D500',
                    '#FCFF56',
                    '#56FFE1',
                    '#5671FF',
                    '#193C5B',
                ],
                hoverBackgroundColor: [
                    '#FF3939',
                    '#93D500',
                    '#FCFF56',
                    '#56FFE1',
                    '#5671FF',
                    '#193C5B',
                ],
                hoverBorderColor: [
                    '#FF3939',
                    '#93D500',
                    '#FCFF56',
                    '#56FFE1',
                    '#5671FF',
                    '#193C5B',
                ],
                barPercentage: 0.3,
            },
        ],
    }

    const options = {
        responsive: true,
        legend: {
            display: false
        },
        maintainAspectRatio: false,
        scales: {
            yAxes: [{
                ticks: {
                    callback(value, _index, values) {
                        return value.toLocaleString('en-US')
                    },
                },
            }],
        },
        tooltips: {
            callbacks: {
                label(tooltipItem) {
                    return tooltipItem.yLabel
                },
            },
        },
        animation: false,
        hover: {
            animationDuration: 0
        },
        responsiveAnimationDuration: 0
    }


    return (
        <Box
            position='relative'
            height="632px"
            width="1037px">
            <Bar
                redraw={true}
                plugins={[
                    {
                        id: 'pluginId',
                        afterDatasetsDraw: (chart) => {
                            const ctx = chart.ctx
                            const xAxis = chart.scales['x-axis-0']
                            xAxis.ticks.forEach((tick, index) => {
                                const x = xAxis.getPixelForTick(index)
                                const img = new Image()
                                img.src = images[index]
                                img.height = '50px'
                                img.objectFit = 'cover'
                                img.onload = () => {
                                    ctx.drawImage(img, x - 25, chart.chartArea.bottom + 10, 60, 50)
                                }
                            })
                        },
                    },
                ]}
                options={options}
                data={data}/>
            <Box textAlign='center' pt={3}>Companies</Box>
        </Box>
    )
}

export default ChartLuminaires
