import React from 'react'
import {Pie} from 'react-chartjs-2'
import {Flex} from '@chakra-ui/react'

const UsersCountChart = ({score, width, height, type}) => {
    const {
        RealEstate,
        Supplier,
        Sales,
        Electrician,
        Administrator,
    } = score

    const options = {
        maintainAspectRatio: false,
        animation: false,
        responsive: true,
        tooltips: {
            callbacks: {
                title(tooltipItem) {
                    return Object.keys(score)[tooltipItem[0].index]
                },
                label(tooltipItem, newLabel) {
                    return `${newLabel['datasets'][0]['data'][tooltipItem['index']]}`
                },
            },
        },
        hover: {mode: null},
    }
    const data = {
        datasets: [
            {
                label: '# of Votes',
                data: [
                    RealEstate?.length > 0 ? (type === 1 ? RealEstate[0]?.count_c : RealEstate[0]?.sum_total) : 0,
                    Supplier?.length > 0 ? (type === 1 ? Supplier[0]?.count_c : Supplier[0]?.sum_total) : 0,
                    Sales?.length > 0 ? (type === 1 ? Sales[0]?.count_c : Sales[0]?.sum_total) : 0,
                    Electrician?.length > 0 ? (type === 1 ? Electrician[0]?.count_c : Electrician[0]?.sum_total) : 0,
                    Administrator?.length > 0 ? (type === 1 ? Administrator[0]?.count_c : Administrator[0]?.sum_total) : 0,
                ],
                backgroundColor:
                    [
                        '#C956FF',
                        '#FFD439',
                        '#8FCF74',
                        '#FF7F56',
                        '#D1D1D1',
                    ],
                borderWidth: 0,
            },
        ],
    }

    return (
        <Flex
            width={width ? width : "288px"}
            height={height ? height : "288px"}>
            <Pie
                width={1000}
                height={1000}
                options={options}
                data={data}
                redraw/>
        </Flex>
    )
}

export default UsersCountChart
