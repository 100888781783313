import {FormControl, Checkbox, FormLabel, Menu, MenuButton, Flex, MenuList, Grid, GridItem, Text} from '@chakra-ui/react'
import React from 'react'
import styles from './styles'
import {ReactComponent as DownArrow} from "../../assets/downArrow.svg";

const CustomSelect = ({label, value, options, setValue, repeat,}) => (
    <FormControl css={styles.menu}>
        <FormLabel fontFamily='Titillium Web' fontWeight='600' fontSize="xl">
            {label}
        </FormLabel>
        <Menu>
            <MenuButton as={Flex} px={4} position='relative' alignItems="center" border="1px solid" overflow="scroll" borderColor="rgba(169, 169, 169, 0.87)" borderRadius="5px" bg="white.100" height="50px">
                <Text whiteSpace="nowrap" fontSize="xl">
                    {(() => {
                            if (value.length > 0) {
                                let a = []
                                for (let i = 0; i < value.length; i++) {
                                    a.push(value[i] + ', ')
                                }
                                if (a) {
                                    let res = a[a.length - 1].slice(0, -2)
                                    a.pop()
                                    a.push(res)
                                }
                                return a
                            }
                        }
                    )()}
                </Text>
                <Flex position='absolute' right='15px' top='20px'>
                    <DownArrow/>
                </Flex>
            </MenuButton>
            <MenuList pl={6}>
                <Grid templateColumns={`repeat(${repeat}, 1fr)`}>
                    {options.map((item) => (
                            <GridItem key={item} colSpan={1}>
                                <Checkbox
                                    isChecked={value?.includes(item)}
                                    name={item}
                                    onChange={(e) => {
                                        let s = []
                                        if (e.target.checked) {
                                            s.push(item);
                                            s = s.concat(value)
                                        } else {
                                            let index = value.indexOf(item)
                                            value.splice(index, 1)
                                            s = s.concat(value);
                                        }
                                        setValue(s)
                                    }}
                                    iconColor="black"
                                    colorScheme="whiteAlpha">
                                    {item}
                                </Checkbox>
                            </GridItem>
                        ))}
                </Grid>
            </MenuList>
        </Menu>
    </FormControl>
)

export default CustomSelect
