import {useEffect, useState} from 'react'
import {Box, Flex, Heading, Text} from '@chakra-ui/react'
import Area from "./Area";
import {EU} from "../../../constants/user/userEnum";

const LuminsTotal = ({proposal,user }) => {
    const [luminaire, setLuminaire] = useState([])
    const [areas, setAreas] = useState([])

    useEffect(() => {
        if (!proposal) return
        const lumins = []
        const ar = []
        for (let i = 0; i < proposal?.proposal?.length; i++) {
            for (let j = 0; j < proposal.proposal[i].luminaires?.length; j++) {
                if (proposal.proposal[i].luminaires[j]?.lampRecomendedAirtable?.length > 0) {
                    lumins.push(proposal.proposal[i].luminaires[j])
                    ar.push(proposal.proposal[i])
                }
                if (proposal.proposal[i].luminaires[j]?.fixtureRecomendedAirtable?.length > 0) {
                    lumins.push(proposal.proposal[i].luminaires[j])
                    ar.push(proposal.proposal[i])
                }
            }
        }
        setLuminaire(lumins)
        setAreas(ar)
    }, [proposal])

    return (
        <>
            <Flex mt={10} justifyContent='space-around' mb={4}>
                <Box w='48%' borderBottomWidth="1px" borderColor="grey.200">
                    <Heading m={0} pb={2} color="#757575" textAlign='center' fontSize="xl">
                        EXISTING COMPONENTS
                    </Heading>
                </Box>
                <Box w='48%' borderBottomWidth="1px" borderColor="#8FCF74">
                    <Heading m={0} color="#428027" textAlign='center' fontSize="xl">
                        RECOMMENDED COMPONENTS
                    </Heading>
                </Box>
            </Flex>
            <Flex
                fontSize='18px'
                mb={3}
                alignItems='center'
                w='full'
                color='#193C5B'
                fontWeight='700'>
                Totals
            </Flex>
            {luminaire?.map((item) => (
                        <Flex maxW="1240px" key={item.id} mb={2}>
                            <Box flex={1} mr={6} p={1} minH='45px' border="1px solid #C4C4C4" fontSize="12px" color='#757575'>
                                {user?.type === EU ? (
                                    <Text>
                                        ({(item.qty ? item.qty : 1)}) &nbsp;
                                        {item.fixtureDescription ? item.fixtureDescription : '' + item.lampDescription ? (item.lampPerFixture ? item.lampPerFixture : '')+item.lampDescription : ''}
                                    </Text>
                                ) : (
                                    <Text>
                                        ({(item.qty ? item.qty : 1) * (item.lampPerFixture ? item.lampPerFixture : 1)}) &nbsp;
                                        {item.lampDescription ? item.lampDescription : item.fixtureDescription}
                                    </Text>
                                    )
                                }
                            </Box>
                            <Box flex={1} p={1} minH='45px' border="1px solid #8FCF74" fontSize="12px" color='#428027'>
                                ({ ((item.recomendedLampQty ? Number(item.recomendedLampQty) : (item.lampPerFixture ? item.lampPerFixture : 1)) * (item.recomendedFixtureQty ? Number(item.recomendedFixtureQty) : (item.qty ? Number(item.qty) : 1))) })&nbsp;
                                {item.fixtureRecomendedDescription ? item.fixtureRecomendedDescription : ''}
                                {item.lampRecomendedDescription ? item.lampRecomendedDescription : ''}
                                {item?.fixtureMountHeight ? '{'+item?.fixtureMountHeight+'}' : ''}
                            </Box>
                        </Flex>
                    ))}
            <Box pt={7} m='auto' maxWidth='1240px'>
                {areas?.map((item) => (
                        <Area
                            recentProposal={item}
                            key={item.id}
                            data={item}
                            areaName={item.title}/>
                    ))}
            </Box>
        </>
    )
}

export default LuminsTotal