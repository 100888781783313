import {useEffect, useState} from 'react'
import {
    AccordionItem,
    AccordionButton,
    Flex,
    AccordionPanel,
    Text,
    useDisclosure,
    Grid,
    Accordion as ChakraAccordion,
} from '@chakra-ui/react'
import Icon from '../../Theme/Icon'
import LuminairesModals from '../CreateEditLuminaire/index'
import LampViewModal from "../../OverviewReport/LedViewModals/LampViewModal";
import AccessoryViewModal from "../../OverviewReport/LedViewModals/AccessoryViewModal";
import styles from "./styles";
import ExistingLampGrid from "./ExistingLampGrid";
import RecommendedLampGrid from "./RecommendedLampGrid";
import {
    RECOMMENDED_ACCESSORY_JSON,
    RECOMMENDED_CONTROL_JSON,
    RECOMMENDED_FIXTURE_JSON,
    RECOMMENDED_LAMP_JSON, RECOMMENDED_POWER_SUPPLY_JSON
} from "../../../constants/lamps/recommendedLampJsons";

const AccordionSolo = ({luminaire,reportId, setRecent,customPricing, setCustomPricing, area, project, allLuminaires, setArea, recent, fromAll}) => {
    const [type, setType] = useState('')
    const {onOpen, isOpen, onClose} = useDisclosure()
    const {onOpen: onOpenLampView, isOpen: isOpenLampView, onClose: onCloseLampView} = useDisclosure()
    const {onOpen: onOpenAccessoryView, isOpen: isOpenAccessoryView, onClose: onCloseAccessoryView} = useDisclosure()
    const [all, setAll] = useState([])
    const [description, setDescription] = useState([])
    const [descriptionRecomended, setDescriptionRecomended] = useState([])
    const [fixture, setFixture] = useState({})
    const [lamp, setLamp] = useState({})
    const [control, setControl] = useState({})
    const [power, setPower] = useState({})
    const [viewType, setViewType] = useState('')
    const [productType, setProductType] = useState('')
    const [accessory, setAccessory] = useState({})

    useEffect(() => {
        if (!luminaire) return
        setAll([luminaire])
    }, [recent])

    useEffect(() => {
        const desce =
            (luminaire?.fixtureRecomendedDescription ? ' , ' + luminaire.fixtureRecomendedDescription : '')
            + (luminaire.lampRecomendedDescription ? ' , (' + luminaire.recomendedLampQty + ') ' + luminaire.lampRecomendedDescription : '')
            + (luminaire.powerSupplyRecomendedDescription ? ' powered by ' + luminaire.powerSupplyRecomendedDescription : '')
            + (luminaire.controlRecomendedDescription ? ' controlled by ' + luminaire.controlRecomendedDescription : '')
            + (luminaire.accessoryRecomendedDescription ? ' width ' + luminaire.accessoryRecomendedDescription : '')
        let f = {}
        let l = {}
        let p = {}
        let c = {}
        let a = {}
        Object.entries(luminaire).map(item => {
            if (item[0].includes('fixtureRecomended')){
                f[item[0]] = item[1]
            }
            if (item[0].includes('lampRecomended')){
                l[item[0]] = item[1]
            }
            if (item[0].includes('powerSupplyRecomended')){
                p[item[0]] = item[1]
            }
            if (item[0].includes('controlRecomended')){
                c[item[0]] = item[1]
            }
            if (item[0].includes('accessoryRecomended')){
                a[item[0]] = item[1]
            }
        })
        setFixture(Object.keys(f).length > 0 ? f : RECOMMENDED_FIXTURE_JSON)
        setLamp(Object.keys(l).length > 0 ? l : RECOMMENDED_LAMP_JSON)
        setPower(Object.keys(p).length > 0 ? p : RECOMMENDED_POWER_SUPPLY_JSON)
        setControl(Object.keys(c).length > 0 ? c : RECOMMENDED_CONTROL_JSON)
        setAccessory(Object.keys(a).length > 0 ? a : RECOMMENDED_ACCESSORY_JSON)
        setDescriptionRecomended(desce.slice(2))
        const desc = []
        if (luminaire.fixtureDescription) {
            desc.push(luminaire.fixtureDescription)
        }
        if (luminaire.lampDescription) {
            desc.push(` , (${luminaire.lampPerFixture}) ${luminaire.lampDescription}`)
        }
        if (luminaire.powerSupplyDescription) {
            desc.push(` powered by ${luminaire.powerSupplyDescription}`)
        }
        if (luminaire.controlDescription) {
            desc.push(` controlled by ${luminaire.controlDescription}`)
        }
        if (luminaire.accessoryDescriptionA) {
            desc.push(` with ${luminaire.accessoryDescriptionA}`)
        }
        if (luminaire.accessoryDescriptionB) {
            desc.push(`, ${luminaire.accessoryDescriptionB}`)
        }
        if (luminaire.accessoryDescriptionC) {
            desc.push(`, ${luminaire.accessoryDescriptionC}`)
        }
        setDescription(desc)
    }, [luminaire])

    return (
        <ChakraAccordion minW="900px" maxW="1240px" allowMultiple>
            <LuminairesModals
                reportId={reportId}
                customPricing={customPricing}
                setCustomPricing={setCustomPricing}
                fromAll={fromAll}
                fixture={fixture}
                setFixture={setFixture}
                lamp={lamp}
                setLamp={setLamp}
                power={power}
                setPower={setPower}
                project={project}
                control={control}
                setControl={setControl}
                accessory={accessory}
                setAccessory={setAccessory}
                allLuminaires={allLuminaires}
                luminaire={luminaire}
                area={area}
                recent={recent}
                setRecent={setRecent}
                setArea={setArea}
                setType={setType}
                type={type}
                luminaireId={luminaire.id}
                isOpen={isOpen}
                onClose={onClose}/>
            <LampViewModal
                type={type}
                viewType={viewType}
                luminaire={luminaire}
                onCloseLampView={onCloseLampView}
                isOpenLampView={isOpenLampView}/>
            <AccessoryViewModal
                type={type}
                luminaire={luminaire}
                viewType={viewType}
                onCloseAccessoryView={onCloseAccessoryView}
                isOpenAccessoryView={isOpenAccessoryView}/>
            {
                all?.map((item, index) => (
                        <AccordionItem key={index} mb={6} border="none">
                            {({isExpanded}) => (
                                <>
                                    <Grid templateColumns='repeat(2, 1fr)' gap={6}>
                                        <AccordionButton css={styles.accordionButtons} borderBottom={isExpanded && 'none !important'}>
                                            <Flex w='full' justifyContent='space-between' alignItems="center">
                                                <Text>{item.globalQty ? item.globalQty : item.qty}</Text>
                                                <Text px={2}>{description}</Text>
                                                <Text>{item.fixtureMountHeight}</Text>
                                            </Flex>
                                        </AccordionButton>
                                        <AccordionButton css={styles.accordionButtons} borderColor='#619C48 !important' borderBottom={isExpanded && 'none !important'}>
                                            {descriptionRecomended.length > 0 ? (
                                                    <Flex w='full' alignItems="center" justifyContent='space-between'>
                                                        <Text>{item.recomendedFixtureQty ? item.recomendedFixtureQty : item.qty}</Text>
                                                        <Text px={2}>{descriptionRecomended}</Text>
                                                        <Text>{item?.fixtureMountHeight}</Text>
                                                    </Flex>
                                                ) :
                                                <Flex width='100%' alignItems="center" justifyContent="center">
                                                    <Text>Add Recommendation</Text>
                                                </Flex>
                                            }
                                            <Icon
                                                type="downArrow"
                                                style={{
                                                    transform: isExpanded ? 'rotate(180deg)' : 'inherit',
                                                    position: 'relative',
                                                    right: '-48px',
                                                    padding: '6px',
                                                    transition: 'all .3s',
                                                    border: '2px solid #5F5F5F'
                                                }}/>
                                        </AccordionButton>
                                    </Grid>
                                    <AccordionPanel css={styles.accordionPanel}>
                                        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                            <ExistingLampGrid
                                                onOpenAccessoryView={onOpenAccessoryView}
                                                setType={setType}
                                                setViewType={setViewType}
                                                onOpenLampView={onOpenLampView}
                                                item={item}/>
                                            <RecommendedLampGrid
                                                customPricing={customPricing}
                                                setType={setType}
                                                onOpenAccessoryView={onOpenAccessoryView}
                                                setViewType={setViewType}
                                                onOpenLampView={onOpenLampView}
                                                onOpen={onOpen}
                                                item={item}/>
                                        </Grid>
                                    </AccordionPanel>
                                </>
                            )}
                        </AccordionItem>
                    )
                )
            }
        </ChakraAccordion>
    )
}

export default AccordionSolo
