import {css} from '@emotion/react'

const styles = {
    container: css`
      margin: auto;
      min-width: 750px;
      max-width: 1240px;
      padding: 20px;
      background-color: #f5f5f5;
      min-height: calc(100vh - 60px);
      width: 100%;
      border-right: 1px solid #DBDADA;
      border-left: 1px solid #DBDADA;
      position: relative;

      .array_action {
        position: absolute;
        top: 25px;
        left: -20px;
        z-index: 1111;
        width: 150px;
        background-color: #F8F8F8;
        padding: 10px;
        border: 1px solid #C4C4C4;
        border-radius: 4px;
      }

      .header {
        margin-bottom: 40px;
        justify-content: space-between;
        font-family: Titillium Web, serif;

        .header_image {
          height: 123px;
          border: 2px solid #DBDADA;
          width: 146px;
          background-color: white;
          border-radius: 5px;

          & img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }

        }

        .header_content {
          padding-left: 20px;
          width: 80%;

          .content_title {
            font-size: 24px;
            font-family: Titillium Web, serif;
          }

          .content_titles {
            padding-top: 15px;
            justify-content: space-between;
            font-size: 18px;

            .eye_icon {
              margin-left: 5px;
              margin-right: 5px;
              cursor: pointer;
            }

            & span {
              font-family: Frank Ruhl Libre, serif;
              text-transform: capitalize;
            }
          }
        }

      }

      .main_lists {
        justify-content: space-between;

        .list {
          width: 32%;
          padding: 35px 25px;
          background-color: white;
          border-radius: 10px;
          border: 2px solid #DBDADA;
          & li {
            height: 22px;
          }
          & ul:first-of-type {
            margin-right: 1rem;
            width: 171px;

            & li {
              font-size: 14px;
              color: #A9A9A9;
              font-family: Titillium Web, serif;
              font-weight: 700;
            }

            & li:not(:last-child) {
              margin-bottom: 20px;
            }
          }

          & ul:last-of-type {
            & li {
              font-size: 14px;
              font-weight: 500;
            }

            & li:not(:last-child) {
              margin-bottom: 20px;
            }
          }
        }
      }

      .secondary_lists {
        margin: 20px 0 50px 0;
        border-radius: 10px;
        border: 1px solid #DBDADA;
        background-color: white;
        width: 100%;
        padding: 35px 25px;
        & li {
          height: 22px;
        }
        & ul:first-of-type {
          margin-right: 1rem;
          width: 220px;

          & li {
            font-size: 14px;
            color: #A9A9A9;
            font-family: Titillium Web, serif;
            font-weight: 700;
          }

          & li:not(:last-child) {
            margin-bottom: 20px;
          }
        }

        & ul:last-of-type {
          & li {
            font-size: 14px;
            font-weight: 500;
          }

          & li:not(:last-child) {
            margin-bottom: 20px;
          }
        }
      }
    `,
}

export default styles
