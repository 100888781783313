import HttpRequest from "./http-request";

const AccessoryService = () => {
    const {post} = HttpRequest()
    const createAccessory =  (input) => {
        return post('new-product',input)
    }
    const updateAccessory = (input) => {
        return post('update-accessory',input)
    }

    const getAccessory = (id) => {
        return post('get-accessory',id)
    }
    const listAccessories = (variables) => {
        return post('list-records',variables)
    }
    return {
        createAccessory,
        updateAccessory,
        getAccessory,
        listAccessories,
    }
}

export default AccessoryService
