import React from 'react'
import {format} from 'date-fns'
import {Flex, Text, Box,} from '@chakra-ui/react'
import {FlexMask, Icon} from '../../components/Theme'
import {S3_BUCKET} from '../../constants'

const History = ({history}) => (
    <a href={S3_BUCKET + history.file}
       download>
        <FlexMask>
            <Flex w="100%" justifyContent="space-between" alignItems="center">
                <Flex alignItems="center">
                    <Icon type="dots"/>
                    <Text ml={14} fontSize="xl">{history.name.split('.')[0]}</Text>
                </Flex>
                <Flex alignItems="center">
                    <Box ml={14} as="span">
                        {format(new Date(history.createdAt), 'MM eee, yyyy')}
                    </Box>
                </Flex>
            </Flex>
        </FlexMask>
    </a>
)

export default History
