import React, {useEffect, useRef, useState} from "react";
import {
    Button,
    Flex,
    Box,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
} from "@chakra-ui/react";
import IconButton from "../../components/Theme/IconButton";
import Icon from "../../components/Theme/Icon";
import httpRequest from "../../core/services/http-request";
import moment from "moment";
import {useUser} from "../../core/hooks";
import AnswerMessageModal from "../Notifications/AnswerMessageModal";

const ChatModal = ({isOpen, onClose, message, getUse}) => {
    const {post} = httpRequest()
    const {user} = useUser()
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [messages, setMessages] = useState([])
    const bodyRef = useRef(null)
    const {isOpen: isOpenNew, onOpen: onOpenNew, onClose: onCloseNew} = useDisclosure()

    const getChat = async (pageNumber) => {
        try {
            setLoading(true);

            const data = {
                compId: message?.actionId,
                userId: message?.user?.id,
                page: pageNumber,
            };

            const response = await post('get-chat', data);
            const newMessages = response.data.data['items'];

            setMessages((prevMessages) => [...newMessages,...prevMessages]);

            // Scroll to the bottom after adding new messages (optional)
            if (pageNumber === 1  && bodyRef.current) {
                bodyRef.current.scrollTop = bodyRef.current.scrollHeight;
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            if (bodyRef.current) {
                const scrollTop = bodyRef.current.scrollTop
                const isTop = scrollTop === 0

                if (isTop && !loading) {
                    setPage((prevPage) => prevPage + 1)
                    getChat(page + 1)
                }
            }
        }

        if (bodyRef.current) {
            bodyRef.current.addEventListener('scroll', handleScroll)
        }

        return () => {
            if (bodyRef.current) {
                bodyRef.current.removeEventListener('scroll', handleScroll)
            }
        }
    }, [loading, page])

    useEffect(() => {
        if (isOpen && message) {
            setPage(1)
            setMessages([])
            getChat(1)
        }
    }, [isOpen, message]);

    return (
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay/>
            <AnswerMessageModal
                getUse={getUse}
                isOpen={isOpenNew}
                message={messages[0]?.contact?.id}
                onCloseOld={onClose}
                onClose={onCloseNew}/>
            <ModalContent overflow="hidden" borderRadius="10px" minW="848px">
                <ModalHeader bg='#DD94FF' fontWeight="700" fontSize="24px" textAlign="center">
                    Message Thread
                </ModalHeader>
                <IconButton height="unset" onClick={onClose} _hover={{bg: 'none',}} _active={{bg: 'none',}} bg="none" aria-label="close button" position="absolute" top="1.5rem" right="0.75rem" icon={<Icon type="darkClose"/>}/>
                <ModalBody p='30px' overflow="scroll" maxH="600px" bg='#F5F5F5' ref={bodyRef}>
                    <Flex margin="auto" alignItems="center" flexDirection="column">
                        {
                            messages?.map((item,index) => (
                                <Flex key={index} justifyContent={item?.sender?.id === user?.id ? 'end' : 'start'} width='100%' mb={5}>
                                    <Box width='80%'>
                                         <Flex
                                             alignItems='center'
                                             justifyContent={item?.sender?.id === user?.id ? 'end' : 'start'}
                                             mb={1} fontSize='14px' color='#2C628D' fontFamily='Titillium Web'>
                                             {item?.sender?.firstName + ' ' + item?.sender?.lastName}
                                             {item?.sender?.id === user?.id ? ' (You) '
                                                 : item?.sender?.type === 'Su' ? ' (Supplier) '
                                                    : item?.sender?.type === 'Reu' ? ' (Real Estate) '
                                                        : item?.sender?.type === 'Eu' ? ' (Electrician) '
                                                            : item?.sender?.type === 'Sa' ? ' (Sales Admin) '
                                                                : item?.sender?.type === 'Au' ? ' (Admin User) '
                                                     : ''}
                                             <Box width='6px' height='6px' bg='#A9A9A9' borderRadius='50%' mx={1}/>
                                             {item?.sender?.email}
                                             <Box width='6px' height='6px' bg='#A9A9A9' borderRadius='50%' mx={1}/>
                                             {moment(item?.createdAt?.date)?.format('DD/MM/YYYY')}
                                             {' ('+moment(item?.createdAt?.date).format('HH:mm')+')'}
                                         </Flex>
                                         <Box p='5px' bg={item?.sender?.id === user?.id ? '#F4FAFF' : 'white'} border='1px solid #A9A9A9' borderRadius='5px'>
                                             {item?.message}
                                        </Box>
                                    </Box>
                                </Flex>
                            ))
                        }
                        <Button  type="submit" mt={3} width="254px" isLoading={loading} onClick={onOpenNew} variant="blue" size="lg">
                            Reply
                        </Button>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
export default ChatModal