import React, {useEffect, useRef, useState} from 'react'
import {useParams, Link} from 'react-router-dom'
import {Box, Flex, Image, List, Text, ListItem, Button,} from '@chakra-ui/react'
import Navbar from '../../components/Navbar'
import {useGetProduct} from '../../core/hooks'
import styles from './styles'
import {ReactComponent as Edit} from '../../assets/edit.svg'
import {ReactComponent as Eye} from '../../assets/EyeIconProducts.svg'
import Loading from "../../components/Loading";

const Accessory = () => {
    const params = useParams()
    const [supplierUser, setSupplierUser] = useState({})
    const [data, setData] = useState({})
    const type = params.type.includes('power') ? 'Power Supplies' : params.type.includes('control') ? 'Controls' : params.type
    const {data: accessoryResponse, isLoading} = useGetProduct({id: params.id, type: type})
    const [certifShow, setCertifShow] = useState(false)
    const certifRef = useRef(null)

    useEffect(() => {
        if (!accessoryResponse) return
        setData(accessoryResponse?.data?.data[0])
        setSupplierUser(accessoryResponse?.data?.data[1][0])
    }, [accessoryResponse])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (certifRef.current && !certifRef.current.contains(event.target)) {
                setCertifShow(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        };
    }, [])

    if (isLoading) return <Loading/>

    return (
        <>
            <Navbar minW="1240px"
                title={(
                    <Flex>
                        <Link as='h2' m={0} to="/catalogues">Catalogues /&nbsp;</Link>
                        <Link as='h2' m={0} style={{textTransform: 'capitalize'}} to={`/catalogues/${params.type}`}>{params.type}</Link>
                        <Text as='h2' m={0}>&nbsp;/&nbsp;{type === 'Controls' ? data['Control'] : data['FE Product Description']}</Text>
                    </Flex>
                )}/>
            <Box css={styles.container}>
                <Flex className='header'>
                    <Flex width='100%'>
                        <Flex className='header_image'>
                            <Image src={!data['Image'] ? '/images/defaultLamp.jpg' : data['Image'][0].url}/>
                        </Flex>
                        <Box className='header_content'>
                            <Text className='content_title'>{
                                (data['Length (inches)'] ? data['Length (inches)']?.toString()?.replace(/(.{15})..+/, "$1…") + '"x' : ' ' )+
                                (data['Width (inches)'] ? data['Width (inches)']?.toString()?.replace(/(.{15})..+/, "$1…") + '"x' : ' ' )+
                                (data['Diameter (inches)'] ? data['Diameter (inches)']?.toString()?.replace(/(.{15})..+/, "$1…") + '"x' : ' ' )+
                                (data['Depth (inches)'] ? data['Depth (inches)']?.toString()?.replace(/(.{15})..+/, "$1…") + '" ' : ' ' )+
                                (type === 'Controls' ? data['Type']?.toString()?.replace(/(.{15})..+/, "$1…") || ''
                                    : type === 'accessories' ? data['Category']?.toString()?.replace(/(.{15})..+/, "$1…") || ''
                                        : data['Type']?.toString()?.replace(/(.{15})..+/, "$1…") || '') + ' ' +
                                (type === 'accessories' ? ' ':(data['Voltage (min)'] ? data['Voltage (min)'] +'V ' : '')+(data['Voltage (max)'] ? '-'+data['Voltage (max)'] +'V ' : ''))
                                 +
                                (type === 'accessories' ? (data['Product Description'] ? data['Product Description'] : ''):(data['Dimmability']?.toString()?.replace(/(.{15})..+/, "$1…") || ''))
                            }</Text>
                            <Flex className='content_titles'>
                                <Box>
                                    <Text>Supplier: <span>{supplierUser?.company?.length > 0 ? supplierUser?.company[0]?.name : supplierUser?.associatedToCompany?.name}</span></Text>
                                    <Text>Manufacturer: <span>{data['Manufacturer']}</span></Text>
                                </Box>
                                <Box>
                                    <Text>Product Type: <span>{params.type.includes('power') ? 'Power Supply' : params.type.includes('accessories') ? 'Accessory' : params.type.slice(0,-1)}</span></Text>
                                    <Text>Order Code: <span>{data['Order Code'] ? data['Order Code'] : ''}</span></Text>
                                </Box>
                            </Flex>
                        </Box>
                    </Flex>
                    <Link to={`/catalogues/edit-accessory/${params.type}/${params.id}`}>
                        <Button leftIcon={<Edit/>} width="112px" size="sm" variant="lightOrange">Edit Details</Button>
                    </Link>
                </Flex>
                <Flex className='main_lists'>
                    <Flex className='list'>
                        <List>
                            <ListItem>Dimensions - Length  (in):</ListItem>
                            <ListItem>Dimensions - Width (in):</ListItem>
                            <ListItem>Dimensions - Diameter (in):</ListItem>
                            <ListItem>Dimensions - Depth (in):</ListItem>
                            <ListItem>{type === 'accessories' ? 'Category:' : 'Type:'}</ListItem>
                            <ListItem display={type === 'accessories' ? 'none' : 'block'}>{type === 'Controls' ? 'Voltage (min)': 'Wattage:'}</ListItem>
                            <ListItem display={type === 'Controls' || type === 'accessories' ? 'none' : 'block'}>Lamps Powered (qty):</ListItem>
                        </List>
                        <List>
                            <ListItem>{data['Length (inches)'] ? data['Length (inches)']?.toString()?.replace(/(.{15})..+/, "$1…") + '"' : ''}</ListItem>
                            <ListItem>{data['Width (inches)'] ? data['Width (inches)']?.toString()?.replace(/(.{15})..+/, "$1…")+ '"' : ''}</ListItem>
                            <ListItem>{data['Diameter (inches)'] ? data['Diameter (inches)']?.toString()?.replace(/(.{15})..+/, "$1…")+ '"' : ''}</ListItem>
                            <ListItem>{data['Depth (inches)'] ? data['Depth (inches)']?.toString()?.replace(/(.{15})..+/, "$1…")+ '"' : ''}</ListItem>
                            <ListItem>{
                                type === 'Controls' ? data['Type']?.toString()?.replace(/(.{15})..+/, "$1…") || ''
                                    : type === 'accessories' ? data['Category']?.toString()?.replace(/(.{15})..+/, "$1…") || ''
                                        : data['Type']?.toString()?.replace(/(.{15})..+/, "$1…") || ''}
                            </ListItem>
                            <ListItem display={type === 'accessories' ? 'none' : 'block'}>{
                                type === 'Controls' ? (data['Voltage (min)'] ? data['Voltage (min)']?.toString()?.replace(/(.{15})..+/, "$1…")+'V' : '') :
                                    (data['Wattage'] ? data['Wattage']+'W' : '')}
                            </ListItem>
                            <ListItem display={type === 'Controls' || type === 'accessories' ? 'none' : 'block'}>{data['Lamps Powered (qty)']?.toString()?.replace(/(.{15})..+/, "$1…") || ''}</ListItem>
                        </List>
                    </Flex>
                    <Flex className='list'>
                        <List>
                            <ListItem>{type === 'Controls' ? 'Voltage (max)' : 'Lifetime (hrs):'}</ListItem>
                            <ListItem>Warranty (yrs):</ListItem>
                            <ListItem display={type === 'accessories' ? 'none' : 'block'}>{type === 'Controls' ? 'Lifetime (hrs):' : 'Voltage (min):'}</ListItem>
                            <ListItem display={type === 'Controls' || type === 'accessories' ? 'none' : 'block'}>Voltage (max):</ListItem>
                            <ListItem display={type === 'accessories' ? 'none' : 'block'}>Dimmability:</ListItem>
                            <ListItem display='flex' alignItems='center'>Certifications:<Eye onClick={() => setCertifShow(true)} style={{marginLeft: '5px', cursor: 'pointer'}}/></ListItem>
                            <ListItem>Buy America:</ListItem>
                            <ListItem display={type === 'accessories' ? 'block' : 'none'}>Installation (minutes):</ListItem>
                        </List>
                        <List>
                            <ListItem>{type === 'Controls' ? data['Voltage (max)']?.toString()?.replace(/(.{15})..+/, "$1…")+'V' || '' : data['Lifetime (hours)']?.toString()?.replace(/(.{15})..+/, "$1…") || ''}</ListItem>
                            <ListItem>{data['Warranty (years)']?.toString()?.replace(/(.{15})..+/, "$1…") || ''}</ListItem>
                            <ListItem display={type === 'accessories' ? 'none' : 'block'}>{type === 'Controls' ? data['Lifetime (hours)']?.toString()?.replace(/(.{15})..+/, "$1…") || '' : data['Voltage (min)']?.toString()?.replace(/(.{15})..+/, "$1…") || ''}</ListItem>
                            <ListItem display={type === 'Controls' || type === 'accessories' ? 'none' : 'block'}>{data['Voltage (max)']?.toString()?.replace(/(.{15})..+/, "$1…") || ''}</ListItem>
                            <ListItem display={type === 'accessories' ? 'none' : 'block'}>{data['Dimmability']?.toString()?.replace(/(.{15})..+/, "$1…") || ''}</ListItem>
                            <ListItem position='relative' ref={certifRef}>
                                {data['Certifications']?.toString()?.replace(/(.{15})..+/, "$1…").replace(/,/g, ", ") || ''}
                                <Box display={certifShow ? 'block' : 'none'} className='array_action'>{data['Certifications']?.toString().replace(/,/g, ", ")}</Box>
                            </ListItem>
                            <ListItem>{data['Buy America']?.toString()?.replace(/(.{15})..+/, "$1…") || ''}</ListItem>
                            <ListItem display={type === 'accessories' ? 'block' : 'none'}>{data['Approx. Installation Time (minutes)']?.toString()?.replace(/(.{15})..+/, "$1…") || ''}</ListItem>
                        </List>
                    </Flex>
                    <Flex className='list'>
                        <List>
                            <ListItem display={type === 'accessories' ? 'none' : 'block'}>Installation (minutes):</ListItem>
                            <ListItem>Delivery (days):</ListItem>
                            <ListItem>Price Per Unit ($):</ListItem>
                            <ListItem>Vol. Tier 1 (min qty):</ListItem>
                            <ListItem>Vol. Tier 1 PPU ($):</ListItem>
                            <ListItem>Vol. Tier 2 (min qty):</ListItem>
                            <ListItem>Vol. Tier 2 PPU ($):</ListItem>
                        </List>
                        <List>
                            <ListItem display={type === 'accessories' ? 'none' : 'block'}>{data['Approx. Installation Time (minutes)']?.toString()?.replace(/(.{15})..+/, "$1…") || ''}</ListItem>
                            <ListItem>{data['Approx. Delivery (days)']?.toString()?.replace(/(.{15})..+/, "$1…") || ''}</ListItem>
                            <ListItem>{data['Price Per Unit ($)'] ? '$'+data['Price Per Unit ($)']?.toString()?.replace(/(.{15})..+/, "$1…") : ''}</ListItem>
                            <ListItem>{data['Volume Tier 1 (min qty)']?.toString()?.replace(/(.{15})..+/, "$1…") || ''}</ListItem>
                            <ListItem>{data['Volume Tier 1 PPU ($)'] ? '$'+data['Volume Tier 1 PPU ($)']?.toString()?.replace(/(.{15})..+/, "$1…") : ''}</ListItem>
                            <ListItem>{data['Volume Tier 2 (min qty)']?.toString()?.replace(/(.{15})..+/, "$1…") || ''}</ListItem>
                            <ListItem>{data['Volume Tier 2 PPU ($)'] ? '$'+data['Volume Tier 2 PPU ($)']?.toString()?.replace(/(.{15})..+/, "$1…") : ''}</ListItem>
                        </List>
                    </Flex>
                </Flex>
                <Flex className='secondary_lists'>
                    <List>
                        <ListItem>Supplier Product Description:</ListItem>
                        <ListItem>Product Specifications (hyperlink):</ListItem>
                        <ListItem>Installation Instructions (hyperlink):</ListItem>
                        <ListItem>Product Image (hyperlink):</ListItem>
                        <ListItem>Compatible Product Order Codes:</ListItem>
                        <ListItem>Notes:</ListItem>
                    </List>
                    <List>
                        <ListItem>{data['Product Description']?.replace(/(.{80})..+/, "$1…") || ''}</ListItem>
                        <ListItem>{data['Specifications (hyperlink)']?.replace(/(.{80})..+/, "$1…") || ''}</ListItem>
                        <ListItem>{data['Installation Instructions (hyperlink)']?.replace(/(.{80})..+/, "$1…") || ''}</ListItem>
                        <ListItem>{data['Product Image (hyperlink)']?.replace(/(.{80})..+/, "$1…") || ''}</ListItem>
                        <ListItem>{data['Compatible Product Order Codes']?.replace(/(.{80})..+/, "$1…") || ''}</ListItem>
                        <ListItem>{data['Notes']?.replace(/(.{80})..+/, "$1…") || ''}</ListItem>
                    </List>
                </Flex>
            </Box>
        </>
    )
}

export default Accessory
