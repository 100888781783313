import React from 'react'
import {Stack, HStack, Box, Heading, Checkbox,} from '@chakra-ui/react'
import {InputLabel, ChakraSelectLabel} from '../../../../components/Theme'
import NavigateModal from '../NavigateModal'
import {
    LAMP_CATEGORY,
    LAMP_BASE_CATEGORY_TYPE,
    LAMP_CATEGORY_TYPE,
    LAMP_BASE_CATEGORY, LAMP_LENS_TYPE
} from "../../../../constants/utils";

const View = ({setType, description, lamp, setLamp, create, filters, setFilters}) => (
    <Stack spacing="5">
        <NavigateModal
            label={description}
            leftAction={() => {
                create()
                setType('fixture')
            }}
            rightAction={() => {
                create()
                setType('power-supply')
            }}
            left="Fixture"
            right="Power Supply"/>
        <HStack w="full" justifyContent="center" position="relative" alignItems="center" pt={5}>
            <Box position="absolute" bg="#A9A9A9" w="full" h="1px"/>
            <Box px="2" position="relative" bg="white" textTransform="uppercase" fontWeight="400" fontSize="xl" color="#2C628D" as="span">
                lamp details
            </Box>
        </HStack>
        <HStack spacing="5" alignItems="flex-end">
            <InputLabel
                height='46px'
                maxW="203px"
                type="number"
                value={lamp?.lampPerFixture}
                onChange={(e) => {
                    setFilters({...filters, lampPerFixture: e.target.value})
                    setLamp({...lamp, lampPerFixture: e.target.value,})
                }}
                pattern="[0-9]*"
                label="QTY/Fixture"/>
            <InputLabel height='46px' color='#737373' backgroundColor='#EEEEEE !important' readOnly label="Lamp Description" value={lamp?.lampDescription}/>
        </HStack>
        <HStack w="full" justifyContent="center" position="relative" alignItems="center" py={5}>
            <Box position="absolute" bg="#A9A9A9" w="full" h="1px"/>
            <Box px="2" position="relative" bg="white" textTransform="uppercase" fontWeight="400" fontSize="xl" color="#2C628D" as="span">
                filters
            </Box>
        </HStack>
        <HStack spacing="5">
            <ChakraSelectLabel height='46px' placeholder="Select" maxW="203px" value={lamp?.lampCategory}
                onChange={(e) => {
                    if (!e.target.value){
                        setFilters({...filters, lampCategory: e.target.value, lampCategoryType: e.target.value})
                        setLamp({...lamp, lampCategory: e.target.value, lampCategoryType: e.target.value})
                        return
                    }
                    setFilters({...filters, lampCategory: e.target.value,})
                    setLamp({...lamp, lampCategory: e.target.value})
                }}
                label="Category:">
                {LAMP_CATEGORY.map((item, index) => (<option key={index} value={item}>{item}</option>))}
            </ChakraSelectLabel>
            <ChakraSelectLabel height='46px' placeholder="Select" maxW="203px" value={lamp?.lampCategoryType}
                onChange={(e) => {
                    setFilters({...filters, lampCategoryType: e.target.value,})
                    setLamp({...lamp, lampCategoryType: e.target.value})
                }}
                label="Type:">
                {LAMP_CATEGORY_TYPE[lamp.lampCategory]?.map((item, index) => (<option key={index} value={item}>{item}</option>))}
            </ChakraSelectLabel>
            <ChakraSelectLabel height='46px' maxW="203px" label="Base Category:" value={lamp?.lampBaseCategory} placeholder="Select"
                onChange={(e) => {
                    if (!e.target.value) {
                        setFilters({...filters, lampBaseCategory: e.target.value, lampBaseCategoryType: e.target.value,})
                        setLamp({...lamp, lampBaseCategory: e.target.value, lampBaseCategoryType: e.target.value})
                        return
                    }
                    setFilters({...filters, lampBaseCategory: e.target.value,})
                    setLamp({...lamp, lampBaseCategory: e.target.value})
                }}>
                {LAMP_BASE_CATEGORY.map((item, index) => (<option key={index} value={item}>{item}</option>))}
            </ChakraSelectLabel>
            <ChakraSelectLabel height='46px' maxW="203px" label="Base Category Type:" value={lamp?.lampBaseCategoryType} placeholder="Select"
                onChange={(e) => {
                    setFilters({...filters, lampBaseCategoryType: e.target.value,})
                    setLamp({...lamp, lampBaseCategoryType: e.target.value})
                }}>
                {LAMP_BASE_CATEGORY_TYPE[lamp?.lampBaseCategory]?.map((item, index) => (<option key={index} value={item}>{item}</option>))}
            </ChakraSelectLabel>
        </HStack>
        <HStack spacing='5'>
            <InputLabel height='46px' type="number" value={lamp?.lampWattage}
                onChange={(e) => {
                    setFilters({...filters, wattage: e.target.value})
                    setLamp({...lamp, lampWattage: e.target.value})
                }}
                label="Wattage:"/>
            <InputLabel height='46px' maxW="203px" type="number" label="Color Temperature:" value={lamp?.lampColorTemperature}
                onChange={(e) => {
                    setFilters({...filters, lampColorTemperature: e.target.value,})
                    setLamp({...lamp, lampColorTemperature: e.target.value})
                }}/>
            <ChakraSelectLabel height='46px' maxW="203px" label="Lens Type:" value={lamp?.lampLensType} placeholder="Select"
                onChange={(e) => {
                    setFilters({...filters, lampLensType: e.target.value,})
                    setLamp({...lamp, lampLensType: e.target.value})
                }}>
                {LAMP_LENS_TYPE.map((item, index) => (<option key={index} value={item}>{item}</option>))}
            </ChakraSelectLabel>
            <InputLabel height='46px' maxW="203px" label="Product Code:" value={lamp?.lampProductCode}
                onChange={(e) => {
                    setFilters({...filters, lampProductCode: e.target.value,})
                    setLamp({...lamp, lampProductCode: e.target.value})
                }}/>
        </HStack>
        <HStack spacing='10px'>
            <Heading fontWeight='400' m={0} color='#2C628D' fontSize='14px'>
                Led
            </Heading>
            <Checkbox
                _checked={{
                    "& .chakra-checkbox__control": {background: "#D4E6FF", boxShadow: 'none'}
                }}
                _hover={{
                    "& .chakra-checkbox__control": {background: "#D4E6FF", boxShadow: 'none'}
                }}
                iconBorder='#2C628D'
                onChange={() => {
                    setLamp({...lamp, ledType: !lamp?.ledType})
                    setFilters({...filters, ledType: !filters?.ledType})
                }}
                isChecked={lamp?.ledType}
                iconColor='black'
                color='#A9A9A9' width='20px' height='20px'
                borderColor='#2C628D' />
        </HStack>
    </Stack>
)

export default View
