import { useQuery } from 'react-query'
import { productUseCase } from '../../factories'

const useListProducts = (variables) => {
  const {
    refetch,
    isLoading,
    data,
    error,
  } = useQuery('products', () => productUseCase.listProducts(variables))

  return {
    refetch,
    isLoading,
    error,
    data,
  }
}

export default useListProducts
