import React, {useRef, useState} from 'react'
import {Flex, Box, Image, Grid, GridItem, Button,Divider} from '@chakra-ui/react'
import {S3_BUCKET,} from '../../constants'
import {ChakraSelectLabel, InputLabel,} from '../../components/Theme'
import {ReactComponent as UploadPhoto} from '../../assets/uploadPhoto.svg'
import {ReactComponent as AlertIcon} from '../../assets/AlertIcon.svg'
import {ReactComponent as SubmitIvon} from '../../assets/accept.svg'
import CustomSelect from "../../components/Theme/CustomSelect";
import {
    APPLICATION,
    BUY_AMERICA,
    CERTIFICATIONS,
    FIXTURE_SHAPE,
    FIXTURE_MOUNT,
    FIXTURE_LENS_TYPE,
    FIXTURE_STYLE,
    FIXTURE_COLOR,
    DIMMABILITY,
    INGRESS_PROTECTION,
    COLOR_TUNABLE,
    FIXTURE_TRIM,
    CCT_COLORS,
    LAMP_CATEGORY,
    LAMP_CATEGORY_TYPE,
    LAMP_LENS_TYPE,
    LAMP_BASE_CATEGORY, LAMP_BASE_CATEGORY_TYPE,
} from "../../constants/utils";
import {ReactComponent as AddImage} from "../../assets/addImage.svg";

const MainFields = ({image, type,editMode,getValues,orderCodeHover,setOrderCodeHover, lampBaseCategory,showAlert,errors,supplierUser, lampCategory, upload, setImage, register, orderCodeError, user, certifications, setCertifications, cctColors, setCctColors}) => {
    const inputRef = useRef()

    const handleImage = (e) => {
        const {files} = e.target
        if (files && files[0]) {
            const file = files[0]
            const blob = URL.createObjectURL(file)
            const type = 'image'
            const name = `${new Date().getTime() * Math.random()}.${type}`
            setImage(blob)
            upload({name, file, type,},
                {onSuccess: (data) => setImage(data.data.data),})
        }
    }

    return (
        <>
            <Flex>
                <Box mr={12}>
                    <Flex bg="white.100" alignItems="center" justifyContent="center" overflow="hidden" borderRadius='5px' border="1px solid #DBDADA" width="264px" height="172px">
                        {image && !image.includes('airtableusercontent') ? (<Image  height="100%" width="100%" src={!!image ? `${S3_BUCKET}${image}` : ' '} alt="Image" objectFit="cover"/>
                        ) : editMode && image.includes('airtableusercontent') ? (<Image  height="100%" width="100%" src={!!image ? image : ' '} alt="Image" objectFit="cover"/>
                        ) : (<AddImage />)}
                    </Flex>
                    <input onChange={handleImage} type="file" style={{display: 'none'}} accept="image/*" ref={inputRef}/>
                    <Button mt={8} leftIcon={<UploadPhoto/>} onClick={() => inputRef.current.click()} width="264px" height="54px" fontFamily='Titillium Web' variant="green">
                        Upload New Photo
                    </Button>
                </Box>
                <Grid width='100%' templateColumns="repeat(2, 1fr)" gap={6}>
                    <GridItem colSpan={2}>
                        <InputLabel disabled isInvalid={!!errors.description} {...register('description')} label="Product Description:"/>
                    </GridItem>
                    <GridItem colSpan={1}>
                        <InputLabel disabled value={supplierUser?.company?.length > 0 ? supplierUser?.company[0]?.name : supplierUser?.associatedToCompany?.name} label="Supplier:"/>
                    </GridItem>
                    <GridItem colSpan={1}>
                        <InputLabel disabled value={type?.replace(/^(\w)(.+)/, (match, p1, p2) => p1.toUpperCase() + p2.toLowerCase())} label="Product Type:"/>
                    </GridItem>
                    <GridItem colSpan={1}>
                        <InputLabel isInvalid={!!errors.manufacturer} {...register('manufacturer')} label="Manufacturer:"/>
                    </GridItem>
                    <GridItem colSpan={1} position='relative'>
                        <InputLabel isInvalid={!!errors.orderCode} {...register('orderCode')} label="Order Code:"/>
                        <Flex display={showAlert ? 'flex' : 'none'} style={{zIndex: '9999'}} alignItems='center' borderRadius='50%' position='absolute' right='-10px' top='20px' justifyContent='center' width='24px' height='24px' backgroundColor={orderCodeError ? '#FF5F5F' : '#8FCF74'}>
                            {orderCodeError ? (<AlertIcon  onMouseEnter={() => orderCodeError && setOrderCodeHover(true)} onMouseLeave={() => setOrderCodeHover(false)}/>
                                ) : (<SubmitIvon />)}
                        </Flex>
                        <Box zIndex='9999' display={orderCodeHover ? 'block' : 'none'} bg='white' position='absolute' right='-70px' top='80px' width='145px' height='68px' border='1px solid #C4C4C4' borderRadius='4px' px='8px' py='4px' fontSize='14px' fontWeight='500'>
                            Order Code quantity should match CCT options
                        </Box>
                    </GridItem>
                </Grid>
            </Flex>
            <Divider my={10} borderColor='#A9A9A9'/>
            <Grid templateColumns="repeat(3, 1fr)" gap={6}>
                <GridItem colSpan={1}>
                    <InputLabel type='number' isInvalid={!!errors.dimensionsLength} {...register('dimensionsLength', {valueAsNumber: true,})} label="Dimensions - Length (inches):" />
                </GridItem>
                <GridItem colSpan={1}>
                    <InputLabel type='number' isInvalid={!!errors.wattage} {...register('wattage')} label="Wattage:" />
                </GridItem>
                <GridItem colSpan={1}>
                    <InputLabel type='number' isInvalid={!!errors.thd} {...register('thd')} label="THD (%):"/>
                </GridItem>
                <GridItem colSpan={1}>
                    <InputLabel type='number' isInvalid={!!errors.dimensionsWidth} {...register('dimensionsWidth', {valueAsNumber: true,})} label="Dimensions - Width (inches):"/>
                </GridItem>
                <GridItem colSpan={1}>
                    <InputLabel type='number' isInvalid={!!errors.lumens} {...register('lumens')} label="Lumens:"/>
                </GridItem>
                <GridItem colSpan={1}>
                    <InputLabel isInvalid={!!errors.beamAngle} {...register('beamAngle')} label="Beam Angle:"/>
                </GridItem>
                <GridItem colSpan={1}>
                    <InputLabel type='number' isInvalid={!!errors.dimensionsDiameter} {...register('dimensionsDiameter', {valueAsNumber: true,})} label="Dimensions - Diameter (inches):"/>
                </GridItem>
                <GridItem colSpan={1}>
                    <CustomSelect
                        repeat={3}
                        value={cctColors}
                        options={CCT_COLORS}
                        setValue={setCctColors}
                        label="CCT (K˚):"/>
                </GridItem>
                <GridItem colSpan={1}>
                    <InputLabel isInvalid={!!errors.optics} {...register('optics')} label="Optics:"/>
                </GridItem>
                <GridItem colSpan={1}>
                    <InputLabel type='number' isInvalid={!!errors.dimensionsDepth} {...register('dimensionsDepth', {valueAsNumber: true,})} label="Dimensions - Depth (inches):"/>
                </GridItem>
                <GridItem colSpan={1}>
                    <InputLabel isInvalid={!!errors.cri} {...register('cri')} label="CRI:"/>
                </GridItem>
                <GridItem colSpan={1}>
                    <CustomSelect
                        repeat={1}
                        value={certifications}
                        options={CERTIFICATIONS}
                        setValue={setCertifications}
                        label="Certifications:"/>
                </GridItem>
                <GridItem colSpan={1}>
                    <ChakraSelectLabel placeholder='Select' isInvalid={!!errors.application} {...register('application')} label="Application:">
                        {APPLICATION.map((item,index) => (
                            <option value={item} key={index}>{item}</option>
                        ))}
                    </ChakraSelectLabel>
                </GridItem>
                <GridItem colSpan={1}>
                    <InputLabel type='number' isInvalid={!!errors.lifetime} {...register('lifetime', {valueAsNumber: true,})} label="L70 Lifetime (hours):"/>
                </GridItem>
                <GridItem colSpan={1}>
                    <ChakraSelectLabel placeholder='Select' isInvalid={!!errors.buyAmerica} {...register('buyAmerica')} label="Buy America:">
                        {BUY_AMERICA.map((item,index) => (
                            <option value={item} key={index}>{item}</option>
                        ))}
                    </ChakraSelectLabel>
                </GridItem>
                <GridItem colSpan={1}>
                    {type === 'lamp' ? (
                        <ChakraSelectLabel placeholder='Select' isInvalid={!!errors.lampCategory} {...register('lampCategory')} label={type === 'lamp' ? 'Category:' : "Style:"}>
                            {LAMP_CATEGORY.map((item,index) => (<option value={item} key={index}>{item}</option>))}
                        </ChakraSelectLabel>
                    ) : (
                        <ChakraSelectLabel placeholder='Select' isInvalid={!!errors.fixtureStyle} {...register('fixtureStyle')} label="Style:">
                            {FIXTURE_STYLE.map((item,index) => (<option value={item} key={index}>{item}</option>))}
                        </ChakraSelectLabel>
                        )
                    }
                </GridItem>
                <GridItem colSpan={1}>
                    <InputLabel isInvalid={!!errors.fixtureWarranty} {...register('fixtureWarranty')} label="Warranty (years):"/>
                </GridItem>
                <GridItem colSpan={1}>
                    <InputLabel isInvalid={!!errors.installationTime} {...register('installationTime')} label="Approx. Installation Time (minutes):"/>
                </GridItem>
                <GridItem colSpan={1}>
                    {
                        type === 'lamp' ? (
                            <ChakraSelectLabel placeholder='Select' isInvalid={!!errors.lampType} {...register('lampType')} label="Type:">
                                {LAMP_CATEGORY_TYPE[lampCategory]?.map((item,index) => (
                                    <option value={item} key={index}>{item}</option>
                                ))}
                            </ChakraSelectLabel>
                        ) : (
                            <ChakraSelectLabel placeholder='Select' isInvalid={!!errors.fixtureMount} {...register('fixtureMount')} label="Mount:">
                                {FIXTURE_MOUNT.map((item,index) => (
                                    <option value={item} key={index}>{item}</option>
                                ))}
                            </ChakraSelectLabel>
                        )
                    }
                </GridItem>
                <GridItem colSpan={1}>
                    <InputLabel type='text' isInvalid={!!errors.vMin} {...register('vMin')} label="Voltage (min):"/>
                </GridItem>
                <GridItem colSpan={1}>
                    <InputLabel  isInvalid={!!errors.approxDeliveryDays} {...register('approxDeliveryDays')} label="Approx. Delivery (days):"/>
                </GridItem>
                <GridItem colSpan={1}>
                    {
                        type === 'lamp' ? (
                            <ChakraSelectLabel placeholder='Select' isInvalid={!!errors.lampBaseCategory} {...register('lampBaseCategory')} label="Base Category:">
                                {LAMP_BASE_CATEGORY.map((item,index) => (
                                    <option value={item} key={index}>{item}</option>
                                ))}
                            </ChakraSelectLabel>
                        ) : (
                            <ChakraSelectLabel placeholder='Select' isInvalid={!!errors.fixtureShape} {...register('fixtureShape')} label="Shape:">
                                {FIXTURE_SHAPE.map((item,index) => (
                                    <option value={item} key={index}>{item}</option>
                                ))}
                            </ChakraSelectLabel>
                        )
                    }
                </GridItem>
                <GridItem colSpan={1}>
                    <InputLabel isInvalid={!!errors.vMax} {...register('vMax')} label="Voltage (max):"/>
                </GridItem>
                <GridItem colSpan={1}>
                    <InputLabel type='number' isInvalid={!!errors.pricePerUnit} {...register('pricePerUnit', {valueAsNumber: true,})} label="Price Per Unit ($):"/>
                </GridItem>
                <GridItem colSpan={1}>
                    {type === 'lamp' ? (
                        <ChakraSelectLabel placeholder='Select' isInvalid={!!errors.lampBaseCategoryType} {...register('lampBaseCategoryType')} label="Base Type:">
                            {LAMP_BASE_CATEGORY_TYPE[lampBaseCategory]?.map((item, index) => (
                                <option value={item} key={index}>{item}</option>))}
                        </ChakraSelectLabel>
                    ) : (
                        <ChakraSelectLabel placeholder='Select' isInvalid={!!errors.ingressProtection} {...register('ingressProtection')} label="Ingress Protection:">
                            {INGRESS_PROTECTION.map((item, index) => (
                                <option value={item} key={index}>{item}</option>))}
                        </ChakraSelectLabel>
                        )}
                </GridItem>
                <GridItem colSpan={1}>
                    <ChakraSelectLabel placeholder='Select' isInvalid={!!errors.dimmability} {...register('dimmability')} label="Dimmability:">
                        {DIMMABILITY.map((item,index) => (
                            <option value={item} key={index}>{item}</option>
                        ))}
                    </ChakraSelectLabel>
                </GridItem>
                <GridItem colSpan={1}>
                    <InputLabel type='number' isInvalid={!!errors.volumeTier1MinQty} {...register('volumeTier1MinQty', {valueAsNumber: true,})} label="Volume Tier 1 (min. qty):"/>
                </GridItem>
                <GridItem colSpan={1}>
                    <ChakraSelectLabel placeholder='Select' isInvalid={!!errors.lensType} {...register('lensType')} label="Lens Type:">
                        {type === 'lamp' ? (LAMP_LENS_TYPE.map((item,index) => (<option value={item} key={index}>{item}</option>))) :
                            (FIXTURE_LENS_TYPE.map((item,index) => (<option value={item} key={index}>{item}</option>)))}
                    </ChakraSelectLabel>
                </GridItem>
                <GridItem colSpan={1}>
                    <ChakraSelectLabel placeholder='Select' isInvalid={!!errors.colorTunable} {...register('colorTunable')} label="Color Tunable:">
                        {COLOR_TUNABLE.map((item,index) => (
                            <option value={item} key={index}>{item}</option>
                        ))}
                    </ChakraSelectLabel>
                </GridItem>
                <GridItem colSpan={1}>
                    <InputLabel type='number' isInvalid={!!errors.volumeTier1PPU} {...register('volumeTier1PPU', {valueAsNumber: true,})} label="Volume Tier 1 PPU ($):"/>
                </GridItem>
                <GridItem colSpan={1}>
                    {type === 'lamp' ? (
                        <ChakraSelectLabel placeholder='Select' isInvalid={!!errors.ingressProtection} {...register('ingressProtection')} label="Ingress Protection:">
                            {INGRESS_PROTECTION.map((item,index) => (
                                <option value={item} key={index}>{item}</option>
                            ))}
                        </ChakraSelectLabel>
                    ) : (
                        <ChakraSelectLabel display={type === 'lamp' ? 'none' : 'block'}  placeholder='Select' isInvalid={!!errors.fixtureColor} {...register('fixtureColor')} label="Fixture Color:">
                            {FIXTURE_COLOR.map((item,index) => (
                                <option value={item} key={index}>{item}</option>
                            ))}
                        </ChakraSelectLabel>
                    )}
                </GridItem>
                <GridItem colSpan={1}>
                    <InputLabel isInvalid={!!errors.powerFactor} {...register('powerFactor')} label="Power Factor:"/>
                </GridItem>
                <GridItem colSpan={1}>
                    <InputLabel type='number' isInvalid={!!errors.volumeTier2MinQty} {...register('volumeTier2MinQty', {valueAsNumber: true,})} label="Volume Tier 2 (min. qty.):"/>
                </GridItem>
                <GridItem colSpan={1}>
                    <ChakraSelectLabel display={type === 'lamp' ? 'none' : 'block'}   placeholder='Select' isInvalid={!!errors.trim} {...register('trim')} label="Trim:">
                        {FIXTURE_TRIM.map((item,index) => (
                            <option value={item} key={index}>{item}</option>
                        ))}
                    </ChakraSelectLabel>
                </GridItem>
                <GridItem colSpan={1}>
                    <InputLabel isInvalid={!!errors.cBCP} {...register('cBCP')} label="CBCP:"/>
                </GridItem>
                <GridItem colSpan={1}>
                    <InputLabel type='number' isInvalid={!!errors.volumeTier2PPU} {...register('volumeTier2PPU', {valueAsNumber: true,})} label="Volume Tier 2 PPU ($):"/>
                </GridItem>
            </Grid>
            <Divider my="10" borderColor="#A9A9A9"/>
        </>
    )
}

export default MainFields
