import React from 'react'
import {Flex, Image, Box} from '@chakra-ui/react'

const Card = ({children, image, user, local = false, location}) => (
    <Flex cursor="pointer" borderRadius="10px" overflow="hidden" flexDirection="column" bg="#EDEDED" boxShadow="2px 4px 10px rgba(0, 0, 0, 0.25)">
        <Box bg="white.100" height="200px" width="100%">
            {user?.type === 'Su' ? (
                <Image height="100%" width="100%" objectFit="contain" alt="lamp"
                       src={image && local ? image : !image ? '/images/defaultLamp.jpg' : image}/>
            ) : (
                <Image height="100%" width="100%" objectFit='contain'
                       alt="lamp" src={image && local ? image : !image ? '/images/defaultLamp.jpg' : image}/>
            )}
        </Box>
        <Box py={4} px={5}>{children}</Box>
    </Flex>
)

export default Card
