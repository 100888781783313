import {Button, Flex, Text, Grid, GridItem, Box, Image, InputLeftElement, Input, InputGroup} from "@chakra-ui/react";
import {Link, useHistory} from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import {useEffect, useState} from "react";
import {useListCompanys} from "../../core/hooks";
import {S3_BUCKET} from "../../constants";
import {ReactComponent as Download} from '../../assets/uploadNewPhotoIcon.svg'
import {Icon} from "../../components/Theme";

const CompaniesSu = () => {
    const [companies, setCompanies] = useState([])
    const {data} = useListCompanys({type: 'Su'})
    const history = useHistory()

    const handleSearch = (e) => {
        setCompanies(data?.data.data.filter((item) => item.name.toLowerCase().includes(e.target.value.toLowerCase())))
    }

    useEffect(() => {
        if (!data) return
        setCompanies(data.data.data)
    }, [data])


    return (
        <>
            <Navbar minW="1240px" title={(
                    <Flex>
                        <Link as='h2' m={0} to="/catalogues">Catalogues&nbsp;</Link>
                        <Text as='h2' m={0}>/ Companies</Text>
                    </Flex>)}>
                <Flex>
                    <InputGroup width='180px'>
                        <InputLeftElement height="30px" pointerEvents="none" children={(<Icon height="16.5px" width="16.5px" type="search"/>)}/>
                        <Input onChange={handleSearch} height='30px' color="white.100" borderRadius="5px" border="none" bg="blue.200" placeholder="Search"/>
                    </InputGroup>
                    <Button ml={7} bg="#8FCF74" color="black" leftIcon={<Download/>} type="submit" height="30px">
                        Add New Product
                    </Button>
                </Flex>
            </Navbar>
            <Flex p={10} width='full' justifyContent='center'>
                <Grid w='full' gap={10} justifyItems='center' templateColumns={{base: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)', xl: 'repeat(4, 1fr)'}}>
                    {companies && companies?.map((item) => (
                            <GridItem key={item.id} onClick={() => history.push(`/companies/${item.id}`)} cursor='pointer' borderRadius='10px' maxH='180px' maxW='280px'>
                                <Image objectFit='cover' width='280px' height='117px' borderRadius='10px 10px 0 0' alt='Company image' src={S3_BUCKET + item.image}/>
                                <Box borderRadius='0 0 10px 10px' p={2} bg='#EDEDED'>
                                    <Text fontWeight='600'>{item.name}</Text>
                                    <Text fontSize='14px'>Products QTY:</Text>
                                </Box>
                            </GridItem>
                        ))}
                </Grid>
            </Flex>
        </>
    )
}

export default CompaniesSu