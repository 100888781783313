import React, {useEffect, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {Heading, Text, GridItem, SimpleGrid,} from '@chakra-ui/react'
import {useListProducts} from '../../core/hooks'
import {Card} from '../../components/Theme'
import HeaderForCatalogues from './HeaderForCatalogues'
import Loading from "../../components/Loading";

const Products = () => {
    const location = useLocation()
    const [products, setProducts] = useState([])
    const history = useHistory()
    const path = location.pathname.split('/')[2]
    let condition = {
        condition: {
            filter: '',
            table: path === 'fixtures' ? 'tblvI3QY2MCUx4x2Y' : 'tblg16sC25Lk6jyLI'
        }
    }
    const {data: productsResponse, isLoading: isProductLoading} = useListProducts(condition)

    const handleSearch = (e) => {
        // setProducts(productsResponse?.data.data.filter((item) => (item.manufacturer).toLowerCase().includes(e.target.value.toLowerCase())))
    }

    useEffect(() => {
        if (!productsResponse) return
        setProducts(productsResponse.data.data)
    }, [productsResponse])

    if (isProductLoading) return <Loading/>

    return (
        <>
            <HeaderForCatalogues handleSearch={handleSearch} title={path}/>
            <SimpleGrid p="12" w="full" columns={{base: 1, sm: 2, md: 3, lg: 4,}} spacing="8">
                {products?.map((product) => (
                    <GridItem colSpan={1} onClick={() => history.push(`/catalogues/product/${path}/${product?.id}`)} key={product?.id}>
                        <Card image={product.fields['Image'] ? product.fields['Image'][0]?.url : ''}>
                            <Heading fontSize="14px" fontFamily='Titillium Web'>{path === 'lamps' ? product.fields['Description'] : product.fields['FE Product Description']}</Heading>
                            <Text fontSize="12px">{
                                path.includes('lamp') ? (
                                    (product.fields['Length (inches)'] ? product.fields['Length (inches)']?.toString()?.replace(/(.{15})..+/, "$1…") + '"x' : ' ') +
                                    (product.fields['Width (inches)'] ? product.fields['Width (inches)']?.toString()?.replace(/(.{15})..+/, "$1…") + '"x' : ' ') +
                                    (product.fields['Diameter (inches)'] ? product.fields['Diameter (inches)']?.toString()?.replace(/(.{15})..+/, "$1…") + '"x' : ' ') +
                                    (product.fields['Depth (inches)'] ? product.fields['Depth (inches)']?.toString()?.replace(/(.{15})..+/, "$1…") + '"' : ' ') +
                                    (product.fields['Base Type'] ? ', ' + product.fields['Base Type'] : '') +
                                    (product.fields['Beam Angle'] ? ', ' + product.fields['Beam Angle'] : '') +
                                    (product.fields['Optics'] ? ', ' + product.fields['Optics'] : '') +
                                    (product.fields['Ingress Protection'] ? ', ' + product.fields['Ingress Protection'] : '') +
                                    (product.fields['Color Tunable'] ? ', CT ' + product.fields['Color Tunable'] : '') +
                                    (product.fields['L70 Lifetime (hours)'] ? ', ' + product.fields['L70 Lifetime (hours)'] + 'hrs' : '') +
                                    (product.fields['Warranty (years)'] ? ', ' + product.fields['Warranty (years)'] + 'yrs' : '')
                                ) : (
                                    (product.fields['Length (inches)'] ? product.fields['Length (inches)']?.toString()?.replace(/(.{15})..+/, "$1…") + '"x' : ' ') +
                                    (product.fields['Width (inches)'] ? product.fields['Width (inches)']?.toString()?.replace(/(.{15})..+/, "$1…") + '"x' : ' ') +
                                    (product.fields['Diameter (inches)'] ? product.fields['Diameter (inches)']?.toString()?.replace(/(.{15})..+/, "$1…") + '"x' : ' ') +
                                    (product.fields['Depth (inches)'] ? product.fields['Depth (inches)']?.toString()?.replace(/(.{15})..+/, "$1…") + '"' : ' ') +
                                    (product.fields['Mount'] ? ', ' + product.fields['Mount'] : '') +
                                    (product.fields['Shape'] ? ' ' + product.fields['Shape'] : '') +
                                    (product.fields['Lens Type'] ? ' ' + product.fields['Lens Type'] : '') +
                                    (product.fields['Ingress Protection'] ? ', ' + product.fields['Ingress Protection'] : '') +
                                    (product.fields['Fixture Color'] ? ', ' + product.fields['Fixture Color'] : '') +
                                    (product.fields['L70 Lifetime (hours)'] ? ', ' + product.fields['L70 Lifetime (hours)'] + 'hrs' : '') +
                                    (product.fields['Warranty (years)'] ? ', ' + product.fields['Warranty (years)'] + 'yrs' : '')
                                )
                            }</Text>
                        </Card>
                    </GridItem>))}
            </SimpleGrid>
        </>
    )
}

export default Products
