import React, {useEffect, useRef, useState} from 'react'
import {Link, useHistory, useParams} from 'react-router-dom'
import {Flex, Text, Box, Tabs, TabList, Tab, TabPanel, TabPanels, useToast} from '@chakra-ui/react'
import Navbar from '../../components/Navbar'
import Investment from './Investment'
import Financing from './Financing'
import Energy from './Energy'
import Eco from './Eco'
import {ReactComponent as CreateEstimate} from '../../assets/createEstimateIcon.svg'
import {ReactComponent as AcceptApprove} from '../../assets/accept.svg'
import {ReactComponent as CloseIcon} from '../../assets/closeNotificationIcon.svg'
import {ReactComponent as QuestionIcon} from '../../assets/Icons/questionIcon.svg'
import {useGetProject, useGetReport, useUser} from "../../core/hooks";
import Lumins from "./Lumins";
import FinancingInactive from "./FinancingInactive";
import httpRequest from "../../core/services/http-request";
import urid from 'urid';
import Loading from "../../components/Loading";
import ToastComponent from "../../components/toastComponent/ToastComponent";
import useCalculator from "../../core/hooks/useCalculator";
import mainStyles from "../../styles/mainStyles";
import styles from "./styles";
import Header from "./Parts/Header";
import {PRINT_TYPE, REPORT_TYPE} from "../../constants/orders/orderEnum";

const Proposal = () => {
    const {post, postPdf} = httpRequest()
    const params = useParams()
    const history = useHistory()
    const {user} = useUser()
    const toast = useToast()
    const {calculate, data, isLoading: isLoadingCalculator} = useCalculator(params.reportId)
    const [ledLuminaires, setLedLuminaires] = useState(0)
    const [shipping, setShipping] = useState(0)
    const [tax, setTax] = useState(0)
    const [rebates, setRebates] = useState(0)
    const [lightCountWaiver, setLightCountWaiver] = useState(0)
    const [reportNPV, setReportNPV] = useState(0)
    const [reportROI, setReportROI] = useState(0)
    const [reportPayback, setReportPayback] = useState(0)
    const [yearOneExisting, setYearOneExisting] = useState(0)
    const [yearThreeExisting, setYearThreeExisting] = useState(0)
    const [yearFiveExisting, setYearFiveExisting] = useState(0)
    const [yearOneLed, setYearOneLed] = useState(0)
    const [yearThreeLed, setYearThreeLed] = useState(0)
    const [yearFiveLed, setYearFiveLed] = useState(0)
    const [savings10, setSavings10] = useState(0)
    const [etaDays, setEtaDays] = useState(0)
    const [colorTunable, setColorTunable] = useState(0)
    const [sensor, setSensor] = useState(0)
    const [calcNumbers, setCalcNumbers] = useState({})
    const {data: projectResponse, isLoading, refetch} = useGetProject(params.projectId)
    const {data: reportDataResponse, refetch: refetchReport} = useGetReport(params.reportId)
    const [proposal, setProposal] = useState([])
    const [project, setProject] = useState([])
    const [loading, setLoading] = useState(false)
    const [energySaved, setEnergySaved] = useState()
    const [CRI, setCRI] = useState(0)
    const [globalCRI, setGlobalCRI] = useState(0)
    const [titles, setTitles] = useState(0)
    const [illuminance, setIlluminance] = useState(0)
    const [mercuryAbated, setMercuryAbated] = useState(0)
    const [cries, setCries] = useState([])
    const [lumens, setLumens] = useState([])
    const [globalLumens, setGlobalLumens] = useState(0)
    const [acSavings, setAcSavings] = useState(0)
    const [co2Abated, setCo2Abated] = useState(0)
    const [laborHourExisting, setLaborHourExisting] = useState(0)
    const [laborHourLed, setLaborHourLed] = useState(0)
    const [error, setError] = useState('')
    const [energySavedEco, setEnergySavedEco] = useState(0)
    const [projectTotal, setProjectTotal] = useState(0)
    const [ledYearEnergySmall, setLedYearEnergySmall] = useState(0)
    const [existingLuminairesCost, setExistingLuminairesCost] = useState(0)
    const [ledLuminairesCost, setLedLuminairesCost] = useState(0)
    const [existingSystemKW, setExistingSystemKW] = useState()
    const [ledSystemKW, setLedSystemKW] = useState()
    const [downloadingPDF, setDownloadingPDF] = useState({type: 'pdf', bool: false})
    const [dailyUsage, setDailyUsage] = useState()
    const [existingYearEnergySmall, setExistingYearEnergySmall] = useState(0)
    const [ledYearEnergyBig, setLedYearEnergyBig] = useState(0)
    const [existingYearEnergyBig, setExistingYearEnergyBig] = useState(0)
    const [totalLoanPdf, setTotalLoanPdf] = useState(0)
    const [financed, setFinanced] = useState(0)
    const refInvestment = useRef(null)
    const [pdfUrl, setPdfUrl] = useState(null);
    const [luminaire, setLuminaire] = useState([])
    const [totalLoan, setTotalLoan] = useState(0)
    const [customBiding, setCustomBiding] = useState(false)
    const [tabIndex, setTabIndex] = useState(0)

    const financingBar = () => {
        toast({
            render: () => (
                <ToastComponent title='Financing estimate will only generate if you populate the Finance input fields'
                                bg='#FCA68B' icon={<CreateEstimate/>}/>
            ),
            duration: 4000,
            isClosable: true,
            position: "top-right"
        })
    }

    const approvedReport = () => {
        setLoading(true)
        post('approve-report', proposal.id).then((e) => {
            if (e.data.data === 5) {
                setLoading(false)
                toast({
                    render: () => (
                        <ToastComponent title='Whoops! Approval removed...' bg='#D03E3E' icon={<CloseIcon/>}/>
                    ),
                    duration: 4000,
                    isClosable: true,
                    position: "top-right"
                })
                refetchReport()
                return refetch()
            }
            const data = {
                userReuId: user?.id,
                reportId: proposal?.id,
                luminaires: luminaire,
                reference: urid(12, 'num'),
            }
            setLoading(true)
            post('create-estimate', data).then((e) => {
                setLoading(false)
                if (e.data.data === 1) {
                    setError('Real Estate User must approve proposal!')
                }
            })
            setLoading(false)
            toast({
                render: () => (
                    <ToastComponent title='Congratulations! Check your inbox for confirmation' bg='#46CD0D'
                                    icon={<AcceptApprove/>}/>
                ),
                duration: 4000,
                isClosable: true,
                position: "top-right"
            })
            refetchReport()
            return refetch()
        })
    }

    const createEstimate = () => {
        if (proposal.status === "Unfinished") {
            return setError('You need to finish report for create estimate')
        }
        const data = {
            userReuId: project?.user?.id,
            reportId: proposal?.id,
            luminaires: luminaire,
            reference: urid(12, 'num'),
        }
        setLoading(true)
        post('create-estimate', data).then((e) => {
            setLoading(false)
            if (e.data.data === 1) {
                return setError('Real Estate User must approve proposal!')
            }else {
                history.push('/orders/su')
            }
        }).catch((e) => {
            if (e.response.data?.errorMessage === '4007') {
                setError('Already Order')
                setLoading(false)
            }else if (e.response.data?.errorMessage === '7001'){
                setLoading(false)
                return setError('Estimate already created')
            }
        })
    }

    useEffect(() => {
        if (!projectResponse) return
        setProject(projectResponse.data.data[0].project)
    }, [projectResponse])

    useEffect(() => {
        if (!reportDataResponse) return
        calculate()
        setProposal(reportDataResponse.data.data)
        if (reportDataResponse.data.data.customPricing){
            if (Object.values(reportDataResponse.data.data.customPricing).some(value => value === true)){
                setCustomBiding(true)
            }
        }

    }, [reportDataResponse])

    useEffect(() => {
        if (!data) return
        let results = data.data.data
        setCalcNumbers(results)
        setExistingSystemKW(results.existingSystem)
        setLedSystemKW(results.ledSystem)
        setEnergySaved(results.energySaved)
        setExistingYearEnergySmall(results.existingYearEnergySmall)
        setLedYearEnergySmall(results.ledYearEnergySmall)
        setEnergySavedEco(results.energySavedEco)
        setExistingYearEnergyBig(results.existingYearEnergyBig)
        setLedYearEnergyBig(results.ledYearEnergyBig)
        setDailyUsage(results.daily)
        setTitles(results.titles)
        setCries(results.cri)
        setLumens(results.lumens)
        setMercuryAbated(results.mercuryAbated)
        setLaborHourExisting(results.existingLaborHours)
        setLaborHourLed(results.ledLaborHours)
        setCRI(results.criLed)
        setIlluminance(results.illuminance)
        setGlobalCRI(results.criGlobal)
        setGlobalLumens(results.lumensGlobal)
        setCo2Abated(results.co2Abated)
        setAcSavings(results.acSavings)
        setColorTunable(results.colorTunable)
        setSensor(results.sensor)
        setReportROI(results.roi)
        setEtaDays(results.etaDay)
        setReportPayback(results.payback.toFixed(3))
        setExistingLuminairesCost(results.existingLuminairesCost)
        setLedLuminairesCost(results.ledLuminairesCost)
        setLedLuminaires(results.ledLuminaires)
        setShipping(results.shipping)
        setTax(results.tax)
        setLightCountWaiver(results.lightCountWaiver)
        setRebates(results.rebates)
        setSavings10(results.savings10.toLocaleString('en-US'))
        setYearOneExisting(results.yearOneExisting)
        setYearThreeExisting(results.yearThreeExisting)
        setYearFiveExisting(results.yearFiveExisting)
        setYearOneLed(results.yearOneLed)
        setYearThreeLed(results.yearThreeLed)
        setYearFiveLed(results.yearFiveLed)
        setReportNPV(results.reportNpv)
        setProjectTotal(results.projectTotal)
    }, [data])

    useEffect(() => {
        if (downloadingPDF.bool) {
            if (downloadingPDF.type === 'print') {
                let resp = {
                    reportId: proposal.id,
                    calculate: calcNumbers,
                    totalLoan: totalLoanPdf,
                    financed: financed,
                    pdfType: PRINT_TYPE,
                }
                postPdf('download-report', resp).then((response) => {
                    const blob = new Blob([response.data], {type: 'application/pdf'});
                    const blobUrl = URL.createObjectURL(blob);
                    const iframe = document.createElement('iframe');
                    iframe.style.display = 'none';
                    iframe.src = blobUrl;
                    document.body.appendChild(iframe);

                    iframe.onload = function() {
                        iframe.contentWindow.print();
                    };
                    setDownloadingPDF(false)
                }).catch(() => setDownloadingPDF(false))
            } else {
                downloadReportPdf()
            }
        }
        document.body.style.overflow = 'auto';
    }, [downloadingPDF.bool])

    function getCurrentDateFormatted() {
        const currentDate = new Date()
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
        const day = currentDate.getDate().toString().padStart(2, '0')
        const year = currentDate.getFullYear().toString().substr(-2)
        return `${month}.${day}.${year}`
    }

    const showBidingBar = () => {
        toast({
            render: () => (
                <ToastComponent
                    title='Investment details pending supplier pricing'
                    bg='#FF7F56'
                    icon={<QuestionIcon/>}/>
            ),
            duration: 4000,
            isClosable: true,
            position: "top-right"
        })
    }

    const downloadReportPdf = () => {
        let resp = {
            reportId: proposal.id,
            calculate: calcNumbers,
            totalLoan: totalLoanPdf,
            financed: financed,
            pdfType: REPORT_TYPE,
        }
        postPdf('download-report', resp).then((response) => {
            const blob = new Blob([response.data], {type: 'application/pdf'});
            const formattedDate = getCurrentDateFormatted();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${project?.address  + ' - ' +proposal.proposalName + ' - ' + formattedDate}.pdf`;
            a.click();
            window.URL.revokeObjectURL(url);
            setDownloadingPDF(false)
        }).catch(() => setDownloadingPDF(false))
    }

    if (isLoading) return <Loading/>

    return (
        <>
            <Navbar
                minW="1240px"
                title={(
                    <Flex>
                        <Link to="/projects">Projects /&nbsp;</Link>
                        <Text as='h2' m={0} cursor='pointer'
                              onClick={() => history.push(`/project/${project.id}`)}>{project?.address}</Text>
                        <Text as='h2' m={0}>&nbsp;/&nbsp;{proposal?.proposalName}</Text>
                    </Flex>
                )}/>
            <Header
                history={history}
                project={project}
                error={error}
                downloadingPDF={downloadingPDF}
                approvedReport={approvedReport}
                loading={loading}
                proposal={proposal}
                params={params}
                user={user}
                createEstimate={createEstimate}
                setDownloadingPDF={setDownloadingPDF}/>
            <Tabs onChange={(index) => setTabIndex(index)} css={mainStyles.container}>
                <TabList css={mainStyles.tabNames} >
                    <Tab
                        position='realative'
                        _selected={{
                            color: customBiding && '#A9A9A9 !important',
                    }}>
                        Investment
                        {customBiding && tabIndex === 0 && (
                            <Box
                                as="span"
                                position="absolute"
                                right="20px"
                                top="35%"
                                transform="translateY(-35%)"
                                width="16px"
                                height="16px"
                                backgroundColor="#FF7F56"
                                borderRadius="50%"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                fontSize="12px"
                                color="black"
                                fontWeight="bold"
                                cursor="pointer"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    showBidingBar()
                                }}>
                                ?
                            </Box>
                            )}
                    </Tab>
                    <Tab
                        position='realative'
                        _selected={{color: customBiding && '#A9A9A9 !important',position: 'relative',}}>
                        Energy
                        {customBiding  && tabIndex === 1 && (
                            <Box
                                as="span"
                                position="absolute"
                                right="20px"
                                top="35%"
                                transform="translateY(-35%)"
                                width="16px"
                                height="16px"
                                backgroundColor="#FF7F56"
                                borderRadius="50%"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                fontSize="12px"
                                color="black"
                                fontWeight="bold"
                                cursor="pointer"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    showBidingBar()
                                }}>
                                ?
                            </Box>
                        )}
                    </Tab>
                    <Tab
                        position='realative'
                        _selected={{color: customBiding && '#A9A9A9 !important',position: 'relative',}}>
                        Eco Incentive
                        {customBiding && tabIndex === 2 && (
                            <Box
                                as="span"
                                position="absolute"
                                right="20px"
                                top="35%"
                                transform="translateY(-35%)"
                                width="16px"
                                height="16px"
                                backgroundColor="#FF7F56"
                                borderRadius="50%"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                fontSize="12px"
                                color="black"
                                fontWeight="bold"
                                cursor="pointer"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    showBidingBar()
                                }}>
                                ?
                            </Box>
                        )}
                    </Tab>
                    <Tab
                        position='realative'
                        _selected={{color: customBiding && '#A9A9A9 !important',position: 'relative',}}>
                        Luminaires
                        {customBiding && tabIndex === 3 && (
                            <Box
                                as="span"
                                position="absolute"
                                right="20px"
                                top="35%"
                                transform="translateY(-35%)"
                                width="16px"
                                height="16px"
                                backgroundColor="#FF7F56"
                                borderRadius="50%"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                fontSize="12px"
                                color="black"
                                fontWeight="bold"
                                cursor="pointer"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    showBidingBar()
                                }}>
                                ?
                            </Box>
                        )}
                    </Tab>
                    <Tab
                        position='realative'
                        _active={{background: 'transparent',}}
                         _selected={{
                             color: project?.beFinancing === 'yes' && !customBiding ? 'green.200 !important' : '#A9A9A9 !important',
                             borderColor: project?.beFinancing === 'yes' && !customBiding ? 'green.200 !important' : '#A9A9A9 !important',
                             boxShadow: 'none',
                         }}
                         color={project?.beFinancing === 'yes'  ? "green.200 !important" : '#A9A9A9 !important'}>
                        Financing
                        {customBiding && tabIndex === 4 && (
                            <Box
                                as="span"
                                position="absolute"
                                right="20px"
                                top="35%"
                                transform="translateY(-35%)"
                                width="16px"
                                height="16px"
                                backgroundColor="#FF7F56"
                                borderRadius="50%"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                fontSize="12px"
                                color="black"
                                fontWeight="bold"
                                cursor="pointer"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    showBidingBar()
                                }}>
                                ?
                            </Box>
                        )}
                        {project?.beFinancing === 'no' && !customBiding && (
                            <Box ml={2} onClick={financingBar} fontSize='12px' backgroundColor='#A9A9A9'
                                 borderRadius='100%' height='17px' color='white' width='17px'>
                                ?
                            </Box>
                        )}
                    </Tab>
                </TabList>
                <TabPanels css={styles.tabPanels}>
                    <TabPanel css={styles.tabPanel} borderColor={customBiding && '#A9A9A9 !important'}>
                        <Investment
                            customBidding={customBiding}
                            loading={isLoadingCalculator}
                            yearFiveExisting={yearFiveExisting}
                            yearThreeExisting={yearThreeExisting}
                            yearOneExisting={yearOneExisting}
                            yearOneLed={yearOneLed}
                            yearFiveLed={yearFiveLed}
                            yearThreeLed={yearThreeLed}
                            lightCountWaiver={lightCountWaiver}
                            rebates={rebates}
                            reportPayback={reportPayback}
                            savings10={savings10}
                            reportNPV={reportNPV}
                            reportROI={reportROI}
                            refInvestment={refInvestment}
                            energySaved={energySaved}
                            energySavedEco={energySavedEco}
                            ledLuminairesCost={ledLuminairesCost}
                            tax={tax}
                            shipping={shipping}
                            ledLuminaires={ledLuminaires}
                            project={project}
                            proposal={proposal}
                            existingLuminairesCost={existingLuminairesCost}
                            projectTotal={projectTotal}
                            />
                    </TabPanel>
                    <TabPanel css={styles.tabPanel}>
                        <Energy
                            customBidding={customBiding}
                            existingSystemKW={existingSystemKW}
                            ledSystemKW={ledSystemKW}
                            loading={isLoadingCalculator}
                            ledYearEnergyBig={ledYearEnergyBig}
                            existingYearEnergySmall={existingYearEnergySmall}
                            existingYearEnergyBig={existingYearEnergyBig}
                            energySaved={energySaved}
                            ledYearEnergySmall={ledYearEnergySmall}/>
                    </TabPanel>
                    <TabPanel css={styles.tabPanel}>
                        <Eco
                            customBidding={customBiding}
                            loading={isLoadingCalculator}
                            acSavings={acSavings}
                            co2Abated={co2Abated}
                            mercuryAbated={mercuryAbated}
                            laborHourLed={laborHourLed}
                            laborHourExisting={laborHourExisting}
                            illuminance={illuminance}
                            globalCRI={globalCRI}
                            globalLumens={globalLumens}
                            titles={titles}
                            cries={cries}
                            lumens={lumens}
                            project={project}
                            proposal={proposal}
                            energySavedEco={energySavedEco}/>
                    </TabPanel>
                    <TabPanel css={styles.tabPanel}>
                        <Lumins
                            customBidding={customBiding}
                            setLuminaire={setLuminaire}
                            luminaire={luminaire}
                            project={project}
                            proposal={proposal}/>
                    </TabPanel>
                    {
                        project?.beFinancing === 'yes' && !customBiding ? (
                            <TabPanel css={styles.tabPanel}>
                                <Financing
                                    customBidding={customBiding}
                                    totalLoan={totalLoan}
                                    setTotalLoan={setTotalLoan}
                                    yearOneLed={yearOneLed}
                                    yearOneExisting={yearOneExisting}
                                    setFinanced={setFinanced}
                                    setTotalLoanPdf={setTotalLoanPdf}
                                    project={project}
                                    proposal={proposal}
                                    projectTotal={projectTotal}
                                    energySaved={ledYearEnergyBig}
                                    led={ledLuminairesCost}
                                    exisiting={existingLuminairesCost}/>
                            </TabPanel>
                        ) : (
                            <TabPanel css={styles.tabPanel}>
                                <FinancingInactive
                                    customBidding={customBiding}
                                    yearOneLed={yearOneLed}
                                    totalLoan={totalLoan}
                                    setTotalLoan={setTotalLoan}
                                    yearOneExisting={yearOneExisting}
                                    project={project}
                                    proposal={proposal}
                                    projectTotal={projectTotal}
                                    energySaved={ledYearEnergyBig}
                                    led={ledLuminairesCost}
                                    exisiting={existingLuminairesCost}/>
                            </TabPanel>
                        )
                    }
                </TabPanels>
            </Tabs>
        </>
    )
}

export default Proposal
