import React, {useState, useEffect, useRef, useMemo} from 'react'
import {Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel, Button, Input, Flex, Image, Box, VStack, HStack, GridItem, SimpleGrid, useDisclosure, Text, IconButton} from '@chakra-ui/react'
import {v4 as uuid} from 'uuid'
import {useUpload} from '../../core/hooks'
import {S3_BUCKET} from '../../constants'
import {ReactComponent as LuminaireIcon} from '../../assets/Icons/addLuminaireSmallIcon.svg'
import {ReactComponent as UploadIcon} from '../../assets/uploadPhoto.svg'
import AreaInput from './AreaInput'
import {AccordionSolo} from '../../components/Theme'
import {ReactComponent as DeleteLum} from '../../assets/del.svg'
import DeleteAlert from './DeleteAlert'

const Area = ({idx, getValues,allAreas, areas, user, areaName, recent, setRecent, register, remove, watch, setValue, data,}) => {
    const [area, setArea] = useState({luminaires: []})
    const {onOpen: onOpenAlert, isOpen: isOpenAlert, onClose: onCloseAlert} = useDisclosure()
    const inputRef = useRef()
    const {upload, isLoading} = useUpload()
    const [application, setApplication] = useState('')
    const [dailyUsage, setDailyUsage] = useState('')
    const [areaSqFt, setAreaSqFt] = useState('')
    const [errors, setErrors] = useState('')

    const onChange = (key, value) => (setArea({...area, [key]: value}))

    const removeLuminaire = (id) => {
        const newLuminaires = area?.luminaires.filter((item) => item.id !== id)
        setArea({...area, luminaires: newLuminaires})
    }

    const handleImage = (e) => {
        const file = e.target.files[0]
        const imageType = file.type.split('/')[1] || 'jpeg'
        const name = `${new Date().getTime() * Math.random()}.${imageType}`
        upload({name, file, type: imageType},
            {
                onSuccess: (key) => {
                    onChange('image', key.data.data)
                    setValue(`areas.${idx}.image`, key.data.data)
                },
                onError: (imageError) => console.log(imageError),
            },
        )
    }

    useMemo(() => {
        if (!area) return
        setValue(`areas.${idx}.luminaires`, area.luminaires)
        setValue(`areas.${idx}.luminaires`, area.luminaires)
        if (getValues(`areas.${idx}.dailyUsage`)) {
            setDailyUsage(getValues(`areas.${idx}.dailyUsage`))
        }
        if (getValues(`areas.${idx}.areaSqFt`)) {
            setAreaSqFt(getValues(`areas.${idx}.areaSqFt`))
        }
    }, [area])

    useEffect(() => {
        if (!data) return
        setArea({
            areaTitle: getValues(`areas.${idx}.title`) ? getValues(`areas.${idx}.title`) : '',
            sqFt: getValues(`areas.${idx}.areaSqFt`) ? getValues(`areas.${idx}.areaSqFt`) : '',
            luminaires: data.luminaires ? data.luminaires : [],
            image: data.image ? data.image : ''
        })
    }, [data])

    useEffect(() => {
        let areaTotal = Number(getValues()?.propertySize)
        if (getValues()?.areas?.length > 1) {
            let count = 0
            for (let i = 0; i < getValues()?.areas?.length; i++) {
                count += Number(getValues()?.areas[i].areaSqFt)
            }
            if (count > areaTotal) {
                return setErrors('Area square footage cannot exceed Property sq. ft.')
            } else {
                setErrors('')
            }
        } else {
            if (areaSqFt > areaTotal) {
                setErrors('Area square footage cannot exceed Property sq. ft.')
            } else {
                setErrors('')
            }
        }
    }, [areaSqFt])

    if (areaName && areaName !== data.title) return <></>
    return (
        <>
            <DeleteAlert onCloseAlert={onCloseAlert} isOpenAlert={isOpenAlert} idx={idx} remove={remove}/>
            <Accordion defaultIndex={0} allowToggle>
                <AccordionItem position="relative">
                    {({isExpanded}) => (
                        <>
                            <IconButton display={isExpanded === true && user.type !== 'Eu' ? 'flex' : 'none'} onClick={onOpenAlert} height="20px" minW="20px" position="absolute" top="-10px" left="-10px" borderRadius="5px" bg="#FB7575" aria-label="del" icon={<DeleteLum width="8px" height="10px"/>}/>
                            <Flex px="4" alignItems="center" justifyContent="space-between" borderRadius="10px" minH="57px" bg="#2C628D" flex="1">
                                <Flex width='70%' alignItems="center">
                                    <Input color="white" variant="unstyled" fontWeight='500' fontSize='32px' width='full' placeholder="Area Title"
                                        {...register(`areas.${idx}.title`, {required: true})}/>
                                    {!!errors.areas && !!errors.areas[idx].title && (
                                            <Box fontSize="sm" whiteSpace="nowrap" color="white" as="span">
                                                Area title is required
                                            </Box>)}
                                </Flex>
                                <Flex alignItems="center">
                                    <Button mr="5"
                                            width='129px'
                                            height='29px'
                                        onClick={() => {setArea({...area, luminaires: [...area.luminaires, {id: uuid()},],})}}
                                        leftIcon={<LuminaireIcon/>}
                                        variant="yellow">
                                        Add Luminaire
                                    </Button>
                                    <AccordionButton borderRadius="3px" border="2px solid" borderColor="white" fontWeight='600' fontSize="sm" color="white" height="30px" maxW="135px">
                                        Area Details
                                        <AccordionIcon ml={3}/>
                                    </AccordionButton>
                                </Flex>
                            </Flex>
                            <AccordionPanel pb={4} mt={-4}>
                                <Flex flexDirection="column">
                                    {errors && (<Text textAlign='center' mt={5} color="red.100" fontSize="m">{errors}</Text>)}
                                    <HStack spacing={10}>
                                        <VStack>
                                            <Flex width="100%">
                                                <Button mb={1} mt={9} width="120px" height="28px" leftIcon={<UploadIcon/>} isLoading={isLoading} onClick={() => inputRef.current.click()} variant="green">
                                                    <Text as='span'>Upload Photo</Text>
                                                </Button>
                                            </Flex>
                                            <Image mb="4" src={area?.image ? area?.image && `${S3_BUCKET}${area.image}` : ' '} height="274px" width="341px"/>
                                            <input style={{display: 'none'}} type="file" ref={inputRef} accept="image/*" onChange={handleImage}/>
                                        </VStack>
                                        <AreaInput
                                            areaSqFt={areaSqFt}
                                            setAreaSqFt={setAreaSqFt}
                                            dailyUsage={dailyUsage}
                                            setDailyUsage={setDailyUsage}
                                            getValues={getValues}
                                            setValue={setValue}
                                            idx={idx}
                                            register={register}/>
                                    </HStack>
                                </Flex>
                            </AccordionPanel>
                        </>
                    )}
                </AccordionItem>
            </Accordion>
            {area?.luminaires?.length > 0 && (
                    <HStack mt="10" mb="5" spacing="6" w="full">
                        <HStack w="550px" spacing="4rem" pl="7" fontWeight="600">
                            <Box fontFamily='Titillium Web' fontSize="xl" fontWeight="600" as="span">QTY</Box>
                            <Box fontFamily='Titillium Web' fontSize="xl" fontWeight="600" as="span">Luminaire Description</Box>
                        </HStack>
                        {area?.luminaires.length > 1 && (
                                <HStack w="550px" spacing="4rem" pl="7" fontWeight="600">
                                    <Box fontSize="xl" fontWeight="600" as="span">QTY</Box>
                                    <Box fontSize="xl" fontWeight="600" as="span">Luminaire Description</Box>
                                </HStack>)}
                    </HStack>)}
            <SimpleGrid my="6" w="full" columns={{base: 2}} spacing={6}>
                {area?.luminaires?.map((luminaire) => (
                        <GridItem key={luminaire.id}>
                            <AccordionSolo
                                allAreas={allAreas}
                                idx={idx}
                                data={data}
                                areaName={areaName}
                                getValues={getValues}
                                watch={watch}
                                register={register}
                                application={application}
                                setApplication={setApplication}
                                areas={areas}
                                luminaires={area?.luminaires}
                                remove={removeLuminaire}
                                setRecent={setRecent}
                                recent={recent}
                                luminaire={luminaire}
                                area={area}
                                setArea={setArea}/>
                        </GridItem>))}
            </SimpleGrid>
        </>
    )
}

export default Area
