import { useQuery } from 'react-query'
import { dataUseCase } from '../../factories'

const useGetDataMain = () => {
  const {
    refetch, isLoading, data: mainData, error,
  } = useQuery('dataMain', () => dataUseCase.getUsageMain())

  return {
    refetch,
    isLoading,
    error,
    mainData,
  }
}

export default useGetDataMain
