import {useEffect, useRef, useState} from 'react'
import {useHistory, useParams} from "react-router-dom";
import httpRequest from "../../core/services/http-request";
import Navbar from "../../components/Navbar";
import {
    useDisclosure,
    Tabs,
    TabPanels,
    TabPanel,
    useToast,
} from "@chakra-ui/react";
import ShareEstimateModal from "./shareEstimateModal";
import {useGetEstimate, useUploadMultiple, useUser} from "../../core/hooks";
import SignatureModal from "./SignatureModal";
import EstimateSuTab from "./EstimateTabs/EstimateSu";
import OrderSuTab from "./EstimateTabs/OrderSuTab";
import Shipping from "./EstimateTabs/Shipping";
import moment from "moment/moment";
import {ReactComponent as SendMessage} from "../../assets/unfinishedIcon.svg";
import {ReactComponent as CloudIcon} from "../../assets/Icons/invitationSendIconBlack.svg";
import Invoice from "./EstimateTabs/Invoice";
import Receipt from "./EstimateTabs/Receipt";
import ToastComponent from "../../components/toastComponent/ToastComponent";
import {INVOICE, RECEIPT} from "../../constants/fileTypes";
import DeliveredModal from "../EstimateReu/DeliveredModal";
import Header from "./parts/Header";
import TabList from "./parts/TabList";
import {SU} from "../../constants/user/userEnum";
import {ORDER_TYPES, SHIPPING_DETAIL} from "../../constants/orders/orderEnum";

const EstimateSu = () => {
    const toast = useToast()
    const refEstimate = useRef(null);
    const refOrder = useRef(null);
    const refShipping = useRef(null);
    const refInvoice = useRef(null);
    const refReceipt = useRef(null);
    const params = useParams()
    const {post, postPdf} = httpRequest()
    const {user} = useUser()
    const [estimate, setEstimate] = useState('')
    const [salesTax, setSalesTax] = useState('')
    const [shipping, setShipping] = useState('')
    const [shippingPageStatus, setShippingPageStatus] = useState(1)
    const [depositAmount, setDepositAmount] = useState(0)
    const [depositDue, setDepositDue] = useState(0)
    const [remainderAmount, setRemainderAmount] = useState(0)
    const [remainderDue, setRemainderDue] = useState(0)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [tabIndex, setTabIndex] = useState(0)
    const [total, setTotal] = useState('')
    const [notes, setNotes] = useState('')
    const [subTotal, setSubTotal] = useState('')
    const [luminaires, setLuminaires] = useState([])
    const [shippingDetails, setShippingDetails] = useState([])
    const [imageURL, setImageURL] = useState(null)
    const [imageUrlAdmin, setImageUrlAdmin] = useState(null)
    const [trackId, setTrackId] = useState('')
    const [edit, setEdit] = useState(false)
    const [loadingPdf, setLoadingPdf] = useState(false)
    const [adminRall, setAdminRall] = useState('')
    const [createShippingAllow, setCreateShippingAllow] = useState(false)
    const [editShippingAllow, setEditShippingAllow] = useState(false)
    const [invoiceFile, setInvoiceFile] = useState([])
    const [receiptFile, setReceiptFile] = useState()
    const [loadingFiles, setLoadingFiles] = useState(false)
    const inputRef = useRef()
    const history = useHistory()
    const {upload,} = useUploadMultiple()
    const {isOpen, onOpen, onClose} = useDisclosure()
    const {isOpen: isOpenDelivered, onOpen: onOpenDelivered, onClose: onCloseDelivered} = useDisclosure()
    const {isOpen: isOpenSignature, onOpen: onOpenSignature, onClose: onCloseSignature} = useDisclosure()
    const date = new Date()
    const {data: orderData, refetch, isLoading: isLoadingOrder} = useGetEstimate({
        type: 'su',
        id: params.id
    })
    let currentDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`
    const [companySu, setCompanySu] = useState([])
    const [companyReu, setCompanyReu] = useState([])

    useEffect(() => {
        if (!estimate?.userSu) return
        if (estimate?.userSu?.company?.length > 0){
            setCompanySu(estimate?.userSu?.company[0])
        } else {
            setCompanySu(estimate?.userSu?.associatedToCompany)
        }
        if (estimate?.userReu?.company?.length > 0){
            setCompanyReu(estimate?.userReu?.company[0])
        } else {
            setCompanyReu(estimate?.userReu?.associatedToCompany)
        }
    },[estimate])


    const handleImage = (e) => {
        const filesToUpload = []
        const dataToPreview = []
        Object.values(e.target.files).forEach(
            (file) => {
                const type = file.type.split('/')[1] || 'jpeg'
                const name = `${new Date().getTime() * Math.random()}.${type}`
                dataToPreview.push({
                    name: file.name,
                    key: name,
                    createdAt: new Date().toISOString(),
                })
                filesToUpload.push({name, file, type,})
            },
        )
        upload(
            filesToUpload,
            {
                onSuccess: (e) => {
                    if (tabIndex === 3) {
                        let estimateType = window.location.pathname.includes('su') ? 'su' : 'reu'
                        post('create-uploads', [e[0].data.data, dataToPreview, 3, estimate.id, estimateType]).then((e) => {
                            // getFiles(INVOICE)
                            refetch()
                        })
                    } else if (tabIndex === 4) {
                        let estimateType = window.location.pathname.includes('su') ? 'su' : 'reu'
                        post('create-uploads', [e[0].data.data, dataToPreview, 4, estimate.id, estimateType]).then((e) => {
                            // getFiles(RECEIPT)
                            refetch()
                        })
                    }
                },
            },
        )
    }
    const approveEstimate = () => {
        if (estimate.suApproved) {
            return setError('Already authorized')
        }
        if (!salesTax) {
            return setError('Write Tax')
        }
        if (!shipping) {
            return setError('Write Shipping')
        }
        if (!imageURL) {
            return setError('Write Signature')
        }
        const data = {
            tax: salesTax,
            shipping: shipping,
            notes: notes,
            estimateId: estimate?.id,
            date: currentDate,
            signature: imageURL,
        }
        setError('')
        setLoading(true)
        post('approve-estimate-supplier', data).then(() => {
            refetch()
            setLoading(false)
            toast({
                render: () => (
                    <ToastComponent bg='#8FCF74' icon={<SendMessage/>} title='Estimate Authorized!'/>
                ),
                duration: 4000,
                isClosable: true,
                position: "top-right"
            })
        }).catch(() => {
            setLoading(false)
        })
    }

    const authorize = () => {
        if (estimate?.adminSignature) return
        let data = {
            estimateId: estimate?.id,
            imageUrlAdmin: imageUrlAdmin,
            date: currentDate,
        }
        setLoading(true)
        post('authorize-order-su', data).then(() => {
            refetch()
            setLoading(false)
            toast({
                render: () => (
                    <ToastComponent bg='#8FCF74' icon={<SendMessage/>} title='Order Authorized!'/>
                ),
                duration: 4000,
                isClosable: true,
                position: "top-right"
            })
        })
    }

    const createShipping = () => {
        setLoading(true)
        post('create-shipping-su', estimate?.id).then(() => {
            setLoading(false)
            refetch()
            toast({
                render: () => (
                    <ToastComponent bg='#8FCF74' icon={<SendMessage/>} title='Shipping Created!'/>
                ),
                duration: 4000,
                isClosable: true,
                position: "top-right"
            })
            setTabIndex(2)
        }).catch((e) => {
            setLoading(false)
            if (e.response.data?.errorMessage === '7002') {
                toast({
                    render: () => (
                        <ToastComponent bg='#FCA68B' icon={<SendMessage/>}
                                        title='Shipping document has already created'/>
                    ),
                    duration: 4000,
                    isClosable: true,
                    position: "top-right"
                })
            }
        })
    }

    const createShippingDetail = (filter) => {
        setCreateShippingAllow(true)
    }

    const editShippingDetail = (trackId, filter) => {
        setLoading(true)
        setEditShippingAllow(true)
    }

    const createOrder = () => {
        let data = {
            type: 'su',
            estimateId: estimate?.id,
        }
        setLoading(true)
        post('create-order', data).then(() => {
            refetch()
            setLoading(false)
            toast({
                render: () => (
                    <ToastComponent bg='#8FCF74' icon={<SendMessage/>} title='Order Created!'/>
                ),
                duration: 4000,
                isClosable: true,
                position: "top-right"
            })
            setTabIndex(1)
        }).catch((e) => {
            setLoading(false)
            if (e.response.data?.errorMessage === '7001') {
                setError('Already Order')
            }
        })
    }

    const createInvoice = () => {
        setLoading(true)
        post('create-invoice-su', estimate?.id).then(() => {
            refetch()
            setLoading(false)
            toast({
                render: () => (
                    <ToastComponent bg='#8FCF74' icon={<SendMessage/>} title='Invoice Created!'/>
                ),
                duration: 4000,
                isClosable: true,
                position: "top-right"
            })
            setTabIndex(3)
        }).catch((e) => {
            if (e.response.data?.errorMessage === '7003') {
                setError('Already Invoice')
            }
            setLoading(false)
        })
    }

    const createReceipt = () => {
        setLoading(true)
        post('create-receipt-su', estimate?.id).then(() => {
            refetch()
            setLoading(false)
            toast({
                render: () => (
                    <ToastComponent bg='#8FCF74' icon={<SendMessage/>} title='Receipt Created!'/>
                ),
                duration: 4000,
                isClosable: true,
                position: "top-right"
            })
            setTabIndex(4)
        }).catch((e) => {
            if (e.response.data?.errorMessage === '7004') {
                setError('Already Receipt')
            }
            setLoading(false)
        })
    }

    const markFulfilled = () => {
        setLoading(true)
        post('create-fulfilled-su', estimate?.id).then(() => {
            refetch()
            setLoading(false)
            toast({
                render: () => (
                    <ToastComponent bg='#8FCF74' icon={<SendMessage/>} title='Marked as fulfilled!'/>
                ),
                duration: 4000,
                isClosable: true,
                position: "top-right"
            })
        }).catch((e) => {
            if (e.response.data?.errorMessage === '7005') {
                setError('Already Fulfilled')
            }
            setLoading(false)
        })
    }

    const archiveOrder = () => {
        setLoading(true)
        let data = {
            id: estimate?.id,
            type: 'Su',
        }
        post('archive-order', data).then(() => {
            refetch()
            setLoading(false)
            toast({
                render: () => (
                    <ToastComponent bg='#8FCF74' icon={<SendMessage/>} title='Archived!'/>
                ),
                duration: 4000,
                isClosable: true,
                position: "top-right"
            })
            history.push('/orders/su')
        }).catch((e) => {
            if (e.response.data?.errorMessage === '7006') {
                setError('Already Archived')
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        if (!orderData) return
        let leds = []
        let subTotalLocal = 0
        setEstimate(orderData.data.data)
        setImageURL(orderData.data.data?.suSignature)
        setSalesTax(orderData.data.data?.salesTax)
        setShipping(orderData.data.data?.shipping)
        setNotes(orderData.data.data?.suNotes)
        setImageUrlAdmin(orderData.data.data?.adminSignature)
        let lamps = orderData.data.data?.luminaires
        for (let j = 0; j < lamps?.length; j++) {
            let qtyGlob = ((lamps[j]?.recomendedLampQty ? Number(lamps[j]?.recomendedLampQty) : (lamps[j]?.lampPerFixture ? lamps[j]?.lampPerFixture : 1)) *
                (lamps[j]?.recomendedFixtureQty ? Number(lamps[j]?.recomendedFixtureQty) : Number(lamps[j]?.qty)))
            if (lamps[j]?.lampRecomendedAirtable?.length > 0) {
                leds.push(lamps[j])
                lamps[j].error = false
                if (JSON.parse(lamps[j].lampRecomendedAirtable)['SU PPU ($) Custom']){
                    subTotalLocal += JSON.parse(lamps[j].lampRecomendedAirtable)['SU PPU ($) Custom'] * qtyGlob
                } else {
                    subTotalLocal += ((qtyGlob >= 100 && qtyGlob < 200 ?
                            ((JSON.parse(lamps[j].lampRecomendedAirtable)['Volume Tier 1 PPU ($)'] ? JSON.parse(lamps[j].lampRecomendedAirtable)['Volume Tier 1 PPU ($)']?.replace(/[^\d.]/g, '') : 1))
                            : qtyGlob >= 200 ?
                                ((JSON.parse(lamps[j].lampRecomendedAirtable)['Volume Tier 2 PPU ($)'] ? JSON.parse(lamps[j].lampRecomendedAirtable)['Volume Tier 2 PPU ($)']?.replace(/[^\d.]/g, '') : 1))
                                : JSON.parse(lamps[j].lampRecomendedAirtable)['Price Per Unit ($)']?.replace(/[^\d.]/g, ''))
                        * qtyGlob)
                }
            } else if (lamps[j]?.fixtureRecomendedAirtable?.length > 0) {
                leds.push(lamps[j])
                lamps[j].error = false
                if (JSON.parse(lamps[j].fixtureRecomendedAirtable)['SU PPU ($) Custom']){
                    subTotalLocal += JSON.parse(lamps[j].fixtureRecomendedAirtable)['SU PPU ($) Custom'] * qtyGlob
                } else {
                    subTotalLocal += ((qtyGlob >= 100 && qtyGlob < 200 ?
                            ((JSON.parse(lamps[j].fixtureRecomendedAirtable)['Volume Tier 1 PPU ($)'] ? JSON.parse(lamps[j].fixtureRecomendedAirtable)['Volume Tier 1 PPU ($)']?.replace(/[^\d.]/g, '') : 1))
                            : qtyGlob >= 200 ?
                                ((JSON.parse(lamps[j].fixtureRecomendedAirtable)['Volume Tier 2 PPU ($)'] ? JSON.parse(lamps[j].fixtureRecomendedAirtable)['Volume Tier 2 PPU ($)']?.replace(/[^\d.]/g, '') : 1))
                                : JSON.parse(lamps[j].fixtureRecomendedAirtable)['Price Per Unit ($)']?.replace(/[^\d.]/g, ''))
                        * qtyGlob)
                }
            }
        }
        setLuminaires(leds)
        setSubTotal(Math.round(subTotalLocal))
        let filinvoice = [];
        if (orderData.data.data?.invoiceFiles) {
            for (let i = 0; i < orderData.data.data?.invoiceFiles?.length; i++) {
                filinvoice.push(orderData.data.data?.invoiceFiles[i]?.file)
            }
            setInvoiceFile(filinvoice)
            // getFiles(INVOICE)
        }
        if (orderData.data.data?.receiptFiles) {
            let fil = [];
            for (let i = 0; i < orderData.data.data?.receiptFiles?.length; i++) {
                fil.push(orderData.data.data?.receiptFiles[i]?.file)
            }
            setReceiptFile(fil)
            // getFiles(RECEIPT)
        }
    }, [orderData])

    async function getFiles(type) {
        setLoadingFiles(true)
        let data = {
            estimateId: orderData.data.data.id,
            files: type === INVOICE ? orderData.data.data.invoiceFiles : orderData.data.data.receiptFiles,
            type: type
        }
        await post('get-files', data).then((e) => {
            if (e.data.data[1] === INVOICE) {
                setInvoiceFile(e.data.data[0])
            } else if (e.data.data[1] === RECEIPT) {
                setReceiptFile(e.data.data[0])
            }
            setLoadingFiles(false)
        }).catch(() => setLoadingFiles(false))
    }

    const downloadPDF = (type = 'download') => {
        let data = {
            luminaires: luminaires,
            salesTax: salesTax?.toLocaleString('en-US') ?? 0,
            rebates: 0,
            reference: estimate?.reference,
            date: moment(estimate?.createdAt?.date).format('MM/DD/YYYY'),
            notes: notes,
            shipping: shipping?.toLocaleString('en-US') ?? 0,
            total: total?.toLocaleString('en-US') ?? 0,
            subTotal: subTotal?.toLocaleString('en-US') ?? 0,
            depositAmount: depositAmount?.toLocaleString('en-US') ?? 0,
            depositDue: depositDue?.toLocaleString('en-US') ?? 0,
            remainderAmount: remainderAmount?.toLocaleString('en-US') ?? 0,
            remainderDue: remainderDue?.toLocaleString('en-US') ?? 0,
            companyName: estimate?.userReu?.company?.length > 0 ? estimate?.userReu?.company[0]?.name : estimate?.userReu?.associatedToCompany?.name,
            estimateId: estimate?.id,
            attn: estimate?.userReu?.firstName + ' ' + estimate?.userReu?.lastName,
            address: estimate?.report?.project?.address,
            adminSignature: imageUrlAdmin,
            adminName: estimate?.userAdminConsultant ? estimate?.userAdminConsultant?.firstName + ' ' + estimate?.userAdminConsultant?.lastName : null,
            adminSignatureDate: estimate?.adminSignatureDate ? estimate?.adminSignatureDate : currentDate,
            address2: estimate?.report?.project?.address2,
            city: estimate?.report?.project?.city + ', ' + estimate?.report?.project?.state + ', ' + estimate?.report?.project?.zipCode,
            phone: estimate?.userReu?.phone,
            companySu: companySu,
            companyReu: companyReu,
            imageURL: imageURL,
            suSignatureDate: estimate?.suApproved ? estimate.suSignatureDate : currentDate,
            printName: estimate?.userSu?.firstName + ' ' + estimate?.userSu?.lastName,
            orderType: SU,
            orderDocumentType: shippingPageStatus === 3 ? SHIPPING_DETAIL : ORDER_TYPES[tabIndex],
        }
        setLoadingPdf(true)
        postPdf('download-estimate', data).then((response) => {
            const blob = new Blob([response.data], {type: 'application/pdf'});
            setLoadingPdf(false)
            if (type.type === 'share') {
                const fileType = 'pdf'
                const name = `order${new Date().getTime() * Math.random()}.${fileType}`
                const formData = new FormData()
                formData.append("file", blob);
                formData.append("name", name);
                formData.append("type", fileType);

                return post('upload-file', formData).then((e) => {
                    let req = {
                        firstName: type.firstName,
                        lastName: type.lastName,
                        email: type.email,
                        fileName: e.data.data,
                        type: shippingPageStatus === 3 ? 'ShippingDetail': ORDER_TYPES[tabIndex] === 1 ? 'Estimate' : ORDER_TYPES[tabIndex] === 2 ? 'Order' : 'Shipping',
                        reference: estimate?.reference,
                    }
                    post('share-estimate-email', req).then(() => {
                        toast({
                            render: () => (
                                <ToastComponent bg='#8FCF74' icon={<CloudIcon/>} title='Shared Success!'/>
                            ),
                            duration: 4000,
                            isClosable: true,
                            position: "top-right"
                        })
                        onClose()
                    })
                })
            } else {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = shippingPageStatus === 3 ? `ShippingDetail #${estimate?.reference} ${moment(estimate?.createdAt?.date).format('MM-DD-YYYY')}.pdf`: ORDER_TYPES[tabIndex] === 1 ? `Estimate #${estimate?.reference} ${moment(estimate?.createdAt?.date).format('MM-DD-YYYY')}.pdf` : ORDER_TYPES[tabIndex] === 2 ? `Order #${estimate?.reference} ${moment(estimate?.createdAt?.date).format('MM-DD-YYYY')}.pdf` : `Shipping #${estimate?.reference} ${moment(estimate?.createdAt?.date).format('MM-DD-YYYY')}.pdf`;
                a.click();
                window.URL.revokeObjectURL(url);
            }
        })
            .catch(() => setLoadingPdf(false))
        setError('')

    }

    useEffect(() => {
        let type = params?.type
        if (type === '2') {
            setTabIndex(1)
        } else if (type === '3') {
            setTabIndex(2)
        } else if (type === '4') {
            setTabIndex(3)
        } else if (type === '5') {
            setTabIndex(4)
        } else {
            setTabIndex(0)
        }
        if (user?.type === 'Su') {
            setAdminRall(false)
        } else {
            setAdminRall(true)
        }
    }, [])

    useEffect(() => {
        if (!luminaires) return
        let totalLocal
        totalLocal = (salesTax ? Number(salesTax) : 0) + (shipping ? Number(shipping) : 0) + (subTotal ? Number(subTotal) : 0)
        setTotal(Math.round(totalLocal))
    }, [salesTax, shipping, subTotal])

    return (
        <>
            <Navbar
                minW="1050px"
                title={`Orders/Supplier/${
                    tabIndex === 0 ? 'Estimate' :
                        tabIndex === 1 ? 'Order' :
                            tabIndex === 2 && shippingPageStatus !== 3 ? 'Shipping Overview' :
                                tabIndex === 2 && shippingPageStatus === 3 ? 'Shipping Details' :
                                    tabIndex === 3 || tabIndex === 4 ? 'Invoice' :
                                        tabIndex === 5 ? 'Fulfilled' :
                                            tabIndex === 6 ? 'Archived'
                                                : ''
                }/#${
                    tabIndex === 2 && shippingPageStatus !== 3 ? estimate?.reference :
                        tabIndex === 2 && shippingPageStatus === 3 ? trackId
                            : estimate?.reference
                }`}/>
            <SignatureModal
                imageUrlAdmin={imageUrlAdmin}
                adminRall={adminRall}
                imageURL={imageURL}
                setImageUrlAdmin={setImageUrlAdmin}
                setImageURL={setImageURL}
                isOpenSignature={isOpenSignature}
                onCloseSignature={onCloseSignature}/>
            <DeliveredModal
                getEstimate={refetch}
                trackId={trackId}
                setShippingPageStatus={setShippingPageStatus}
                onCloseDelivered={onCloseDelivered}
                isOpenDelivered={isOpenDelivered}/>
            <ShareEstimateModal
                shareEstimate={downloadPDF}
                tabIndex={tabIndex}
                loading={loadingPdf}
                refEstimate={refEstimate}
                refOrder={refOrder}
                refShipping={refShipping}
                refInvoice={refInvoice}
                refReceipt={refReceipt}
                estimate={estimate}
                isOpen={isOpen}
                onClose={onClose}/>
            <Header
                shippingDetails={shippingDetails}
                error={error}
                archiveOrder={archiveOrder}
                edit={edit}
                editShippingDetail={editShippingDetail}
                onOpen={onOpen}
                tabIndex={tabIndex}
                createShippingDetail={createShippingDetail}
                estimate={estimate}
                shippingPageStatus={shippingPageStatus}
                trackId={trackId}
                handleImage={handleImage}
                inputRef={inputRef}
                setShippingPageStatus={setShippingPageStatus}
                setEdit={setEdit}
                setTrackId={setTrackId}
                loading={loading}
                user={user}
                loadingPdf={loadingPdf}
                downloadPDF={downloadPDF}
                createInvoice={createInvoice}
                onOpenDelivered={onOpenDelivered}
                createShipping={createShipping}
                createOrder={createOrder}
                createReceipt={createReceipt}
                markFulfilled={markFulfilled}
            />
            <Tabs
                index={tabIndex}
                onChange={(index) => setTabIndex(index)}
                minW="1240px"
                m="auto"
                maxW="1240px">
                <TabList
                    estimate={estimate}
                    tabIndex={tabIndex}
                />
                <TabPanels bg="#F5F5F5" py={10} px={10}>
                    <TabPanel px={20}>
                        <EstimateSuTab
                            isLoading={isLoadingOrder}
                            refererEstimate={refEstimate}
                            user={user}
                            loading={loading}
                            approveEstimate={approveEstimate}
                            tabIndex={tabIndex}
                            luminaires={luminaires}
                            estimate={estimate}
                            subTotal={subTotal}
                            imageURL={imageURL}
                            onOpenSignature={onOpenSignature}
                            currentDate={currentDate}
                            total={total}
                            notes={notes}
                            salesTax={salesTax}
                            shipping={shipping}
                            setNotes={setNotes}
                            setSalesTax={setSalesTax}
                            setShipping={setShipping}/>
                    </TabPanel>
                    <TabPanel px={20}>
                        <OrderSuTab
                            isLoading={isLoadingOrder}
                            refererOrder={refOrder}
                            loading={loading}
                            user={user}
                            authorize={authorize}
                            currentDate={currentDate}
                            imageUrlAdmin={imageUrlAdmin}
                            onOpenSignatureAdmin={onOpenSignature}
                            tabIndex={tabIndex}
                            setRemainderDue={setRemainderDue}
                            setRemainderAmount={setRemainderAmount}
                            setDepositDue={setDepositDue}
                            setDepositAmount={setDepositAmount}
                            remainderDue={remainderDue}
                            remainderAmount={remainderAmount}
                            depositAmount={depositAmount}
                            depositDue={depositDue}
                            luminaires={luminaires}
                            estimate={estimate}
                            subTotal={subTotal}
                            total={total}
                            notes={notes}
                            salesTax={salesTax}
                            shipping={shipping}/>
                    </TabPanel>
                    <TabPanel px={20}>
                        <Shipping
                            setCompanyReu={setCompanyReu}
                            companySu={companySu}
                            companyReu={companyReu}
                            setCompanySu={setCompanySu}
                            onOpenDelivered={onOpenDelivered}
                            user={user}
                            isLoading={isLoadingOrder}
                            editShippingAllow={editShippingAllow}
                            setEditShippingAllow={setEditShippingAllow}
                            setLoading={setLoading}
                            getEstimate={refetch}
                            setCreateShippingAllow={setCreateShippingAllow}
                            createShippingAllow={createShippingAllow}
                            refererShipping={refShipping}
                            edit={edit}
                            setEdit={setEdit}
                            setTrackId={setTrackId}
                            trackId={trackId}
                            setShippingPageStatus={setShippingPageStatus}
                            shippingPageStatus={shippingPageStatus}
                            luminaires={luminaires}
                            callback={(e) => {
                                setShippingDetails(e)
                            }}
                            estimate={estimate}
                            notes={notes}/>
                    </TabPanel>
                    <TabPanel px={20}>
                        <Invoice
                            refetch={refetch}
                            loadingFiles={loadingFiles}
                            getFiles={getFiles}
                            isLoadingOrder={isLoadingOrder}
                            file={invoiceFile}
                            refererInvoice={refInvoice}
                            estimate={estimate}/>
                    </TabPanel>
                    <TabPanel px={20}>
                        <Receipt
                            refetch={refetch}
                            getFiles={getFiles}
                            isLoading={isLoadingOrder}
                            file={receiptFile}
                            refererReceipt={refReceipt}
                            estimate={estimate}/>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    )
}
export default EstimateSu