import {useEffect, useState} from 'react'
import {Tab, TabList, TabPanel, Tabs, TabPanels,} from '@chakra-ui/react'
import Navbar from '../../components/Navbar'
import OrdersTab from "./OrdersTab";
import EstimatesTab from "./EstimatesTab";
import ShippingsTab from "./ShippingsTab";
import InvoicesTab from "./InvoicesTab";
import ReceiptsTab from "./ReceiptsTab";
import Archived from "./Archived";
import Fulfilled from "./Fulfilled";
import {useGetEstimates, useUser} from "../../core/hooks";
import mainStyles from "../../styles/mainStyles";
import moment from "moment/moment";
import {currentDate} from "../../constants/mainFunctions";
import {REU, SU} from "../../constants/user/userEnum";
import httpRequest from "../../core/services/http-request";

const Orders = () => {
    const {user} = useUser()
    const [estimates, setEstimates] = useState([])
    const [tabIndex, setTabIndex] = useState(0)
    const {data, refetch, isLoading} = useGetEstimates({
        type: 'reu',
    })
    const [loadingPdf, setLoadingPdf] = useState(estimates.reduce((acc, item) => ({ ...acc, [item.reference]: false }), {}))
    const {postPdf} = httpRequest()

    useEffect(() => {
        if (!data) return
        setEstimates(data.data.data)
    }, [data])

    const downloadPdf = (estimate, type) => {
        let companySu = null
        let companyReu = null
        let leds = []
        let subTotalLocal = 0
        let lamps = estimate?.luminaires

        if (estimate?.estimateSu?.length > 0){
            if (estimate?.estimateSu[0]?.userSu?.company?.length > 0){
                companySu = estimate?.estimateSu[0]?.userSu?.company[0]
            }else {
                companySu = estimate?.estimateSu[0]?.userSu?.associatedToCompany
            }
        }
        if (estimate?.userReu?.company?.length > 0){
            companyReu = estimate?.userReu?.company[0]
        } else {
            companyReu = estimate?.userReu?.associatedToCompany
        }

        for (let j = 0; j < lamps?.length; j++) {
            let qtyGlob = ((lamps[j]?.recomendedLampQty ? Number(lamps[j]?.recomendedLampQty) : (lamps[j]?.lampPerFixture ? lamps[j]?.lampPerFixture : 1)) *
                (lamps[j]?.recomendedFixtureQty ? Number(lamps[j]?.recomendedFixtureQty) : Number(lamps[j]?.qty)))
            if (lamps[j]?.lampRecomendedAirtable?.length > 0) {
                leds.push(lamps[j])
                lamps[j].error = false
                if (JSON.parse(lamps[j].lampRecomendedAirtable)['SU PPU ($) Custom FE']){
                    subTotalLocal += JSON.parse(lamps[j].lampRecomendedAirtable)['SU PPU ($) Custom FE'] * qtyGlob
                } else {
                    subTotalLocal += ((qtyGlob >= 100 && qtyGlob < 200 ?
                            ((JSON.parse(lamps[j].lampRecomendedAirtable)['Volume Tier 1 PPU ($)'] ? JSON.parse(lamps[j].lampRecomendedAirtable)['Volume Tier 1 PPU ($)']?.replace(/[^\d.]/g, '') : 1))
                            : qtyGlob >= 200 ?
                                ((JSON.parse(lamps[j].lampRecomendedAirtable)['Volume Tier 2 PPU ($)'] ? JSON.parse(lamps[j].lampRecomendedAirtable)['Volume Tier 2 PPU ($)']?.replace(/[^\d.]/g, '') : 1))
                                : JSON.parse(lamps[j].lampRecomendedAirtable)['Price Per Unit ($)']?.replace(/[^\d.]/g, ''))
                        * qtyGlob)
                }
            } else if (lamps[j]?.fixtureRecomendedAirtable?.length > 0) {
                leds.push(lamps[j])
                lamps[j].error = false
                if (JSON.parse(lamps[j].fixtureRecomendedAirtable)['SU PPU ($) Custom FE']){
                    subTotalLocal += JSON.parse(lamps[j].fixtureRecomendedAirtable)['SU PPU ($) Custom FE'] * qtyGlob
                } else {
                    subTotalLocal += ((qtyGlob >= 100 && qtyGlob < 200 ?
                            ((JSON.parse(lamps[j].fixtureRecomendedAirtable)['Volume Tier 1 PPU ($)'] ? JSON.parse(lamps[j].fixtureRecomendedAirtable)['Volume Tier 1 PPU ($)']?.replace(/[^\d.]/g, '') : 1))
                            : qtyGlob >= 200 ?
                                ((JSON.parse(lamps[j].fixtureRecomendedAirtable)['Volume Tier 2 PPU ($)'] ? JSON.parse(lamps[j].fixtureRecomendedAirtable)['Volume Tier 2 PPU ($)']?.replace(/[^\d.]/g, '') : 1))
                                : JSON.parse(lamps[j].fixtureRecomendedAirtable)['Price Per Unit ($)']?.replace(/[^\d.]/g, ''))
                        * qtyGlob)
                }
            }
        }

        let total = (estimate?.salesTax ? Number(estimate?.salesTax) : 0) + (estimate?.shipping ? Number(estimate?.shipping) : 0) + (subTotalLocal ? Number(subTotalLocal) : 0)
        let data = {
            luminaires: leds,
            salesTax: estimate?.salesTax?.toLocaleString('en-US') ?? 0,
            rebates: 0,
            reference: estimate?.reference,
            date: moment(estimate?.createdAt?.date).format('MM/DD/YYYY'),
            notes: estimate?.reuNotes,
            shipping: estimate?.shipping?.toLocaleString('en-US') ?? 0,
            total: total?.toLocaleString('en-US') ?? 0,
            subTotal: Math.round(subTotalLocal)?.toLocaleString('en-US') ?? 0,
            depositAmount: estimate?.depositAmount ? estimate?.depositAmount?.toLocaleString('en-US') : 0,
            depositDue: estimate?.depositDue ?? 0,
            remainderAmount: estimate?.remainderAmount ? estimate?.remainderAmount?.toLocaleString('en-US') : 0,
            remainderDue: estimate?.remainderDue ?? 0,
            remainderDueDate: estimate?.remainderDueDate ?? 0,
            depositPaidDate: estimate?.depositPaidDate  ? estimate?.depositPaidDate : 0,
            companyName: estimate?.userReu?.company?.length > 0 ? estimate?.userReu?.company[0]?.name : estimate?.userReu?.associatedToCompany?.name,
            estimateId: estimate?.id,
            attn: estimate?.userReu?.firstName + ' ' + estimate?.userReu?.lastName,
            address: estimate?.report?.project?.address,
            adminSignature: estimate?.adminSignature,
            adminName: estimate?.userAdminConsultant ? estimate?.userAdminConsultant?.firstName + ' ' + estimate?.userAdminConsultant?.lastName : null,
            adminSignatureDate: estimate?.adminSignatureDate ? estimate?.adminSignatureDate : currentDate,
            address2: estimate?.report?.project?.address2,
            city: estimate?.report?.project?.city + ', ' + estimate?.report?.project?.state + ', ' + estimate?.report?.project?.zipCode,
            phone: estimate?.userReu?.phone,
            companySu: companySu,
            companyReu: companyReu,
            userSignature: estimate?.reuSignature,
            imageURL: estimate?.suSignature,
            suSignatureDate: estimate?.suApproved ? estimate.suSignatureDate : currentDate,
            printName: estimate?.userSu?.firstName + ' ' + estimate?.userSu?.lastName,
            userName: estimate?.userReu ? estimate?.userReu?.firstName + ' ' + estimate?.userReu?.lastName : null,
            orderType: REU,
            orderDocumentType: type,
            userSignatureDate: estimate?.reuSignatureDate ? estimate?.reuSignatureDate : currentDate,
        }
        setLoadingPdf((prevStates) => ({ ...prevStates, [estimate.reference]: true }));
        postPdf('download-estimate', data).then((response) => {
            const blob = new Blob([response.data], {type: 'application/pdf'});
            setLoadingPdf((prevStates) => ({ ...prevStates, [estimate.reference]: false }));
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = type === 1 ? `Estimate #${estimate?.reference} ${moment(estimate?.createdAt?.date).format('MM-DD-YYYY')}.pdf`
                : type === 2 ? `Order #${estimate?.reference} ${moment(estimate?.createdAt?.date).format('MM-DD-YYYY')}.pdf`
                    : type === 3 ? `Shipping #${estimate?.reference} ${moment(estimate?.createdAt?.date).format('MM-DD-YYYY')}.pdf`
                        : type === 4 ? `Invoice #${estimate?.reference} ${moment(estimate?.createdAt?.date).format('MM-DD-YYYY')}.pdf`
                            : `Receipt #${estimate?.reference} ${moment(estimate?.createdAt?.date).format('MM-DD-YYYY')}.pdf`;
            a.click();
            window.URL.revokeObjectURL(url);
        })
            .catch(() => setLoadingPdf((prevStates) => ({ ...prevStates, [estimate.reference]: false })))
    }


    return (
        <>
            <Navbar minW="1050px"
                title={`Orders${user?.type === 'Reu' ? '' : '/Real Estate'}/${
                    tabIndex === 0 ? 'Estimates' :
                        tabIndex === 1 ? 'Orders' :
                            tabIndex === 2 ? 'Shipping' :
                                tabIndex === 3 ? 'Invoices' :
                                    tabIndex === 4 ? 'Receipts' :
                                        tabIndex === 5 ? 'Fulfilled' :
                                            'Archived'
                }`}/>
            <Tabs onChange={(index) => setTabIndex(index)} minW="1240px" m="auto" maxW="1240px">
                <TabList css={mainStyles.orderTabNames}>
                    <Tab>Estimates</Tab>
                    <Tab>Orders</Tab>
                    <Tab>Shipping</Tab>
                    <Tab>Invoices</Tab>
                    <Tab>Receipts</Tab>
                    <Tab>Fulfilled</Tab>
                    <Tab>Archived</Tab>
                </TabList>
                <TabPanels bg="#F5F5F5" px={10}>
                    <TabPanel py={8} px={0}>
                        <EstimatesTab
                            loading={loadingPdf}
                            downloadPdf={downloadPdf}
                            isLoading={isLoading}
                            estimates={estimates}/>
                    </TabPanel>
                    <TabPanel py={8} px={0}>
                        <OrdersTab
                            loading={loadingPdf}
                            downloadPdf={downloadPdf}
                            isLoading={isLoading}
                            estimates={estimates}/>
                    </TabPanel>
                    <TabPanel py={8} px={0}>
                        <ShippingsTab
                            loading={loadingPdf}
                            downloadPdf={downloadPdf}
                            isLoading={isLoading}
                            estimates={estimates}/>
                    </TabPanel>
                    <TabPanel py={8} px={0}>
                        <InvoicesTab
                            loading={loadingPdf}
                            downloadPdf={downloadPdf}
                            isLoading={isLoading}
                            estimates={estimates}/>
                    </TabPanel>
                    <TabPanel py={8} px={0}>
                        <ReceiptsTab
                            loading={loadingPdf}
                            downloadPdf={downloadPdf}
                            isLoading={isLoading}
                            estimates={estimates}/>
                    </TabPanel>
                    <TabPanel py={8} px={0}>
                        <Fulfilled
                            loading={loadingPdf}
                            downloadPdf={downloadPdf}
                            isLoading={isLoading}
                            estimates={estimates}/>
                    </TabPanel>
                    <TabPanel py={8} px={0}>
                        <Archived
                            isLoading={isLoading}
                            getEstimates={refetch}
                            estimates={estimates}/>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    )
}

export default Orders
